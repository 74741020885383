import React, { Component } from 'react';
import { EditorState, RichUtils, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

import htmlToDraft from 'html-to-draftjs';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


class CMSRichTextEditor extends Component {

    constructor(props) {
        super(props);
        const html = this.props.form.initialValues[this.props.name];
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            this.state = {
                editorState,
            };
        }
    }

    onEditorStateChange = (editorState) => {
        this.setState({ editorState });

        if (editorState.getCurrentContent().getPlainText() === '') {
            this.props.form.setFieldValue(this.props.name, "");
            //
        } else {
            // this.props.form.setFieldValue(this.props.name, convertToHTML(editorState.getCurrentContent()));
            this.props.form.setFieldValue(this.props.name, draftToHtml(convertToRaw(editorState.getCurrentContent())));

        }
    };

    onBlurHandler = (event, editorState) => {
        this.props.form.setFieldTouched(this.props.name, true)
    }

    handleKeyCommand = command => {

        const newState = RichUtils.handleKeyCommand(this.state.editorState, command);
        if (newState) {
            this.onChange(newState);
            return true;
        }
        return false;
    };

    onTab = e => {
        const maxDepth = 4;
        this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
    };

    render() {

        return (<>
            <Editor

                toolbar={
                    {
                        //options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history']
                        options: ['inline', 'blockType', 'list', 'link', 'remove', 'history'],
                        // link: { inDropdown: true },

                    }
                }
                editorState={this.state.editorState}
                wrapperClassName="RichEditor-root"
                editorClassName="richEditor"
                onEditorStateChange={this.onEditorStateChange}
                //handleKeyCommand={this.handleKeyCommand}
                // onTab={this.onTab}
                placeholder="Write here.."
                spellCheck={true}
                //onBlur={this.props.form.handleBlur}
                onBlur={this.onBlurHandler}
            />
        </>

        )
    }
}

export default CMSRichTextEditor;