import React, { useState, useReducer } from "react";
import {
  Alert,
  Card,
  Col,
  Row,
  Table,
  Badge,
  Image,
  Button,
  Modal,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayFieldsController from "../../../../UI/modules/fieldsDisplay/ModuleDisplayFieldsController";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
import memberDummyImage from "../../../../../images/memberDummyImage.jpg";
import memberStatusEmailVerificationRequired from "../../../../../images/memberStatusEmailVerificationRequired.png";
import memberStatusSignupRequest from "../../../../../images/memberStatusSignupRequest.png";
import memberStatusRejected from "../../../../../images/memberStatusRejected.png";
import memberStatusRegistrationPaymentPending from "../../../../../images/memberStatusRegistrationPaymentPending.png";
import memberStatusMember from "../../../../../images/memberStatusMember.jpg";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";

import useConfirm from "../../../../../HOC/custom/useConfirm";
import axios from "../../../../../config/axios";
import moduleConfig from "../../moduleConfig";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../../../config/toastNotification";
import { ContainerLoading } from "../../../../UI/loading/ContainerLoading";
import BlockSuccessMessage from "../../../../UI/errorMessages/BlockSuccessMessage";
import FormErrorMessage from "../../../../UI/errorMessages/FormErrorMessage";
import CMSFormInput from "../../../../UI/forms/CMSFormInput";
import { Multiselect } from "multiselect-react-dropdown";
import AddCircleMembers from "./AddCircleMembers";

import MemberItem from "../../../../UI/shared/manageTargetedMembers/MemberItem";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";

const Details = ({ item, members, detailsDispatch, loadItem, loading }) => {
  const { confirm } = useConfirm();
  const [show, setShow] = useState(false);

  const confirmAndRemoveMember = async (memberId) => {
    //console.log(memberId, item._id)
    const isConfirmed = await confirm(
      `Do you really want to remove this member from circle?`
    );

    if (isConfirmed) {
      await removeMember(memberId);
    }
  };

  const removeMember = async (memberId) => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.post(
        `${moduleConfig.url}/remove-member/${item._id}`,
        { memberId }
      );

      loadItem();

      // detailsDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Member removed successfully.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "BLOCK_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };
  const getItems = () => {
    let items = [];

    if (item.sendEmail) {
      items = [
        ...items,
        {
          text: "Email",
          value: item.sendEmail,
          type: "boolean",
          disable: true,
        },
      ];
    }
    if (item.sendNotification) {
      items = [
        ...items,
        {
          text: "Notification",
          value: item.sendNotification,
          type: "boolean",
          disable: true,
        },
      ];
    }

    if (item.sendPushNotification) {
      items = [
        ...items,
        {
          text: "Push Notification",
          value: item.sendPushNotification,
          type: "boolean",
          disable: true,
        },
      ];
    }

    return items;
  };
  const getCircles = () => {
    let items = [];

    {
      item.circles.map((item, i) => {
        if (item.name) {
          items = [
            ...items,
            {
              text: "",
              value: item.name,
              type: "text",
            },
          ];
        }
      });
    }
    return items;
  };
  return (
    <>
      <Row>
        <Col>
          <Card className="item mb-2">
            {loading && (
              <div class="overlay">
                <i class="fas fa-2x fa-sync-alt fa-spin"></i>
              </div>
            )}
            <Card.Header className="ps-1 pe-2 py-0">
              <Card.Title className="d-flex m-0 p-0">
                <div className="d-flex ImageSectionSpacing">
                  {/* <div className="checkboxCol me-2 ms-2 mt-2">
              <input
                type="checkbox"
                id={item._id}
                name={item._id}
                onChange={handleCheckBoxClick}
                checked={isCheck.includes(item._id)}
              />
            </div> */}

                  <div className="position-relative ms-1 me-2">
                    <i className={`fas fa-bell position-relative usericon`}></i>

                    {item.status === "0" || item.status === 0 ? (
                      <i className="fas fa-ban position-absolute ms-1 banned"></i>
                    ) : null}
                  </div>
                  {console.log(item)}

                  <div className="d-flex flex-column align-content-center justify-content-center ms-1">
                    <span className="m-0 text-bold memberName">
                      Send to -{" "}
                      {item.target === "all-members"
                        ? "All Members"
                        : "Selected Circles"}{" "}
                    </span>
                  </div>
                </div>
              </Card.Title>

              <div class="card-tools">
                <div>
                  {item.importance === "low" && (
                    <span class="badge badge-dark me-1">Low Importance</span>
                  )}
                  {item.importance === "medium" && (
                    <span class="badge badge-warning me-1">
                      Medium Importance
                    </span>
                  )}
                  {item.importance === "high" && (
                    <span class="badge badge-danger me-1">High Importance</span>
                  )}
                  <span class="badge badge-light me-1 text-dark">
                    Send Date/Time :{" "}
                    <span class="text-secondary">
                      <ModuleDisplayDateTime value={item.createdAt} />{" "}
                    </span>
                  </span>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="p-2 mb-0">
              <StatsListing items={getItems()} />
              {item.circles && item.circles.length > 0 && (
                <StatsListing heading="Circles" items={getCircles()} />
              )}
              {item.message && (
                <Alert variant="primary mb-0 mt-1 p-1">
                  <span className="text-bold">Message : </span>
                  {item.message}
                </Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Details;
