import React from 'react'
import { Card, Nav } from 'react-bootstrap'
const TabHeadings = ({ lookups }) => {
    return lookups.length > 0 ?
        <Card>
            <Card.Body  className='m-0 p-0'>
                <Nav variant="pills" className="flex-column">
                    {lookups.map((lookup, index) => {
                        return <Nav.Item key={index}>
                            <Nav.Link eventKey={index}>{lookup.name}</Nav.Link>
                        </Nav.Item>
                    })}
                </Nav>
            </Card.Body>
        </Card>
        : null
}

export default TabHeadings