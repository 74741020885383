import React, { useState, useContext, useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import moduleConfig from "./moduleConfig";
import { Store as toast } from "react-notifications-component";
import { updateInitialState, updateReducer } from "./reducers/updateReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import { toastNotification } from "../../../config/toastNotification";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import { isEmpty } from "../../../helpers/CommonHelper";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSFormDate from "../../UI/forms/CMSFormDate";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";
import CMSFormSwitchWithSubmission from "../../UI/forms/CMSFormSwitchWithSubmission";
import useConfirm from "../../../HOC/custom/useConfirm";

const MemberPermissions = () => {
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [formInitialValues, setFormInitialValues] = useState({});
  const { confirm } = useConfirm();



  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let { id } = useParams();
  let navigate = useNavigate();

  let breadcrumbs = {
    listing: {
      label: moduleConfig.title,
      url: `/${moduleConfig.url}`,
    },
    details: {
      label: "Details",
      url: `/${moduleConfig.url}/${id}`,
    },
    update: {
      label: "Permissions",
    },
  };

  const onSubmit = async (data) => {
    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    try {
      const res = await axios.post(`${moduleConfig.url}/${id}`, data); //
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      navigate(`/${moduleConfig.url}`);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} updated successfully.`,
        type: "success",
      });
    } catch (error) {
      //console.log(error.message);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      console.log(errorMessage);

      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  const loadItem = async () => {
    if (id) {
      updateDispatch({
        type: "FETCH_REQUEST",
      });

      try {
        const res = await axios.get(`${moduleConfig.url}/permissions/${id}`); //
        updateDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        updateDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };
  useEffect(() => {
    //console.log("useEffect called for [id]")
    loadItem();

    setBreadcrumb([
      breadcrumbs.listing,
      breadcrumbs.details,
      breadcrumbs.update,
    ]);
  }, [id]);
  useEffect(() => {
    console.log(updateState.data.memberPermissions)
    if (updateState.data.memberPermissions && updateState.data.memberPermissions._id) {
      setFormInitialValues(updateState.data.memberPermissions);
    }
  }, [updateState.data]);


  const getConfirmPermissionText = (permissionName, newValue) => {
    let text = '';
    if (permissionName === 'canManageComments') {
      if (newValue) {
        text = 'Do you really want to allow this member to "Manage Comments"?';
      } else {
        text = 'Do you really want to deny this member to "Manage Comments"?';
      }
    }
    if (permissionName === 'canManageAlbums') {
      if (newValue) {
        text = 'Do you really want to allow this member to "Manage Albums"?';
      } else {
        text = 'Do you really want to deny this member to "Manage Albums"?';
      }
    }
    if (permissionName === 'canManagePhotos') {
      if (newValue) {
        text = 'Do you really want to allow this member to "Manage Photos"?';
      } else {
        text = 'Do you really want to deny this member to "Manage Photos"?';
      }
    }
    if (permissionName === 'canSendNotifications') {
      if (newValue) {
        text = 'Do you really want to allow this member to "Send Notifications"?';
      } else {
        text = 'Do you really want to deny this member to "Send Notifications"?';
      }
    }
    if (permissionName === 'canViewMemberDetails') {
      if (newValue) {
        text = 'Do you really want to allow this member to "View Other Member Details"?';
      } else {
        text = 'Do you really want to deny this member to "View Other Member Details"?';
      }
    }
    if (permissionName === 'hasCMSAccess') {
      if (newValue) {
        text = 'Do you really want to allow this member to "Access CMS"?';
      } else {
        text = 'Do you really want to deny this member to "Access CMS"?';
      }
    }
    return text;
  }



  const handlePermissionChange = async (permissionName, newValue) => {
    console.log("handlePermissionChange called", permissionName, newValue)

    const isConfirmed = await confirm(getConfirmPermissionText(permissionName, newValue));
    console.log("isConfirmed", isConfirmed)

    if (isConfirmed) {
      updateDispatch({
        type: "FORM_SUBMISSION_REQUEST",
      });



      try {
        const res = await axios.post(`${moduleConfig.url}/change-permission`, { permissionName, newValue, id }); //
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });

        toast.addNotification({
          ...toastNotification,
          title: "Success!",
          message: `Action performed successfully.`,
          type: "success",
        });
        return true;
      } catch (error) {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
        toast.addNotification({
          ...toastNotification,
          title: "Action Failed!",
          message: `Action failed, try again later.`,
          type: "danger",
        });
      }
      return false;
    } else {
      return false;
    }
  }

  return (
    <DashboardLayout>
      {updateState.status === "fetching" && updateState.isLoading && (
        <ContainerLoading />
      )}

      {updateState.error && updateState.status === "fetchFailed" ? (
        <BlockErrorMessage error={updateState.error} />
      ) : (
        <>
          <ModuleHeader
            moduleTitle={`Manage Permissions`}
            moduleUrl={moduleConfig.url}
          ></ModuleHeader>

          {
            updateState.data &&
            updateState.data.memberPermissions &&
            !isEmpty(updateState.data.memberPermissions._id) && (
              <section className="content">
                <Row>
                  <Col xl={5} lg={5} md={8} sm={12} xs={12}>
                  <Card>
                  <Card.Body>
                    {updateState.isLoading && (
                      <div class="overlay position-absolute containerLoading">
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                      </div>
                    )}
                    <Row>
                      <Col xl={12}>
                     
                      <CMSFormSwitchWithSubmission
                        name="canManageComments"
                        label="Can manage comments?"
                        initialValue={(updateState.data.memberPermissions.canManageComments === "1") ? true : false}
                        handlePermissionChange={handlePermissionChange}
                      />
                  
                      <CMSFormSwitchWithSubmission
                        name="canManageAlbums"
                        label="Can manage albums?"
                        initialValue={(updateState.data.memberPermissions.canManageAlbums === "1") ? true : false}
                        handlePermissionChange={handlePermissionChange}
                      />
                   
                      <CMSFormSwitchWithSubmission
                        name="canManagePhotos"
                        label="Can manage photos?"
                        initialValue={(updateState.data.memberPermissions.canManagePhotos === "1") ? true : false}
                        handlePermissionChange={handlePermissionChange}
                      />
                 
                      <CMSFormSwitchWithSubmission
                        name="canSendNotifications"
                        label="Can send notifications?"
                        initialValue={(updateState.data.memberPermissions.canSendNotifications === "1") ? true : false}
                        handlePermissionChange={handlePermissionChange}
                      />
                 
                      <CMSFormSwitchWithSubmission
                        name="canViewMemberDetails"
                        label="Can view other member details?"
                        initialValue={(updateState.data.memberPermissions.canViewMemberDetails === "1") ? true : false}
                        handlePermissionChange={handlePermissionChange}
                      />
                  
                      <CMSFormSwitchWithSubmission
                        name="hasCMSAccess"
                        label="Has CMS access?"
                        initialValue={(updateState.data.memberPermissions.hasCMSAccess === "1") ? true : false}
                        handlePermissionChange={handlePermissionChange}
                      />
                       </Col>
                    </Row>


                  </Card.Body>
                </Card>
                  </Col>
                </Row>

              
              </section>
            )}
        </>
      )}
    </DashboardLayout>
  );
};
export default MemberPermissions;
