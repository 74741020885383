import React, { useReducer, useEffect, useState } from "react";
import { Field, Form, Formik, useFormikContext } from "formik";
import * as yup from "yup";
import {
  Alert,
  Card,
  Col,
  Row,
  Table,
  Badge,
  Image,
  Button,
  Modal,
} from "react-bootstrap";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../../../config/toastNotification";
import { ContainerLoading } from "../../../../UI/loading/ContainerLoading";
import BlockSuccessMessage from "../../../../UI/errorMessages/BlockSuccessMessage";
import FormErrorMessage from "../../../../UI/errorMessages/FormErrorMessage";
import CMSFormInput from "../../../../UI/forms/CMSFormInput";
import { Multiselect } from "multiselect-react-dropdown";
import moduleConfig from "../../moduleConfig";
import axios from "../../../../../config/axios";
import {
  addMembersInitialState,
  addMembersReducer,
} from "../../reducers/addMembersReducer";
import BlockErrorMessage from "../../../../UI/errorMessages/BlockErrorMessage";
import CMSFormMultiSelect from "../../../../UI/forms/CMSFormMultiSelect";

const AddCircleMembers = ({ show, setShow, circleId, loadItem }) => {
  const [state, dispatch] = useReducer(
    addMembersReducer,
    addMembersInitialState
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const loadMembers = async () => {
    if (circleId) {
      try {
        const res = await axios.get(
          `${moduleConfig.url}/all-members/${circleId}`
        ); //
        dispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
        //  console.log(res);
      } catch (error) {
        dispatch({
          type: "FETCH_FAILURE",
          payload: "Members loading failed.",
        });
      }
    }
  };

  // const getMembersListForDropDown = () =>{
  //     let objArr = [];

  //     return objArr[];
  // }

  useEffect(() => {
    if (show) {
      // console.log("useEffect called");
      loadMembers();
    }
  }, [show]);

  const onSubmit = async (data, resetForm) => {
    dispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(
        `${moduleConfig.url}/add-members/${circleId}`,
        data
      ); //
      // dispatch({
      //     type: "FORM_SUBMISSION_SUCCESS",
      //     payload: res.data
      // });
      loadItem();
      resetForm();
      handleClose();
      dispatch({
        type: "FORM_INITIAL_STAGE",
      });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Members added successfully.`,
        type: "success",
      });
    } catch (error) {
      dispatch({
        type: "FORM_SUBMISSION_FAILED",
        payload: error,
      });
      setTimeout(() => {
        dispatch({
          type: "FORM_INITIAL_STAGE",
        });
      }, 5000);
    }
  };
  return (
    <Formik
      initialValues={{
        memberIds: [],
      }}
      validationSchema={yup.object().shape({
        memberIds: yup.array().of(yup.string()).min(1, "Select Members"),
      })}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
      onSubmit={async (values, { resetForm }) => {
        console.log(values);
        onSubmit(values, resetForm);
      }}
    >
      {(form) => (
        <Modal show={show} onHide={handleClose}>
          {/* {console.log(form.errors)} */}
          {(state.isLoading) && <div class="overlay">
            <i class="fas fa-2x fa-sync-alt text-white fa-spin"></i>
          </div>}

          {state.error && state.status === "fetchFailed" ? (
            <BlockErrorMessage error={state.error} />
          ) : (
            <>
              <Form onSubmit={form.handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Members in Circle</Modal.Title>
                </Modal.Header>
                {state.status === "submitted" ? (
                  <Modal.Body>
                    <BlockSuccessMessage
                      heading="Done!"
                      message={`Members added to circle successfully.`}
                    />
                  </Modal.Body>
                ) : (
                  <>
                    <Modal.Body>
                      {state.error && <FormErrorMessage error={state.error} />}


                      <CMSFormMultiSelect
                        name="memberIds"
                        label="Select Members"
                        required={true}
                        form={form}
                        options={state.data.items}
                        displayValue="fullName"
                      />
                      {/* 
                      <div className="form-group ">
                        <label htmlFor="memberIds" className={`required ' ${form.touched['memberIds'] && form.errors['memberIds']
                          ? "is-invalidText"
                          : ""
                          }`}>Select Members</label>
                        <Multiselect
                          className={`form-control ${form.errors["memberIds"] ? "is-invalid" : ""
                            }`}
                          name="memberIds"
                          isObject={true}
                          onSelect={(selectedList, selectedItem) => {
                            let values = [];
                            if (selectedList && selectedList.length) {
                              values = selectedList.map((el) => el._id);
                            }
                            form.setFieldValue("memberIds", values);
                          }}
                          onRemove={(selectedList, selectedItem) => {
                            let values = [];
                            if (selectedList && selectedList.length) {
                              values = selectedList.map((el) => el._id);
                            }
                            form.setFieldValue("memberIds", values);
                          }}
                          options={state.data.items}
                          displayValue="fullName"
                          showCheckbox
                        />


                        {form.errors["memberIds"] !== undefined && (
                          <span
                            id="memberIds-error"
                            class="error invalid-feedback "
                            style={{ display: "block" }}
                          >
                            {form.errors["memberIds"]}
                          </span>
                        )}
                      </div> */}
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button variant="primary" type="submit">
                        Submit
                      </Button>
                    </Modal.Footer>
                  </>
                )}
              </Form>
            </>
          )}
        </Modal>
      )}
    </Formik>
  );
};

export default AddCircleMembers;
