import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import LookupValueItem from './LookupValueItem';

const SortableList = (props) => {
    return (
        <ul className="m-0  ps-xl-4 ps-lg-4 ps-md-4 ps-0 mx-xl-0 mx-lg-0 mx-md-0 mx-1 cursor-move">
            {
                props.items.map((item, index) => (
                    <LookupValueItem key={`item-${index}`} index={index} item={item} deleteItem={props.deleteItem} />
                ))
            }
        </ul>
    );
}

export default SortableContainer(SortableList);