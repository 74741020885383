import React, { useState, useEffect } from "react";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";
const CMSFormDisplaySelected = (props) => {
  const {
    label,
    name,
    form,
    options,
    placeholder,
    displayValue,
    required,
    ...rest
  } = props;

  const [screen, setScreen] = useState(options);
  const removeElement = (index) => {
    const newScreen = screen.filter((_, i) => i !== index);
    setScreen(newScreen);
    form.setFieldValue(props.name, newScreen);
  };
  useEffect(() => {
    form.setFieldValue(props.name, screen);
  }, [])

  return (
    <div className="form-group ">
      {label !== "" && (
        <>
          {required === true ? (
            <OverlayTrigger
              key={`tooltip-${name}`}
              placement="right"
              overlay={<Tooltip id={`tooltip-right`}>Required field.</Tooltip>}
            >
              <label
                htmlFor={name}
                className={`required ' ${form.touched[name] && form.errors[name]
                  ? "is-invalidText"
                  : ""
                  }`}
              >
                {" "}
                {label}
              </label>
            </OverlayTrigger>
          ) : (
            <label
              htmlFor={name}
              className={` ${form.touched[name] && form.errors[name] ? "is-invalidText" : ""
                }`}
            >
              {" "}
              {label}
            </label>
          )}
        </>
      )}
      <div className="form-control displayEmails">
        {screen.map((item, index) => (
          <div className="emailItem" key={index}>
            {item}{" "}

            {(screen.length > 1) &&
              <>
                <i
                  class="fa fa-times ms-2 emailRemoveIcon"
                  onClick={() => removeElement(index)}
                ></i>
                &nbsp;
              </>
            }
          </div>
        ))}
      </div>
    </div>
  );
};

export default CMSFormDisplaySelected;
