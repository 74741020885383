import React from 'react'
import DetailsViewHOC from '../../../HOC/module/DetailsViewHOC';
import moduleConfig from "./moduleConfig"
let breadcrumbs = {
    listing: {
        label: moduleConfig.title,
        url: `/${moduleConfig.url}`
    },
    details: {
        label: "Details",
    },
};

const UsefulLinkView = () => {
    const Component = DetailsViewHOC(breadcrumbs);
    return <Component moduleConfig={moduleConfig} />;
}

export default UsefulLinkView;


