import React from "react";
import { Alert,Row,Col } from "react-bootstrap";
import StatsListing from "../shared/infoListing/StatsListing";

function ActivityTimeline() {
  return (
    <>
     <Row>
      <Col>
      <h5 className="mt-3 px-1 text-bold">Recent Activities</h5>
      </Col>

    </Row>
    <div class="timeline mt-2">
      <div class="time-label">
        <span class="text-secondary font-small">10 Feb, 2023</span>
      </div>
      <div>
        <i class="fas fa-thumbs-up  bg-blue"></i>
        <div class="timeline-item">
          <span class="time">
            <i class="fas fa-clock"></i> 12:05
          </span>

          <h3 class="timeline-header">
            <a href="#">Member Name</a> liked the activity
          </h3>

          <div class="timeline-body">
            Etsy doostang zoodles disqus groupon greplin oooj voxy zoodles,
            weebly ning heekya handango imeem plugg dopplr jibjab, movity jajah
            plickers sifteo edmodo ifttt zimbra. Babblely odeo kaboodle quora
            plaxo ideeli hulu weebly balihoo...
          </div>

          {/* <div class="timeline-footer">
            <a class="btn btn-primary btn-sm me-2">Read more</a>
            <a class="btn btn-danger btn-sm">Delete</a>
          </div> */}
        </div>
      </div>
      <div>
        <i class="fas fa-comment  bg-blue"></i>
        <div class="timeline-item">
          <span class="time">
            <i class="fas fa-clock"></i> 11:20
          </span>

          <h3 class="timeline-header">
            <a href="#">Member Name</a> commented on the photo
          </h3>
 
          <div class="timeline-body p-0">
           <Alert variant="secondary mb-0" >Good</Alert>
          </div>

          {/* <div class="timeline-footer">
            <a class="btn btn-primary btn-sm me-2">Read more</a>
            <a class="btn btn-danger btn-sm">Delete</a>
          </div> */}
        </div>
      </div>
      <div>
        <i class="fas fa-user  bg-blue"></i>
        <div class="timeline-item">
          <span class="time">
            <i class="fas fa-clock"></i> 10:00
          </span>

          <h3 class="timeline-header">
            <b>New sign up request</b>
          </h3>

          <div class="timeline-body">
          <StatsListing
              items={[
                { text: "Email", value: "abc@abc.com", type: "email" },
                { text: "Mobile", value: "+97154856233", type: "phone" },
                { text: "Nationality", value: "India", type: "text" },
                { text: "Date of Birth", value: "18-03-1888", type: "text" },
                { text: "Referred By", value: "Member", type: "text" },
                { text: "Referred Name", value: "Member", type: "text" },
              ]}
            />
          </div>

          {/* <div class="timeline-footer">
            <a class="btn btn-primary btn-sm me-2">Read more</a>
            <a class="btn btn-danger btn-sm">Delete</a>
          </div> */}
        </div>
      </div>
      <div>
        <i class="fas fa-clock bg-gray"></i>
      </div>
    </div>
    </>
   
  );
}

export default ActivityTimeline;
