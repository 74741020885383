import React from 'react'
import ModuleDisplayTimeAgo from '../../../../UI/modules/fieldsDisplay/ModuleDisplayTimeAgo'

const MemberMessageItem = ({ message, member }) => {
    return (
        <div className="direct-chat-msg">

            <div className="direct-chat-text memberMessage float-left text-dark text-left">
                <div className="direct-chat-infos  clearfix">
                    <span className="direct-chat-name text-purple float-left">{(member && member.fullName) ? member.fullName : "Member"}</span>
                    {/* <span className="direct-chat-timestamp float-right"><ModuleDisplayTimeAgo value={message.createdAt} /></span> */}
                </div>
                {message.message}
                <p className="direct-chat-timestamp chatTime fs-10  mb-0">{message.createdAt}</p>
            </div>

        </div>

    )
}

export default MemberMessageItem