import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../../images/logo.png";
import { AuthContext } from "../../../../contexts/AuthContext";

const SideBar = () => {
  const { pathname } = useLocation();
  const { userAuthState } = useContext(AuthContext);
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <Link
        to="/"
        className={
          window.location.pathname == "/"
            ? "brand-link bg-primary primary"
            : "brand-link"
        }
      >
        <img
          src={logo}
          alt="Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: "1" }}
        />
        <span className="brand-text font-weight-light">
          {process.env.REACT_APP_WEBSITE_NAME} CMS
        </span>
      </Link>

      <div className="sidebar">
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {userAuthState.user.role === "Admin" && (
              <li className="nav-item ">
                <Link
                  to="/admins"
                  className={`nav-link ${
                    /^(\/)admins(\/)|^(\/)admin/i.test(pathname)
                      ? "active primary primary"
                      : ""
                  }`}
                >
                  <i className="nav-icon fas fa-angle-right" />
                  <p>Admin Users</p>
                </Link>
              </li>
            )}
            <li className="nav-item ">
              <Link
                to="/activities"
                className={`nav-link ${
                  /^(\/)activities(\/)|^(\/)activities|^(\/)activity-comments(\/)|^(\/)activity-comments|^(\/)activity-likes(\/)|^(\/)activity-likes|^(\/)activity-registrations(\/)|^(\/)activity-registrations/i.test(
                    pathname
                  )
                    ? "active primary"
                    : ""
                }`}
              >
                <i className="nav-icon fas fa-angle-right" />
                <p>Activities</p>
              </Link>
            </li>

            <li className="nav-item ">
              <Link
                to="/news-feed"
                className={`nav-link ${
                  /^(\/)news-feed(\/)|^(\/)news-feed|^(\/)news-feed-comments(\/)|^(\/)news-feed-comments/i.test(
                    pathname
                  )
                    ? "active primary"
                    : ""
                }`}
              >
                <i className="nav-icon fas fa-angle-right" />
                <p>News Feed</p>
              </Link>
            </li>

            <li className="nav-item ">
              <Link
                to="/members"
                className={`nav-link ${
                  /^(\/)members(\/)|^(\/)members|^(\/)member-cars(\/)|^(\/)member-cars/i.test(
                    pathname
                  )
                    ? "active primary"
                    : ""
                }`}
              >
                <i className="nav-icon fas fa-angle-right" />
                <p>Members</p>
              </Link>
            </li>

            <li className="nav-item ">
              <Link
                to="/circles"
                className={`nav-link ${
                  /^(\/)circles(\/)|^(\/)circles/i.test(pathname)
                    ? "active primary"
                    : ""
                }`}
              >
                <i className="nav-icon fas fa-angle-right" />
                <p>Circles</p>
              </Link>
            </li>

            <li className="nav-item ">
              <Link
                to="/chat"
                className={`nav-link ${
                  /^(\/)chat(\/)|^(\/)chat/i.test(pathname)
                    ? "active primary"
                    : ""
                }`}
              >
                <i className="nav-icon fas fa-angle-right" />
                <p>Chat</p>
              </Link>
            </li>

            <li className="nav-item ">
              <Link
                to="/gallery-albums"
                className={`nav-link ${
                  /^(\/)gallery-albums(\/)|^(\/)gallery-albums|^(\/)gallery-picture-comments(\/)|^(\/)gallery-picture-comments|^(\/)gallery-picture-likes(\/)|^(\/)gallery-picture-likes/i.test(
                    pathname
                  )
                    ? "active primary"
                    : ""
                }`}
              >
                <i className="nav-icon fas fa-angle-right" />
                <p>Gallery</p>
              </Link>
            </li>
            {(userAuthState.user.role === "Admin" ||
              userAuthState.user.role === "Club President" ||
              userAuthState.user.role === "Club Vice President") && (
              <li className="nav-item ">
                <Link
                  to="/payments"
                  className={`nav-link ${
                    /^(\/)payments(\/)|^(\/)payments/i.test(pathname)
                      ? "active primary"
                      : ""
                  }`}
                >
                  <i className="nav-icon fas fa-angle-right" />
                  <p>Payments</p>
                </Link>
              </li>
            )}
            {(userAuthState.user.role === "Admin" ||
              userAuthState.user.role === "Club President" ||
              userAuthState.user.role === "Club Vice President") && (
              <li className="nav-item ">
                <Link
                  to="/notifications"
                  className={`nav-link ${
                    /^(\/)notifications(\/)|^(\/)notifications/i.test(pathname)
                      ? "active primary"
                      : ""
                  }`}
                >
                  <i className="nav-icon fas fa-angle-right" />
                  <p>Send Notifications</p>
                </Link>
              </li>
            )}
            <li
              className={`nav-item ${
                /^(\/)contact-us(\/)|^(\/)contact-us/i.test(pathname)
                  ? "menu-is-opening menu-open "
                  : ""
              }`}
            >
              <a
                className={`nav-link ${
                  /^(\/)contact-us(\/)|^(\/)contact-us/i.test(pathname)
                    ? "active primary "
                    : ""
                }`}
              >
                <i className="nav-icon fas fa-edit" />
                <p>
                  Online Forms
                  <i className="fas fa-angle-left right" />
                  {/* <span className="badge badge-info right">6</span> */}
                </p>
              </a>
              <ul className="nav nav-treeview">
                <li className="nav-item">
                  <Link
                    to="/contact-us"
                    className={`nav-link ${
                      /^(\/)contact-us(\/)|^(\/)contact-us/i.test(pathname)
                        ? "active primary"
                        : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Contact Us</p>
                  </Link>
                </li>
              </ul>
            </li>

            <li
              className={`nav-item ${
                /^(\/)settings(\/)*|^(\/)lookups(\/)*|^(\/)pages(\/)*|^(\/)countries(\/)*|^(\/)nationalities(\/)*|^(\/)useful-links(\/)*/i.test(
                  pathname
                )
                  ? "menu-is-opening menu-open "
                  : ""
              }`}
            >
              <a
                className={`nav-link ${
                  /^(\/)settings(\/)*|^(\/)lookups(\/)*|^(\/)pages(\/)*|^(\/)countries(\/)*|^(\/)nationalities(\/)*|^(\/)useful-links(\/)*/i.test(
                    pathname
                  )
                    ? "active primary "
                    : ""
                }`}
              >
                <i className="nav-icon fas fa-cog" />
                <p>
                  Settings & Content
                  <i className="fas fa-angle-left right" />
                  {/* <span className="badge badge-info right">6</span> */}
                </p>
              </a>
              <ul className="nav nav-treeview">
                {userAuthState.user.role === "Admin" && (
                  <li className="nav-item">
                    <Link
                      to="/settings"
                      className={`nav-link ${
                        /^(\/)settings(\/)*/i.test(pathname)
                          ? "active primary"
                          : ""
                      }`}
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Settings</p>
                    </Link>
                  </li>
                )}
                {userAuthState.user.role === "Admin" && (
                  <li className="nav-item">
                    <Link
                      to="/lookups"
                      className={`nav-link ${
                        /^(\/)lookups(\/)*/i.test(pathname)
                          ? "active primary"
                          : ""
                      }`}
                    >
                      <i className="far fa-circle nav-icon" />
                      <p>Lookup Values</p>
                    </Link>
                  </li>
                )}

                <li className="nav-item">
                  <Link
                    to="/pages"
                    className={`nav-link ${
                      /^(\/)pages(\/)*/i.test(pathname) ? "active primary" : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Pages & Content</p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/useful-links"
                    className={`nav-link ${
                      /^(\/)useful-links(\/)*/i.test(pathname)
                        ? "active primary"
                        : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Useful Links</p>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/countries"
                    className={`nav-link ${
                      /^(\/)countries(\/)*/i.test(pathname)
                        ? "active primary"
                        : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Countries</p>
                  </Link>
                </li>

                <li className="nav-item">
                  <Link
                    to="/nationalities"
                    className={`nav-link ${
                      /^(\/)nationalities(\/)*/i.test(pathname)
                        ? "active primary"
                        : ""
                    }`}
                  >
                    <i className="far fa-circle nav-icon" />
                    <p>Nationalities</p>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="nav-item ">
              <a
                href="https://admin.ferrariownersclubuae.com/user-manual/index.html"
                target="_blank"
                className="nav-link motLightText"
              >
                <i className="nav-icon fas fa-file" />
                <p>User Manual</p>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default SideBar;
