import React from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayFieldsController from "../../../../UI/modules/fieldsDisplay/ModuleDisplayFieldsController";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
import memberDummyImage from "../../../../../images/memberDummyImage.jpg";
import memberStatusEmailVerificationRequired from "../../../../../images/memberStatusEmailVerificationRequired.png";
import memberStatusSignupRequest from "../../../../../images/memberStatusSignupRequest.png";
import memberStatusRejected from "../../../../../images/memberStatusRejected.png";
import memberStatusRegistrationPaymentPending from "../../../../../images/memberStatusRegistrationPaymentPending.png";
import memberStatusMember from "../../../../../images/memberStatusMember.jpg";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import ModuleDisplayTimeAgo from "../../../../UI/modules/fieldsDisplay/ModuleDisplayTimeAgo";

const Item = ({
  item,
  handleCheckBoxClick,
  isCheck,
  moduleConfig,
  confirmAndDelete,
  confirmAndDisabled,
  confirmAndEnabled,
  customButtonsForItems,
}) => {
  return (
    <Card className="item mb-2">
      <Card.Header className="ps-1 pe-2 py-0">
        <Card.Title className="d-flex m-0 p-0">
          <div className="d-flex ImageSectionSpacing">
            {/* <div className="checkboxCol me-2 ms-2 mt-2">
                            <input
                                type="checkbox"
                                id={item._id}
                                name={item._id}
                                onChange={handleCheckBoxClick}
                                checked={isCheck.includes(item._id)}
                            />
                        </div> */}

            <div className="position-relative me-2">
              <i className={`fas fa-thumbs-up position-relative usericon`}></i>
            </div>

            <div className="d-flex flex-column ms-1">
              <span className="m-0 text-bold memberName">
                {item.member && item.member.fullName
                  ? item.member.fullName
                  : "Member"}
              </span>

              <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                <span className="membershipId">
                  <ModuleDisplayTimeAgo value={item.createdAt} />
                </span>
              </span>
            </div>
          </div>
        </Card.Title>
      </Card.Header>
    </Card>
  );
};

export default Item;
