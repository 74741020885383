import React, { useReducer, useEffect, useContext } from "react";
import {
  Col,
  Row,
  Accordion,
  Image,
  Button,
  Alert,
  Card,
} from "react-bootstrap";
import {
  dashboardInitialState,
  dashboardReducer,
} from "../../modules/activities/reducers/dashboardReducer";
import axiosInstance from "../../../config/axios";
import { FullPageOverlay } from "../loading/FullPageOverlay";
import { AuthContext } from "../../../contexts/AuthContext";
function QuickTabs({ view, setView }) {
  const [detailsState, detailsDispatch] = useReducer(
    dashboardReducer,
    dashboardInitialState
  );
  const { userAuthState } = useContext(AuthContext);
  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });
    try {
      const res = await axiosInstance.get(
        `general/mobile-view-dashboard-stats`
      );
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    loadItem();
  }, []);

  return (
    <>
      {detailsState.isFetching == true && <FullPageOverlay />}
      {detailsState.data && (
        <>
          <Card className="mt-2 mb-0 border-themePrimary">
            <Card.Body className="p-0">
              <Row className="mt-xl-3 mt-lg-3 mt-md-3 mt-1 px-1 ">
                <Col xl={4} lg={6} md={6} sm={6} xs={6} className="flex-1 mb-xl-2">
                  <a
                    href="/members?status=SignupRequest"
                    className="d-flex h-100 w-100"
                  >
                    <div class="dashboard-box bg-linear-primary">
                      <div>
                        <h5 class="dashboard-box-heading">
                          Manage Membership Requests
                        </h5>
                      </div>
                      <div class="dashboard-box-icon">
                        <i class="fa fa-user-plus"></i>
                      </div>
                      <div>
                        <h5 class="dashboard-box-text">
                          {" "}
                          {detailsState.data.signupRequests === 0 ||
                          detailsState.data.signupRequests === "0" ? (
                            "No Pending Requests"
                          ) : (
                            <>
                              <strong className="fs-3">
                                {detailsState.data.signupRequests}
                              </strong>{" "}
                              Signup Request(s)
                            </>
                          )}
                        </h5>
                      </div>
                    </div>
                  </a>
                </Col>
                <Col xl={4} lg={6} md={6} sm={6} xs={6} className="flex-1 mb-xl-2">
                  <a href="/chat?sort=unread" className="d-flex h-100 w-100">
                    <div class="dashboard-box  bg-linear-secondary">
                      <div>
                        <h5 class="dashboard-box-heading">Manage Messages</h5>
                      </div>
                      <div class="dashboard-box-icon">
                        <i class="fa fa-comments"></i>
                      </div>
                      <div>
                        <h5 class="dashboard-box-text">
                          {" "}
                          {detailsState.data.unreadMessages === 0 ||
                          detailsState.data.unreadMessages === "0" ? (
                            "No Unread Message(s)"
                          ) : (
                            <>
                              <strong className="fs-3">
                                {detailsState.data.unreadMessages}
                              </strong>{" "}
                              Unread Message(s)
                            </>
                          )}
                        </h5>
                      </div>
                    </div>
                  </a>
                </Col>
                <Col xl={4} lg={6} md={6} sm={6} xs={6} className="flex-1 mb-xl-2">
                  <a
                    href="/activities?pastActivity=0"
                    className="d-flex h-100 w-100"
                  >
                    <div class="dashboard-box  bg-linear-primary">
                      <div>
                        <h5 class="dashboard-box-heading">Manage Activities</h5>
                      </div>
                      <div class="dashboard-box-icon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div>
                        <h5 class="dashboard-box-text">
                          {" "}
                          {detailsState.data.upcomingActivities === 0 ||
                          detailsState.data.upcomingActivities === "0" ? (
                            "No Upcoming Activities"
                          ) : (
                            <>
                              <strong className="fs-3">
                                {detailsState.data.upcomingActivities}
                              </strong>{" "}
                              Upcoming Activity(s)
                            </>
                          )}
                        </h5>
                      </div>
                    </div>
                  </a>
                </Col>
                <Col xl={4} lg={6} md={6} sm={6} xs={6} className="flex-1 mb-xl-2">
                  <a href="/circles" className="d-flex h-100 w-100">
                    <div class="dashboard-box  bg-linear-secondary">
                      <div>
                        <h5 class="dashboard-box-heading">Manage Circles</h5>
                      </div>
                      <div class="dashboard-box-icon">
                        <i class="fa fa-users"></i>
                      </div>
                    </div>
                  </a>
                </Col>

                <Col xl={4} lg={6} md={6} sm={6} xs={6} className="flex-1 mb-xl-2">
                  <a href="/contact-us" className="d-flex h-100 w-100">
                    <div class="dashboard-box bg-linear-primary">
                      <div>
                        <h5 class="dashboard-box-heading">Contact Us </h5>
                      </div>
                      <div class="dashboard-box-icon">
                        <i class="fa fa-users"></i>
                      </div>
                      <div>
                        <h5 class="dashboard-box-text">
                          {" "}
                          {detailsState.data.contactUsSubmissionsCount === 0 ||
                          detailsState.data.contactUsSubmissionsCount ===
                            "0" ? (
                            "No Submissions"
                          ) : (
                            <>
                              <strong className="fs-3">
                                {detailsState.data.contactUsSubmissionsCount}
                              </strong>{" "}
                              Submission(s)
                            </>
                          )}
                        </h5>
                      </div>
                    </div>
                  </a>
                </Col>
                {(userAuthState.user.role === "Admin" ||
                  userAuthState.user.role === "Club Vice President" ||
                  userAuthState.user.role === "Club President") && (
                  <Col xl={4} lg={6} md={6} sm={6} xs={6} className="flex-1 mb-xl-2">
                    <a href="/payments?status=0" className="d-flex h-100 w-100">
                      <div class="dashboard-box  bg-linear-secondary">
                        <div>
                          <h5 class="dashboard-box-heading">Manage Payments</h5>
                        </div>
                        <div class="dashboard-box-icon">
                          <i class="fa fa-dollar-sign"></i>
                        </div>
                        <div>
                          <h5 class="dashboard-box-text">
                            {" "}
                            {detailsState.data.pendingPayments === 0 ||
                            detailsState.data.pendingPayments === "0" ? (
                              "No Pending Payments"
                            ) : (
                              <>
                                <strong className="fs-3">
                                  {detailsState.data.pendingPayments}
                                </strong>{" "}
                                Pending Payment(s)
                              </>
                            )}
                          </h5>
                        </div>
                      </div>
                    </a>
                  </Col>
                )}
              </Row>

              {view === false && (
                <>
                  <Row className=" my-xl-0 my-lg-0 my-md-0 my-2 p-xl-0 px-lg-0 px-md-0 px-1">
                    <Col>
                      <Button
                        variant="primary"
                        className="float-end"
                        size="sm"
                        onClick={() => setView(true)}
                      >
                        View More
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
}

export default QuickTabs;
