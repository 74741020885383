import React, { useReducer, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import moduleConfig from "./moduleConfig"
import { detailsReducer, detailsInitialState } from "./reducers/detailsReducer";
import Details from "./partials/details/Details";
import { Button } from "react-bootstrap";
import useConfirm from "../../../HOC/custom/useConfirm";
import { Store as toast } from 'react-notifications-component';
import { toastNotification } from '../../../config/toastNotification'


let breadcrumbs = {
    listing: {
        label: moduleConfig.title,
        url: `/${moduleConfig.url}`
    },
    details: {
        label: "Details",
    }
};

const GalleryAlbumView = () => {
    const { setBreadcrumb } = useContext(BreadcrumbContext);
    const [detailsState, detailsDispatch] = useReducer(detailsReducer, detailsInitialState)
    const { confirm } = useConfirm();
    let { id } = useParams();

    const loadItem = async () => {
        if (id) {
            detailsDispatch({
                type: "FETCH_REQUEST"
            });
            try {
                const res = await axios.get(`${moduleConfig.url}/${id}`)//
                detailsDispatch({
                    type: "FETCH_SUCCESS",
                    payload: res.data
                });
            } catch (error) {
                detailsDispatch({
                    type: "FETCH_FAILURE",
                    payload: error
                });
            }
        }
    }

    const confirmAndBlock = async (id) => {
        const isConfirmed = await confirm(`Do you really want to block this ${moduleConfig.singleItemTitle}?`);

        if (isConfirmed) {
            await changeStatus(id, "0")
        }
    }

    const confirmAndUnblock = async (id) => {
        const isConfirmed = await confirm(`Do you really want to unblock this ${moduleConfig.singleItemTitle}?`);

        if (isConfirmed) {
            await changeStatus(id, "1")
        }
    }

    const changeStatus = async (id, status) => {

        console.log("In block Item Function");
        detailsDispatch({
            type: "BLOCK_REQUEST"
        });






        try {
            const res = await axios.post(`${moduleConfig.url}/change-status`, { id, status })
            loadItem();
            // detailsDispatch({
            //     type: "DELETE_SUCCESS"
            // });
            toast.addNotification({
                ...toastNotification,
                title: "Success!",
                message: `${moduleConfig.singleItemTitle} ${(status === "1") ? "restored" : "archived"} successfully.`,
                type: "success",
            });
        } catch (error) {
            detailsDispatch({
                type: "BLOCK_FAILURE",
                payload: error
            });

            setTimeout(
                () => {
                    detailsDispatch({
                        type: "REMOVE_ERROR"
                    })
                }
                , 5000)
        }
    }
    useEffect(() => {
        loadItem();
        setBreadcrumb([
            breadcrumbs.listing,
            breadcrumbs.details
        ])
    }, [id])



    return (<DashboardLayout >


        {(!detailsState.data.item &&detailsState.isFetching)
            ? <ContainerLoading />
            : <>


                {(detailsState.hasError)

                    ? <BlockErrorMessage error={detailsState.error} />
                    : <>

                        {
                            (detailsState.data.item) &&
                            <>

                                <ModuleHeader
                                    moduleTitle={`View ${moduleConfig.singleItemTitle}`}
                                    moduleUrl={moduleConfig.url}
                                ></ModuleHeader>

                                <div className="border mb-1 p-1 bg-light text-white rounded">

                                    <Link title="Update Item" to={`/${moduleConfig.url}/update/${detailsState.data.item._id}`} className="btn btn-primary  border me-1" >
                                        <i className="fas fa-pencil-alt text-white me-1"></i> <span className="text-white">Update</span>
                                    </Link>

                                    {(detailsState.data.item.status === "1" || detailsState.data.item.status === 1) &&
                                         <Link title="Archive Item" onClick={() => confirmAndBlock(detailsState.data.item._id)} className="btn btn-primary  border me-1" >
                                         <i className="fas fa-trash text-white me-1"></i> <span className="text-white">Archive</span>
                                     </Link>
                                    }

                                    {(detailsState.data.item.status === "0" || detailsState.data.item.status === 0) &&
                                        <Link title="Restore Item" onClick={() => confirmAndUnblock(detailsState.data.item._id)} className="btn btn-primary  border me-1" >
                                        <i className="fa fa-arrow-up text-white me-1"></i> <span className="text-white">Restore</span>
                                    </Link>
                                    }


                                    {/* <Link title="View Cars" to={`/member-cars/${detailsState.data.item._id}`} className="btn btn-primary border me-1">
                                        <i className="fas fa-car text-white me-1"></i> <span className="text-white">Cars</span>
                                    </Link> */}

                                </div>

                                <Details
                                    item={detailsState.data.item}
                                    pictures={detailsState.data.pictures}
                                    moduleConfig={moduleConfig}
                                    detailsDispatch={detailsDispatch}
                                    loadItem={loadItem}
                                    loading={detailsState.isFetching}


                                />

                            </>
                        }


                    </>
                }
            </>
        }
    </DashboardLayout>);
}

export default GalleryAlbumView;


