import React, { useReducer, useEffect } from "react";
import {
  Col,
  Row,
  Accordion,
  Image,
  Button,
  Alert,
  Card,
  Badge,
} from "react-bootstrap";
import StatsListing from "../shared/infoListing/StatsListing";
import ActivityRecentRegistrations from "../../modules/activities/partials/details/ActivityRecentRegistrations";
import memberDummyImage from "../../../images/memberDummyImage.jpg";
import memberStatusEmailVerificationRequired from "../../../images/memberStatusEmailVerificationRequired.png";
import memberStatusSignupRequest from "../../../images/memberStatusSignupRequest.png";
import memberStatusRejected from "../../../images/memberStatusRejected.png";
import memberStatusRegistrationPaymentPending from "../../../images/memberStatusRegistrationPaymentPending.png";
import memberStatusMember from "../../../images/memberStatusMember.jpg";
import accountDeleted from "../../../images/accountDelete.png";
import ModuleDisplayDateTime from "../modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayBoolean from "../modules/fieldsDisplay/ModuleDisplayBoolean";
import { getFullName } from "../../../../src/helpers/CommonHelper";
import {
  dashboardInitialState,
  dashboardReducer,
} from "../../modules/activities/reducers/dashboardReducer";
import axiosInstance from "../../../config/axios";
import { FullPageOverlay } from "../loading/FullPageOverlay";
function MemberContent() {
  const [detailsState, detailsDispatch] = useReducer(
    dashboardReducer,
    dashboardInitialState
  );

  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });
    try {
      const res = await axiosInstance.get(`members/stats-for-dashboard`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    loadItem();
  }, []);
  const getItems = (item) => {
    let items = [];
    if (item.email) {
      items = [...items, { text: "Email", value: item.email, type: "email" }];
    }
    if (item.mobile) {
      items = [...items, { text: "Mobile", value: item.mobile, type: "phone" }];
    }
    if (item.nationality) {
      items = [
        ...items,
        { text: "Nationality", value: item.nationality, type: "text" },
      ];
    }
    if (item.dob) {
      items = [
        ...items,
        { text: "Date of Birth", value: item.dob, type: "text" },
      ];
    }
    if (item.referredBy) {
      items = [
        ...items,
        { text: "Referred By", value: item.referredBy, type: "text" },
      ];
    }
    if (item.referredName) {
      items = [
        ...items,
        { text: "Referred Name", value: item.referredName, type: "text" },
      ];
    }
    if (item.about) {
      items = [...items, { text: "About", value: item.about, type: "text" }];
    }
    return items;
  };
  return (
    <>
      {detailsState.isFetching == true && <FullPageOverlay />}
      {detailsState.data && detailsState.data.summary && (
        <>
          <Card className="mt-xl-0 mt-lg-0 mt-md-0 mt-2 mb-xl-0 mb-lg-0 mb-md-0 border-themePrimary">
            <Card.Body className="p-xl-2 p-lg-2 p-md-2 p-0">
              <Row className="showOnMobile bg-themePrimary text-center w-100 mx-auto">
                <Col>
                  <h5 className="my-3 px-1 text-bold text-themePrimary">
                    Members
                  </h5>
                </Col>
              </Row>
              {console.log(detailsState.data.summary)}
              <Row className="mt-xl-3 mt-lg-3 mt-md-3 mt-1 px-1 ">
                <Col xl={2} lg={4} md={4} sm={6} x className="flex-1" xs={12}>
                  <a
                    href="/members?status=SignupRequest"
                    className="d-flex h-100 w-100"
                  >
                    <div class="dashboard-box action bg-linear-one">
                      <div class="dashboard-box-icon">
                        <i class="fa fa-user-plus" aria-hidden="true"></i>
                      </div>
                      <div>
                        {detailsState.data.summary.signupRequest > 0 ? (
                          <h5 class="dashboard-box-heading">
                            <span className="fs-4">
                              {detailsState.data.summary.signupRequest}
                            </span>{" "}
                            Pending Signup Request(s)
                          </h5>
                        ) : (
                          <h5 class="dashboard-box-heading">
                            No Pending signup request(s)
                          </h5>
                        )}
                      </div>
                      <div>
                        <p className="text-white font-small action">
                          Action:{" "}
                          <b>{process.env.REACT_APP_WEBSITE_NAME} Admin</b>
                        </p>
                      </div>
                    </div>
                  </a>
                </Col>
                {detailsState.data.summary.accountDeletionRequested > 0 && (
                  <Col xl={2} lg={4} md={4} sm={6} x className="flex-1" xs={12}>
                    <a
                      href="/members?accountDeleted=1"
                      className="d-flex h-100 w-100"
                    >
                      <div class="dashboard-box action bg-linear-black">
                        <div class="dashboard-box-icon">
                          <i class="fa fa-trash" aria-hidden="true"></i>
                        </div>
                        <div>
                          <h5 class="dashboard-box-heading">
                            <span className="fs-4">
                              {detailsState.data.summary.accountDeletionRequested}
                            </span>{" "}
                            Account Deletion Request(s)
                          </h5>
                        </div>
                        <div>
                          <p className="text-white font-small action">
                            Action:{" "}
                            <b>{process.env.REACT_APP_WEBSITE_NAME} Admin</b>
                          </p>
                        </div>
                      </div>
                    </a>
                  </Col>
                )}
                <Col xl={2} lg={4} md={4} sm={6} xs={12} className="flex-1">
                  <a
                    href="/members?status=EmailVerificationRequired"
                    className="d-flex h-100 w-100"
                  >
                    <div class="dashboard-box action bg-linear-two">
                      <div class="dashboard-box-icon">
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                      </div>

                      <div>
                        <h5 class="dashboard-box-heading">
                          <span className="fs-4">
                            {
                              detailsState.data.summary
                                .emailVerificationRequired
                            }
                          </span>{" "}
                          Pending Email Verification(s)
                        </h5>
                      </div>
                      <div>
                        <p className="text-white font-small action">
                          Action: <b>Member</b>
                        </p>
                      </div>
                    </div>
                  </a>
                </Col>
                {detailsState.data.summary.registrationPaymentPending > 0 && (
                  <Col xl={2} lg={4} md={4} sm={6} xs={12} className="flex-1">
                    <a
                      href="/members?status=registrationPaymentPending"
                      className="d-flex h-100 w-100"
                    >
                      <div class="dashboard-box action bg-linear-three">
                        <div class="dashboard-box-icon">
                          <i class="fa fa-dollar-sign" aria-hidden="true"></i>
                        </div>

                        <div>
                          <h5 class="dashboard-box-heading">
                            <span className="fs-4">
                              {
                                detailsState.data.summary
                                  .registrationPaymentPending
                              }
                            </span>{" "}
                            Registration Payment(s) Pending
                          </h5>
                        </div>
                        <div>
                          <p className="text-white font-small action">
                            Action: <b>Member</b>
                          </p>
                        </div>
                      </div>
                    </a>
                  </Col>
                )}
                {detailsState.data.summary.expiringSoon > 0 && (
                  <Col xl={2} lg={4} md={4} sm={6} xs={12} className="flex-1">
                    <a
                      href="/members?status=expiringSoon"
                      className="d-flex h-100 w-100"
                    >
                      <div class="dashboard-box action bg-linear-four">
                        <div class="dashboard-box-icon">
                          <i class="fa fa-clock" aria-hidden="true"></i>
                        </div>

                        <div>
                          <h5 class="dashboard-box-heading">
                            <span className="fs-4">
                              {detailsState.data.summary.expiringSoon}
                            </span>{" "}
                            Membership(s) Expiring Soon
                          </h5>
                        </div>
                        <div>
                          <p className="text-white font-small action">
                            Action: <b>Member</b>
                          </p>
                        </div>
                      </div>
                    </a>
                  </Col>
                )}

                {detailsState.data.summary.expiringSoon > 0 && (
                  <Col xl={2} lg={4} md={4} sm={6} xs={12} className="flex-1">
                    <a
                      href="/members?status=expiredMembers"
                      className="d-flex h-100 w-100"
                    >
                      <div class="dashboard-box action bg-linear-red">
                        <div class="dashboard-box-icon">
                          <i class="fa fa-hourglass-end"></i>
                        </div>

                        <div>
                          <h5 class="dashboard-box-heading">
                            <span className="fs-4">
                              {detailsState.data.summary.expiredMembers}
                            </span>{" "}
                            Membership(s) Expired
                          </h5>
                        </div>
                        <div>
                          <p className="text-white font-small action">
                            Action: <b>Member</b>
                          </p>
                        </div>
                      </div>
                    </a>
                  </Col>
                )}
                <Col xl={2} lg={4} md={4} sm={6} xs={12} className="flex-1">
                  <span className="d-flex h-100 w-100">
                    <div class="dashboard-box bg-linear-six">
                      <div class="dashboard-box-icon">
                        <i class="fa fa-user-circle"></i>
                      </div>

                      <div>
                        {detailsState.data.summary.loggedIns > 0 ? (
                          <h5 class="dashboard-box-heading">
                            <span className="fs-4">
                              {detailsState.data.summary.loggedIns}
                            </span>{" "}
                            Active Member(s)
                          </h5>
                        ) : (
                          <h5 class="dashboard-box-heading">
                            No Active Member(s)
                          </h5>
                        )}
                      </div>
                    </div>
                  </span>
                </Col>
              </Row>

              <Row className="hideOnMobile">
                <Col>
                  <h5 className="my-3 px-1 text-bold">Recent Member(s)</h5>
                </Col>
                <Col>
                  {detailsState.data &&
                    detailsState.data.summary &&
                    detailsState.data.summary.total > 0 && (
                      <Row className="my-1  px-1">
                        <Col>
                          <Button
                            variant="primary"
                            className="float-end"
                            size="sm"
                            href={`/members`}
                          >
                            View All
                          </Button>
                        </Col>
                      </Row>
                    )}
                </Col>
              </Row>
              {detailsState.data &&
                detailsState.data.recentItems &&
                detailsState.data.recentItems.length <= 0 && (
                  <Alert variant="warning mb-0 mt-1" className="hideOnMobile">
                    No records found.
                  </Alert>
                )}

              {detailsState.data &&
                detailsState.data.recentItems &&
                detailsState.data.recentItems.length > 0 && (
                  <>
                    {detailsState.data.recentItems.map((item, index) => {
                      if (item.email != "test@ahead.pro")
                        return (
                          <Card
                            className={`borderTopRed hideOnMobile memberItem`}
                          >
                            {item.accountDeletionRequested === "1" ||
                            item.accountDeletionRequested === 1 ||
                            item.accountDeletionRequested === true ? (
                              <>
                                <div
                                  className={` ${
                                    item.accountDeletionRequested === "1" ||
                                    item.accountDeletionRequested === 1 ||
                                    item.accountDeletionRequested === true
                                      ? "accountDeleted"
                                      : ""
                                  }`}
                                ></div>
                              </>
                            ) : null}
                            <Card.Header
                              className={`p-0  ${
                                item.accountDeletionRequested === "1" ||
                                item.accountDeletionRequested === 1 ||
                                item.accountDeletionRequested === true
                                  ? "accountDeletedBg"
                                  : ""
                              }`}
                            >
                              <Row className="w-100 mx-auto">
                                <Col className="memberDashboardRow px-0">
                                  <div className="d-flex ImageSectionSpacing">
                                    <div className="position-relative ms-1 me-2">
                                      {item.accountDeletionRequested === "1" ||
                                      item.accountDeletionRequested === 1 ||
                                      item.accountDeletionRequested === true ? (
                                        <div className="accountDeleteIcon">
                                          <i
                                            class="fa fa-trash"
                                            aria-hidden="true"
                                            title={`${getFullName(item)
                                            } requested for account deletion`}
                                          ></i>
                                        </div>
                                      ) : null}

                                      {item.accountDeletionRequested === "1" ||
                                      item.accountDeletionRequested === 1 ||
                                      item.accountDeletionRequested ===
                                        true ? null : (
                                        <>
                                          {item.isLoggedIn === "1" ||
                                          item.isLoggedIn === 1 ||
                                          item.isLoggedIn === true ? (
                                            <div className="activeStatus"></div>
                                          ) : (
                                            <div className="notActiveStatus"></div>
                                          )}
                                        </>
                                      )}

                                      <a href={`/members/${item._id}`}>
                                        {item.image ? (
                                          <Image
                                            src={
                                              process.env
                                                .REACT_APP_BACKEND_URL +
                                              "public/" +
                                              item.image
                                            }
                                            width="52px"
                                            height="52px"
                                            className="border rounded-circle"
                                          />
                                        ) : (
                                          <Image
                                            src={memberDummyImage}
                                            width="45px"
                                            className="border rounded-circle"
                                          />
                                        )}
                                      </a>
                                    </div>

                                    <div className="d-flex flex-column ms-1 ">
                                      <span className="m-0 text-bold memberName memberNameDashboard">
                                        <a
                                          href={`/members/${item._id}`}
                                          className="hoveredText"
                                        >
                                          {item.fullName
                                            ? item.fullName
                                            : getFullName(item)}{" "}
                                        </a>
                                        {item.role === "Board Member" ?    <span className="boardMemberText">{item.role}</span>: item.role === "Club President" ?   <span className="greenBackgroundText">{item.role}</span> :item.role === "Club Vice President" ?<span className="greenBackgroundText">{item.role}</span> :item.role === "Member"? null :<span className="normalText">({item.role})</span> }
                  
                                        {/* {item.hasCMSAccess === "1" && (
                                          <span className="hasCMSAccessText">
                                            Has CMS Access
                                          </span>
                                        )} */}
                                      </span>

                                      <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                                        <span className="membershipId">
                                          {item.membershipId &&
                                            item.membershipId}
                                        </span>

                                        {item.accountDeletionRequested ===
                                          "1" ||
                                        item.accountDeletionRequested === 1 ||
                                        item.accountDeletionRequested ===
                                          true ? (
                                          <span className="memberStatusImageContainer">
                                            <Image
                                              src={accountDeleted}
                                              title="Account Deletion Requested"
                                              className="memberStatusImage"
                                            />
                                          </span>
                                        ) : (
                                          <>
                                            {item.status ===
                                              "EmailVerificationRequired" && (
                                              <span className="memberStatusImageContainer">
                                                <Image
                                                  src={
                                                    memberStatusEmailVerificationRequired
                                                  }
                                                  title="Email Verification Pending"
                                                  className="memberStatusImage"
                                                />
                                              </span>
                                            )}
                                           
                                            {item.status === "Member" && (
                                                <span className="memberStatusImageContainer">
                                                  <Image
                                                    src={memberStatusMember}
                                                    title="Registration Payment Pending"
                                                    className="memberStatusImage"
                                                  />
                                                </span>
                                              )}
                                            {item.status ===
                                              "SignupRequest" && (
                                              <span className="memberStatusImageContainer">
                                                <Image
                                                  src={
                                                    memberStatusSignupRequest
                                                  }
                                                  title="Signup Request"
                                                  className="memberStatusImage"
                                                />
                                              </span>
                                            )}

                                            {item.status ===
                                              "SignupRequestRejected" && (
                                              <span className="memberStatusImageContainer">
                                                <Image
                                                  src={memberStatusRejected}
                                                  title="Signup Request Rejected"
                                                  className="memberStatusImage"
                                                />
                                              </span>
                                            )}

                                            {item.status ===
                                              "RegistrationPaymentPending" && (
                                              <span className="memberStatusImageContainer">
                                                <Image
                                                  src={
                                                    memberStatusRegistrationPaymentPending
                                                  }
                                                  title="Registration Payment Pending"
                                                  className="memberStatusImage"
                                                />
                                              </span>
                                            )}

                                            {item.status === "SignupRequest" &&
                                              item.resubmission === "1" && (
                                                <Badge
                                                  bg="pink"
                                                  className="ms-2 resubmission"
                                                >
                                                  Resubmission
                                                </Badge>
                                              )}
                                          </>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <div>
                                    {item.accountDeletionRequested === "1" ||
                                    item.accountDeletionRequested === 1 ||
                                    item.accountDeletionRequested === true ? (
                                      <Badge
                                        bg="dark"
                                        className="ms-2 me-xl-1 resubmission"
                                      >
                                        Account Deletion Requested
                                      </Badge>
                                    ) : null}
                                    <span className="badge badge-white me-0 text-dark">
                                      Email Verified :{" "}
                                      <span class="text-secondary">
                                        <ModuleDisplayBoolean
                                          value={item.emailVerified}
                                        />{" "}
                                      </span>
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                            </Card.Header>
                            <Card.Body className="p-0">
                              <StatsListing items={getItems(item)} />
                            </Card.Body>
                          </Card>
                        );
                    })}
                  </>
                )}
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
}

export default MemberContent;
