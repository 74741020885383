import React from "react";
import { Alert, Badge, Button, Card, Col, Row, Table } from "react-bootstrap";

import CircleItem from "./CircleItem";

const MemberCircles = ({ items }) => {
  return (
    <div className="member-recent-registrations">
      <Card className="card-primary">
        <Card.Header>
          <Card.Title>
            Circle(s) {" "}
            {items && items.length > 0 && "- Total " + items.length + " item(s)"}
          </Card.Title>
        </Card.Header>
        <Card.Body className="m-0 p-0">
          {!items || items.length <= 0 ? (
            <Alert variant="warning mb-0">No records found.</Alert>
          ) : (
            <Row>
              {items.map((item, index) => {
                return (
                  <Col xl={4} lg={6} md={4} sm={6} xs={12}>
                    <CircleItem index={index} item={item} />
                  </Col>
                );
              })}
            </Row>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default MemberCircles;
