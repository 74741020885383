import React, { useContext, useEffect, useReducer, useState } from "react";
import { BreadcrumbContext } from "../../../../contexts/BreadcrumbContext";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import axios from "../../../../config/axios";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../../config/toastNotification";
import { ContainerLoading } from "../../loading/ContainerLoading";
import ModuleHeader from "../../modules/views/partials/common/ModuleHeader";
import { initialState, manageMembersReducer } from "./manageMembersReducer";
import BlockErrorMessage from "../../errorMessages/BlockErrorMessage";
import MemberDetailsCard from "./MemberItem";
import useConfirm from "../../../../HOC/custom/useConfirm";
import AddMembers from "./AddMembers";
import MemberItem from "./MemberItem";

const ManageTargetedMembers = ({ parentId, moduleConfig }) => {
  const { confirm } = useConfirm();

  const [show, setShow] = useState(false);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [updateState, updateDispatch] = useReducer(manageMembersReducer, initialState);

  const confirmAndRemoveMember = async (memberId) => {
    const isConfirmed = await confirm(
      `Do you really want to remove this member?`
    );

    if (isConfirmed) {
      await removeMember(memberId);
    }
  };

  const removeMember = async (memberId) => {
    updateDispatch({
      type: "BLOCK_REQUEST",
    });

    try {
      const res = await axios.post(
        `${moduleConfig.url}/remove-member-from-target/${parentId}`,
        { memberId }
      );

      loadData();

      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Member removed successfully.`,
        type: "success",
      });
    } catch (error) {
      updateDispatch({
        type: "BLOCK_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        updateDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  const loadData = async () => {
    if (parentId) {
      updateDispatch({
        type: "FETCH_REQUEST",
      });

      try {
        const res = await axios.get(
          `${moduleConfig.url}/targeted-members/${parentId}`
        ); //
        console.log(res)
        updateDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        updateDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };
  useEffect(() => {
    loadData();

    let breadcrumbs = {
      listing: {
        label: moduleConfig.title,
        url: `/${moduleConfig.url}`,
      },
      details: {
        label: "Details",
        url: `/${moduleConfig.url}/${parentId}`,
      },
      featuresSettings: {
        label: "Manage Targeted Members",
      },
    };

    setBreadcrumb([
      breadcrumbs.listing,
      breadcrumbs.details,
      breadcrumbs.featuresSettings,
    ]);
  }, [parentId]);




  return (
    <DashboardLayout>
      {updateState.isLoading && <ContainerLoading />}

      {updateState.error && updateState.status === "fetchFailed" ? (
        <BlockErrorMessage error={updateState.error} />
      ) : (
        <>
          <ModuleHeader
            moduleTitle={`Manage Targeted Members`}
            moduleUrl={moduleConfig.url}
          >
            <Button
              variant="primary"
              size="sm"
              //className="rounded-circle roundButton"
              onClick={() => setShow(true)}
            >
              <i className="fas fa-plus"> </i> <span className="text-white">Add Member(s)</span> 
            </Button>
          </ModuleHeader>

          <Card>
          <Card.Body className="px-1 py-0 ">
              <Row className="circleMembersList">
                {updateState.data && updateState.data.items && updateState.data.items.length > 0 ? (
                  <>
                    {updateState.data.items.map((member, index) => {
                      return (
                        <Col
                          className="mb-0 p-xl-1 mx-xl-1 my-xl-0 my-1"
                          key={index}
                          xs={12}
                          sm={12}
                          md={6}
                          lg={4}
                          xl={3}
                        >
                          <MemberItem
                            member={member}
                            confirmAndRemoveMember={confirmAndRemoveMember}
                          />{" "}
                        </Col>
                      );
                    })}
                  </>
                ) : (
                  <Alert variant="warning mb-0">No records found.</Alert>
                )}
              </Row>
            </Card.Body>
          </Card>
        </>
      )}

      <AddMembers
        show={show}
        setShow={setShow}
        parentId={parentId}
        loadData={loadData}
        moduleConfig={moduleConfig}
      />
    </DashboardLayout>
  );
};
export default ManageTargetedMembers;
