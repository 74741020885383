import React from 'react'
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import ModuleRecordsPerPageDropdown from '../../../../UI/modules/views/partials/common/ModuleRecordsPerPageDropdown';
import ModuleSearchBox from '../../../../UI/modules/views/partials/common/ModuleSearchBox';
import ModuleStatusDropdown from '../../../../UI/modules/views/partials/common/ModuleStatusDropdown';
import ModuleDynamicFilterDropdown from '../../../../UI/modules/views/partials/common/ModuleDynamicFilterDropdown';

const SearchArea = ({ moduleConfig, setFilter, appliedFilters, resetFilters }) => {
    //console.log(appliedFilters);
    return (
        <div className="position-relative">
            <Card className="listingPageHeader p-0 m-0 p-1 mt-2 mb-1">
                <Card.Body className=" p-0 m-0">


                    {
                        (
                            (appliedFilters.limit)
                            || (appliedFilters.q)
                            || (appliedFilters.status)
                            || (appliedFilters.source)
                        ) &&
                        <div div className="resetFilters text-right " onClick={() => resetFilters()}>
                            <span>
                                <i className="fas fa-times" /> Reset Filters
                            </span>
                        </div>
                    }

                    <Row className="mb-0">

                        {
                            (moduleConfig.hasPagination === true && moduleConfig.perPageItemsOptions && moduleConfig.perPageItemsOptions.length > 0) &&
                            <Col className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-0">
                                <ModuleRecordsPerPageDropdown
                                    selectedPerPageItems={moduleConfig.perPageItems}
                                    perPageItemsOptions={moduleConfig.perPageItemsOptions}
                                    setFilter={setFilter}

                                />
                            </Col>
                        }
 
                        <Col className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1">

                            <ModuleDynamicFilterDropdown
                                filterField='source'
                                setFilter={setFilter}
                                appliedFilters={appliedFilters}
                                options={[
                                    {
                                        label: "Select Source...",
                                        value: "all"
                                    },
                                    {
                                        label: "Instagram",
                                        value: "Instagram"
                                    },
                                    {
                                        label: "FOC CMS",
                                        value: "FOC CMS"
                                    }
                                ]}
                            />


                        </Col>

                        {
                            (moduleConfig.hasStatusSearch === true) &&
                            <Col className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0  mt-1">
                                <ModuleStatusDropdown
                                    setFilter={setFilter}
                                    appliedFilters={appliedFilters}
                                    statusOptions={[
                                        {
                                            label: "Select Status...",
                                            value: "all"
                                        },
                                        {
                                            label: "Enabled",
                                            value: "1"
                                        },
                                        {
                                            label: "Disabled",
                                            value: "0"
                                        }
                                    ]}
                                />
                            </Col>
                        }
                        {
                            (moduleConfig.hasSearch === true) &&

                            <Col className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 mt-1">
                                <ModuleSearchBox
                                    setFilter={setFilter}
                                    appliedFilters={appliedFilters}
                                />
                            </Col>

                        }
                    </Row>

                </Card.Body>
            </Card>

        </div>



    )
}

export default SearchArea