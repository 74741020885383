import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string'
import { Form } from 'react-bootstrap';

const ModuleStatusDropdown = ({ setFilter, appliedFilters, statusOptions }) => {
    const [searchStatus, setSearchStatus] = useState('all')
    useEffect(() => {
        setSearchStatus(((appliedFilters.status) ? appliedFilters.status : 'all'))
    }, [appliedFilters])
    return <>

        <Form.Group className="m-0 p-0">
            {/* <Form.Label>Recrods Per Page</Form.Label> */}
            <Form.Select
                value={searchStatus}
                onChange={(e) => {
                   // console.log(e.target.value)
                    setFilter('status', e.target.value);
                }}
            >

                {
                    (statusOptions && statusOptions.length > 0) && (
                        statusOptions.map(({ label, value }, index) => {
                            return <option value={value} key={index}>{label}</option>
                        })


                    )

                }

                {/* <option value="all">Status</option>
                <option value="enabled">Enabled</option>
                <option value="disabled">Disabled</option> */}

            </Form.Select>
        </Form.Group>
    </>
};

export default ModuleStatusDropdown;
