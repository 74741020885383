export const memberDetailsInitialState = {
  data: [],
  isFetching: false,
  hasError: false,
  error: "",
};

export const detailsReducer = (state, action) => {
  switch (action.type) {
    case "FETCH_REQUEST":
      return {
        ...state,
        isFetching: true,
        hasError: false,
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        isFetching: false,
        data: action.payload,
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        hasError: true,
        isFetching: false,
        error: action.payload,
      };
    case "REMOVE_ERROR":
      return {
        ...state,
        hasError: false,
        error: "",
      };
    case "REMOVE_LOADING":
      return {
        ...state,
        isFetching: false,
        hasError: false,
      };
    default:
      return state;
  }
};
