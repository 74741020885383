import React, { useContext } from "react";
import { Tabs, Tab, Alert } from "react-bootstrap";
import ActivityTimeline from "./ActivityTimeline";
import CollapseActivity from "./CollapseActivity";
import ContactUsContent from "./ContactUsContent";
import MemberContent from "./MemberContent";
import UnreadMessagesContent from "./UnreadMessagesContent";
import PaymentContent from "./PaymentContent";
import { ContainerLoading } from "../loading/ContainerLoading";
import ImportantNotifications from "./ImportantNotifications";
import { AuthContext } from "../../../contexts/AuthContext";
import QuickTabs from "./QuickTabs";
function ContentSelectionMenu(props) {
  const { userAuthState } = useContext(AuthContext);
  return (
    <>
      <Tabs defaultActiveKey={props.activeKey} id="uncontrolled-tab-example">
      <Tab eventKey="quick" title="Important Stats" className="p-0">
          <QuickTabs />
        </Tab>
        <Tab eventKey="Members" title="Members" className="p-0">
          <MemberContent />
        </Tab>
        <Tab eventKey="Activities" title="Activities" className="p-0">
          <CollapseActivity />
        </Tab>
        {(userAuthState.user.role === "Admin" ||
          userAuthState.user.role === "Club Vice President" ||
          userAuthState.user.role === "Club President") && (
          <Tab eventKey="Payment" title="Payments" className="p-0">
            <PaymentContent />
          </Tab>
        )}
      
          <Tab eventKey="Contact Us" title="Contact Us" className="p-0">
            <ContactUsContent />
          </Tab>
       
        <Tab eventKey="Important" title="Car Conflicts" className="p-0">
          <ImportantNotifications />
        </Tab>
      </Tabs>
    </>
  );
}

export default ContentSelectionMenu;
