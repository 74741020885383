import React, { useState } from 'react'
import { ErrorMessage } from 'formik';
import CMSRichTextEditor from './CMSRichTextEditor';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';


const CMSFormRichText = (props) => {
    const { name, label, form ,required} = props
    //console.log("CMSFormRichText called")
    return (
        <div>
             {
                (label !== "") &&
                <>
                    {
                        (required === true)
                            ? <OverlayTrigger
                                key={`tooltip-${name}`}
                                placement='right'
                                overlay={
                                    <Tooltip id={`tooltip-right`}>
                                        Required field.
                                    </Tooltip>
                                }
                            ><label htmlFor={name} className={`required ' ${(form.touched[name] && form.errors[name]) ? "is-invalidText" : ""}`}> {label}</label></OverlayTrigger>
                            : <label htmlFor={name} className={` ${(form.touched[name] && form.errors[name]) ? "is-invalidText" : ""}`}> {label}</label>
                    }
                </>
            }

            {/* <label htmlFor={name} className={` ${(form.touched[name] && form.errors[name]) ? "is-invalidText required" : " required"}`}>{label}</label> */}
            <div className={`form-group ${(form.touched[name] && form.errors[name]) ? "is-invalid" : ""}`}>
                <CMSRichTextEditor
                    name={name}
                    id={name + '_editor'}
                    form={form}
                //content={form.initialValues[name]}
                />
            </div>

            {(form.touched[name] && form.errors[name]) &&
                <span id={`${name}-error"`} className="error invalid-feedback"  >
                    <ErrorMessage name={name} />
                </span>
            }

        </div>
    )


}

export default CMSFormRichText