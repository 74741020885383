import React, { useState } from 'react'
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import ModuleRecordsPerPageDropdown from '../../../../UI/modules/views/partials/common/ModuleRecordsPerPageDropdown';
import ModuleSearchBox from '../../../../UI/modules/views/partials/common/ModuleSearchBox';
import ModuleStatusDropdown from '../../../../UI/modules/views/partials/common/ModuleStatusDropdown';
import ModuleDynamicFilterDropdown from '../../../../UI/modules/views/partials/common/ModuleDynamicFilterDropdown';


const SearchArea = ({ moduleConfig, setFilter, appliedFilters, resetFilters }) => {
    //console.log(appliedFilters);


    return (
        <Card className="listingPageHeader p-0 m-0 p-1 mt-2 mb-1">
            <Card.Body className=" p-0 m-0">


                {
                    (
                        (appliedFilters.sort)
                        || (appliedFilters.q)
                        || (appliedFilters.status)
                    ) &&
                    <div div className="resetFilters text-right" onClick={() => resetFilters()}>
                        <span>
                            <i className="fas fa-times" /> Reset Filters
                        </span>
                    </div>
                }

                <Row className="mb-0">

                    {
                          <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-0">
                          <ModuleDynamicFilterDropdown
                                filterField='sort'
                                setFilter={setFilter}
                                appliedFilters={appliedFilters}
                                options={[
                                    {
                                        label: "Sort by Read Status",
                                        value: "all"
                                    },
                                    {
                                        label: "Unread Messages First",
                                        value: "unread"
                                    },
                                    {
                                        label: "Read Messages First",
                                        value: "read"
                                    },
                                ]}
                            />
                      </Col>
                    }
                    {
                        (moduleConfig.hasStatusSearch === true) &&
                        <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1">
                            <ModuleStatusDropdown
                                setFilter={setFilter}
                                appliedFilters={appliedFilters}
                                statusOptions={[
                                    {
                                        label: "Select Type...",
                                        value: "all"
                                    },
                                    {
                                        label: "Members",
                                        value: "Member-Admin"
                                    },
                                    {
                                        label: "Circles",
                                        value: "Circle"
                                    }
                                ]}
                            />
                        </Col>
                    }
                    {
                        (moduleConfig.hasSearch === true) &&
                     
                        <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1">
                            <ModuleSearchBox
                                setFilter={setFilter}
                                appliedFilters={appliedFilters}
                            />
                        </Col>

                    }
                </Row>

            </Card.Body>

        </Card>
    )
}

export default SearchArea