import React, { useReducer, useEffect, useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import moduleConfig from "./moduleConfig";
import {
  detailsReducer,
  memberDetailsInitialState,
} from "./reducers/detailsReducer";
import Details from "./partials/details/Details";
import MemberRecentRegistrations from "./partials/details/MemberRecentRegistrations";
import MemberRecentPayments from "./partials/details/MemberRecentPayments";
import MemberCircles from "./partials/details/MemberCircles";
import useConfirm from "../../../HOC/custom/useConfirm";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import { Button } from "react-bootstrap";
import SignupRequestRejectModal from "./partials/details/SignupRequestRejectModal";
import MemberBlockModal from "./partials/details/MemberBlockModal";
import { AuthContext } from "../../../contexts/AuthContext";
import AddMembershipIdModal from "./partials/details/AddMembershipIdModal";
import ChangeMemberRoleModal from "./partials/details/ChangeMemberRoleModal";
let breadcrumbs = {
  listing: {
    label: moduleConfig.title,
    url: `/${moduleConfig.url}`,
  },
  details: {
    label: "Details",
  },
};

const MemberView = () => {
  const { userAuthState } = useContext(AuthContext);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [detailsState, detailsDispatch] = useReducer(
    detailsReducer,
    memberDetailsInitialState
  );
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [showAddMembershipIdModal, setShowAddMembershipIdModal] =
    useState(false);
  const [showChangeMemberRoleModal, setShowChangeMemberRoleModal] =
    useState(false);
  const [showBlockModal, setShowBlockModal] = useState(false);
  let { id } = useParams();
  const { confirm } = useConfirm();

  // { console.log("userAuthState user ==>", userAuthState.user) }
  const loadItem = async () => {
    if (id) {
      detailsDispatch({
        type: "FETCH_REQUEST",
      });

      try {
        const res = await axios.get(`${moduleConfig.url}/details/${id}`); //
        detailsDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        detailsDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };
  useEffect(() => {
    loadItem();
    setBreadcrumb([breadcrumbs.listing, breadcrumbs.details]);
  }, [id]);

  const confirmAndRespondToSignupRequest = async (id, accept) => {
    const isConfirmed = await confirm(
      `Do you really want to ${accept ? "accept" : "reject"
      } the signup request?`
    );

    if (isConfirmed) {
      if (accept) {
        console.log("accepted");

        setShowAddMembershipIdModal(true);

        //await respondToSignupRequest(id, accept);
      } else {
        console.log("reject");
        setShowRejectionModal(true);
      }
    }
  };

  // const respondToSignupRequest = async (id, accept = false, comments = "") => {
  //   detailsDispatch({
  //     type: "FETCH_REQUEST",
  //   });

  //   try {
  //     const res = await axios.post(
  //       `${moduleConfig.url}/respond-to-signup-request`,
  //       {
  //         id,
  //         accept,
  //         comments,
  //       }
  //     );
  //     loadItem();

  //     toast.addNotification({
  //       ...toastNotification,
  //       title: "Success!",
  //       message: `Signup request is ${accept ? "accepted" : "rejected"}.`,
  //       type: "success",
  //     });
  //   } catch (error) {
  //     detailsDispatch({
  //       type: "FETCH_FAILURE",
  //       payload: error,
  //     });
  //     toast.addNotification({
  //       ...toastNotification,
  //       title: "Error!",
  //       message: `Action failed.`,
  //       type: "danger",
  //     });
  //     setTimeout(() => {
  //       detailsDispatch({
  //         type: "REMOVE_ERROR",
  //       });
  //     }, 5000);
  //   }
  // };

  const confirmAndEmailPassword = async (id, block) => {
    const isConfirmed = await confirm(
      `Do you really want to email the new password?`
    );

    if (isConfirmed) {
      await sendPasswordEmail(id);
    }
  };

  const sendPasswordEmail = async (id) => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.post(
        `${moduleConfig.url}/send-password-email`,
        {
          id,
        }
      );

      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `New password sent successfully.`,
        type: "success",
      });
      detailsDispatch({
        type: "REMOVE_LOADING",
      });
    } catch (error) {
      toast.addNotification({
        ...toastNotification,
        title: "Alert!",
        message: `Action failed, try again later.`,
        type: "danger",
      });

      detailsDispatch({
        type: "REMOVE_LOADING",
      });
    }
  };


  const confirmAndChangeBlockStatus = async (id, block) => {
    const isConfirmed = await confirm(
      `Do you really want to ${block ? "block" : "unblock"} this ${moduleConfig.singleItemTitle
      }?`
    );

    if (isConfirmed) {
      if (block) {
        setShowBlockModal(true);
      } else {
        await blockItem(id, block);
      }
    }
  };

  const blockItem = async (id, block = false) => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.post(`${moduleConfig.url}/block-item`, {
        id,
        block,
      });
      loadItem();
      // detailsDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} ${block ? "blocked" : "unblocked"
          } successfully.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  const confirmAndChangeBoardMemberStatus = async (id, status) => {
    const isConfirmed = await confirm(
      `Do you really want to ${status ? "mark as board member" : "remove from board member"
      }?`
    );

    if (isConfirmed) {
      await changeBoardMemberStatus(id, status);
    }
  };

  const changeBoardMemberStatus = async (id, status = false) => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.post(
        `${moduleConfig.url}/change-status-board-member`,
        {
          id,
          status,
        }
      );
      loadItem();

      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Member is ${status ? "added in" : "removed from"
          } board members list.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  const confirmAndChangeCMSAccessStatus = async (id, status) => {
    const isConfirmed = await confirm(
      `Do you really want to ${status ? "allow" : "deny"
      } CMS access for this member?`
    );

    if (isConfirmed) {
      await changeCMSAccessStatus(id, status);
    }
  };

  const changeCMSAccessStatus = async (id, status = false) => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.post(
        `${moduleConfig.url}/change-status-cms-access`,
        {
          id,
          status,
        }
      );
      loadItem();

      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `CMS access is ${status ? "granted" : "denied"}.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  const confirmAndChangeViewMemberDetailsStatus = async (id, status) => {
    const isConfirmed = await confirm(
      `Do you really want to ${status ? "allow" : "deny"
      } this member to view other member details?`
    );

    if (isConfirmed) {
      await changeViewMemberDetailsStatus(id, status);
    }
  };

  const changeViewMemberDetailsStatus = async (id, status = false) => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.post(
        `${moduleConfig.url}/change-status-view-member-details`,
        {
          id,
          status,
        }
      );
      loadItem();

      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `View member details access is ${status ? "granted" : "denied"
          }.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  return (
    <DashboardLayout>
      {detailsState.isFetching ? (
        <ContainerLoading />
      ) : (
        <>
          {detailsState.hasError ? (
            <BlockErrorMessage error={detailsState.error} />
          ) : (
            <>
              {detailsState.data.item && (
                <>
                  <ModuleHeader
                    moduleTitle={`View ${moduleConfig.singleItemTitle}`}
                    moduleUrl={moduleConfig.url}
                  ></ModuleHeader>

                  <div className="border mb-1 p-1 bg-light text-white rounded">
                    <Link
                      title="Update Item"
                      to={`/${moduleConfig.url}/update/${detailsState.data.item._id}`}
                      className="btn btn-primary  border me-1"
                    >
                      <i className="fas fa-pencil-alt text-white me-1"></i>{" "}
                      <span className="text-white">Update</span>
                    </Link>

                    <Link
                      title="View Cars"
                      to={`/member-cars/${detailsState.data.item._id}`}
                      className="btn btn-primary border me-1"
                    >
                      <i className="fas fa-car text-white me-1"></i>{" "}
                      <span className="text-white">Cars</span>
                    </Link>

                    {detailsState.data.item.status === "Member" && (
                      <>
                        {detailsState.data.item.blockedByAdmin === "0" &&
                          detailsState.data.item.accountDeletionRequested ===
                          "0" && (
                            <>
                              {userAuthState.user.role === "Admin" && (
                                <Link
                                  title="Change Role"
                                  onClick={() =>
                                    setShowChangeMemberRoleModal(true)
                                  }
                                  className="btn btn-primary  border me-1"
                                >
                                  <i className="fas fa-user text-white me-1"></i>{" "}
                                  <span className="text-white">
                                    Change Role
                                  </span>
                                </Link>
                              )}
                              <Link
                                title="View Messages"
                                to={`/chat/member/${detailsState.data.item._id}`}
                                className="btn btn-primary border me-1"
                              >
                                <i className="fas fa-comments text-white me-1"></i>{" "}
                                <span className="text-white">Messages</span>
                              </Link>

                              <Link
                                title="Email Password"
                                onClick={() =>
                                  confirmAndEmailPassword(
                                    detailsState.data.item._id,
                                    true
                                  )
                                }
                                className="btn btn-primary  border me-1"
                              >
                                <i className="fas fa-key text-white me-1"></i>{" "}
                                <span className="text-white">
                                  Email Password
                                </span>
                              </Link>



                              {userAuthState.user.role === "Admin" && (
                                <Link
                                  title="Manage Permissions"
                                  to={`/${moduleConfig.url}/permissions/${detailsState.data.item._id}`}
                                  className="btn btn-primary  border me-1"
                                >
                                  <i className="fas fa-lock text-white me-1"></i>{" "}
                                  <span className="text-white">
                                    Manage Permissions
                                  </span>
                                </Link>
                              )}

                              {(userAuthState.user.role === "Admin" ||
                                userAuthState.user.role === "Club President" ||
                                userAuthState.user.role ===
                                "Club Vice President") && (
                                  <Link
                                    title="Block Member"
                                    onClick={() =>
                                      confirmAndChangeBlockStatus(
                                        detailsState.data.item._id,
                                        true
                                      )
                                    }
                                    className="btn btn-danger  border me-1"
                                  >
                                    <i className="fas fa-ban text-white me-1"></i>{" "}
                                    <span className="text-white">
                                      Block Member
                                    </span>
                                  </Link>
                                )}

                            </>
                          )}
                      </>
                    )}

                    {detailsState.data.item.status === "SignupRequest" && (
                      <>
                        {(userAuthState.user.role === "Admin" ||
                          userAuthState.user.role === "Club President" ||
                          userAuthState.user.role ===
                          "Club Vice President") && (
                            <>
                              <Link
                                title="Accept Request"
                                onClick={() =>
                                  confirmAndRespondToSignupRequest(
                                    detailsState.data.item._id,
                                    true
                                  )
                                }
                                className="btn btn-success  border me-1"
                              >
                                <i className="fas fa-times text-white me-1"></i>{" "}
                                <span className="text-white">Accept Request</span>
                              </Link>

                              <Link
                                title="Reject Request"
                                onClick={() =>
                                  confirmAndRespondToSignupRequest(
                                    detailsState.data.item._id,
                                    false
                                  )
                                }
                                className="btn btn-danger  border me-1"
                              >
                                <i className="fas fa-times text-white me-1"></i>{" "}
                                <span className="text-white">Reject Request</span>
                              </Link>
                            </>
                          )}
                      </>
                    )}

                    {detailsState.data.item.blockedByAdmin === "1" && (
                      <>
                        {(userAuthState.user.role === "Admin" ||
                          userAuthState.user.role === "Club President" ||
                          userAuthState.user.role ===
                          "Club Vice President") && (
                            <Link
                              title="Unblock Request"
                              onClick={() =>
                                confirmAndChangeBlockStatus(
                                  detailsState.data.item._id,
                                  false
                                )
                              }
                              className="btn btn-success  border me-1"
                            >
                              <i className="fas fa-undo text-white me-1"></i>{" "}
                              <span className="text-white">Unblock Member</span>
                            </Link>
                          )}
                      </>
                    )}
                  </div>

                  <Details
                    item={detailsState.data.item}
                    moduleConfig={moduleConfig}
                  />

                  <MemberCircles items={detailsState.data.circles} />

                  <MemberRecentRegistrations
                    items={detailsState.data.registrations}
                  />
                  <MemberRecentPayments items={detailsState.data.payments} />

                  <SignupRequestRejectModal
                    memberId={detailsState.data.item._id}
                    show={showRejectionModal}
                    setShow={setShowRejectionModal}
                    loadItem={loadItem}
                  />

                  <AddMembershipIdModal
                    memberId={detailsState.data.item._id}
                    show={showAddMembershipIdModal}
                    setShow={setShowAddMembershipIdModal}
                    loadItem={loadItem}
                  />

                  <ChangeMemberRoleModal
                    memberId={detailsState.data.item._id}
                    initialValue={detailsState.data.item.role}
                    show={showChangeMemberRoleModal}
                    setShow={setShowChangeMemberRoleModal}
                    loadItem={loadItem}
                  />

                  <MemberBlockModal
                    memberId={detailsState.data.item._id}
                    show={showBlockModal}
                    setShow={setShowBlockModal}
                    loadItem={loadItem}
                  />
                </>
              )}
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default MemberView;
