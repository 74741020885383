import React from 'react'
import SendEmail from '../../mix/SendEmail'



const ModuleDisplayEmail = ({ value }) => {
    return (
        <SendEmail email={value} >{value}</SendEmail>
    )
}

export default ModuleDisplayEmail