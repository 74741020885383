import React, { useReducer, useEffect, useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import moduleConfig from "./moduleConfig"
import { detailsReducer, memberDetailsInitialState } from "./reducers/detailsReducer";
import Details from "./partials/details/Details";



import useConfirm from "../../../HOC/custom/useConfirm";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import { Button } from "react-bootstrap";

import PaymentAcceptModal from "./partials/details/PaymentAcceptModal";
let breadcrumbs = {
    listing: {
        label: moduleConfig.title,
        url: `/${moduleConfig.url}`
    },
    details: {
        label: "Details",
    }
};

const PaymentView = () => {
    const { setBreadcrumb } = useContext(BreadcrumbContext);
    const [detailsState, detailsDispatch] = useReducer(detailsReducer, memberDetailsInitialState)
    const [showAcceptPaymentModal, setShowAcceptPaymentModal] = useState(false);
    let { id } = useParams();
    const { confirm } = useConfirm();

    const loadItem = async () => {
        if (id) {
            detailsDispatch({
                type: "FETCH_REQUEST"
            });

            try {
                const res = await axios.get(`${moduleConfig.url}/details/${id}`)//
                detailsDispatch({
                    type: "FETCH_SUCCESS",
                    payload: res.data
                });
            } catch (error) {
                detailsDispatch({
                    type: "FETCH_FAILURE",
                    payload: error
                });
            }
        }
    }
    useEffect(() => {
        loadItem();
        setBreadcrumb([
            breadcrumbs.listing,
            breadcrumbs.details
        ])
    }, [id])

    return (<DashboardLayout >


        {(detailsState.isFetching)
            ? <ContainerLoading />
            : <>


                {(detailsState.hasError)

                    ? <BlockErrorMessage error={detailsState.error} />
                    : <>

                        {
                            (detailsState.data.item) &&
                            <>

                                <ModuleHeader
                                    moduleTitle={`View ${moduleConfig.singleItemTitle}`}
                                    moduleUrl={moduleConfig.url}
                                ></ModuleHeader>



                                <div className="border mb-1 p-1 bg-light text-white rounded">
                                    <Link
                                        title="Update Item"
                                        to={`/${moduleConfig.url}/update/${detailsState.data.item._id}`}
                                        className="btn btn-primary  border me-1"
                                    >
                                        <i className="fas fa-pencil-alt text-white me-1"></i>{" "}
                                        <span className="text-white">Update</span>
                                    </Link>
                                    {(detailsState.data.item.status === "0") &&
                                        <Link
                                            title="Unblock Request"
                                            onClick={() =>
                                                setShowAcceptPaymentModal(true)
                                            }
                                            className="btn btn-success  border me-1"
                                        >

                                            <i className="fas fa-dollar-sign text-white me-1"></i>{" "}
                                            <span className="text-white">Accept Payment</span>
                                        </Link>
                                    }
                                </div>





                                <Details
                                    item={detailsState.data.item}
                                    moduleConfig={moduleConfig}

                                />

                                <PaymentAcceptModal
                                    memberId={detailsState.data.item.memberId}
                                    paymentId={detailsState.data.item._id}
                                    show={showAcceptPaymentModal}
                                    setShow={setShowAcceptPaymentModal}
                                    loadItem={loadItem}
                                    item={detailsState.data.item}
                                />
                            </>
                        }


                    </>
                }

            </>
        }
    </DashboardLayout>);
}

export default PaymentView;


