import React, { useState, useReducer } from "react";
import * as yup from "yup";
import { Form, Formik } from "formik";
import Modal from "react-bootstrap/Modal";
import BlockSuccessMessage from "../../../UI/errorMessages/BlockSuccessMessage";
import {
  formInitialState,
  formReducer,
} from "../../../../reducers/formReducer";
import FormErrorMessage from "../../../UI/errorMessages/FormErrorMessage";
import CMSFormTextArea from "../../../UI/forms/CMSFormTextArea";
import { Button } from "react-bootstrap";
import { ContainerLoading } from "../../../UI/loading/ContainerLoading";
import axios from "../../../../config/axios";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../../config/toastNotification";


const CreateConflict = ({
  id,
  memberId,
  showCreateConflictModal,
  setShowCreateConflictModal,
  loadAgain,
}) => {
  const [formState, formDispatch] = useReducer(formReducer, formInitialState);

  //  console.log("CreateConflict ==============> ", id, memberId);

  const handleClose = () => setShowCreateConflictModal(false);
  const handleShow = () => setShowCreateConflictModal(true);

  const onSubmit = async (data, resetForm) => {
    formDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    const sleep = (ms) => {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    };

    await sleep(2000);
    try {
      const res = await axios.post(
        `/member-cars/create-conflict/${memberId}/${id}`,
        data
      ); //
      formDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      handleClose();
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Conflict set successfully.`,
        type: "success",
      });
      resetForm();
      formDispatch({
        type: "FORM_INITIAL_STAGE",
      });
      loadAgain();
    } catch (error) {
      formDispatch({
        type: "FORM_SUBMISSION_FAILED",
        payload: error,
      });
      setTimeout(() => {
        formDispatch({
          type: "FORM_INITIAL_STAGE",
        });
      }, 5000);
    }
  };

  return (
    <Formik
      initialValues={{
        conflictReason: "",
        hasConflict: "1",
      }}
      validationSchema={yup.object({
        hasConflict: yup.string().required(),
        conflictReason: yup.string().required("Required").max(200),
      })}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
      onSubmit={async (values, { resetForm }) => {
        console.log(values);

        onSubmit(values, resetForm);

        // const formData = new FormData();
        // for (let name in values) {
        //     if (Array.isArray(values[name])) {
        //         formData.append(name, JSON.stringify(values[name]));
        //     } else {
        //         formData.append(name, values[name]);
        //     }
        // }

        //onSubmit(formData)
      }}
    >
      {(form) => (
        <Modal show={showCreateConflictModal} onHide={handleClose}>
          <Form onSubmit={form.handleSubmit}>
            {formState.isFormSubmitting &&   <div class="overlay">
                <i class="fas fa-2x fa-sync-alt text-white fa-spin"></i>
              </div>}
            <Modal.Header closeButton>
              <Modal.Title>Create Car Conflict</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              {formState.hasSubmissionError && (
                <FormErrorMessage error={formState.formSubmissionError} />
              )}

              <CMSFormTextArea
                name="conflictReason"
                label="Conflict Reason"
                form={form}
                required={true}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default CreateConflict;
