import React, { useContext, useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { formReducer, formInitialState } from "../../../reducers/formReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import moduleConfig from "./moduleConfig";
import DashboardLayout from "../../UI/layouts/DashboardLayout";

import { Card } from "react-bootstrap";
import { Form, Formik } from "formik";
import * as yup from "yup";
import axios from "../../../config/axios";

import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";
import { updateInitialState, updateReducer } from "./reducers/updateReducer";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import { isEmpty } from "../../../helpers/CommonHelper";
let breadcrumbs = {
  listing: {
    label: moduleConfig.title,
    url: `/${moduleConfig.url}`,
  },
  details: {
    label: "",
    url: "",
  },
  featuresSettings: {
    label: "Guest Participation Settings",
  },
};

const ActivityGuestParticipationSettings = () => {
  let { id } = useParams();
  let navigate = useNavigate();

  console.log(id);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [formInitialValues, setFormInitialValues] = useState({});

  const onSubmit = async (data) => {
    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    try {
      const res = await axios.post(
        `${moduleConfig.url}/guest-participation-settings`,
        data
      ); //
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      navigate(`/${moduleConfig.url}/${updateState.data.item.activityId}`);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Guest participation settings updated successfully.`,
        type: "success",
      });
    } catch (error) {
      updateDispatch({
        type: "FORM_SUBMISSION_FAILED",
        payload: error,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STAGE",
        });
      }, 5000);
    }
  };

  const loadItem = async () => {
    if (id) {
      updateDispatch({
        type: "FETCH_REQUEST",
      });

      try {
        const res = await axios.get(
          `${moduleConfig.url}/guest-participation-settings/${id}`
        ); //
        updateDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        updateDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };
  useEffect(() => {
    //console.log("useEffect called for [id]")
    loadItem();

    breadcrumbs.details = {
      label: "Details",
      url: `/${moduleConfig.url}/${id}`,
    };
    setBreadcrumb([
      breadcrumbs.listing,
      breadcrumbs.details,
      breadcrumbs.featuresSettings,
    ]);
  }, [id]);
  useEffect(() => {
    if (updateState.data.item && updateState.data.item._id) {
      setFormInitialValues(updateState.data.item);
    }
  }, [updateState.data]);

  let options = [
    { label: "Yes", value: "1" },
    { label: "No", value: "0" },
  ];

  return (
    <DashboardLayout>
      {!updateState.data.item && updateState.isLoading && <ContainerLoading />}

      {updateState.error && updateState.status === "fetchFailed" ? (
        <BlockErrorMessage error={updateState.error} />
      ) : (
        <>
          <ModuleHeader
            moduleTitle={`Guest Participation Settings`}
            moduleUrl={moduleConfig.url}
          ></ModuleHeader>

          {Object.keys(formInitialValues).length > 0 &&
            updateState.data &&
            updateState.data.item &&
            !isEmpty(updateState.data.item._id) && (
              <Card>
                <Card.Body>
                  {updateState.status === "submitting" && (
                    <div class="overlay position-absolute containerLoading">
                      <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                    </div>
                  )}
                  <Formik
                    initialValues={formInitialValues}
                    validationSchema={yup.object({
                      activityId: yup.string().required("Required"),
                      allowed: yup.string().required("Required"),
                      allowedNumbers: yup
                        .number()
                        .nullable()
                        .transform((cv) => (isNaN(cv) ? undefined : cv))
                        .when("allowed", {
                          is: "1",
                          then: yup
                            .number()
                            .transform((cv) => (isNaN(cv) ? undefined : cv))
                            .positive()
                            .integer()
                            .required(),
                        }),
                      paidParticipation: yup.string().when("allowed", {
                        is: "1",
                        then: yup.string().required(),
                      }),
                      participationFee: yup
                        .number()
                        .transform((cv) => (isNaN(cv) ? undefined : cv))
                        .positive()
                        .integer()
                        .when(["paidParticipation", "allowed"], {
                          is: (paidParticipation, allowed) => {
                            if (allowed === "1" && paidParticipation === "1") {
                              return true;
                            } else return false;
                          },
                          then: yup
                            .number()
                            .transform((cv) => (isNaN(cv) ? undefined : cv))
                            .positive()
                            .integer()
                            .required(),
                        }),
                    })}
                    enableReinitialize={true}
                    validateOnChange={true}
                    validateOnBlur={false}
                    onSubmit={async (values) => {
                      console.log(values);
                      onSubmit(values);
                      // const formData = new FormData();
                      // for (let name in values) {
                      //     if (Array.isArray(values[name])) {
                      //         formData.append(name, JSON.stringify(values[name]));
                      //     } else {
                      //         formData.append(name, values[name]);
                      //     }
                      // }

                      //onSubmit(formData)
                    }}
                  >
                    {(form) => (
                      <Form onSubmit={form.handleSubmit}>
                        {updateState.error && (
                          <FormErrorMessage error={updateState.error} />
                        )}

                        <CMSFormSelect
                          name="allowed"
                          label="Guest Participation Allowed?"
                          required={true}
                          form={form}
                          options={options}
                        />

                        {(form.values["allowed"] === 1 ||
                          form.values["allowed"] === "1") && (
                            <>
                              <CMSFormInput
                                name="allowedNumbers"
                                label="Max. Number of Allowed Guests?"
                                required={true}
                                form={form}
                                type="number"
                              />
                              <CMSFormSelect
                                name="paidParticipation"
                                label="Paid Participation?"
                                required={true}
                                form={form}
                                options={options}
                              />
                              {(form.values["paidParticipation"] === 1 ||
                                form.values["paidParticipation"] === "1") && (
                                  <CMSFormInput
                                    name="participationFee"
                                    label="Participation Fee (AED)"
                                    required={true}
                                    form={form}
                                    type="number"
                                  />
                                )}
                            </>
                          )}

                        <button className="btn btn-primary" type="submit">
                          Submit
                        </button>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            )}
        </>
      )}
    </DashboardLayout>
  );
};
export default ActivityGuestParticipationSettings;
