import React, { useState, useEffect } from 'react'
import { Alert, Button, Card } from 'react-bootstrap'
import axios from '../../../../config/axios'
import CommentItem from './CommentItem'

const RecentComments = ({ moduleUrl, parentId }) => {
    const [state, setState] = useState({
        isLoading: true,
        status: 'fetching', //fetching, fetched, fetchFailed
        error: '',
        data: {}
    })

    const loadData = async () => {
        try {
            const res = await axios.get(`${moduleUrl}/${parentId}`)//
            //console.log(res);
            setState({
                isLoading: false,
                status: 'fetched', //fetching, fetched, fetchFailed
                error: '',
                data: res.data
            })
        } catch (error) {
            setState({
                isLoading: false,
                status: 'fetchFailed', //fetching, fetched, fetchFailed
                error: error,
                data: {}
            })
        }
    }


    useEffect(() => {
        if (parentId) {
            loadData();
        }
    }, [parentId])

    return (
        <div className="recent-comments">
            <Card className="card-primary direct-chat direct-chat-info">
                <Card.Header className='cardHead'>
                    <Card.Title>Recent Comment(s)  {(state.data.items && state.data.items.length > 0) && "- Total " + state.data.items.length  + " item(s)"}</Card.Title>

                    <div class="card-tools">
                        <Button variant="primary" size="xs" href={`/${moduleUrl}/${parentId}`}>View More</Button>
                    </div>


                </Card.Header>
                <Card.Body className="m-0 p-0">

                    {
                        (state.status === 'fetchFailed' && state.error)
                            ? <Alert variant="danger" className="m-0"><b>Error!</b> Fetching items failed.</Alert>
                            : <>

                                {(!state.data.items || state.data.items.length <= 0)
                                    ? <Alert variant="warning" className="m-0">No items Found.</Alert>
                                    :
                                    <>
                                        <div className="direct-chat-messages ">
                                            {
                                                state.data.items.map((comment, index) => {
                                                    return <CommentItem comment={comment} key={index} />
                                                })

                                            }
                                        </div>
                                    </>
                                }

                            </>
                    }





                </Card.Body>


                {(state.isLoading) &&
                    <div class="overlay">
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                    </div>
                }
            </Card>
        </div>
    )
}

export default RecentComments