import React from "react"
import { Field, ErrorMessage } from "formik"
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import Multiselect from "multiselect-react-dropdown";
const CMSFormMultiSelect = (props) => {
    const { label, name, form, options, displayValue, required, ...rest } = props
    return (
        <div className="form-group ">
            {
                (label !== "") &&
                <>
                    {
                        (required === true)
                            ? <OverlayTrigger
                                key={`tooltip-${name}`}
                                placement='right'
                                overlay={
                                    <Tooltip id={`tooltip-right`}>
                                        Required field.
                                    </Tooltip>
                                }
                            ><label htmlFor={name} className={`required ' ${(form.touched[name] && form.errors[name]) ? "is-invalidText" : ""}`}> {label}</label></OverlayTrigger>
                            : <label htmlFor={name} className={` ${(form.touched[name] && form.errors[name]) ? "is-invalidText" : ""}`}> {label}</label>
                    }
                </>
            }

            <Multiselect
                className={`form-control ${form.errors[name] ? "is-invalid" : ""}`}
                name={name}
                isObject={true}
                onSelect={(selectedList, selectedItem) => {
                    let values = [];
                    if (selectedList && selectedList.length) {
                        values = selectedList.map((el) => el._id);
                    }
                    form.setFieldValue(name, values);
                }}
                onRemove={(selectedList, selectedItem) => {
                    let values = [];
                    if (selectedList && selectedList.length) {
                        values = selectedList.map((el) => el._id);
                    }
                    form.setFieldValue(name, values);
                }}
                options={options}
                displayValue={displayValue}
                showCheckbox
                {...rest}
            />

            {(form.errors[name]) &&
                <span id={`${name}-error"`} className="error invalid-feedback" style={{ display: "block" }} >
                    <ErrorMessage name={name} />
                </span>
            }
        </div>
    )

}

export default CMSFormMultiSelect