import React from 'react';
import { Field, ErrorMessage } from "formik"

const CMSFormHidden = (props) => {
    const { name, form, ...rest } = props
    return (

        <Field
            name={name}

            {...rest}
            type="hidden"

        />

    )
};

export default CMSFormHidden;
