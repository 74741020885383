import React from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import ModuleBulkActionsDropdown from "../../../../UI/modules/views/partials/common/ModuleBulkActionsDropdown";
import ModulePagination from "../../../../UI/modules/views/partials/common/ModulePagination";
import ModulePaginationSummary from "../../../../UI/modules/views/partials/common/ModulePaginationSummary";
const ListingFooter = ({
  listingState,
  moduleConfig,
  isCheck,
  performBulkAction,
  setFilter,
  removeBulkAction,
}) => {
  return (
    <>
      {listingState.data &&
        listingState.data.items &&
        listingState.data.items.length > 0 && (
          <Card className="bg-light m-0 my-3 p-1 searchArea">
            <Card.Body className="m-0 p-0">
              <Row className="footer">
                {removeBulkAction !== true && (
                  <Col>
                    {moduleConfig.bulkActions.length > 0 && (
                      <ModuleBulkActionsDropdown
                        bulkActions={moduleConfig.bulkActions}
                        isCheck={isCheck}
                        performBulkAction={performBulkAction}
                      />
                    )}
                  </Col>
                )}
                <Col className="text-center">
                  {moduleConfig.hasPagination === true && (
                    <ModulePagination
                      pagination={
                        listingState.data && listingState.data.pagination
                          ? listingState.data.pagination
                          : {}
                      }
                      setFilter={setFilter}
                    />
                  )}
                </Col>
                <Col className="text-right">
                  {moduleConfig.hasPagination === true && (
                    <ModulePaginationSummary
                      pagination={
                        listingState.data && listingState.data.pagination
                          ? listingState.data.pagination
                          : {}
                      }
                      currentItemsCount={listingState.data.items.length}
                    />
                  )}
                </Col>
                <div className="clearfix"></div>
              </Row>
            </Card.Body>
          </Card>
        )}
    </>
  );
};

export default ListingFooter;
