import React from "react";
import { useParams } from "react-router-dom";
import moduleConfig from "./moduleConfig";
import ManageTargetedCircles from "../../UI/shared/manageTargetedCircles/ManageTargetedCircles";

const NewsFeedManageCircles = () => {
  let { id } = useParams();

  return (
    <ManageTargetedCircles
      parentId={id}
      moduleConfig={moduleConfig}
    />
  );
};
export default NewsFeedManageCircles;
