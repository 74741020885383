import React from "react";
import { Card, Alert, Row, Col, Table } from "react-bootstrap";
import FormErrorMessage from "../../errorMessages/FormErrorMessage";
import ModuleBulkActionsDropdown from "./partials/common/ModuleBulkActionsDropdown";
import ModulePagination from "./partials/common/ModulePagination";
import ModulePaginationSummary from "./partials/common/ModulePaginationSummary";
import ModuleRecordsPerPageDropdown from "./partials/common/ModuleRecordsPerPageDropdown";
import ModuleSearchBox from "./partials/common/ModuleSearchBox";
import ModuleStatusDropdown from "./partials/common/ModuleStatusDropdown";
import ModuleDataTableHeader from "./partials/tableStyle/ModuleDataTableHeader";
import ModuleDataTableRows from "./partials/tableStyle/ModuleDataTableRows";

const ModuleListingViewInTableStyle = ({
  listingState,
  appliedFilters,
  resetFilters,
  moduleConfig,
  setFilter,
  setSorting,
  isCheck,
  setIsCheck,
  isCheckAll,
  setIsCheckAll,
  handleSelectAll,
  deleteItem,
  customButtonsForItems,
  performBulkAction,
}) => {
  return (
    <div className=" listingPage">
      {listingState.data &&
      listingState.data.pagination &&
      listingState.data.pagination.totalItems > 0 ? (
        <>
          <section className="content listing">
            {listingState.hasError === true && (
              <FormErrorMessage error={listingState.error} />
            )}
          </section>

          <div className="mydatatable">
            {!listingState.data.items || listingState.data.items.length <= 0 ? (
              <Alert variant="warning" className="m-0">
                No records found with selected filters.{" "}
                <Alert.Link href="#" onClick={() => resetFilters()}>
                  <i className="fas fa-times" /> Reset Filters
                </Alert.Link>
                .
              </Alert>
            ) : (
              <Card>
                <Card.Body className="p-1">
                  <div className="tabledata ">
                    <Table responsive striped bordered className="mb-2">
                      <ModuleDataTableHeader
                        moduleConfig={moduleConfig}
                        appliedSorting={
                          appliedFilters["sort"] ? appliedFilters["sort"] : ""
                        }
                        setSorting={setSorting}
                        isCheckAll={isCheckAll}
                        handleSelectAll={handleSelectAll}
                      />
                      <tbody>
                        <ModuleDataTableRows
                          moduleConfig={moduleConfig}
                          items={
                            listingState.data && listingState.data.items
                              ? listingState.data.items
                              : []
                          }
                          deleteItem={deleteItem}
                          customButtonsForItems={customButtonsForItems}
                          isCheck={isCheck}
                          setIsCheck={setIsCheck}
                          setIsCheckAll={setIsCheckAll}
                          pagination={
                            listingState.data && listingState.data.pagination
                              ? listingState.data.pagination
                              : {}
                          }
                        />
                      </tbody>
                    </Table>
                  </div>

                  {listingState.data &&
                    listingState.data.items &&
                    listingState.data.items.length && (
                      <Row className="footer">
                        <Col
                          className={
                            moduleConfig.hasPagination === true
                              ? "col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-0"
                              : "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-0"
                          }
                        >
                          {moduleConfig.bulkActions.length > 0 && (
                            <ModuleBulkActionsDropdown
                              bulkActions={moduleConfig.bulkActions}
                              isCheck={isCheck}
                              performBulkAction={performBulkAction}
                            />
                          )}
                        </Col>
                        <Col className={
                            moduleConfig.hasPagination === true
                              ? "col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-0"
                              : "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-0"
                          }>
                          {moduleConfig.hasPagination === true && (
                            <ModulePagination
                              pagination={
                                listingState.data &&
                                listingState.data.pagination
                                  ? listingState.data.pagination
                                  : {}
                              }
                              setFilter={setFilter}
                            />
                          )}
                        </Col>
                        <Col className={
                            moduleConfig.hasPagination === true
                              ? "col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-0 footerTextCenter"
                              : "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-0 footerTextCenter"
                          }>
                          {moduleConfig.hasPagination === true && (
                            <ModulePaginationSummary
                              pagination={
                                listingState.data &&
                                listingState.data.pagination
                                  ? listingState.data.pagination
                                  : {}
                              }
                              currentItemsCount={listingState.data.items.length}
                            />
                          )}
                        </Col>
                        <div className="clearfix"></div>
                      </Row>
                    )}
                </Card.Body>
              </Card>
            )}
          </div>
        </>
      ) : (
        <Alert variant="warning" className="m-0">
          No records found.
        </Alert>
      )}
    </div>
  );
};

export default ModuleListingViewInTableStyle;
