import React, { useEffect } from 'react'
import ApplicationRoutes from './config/ApplicationRoutes';
import AuthContextProvider from "./contexts/AuthContext";
import ConfirmContextProvider from "./contexts/ConfirmContext";
import BreadcrumbContextProvider from "./contexts/BreadcrumbContext";
import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import 'react-fancybox/lib/fancybox.css'
//import 'react-image-lightbox/style.css';
import './App.css';
import logo from './images/ahead-hash-icon-black.png'
import ConfirmDialog from './components/UI/mix/confirmDialog';
import "./helpers/yupAdditionalValidations";
import socket from "./config/socket";

function App() {

  useEffect(() => {


    // client-side
    // socket.on("connect", () => {
    //   console.log(socket.id); // x8WIv7-mJelg7on_ALbx
    // });

    socket.on("connect_error", (error) => {
      console.log("Socket Error:", error)
    });

    socket.on('connect', function () {
      console.log('Socket Connected 1111: ', socket.connected, socket.id);


      // socket.emit("createRoom", 'Testing');



      // socket.on("roomsList", (rooms) => {
      //   console.log("roomsList", rooms)
      //   //setRooms(rooms);
      // });

      // socket.on("socketTest", (data) => {
      //   console.log(data)
      //   //setRooms(rooms);
      // });
    });


    return () => {
      socket.disconnect();
    }


  }, [socket])

  return (

    <AuthContextProvider>
      <BreadcrumbContextProvider>
        <ConfirmContextProvider>

          <div className="wrapper">
            <ReactNotifications />
            <div className="preloader flex-column justify-content-center align-items-center">
              <img className="animation__shake" src={logo} alt="Logo" />
            </div>


            <ApplicationRoutes />


          </div>
          <ConfirmDialog />
        </ConfirmContextProvider>
      </BreadcrumbContextProvider>
    </AuthContextProvider>

  );
}

export default App;
