import React, { useState } from "react";
import { Alert, Card, Col, ListGroup, Row } from "react-bootstrap";
import ReactFancyBox from "react-fancybox";
import { Link } from "react-router-dom";
import image from "../../../../../images/memberDummyImage.jpg";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
import moduleConfig from "../../moduleConfig";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import VideoExpanded from "./VideoExpanded";
import { MdOutlinePlayCircleFilled } from "react-icons/md";
const GalleryPicture = ({
  picture,
  confirmAndRemovePicture,
  confirmAndArchivePicture,
  confirmAndRestorePicture,
}) => {
  const [show, setShow] = useState(false);
  const [videoShow, setVideoShow] = useState(false);
  return (
    <div className="card ">
      <div className="card-header  p-0 d-flex flex-column">
        <div class="card-tools w-100  mb-2">
           <div className={`${picture.type} galleryType`}>
              {picture.type}
            </div>
          <div className="mt-1 ">
           
            {picture.createdAt && (
              <span class="badge badge-light me-1 float-end text-dark">
                Created Time :{" "}
                <span class="text-secondary">
                  <ModuleDisplayDateTime value={picture.createdAt} />{" "}
                </span>
              </span>
            )}
          </div>
        </div>
        <h3 className="card-title px-3 pictureTitle">{picture.title}</h3>
        <h3 className="uploadedBy px-3">
          Uploaded By {picture.memberId ? picture.memberName : "Admin"}
        </h3>

      </div>
    

      <div className="card-body d-flex align-items-center justify-content-center p-0">
        {picture.type === "video" ? (
          <div class="galleryVideo">
            <div class="holdsTheIframe" onClick={() => setVideoShow(true)}>
              <div className="videoIcon"><MdOutlinePlayCircleFilled className="videoIconSmall" /></div>
              <img src={process.env.REACT_APP_BACKEND_URL + "public/" + picture.image} onClick={() => setVideoShow(true)} className="galleryImageVideo" />
            </div>
          </div>

        ) :
          <div className="galleryImage2 photoInsideAlbum">
            <ReactFancyBox
              thumbnail={
                process.env.REACT_APP_BACKEND_URL + "public/" + picture.image
              }
              image={
                process.env.REACT_APP_BACKEND_URL + "public/" + picture.image
              }
              caption={picture.title}
            />
          </div>}

        {(picture.status === "0" || picture.status === 0) && (
          <div class="ribbon-wrapper">
            <div class="ribbon bg-danger">Archived</div>
          </div>
        )}
      </div>

      {/* <ListGroup variant="flush">
                <ListGroup.Item>
                    <span>Like(s)</span>  <span class="badge badge-primary">23</span>
                </ListGroup.Item>
                <ListGroup.Item>Comment(s) 23</ListGroup.Item>
            </ListGroup> */}

      {/* <div className="card-body likesCommentsLinks d-flex align-items-center    m-0 ">
        <Card.Link
          href={`/${moduleConfig.url}/picture-details/${picture._id}`}
          className="d-flex align-items-center"
        >
          {" "}
          <i className="fas fa-heart"></i>{" "}
          <span class="badge badge-secondary ms-2">{picture.likesCount}</span>
        </Card.Link>
        <Card.Link
          href={`/${moduleConfig.url}/picture-details/${picture._id}`}
          className="d-flex align-items-center justify-content-end ms-5 ps-3"
        >
          {" "}
          <i className="fas fa-comment"></i>{" "}
          <span class="badge badge-secondary ms-2">{picture.commentsCount}</span>
        </Card.Link>
       
      </div> */}
      <Row className="mx-1 py-3">
        <Col md={6} lg={6} xs={6} className="">
          <Card.Link
            href={`/${moduleConfig.url}/picture-details/${picture._id}`}
            className="d-flex align-items-center"
          >
            {" "}
            <i className="fas fa-heart"></i>{" "}
            <span class="badge badge-secondary ms-2">{picture.likesCount}</span>
          </Card.Link>
        </Col>
        <Col md={6} lg={6} xs={6} className="">
          <Card.Link
            href={`/${moduleConfig.url}/picture-details/${picture._id}`}
            className="d-flex align-items-center justify-content-end ms-5 ps-3"
          >
            {" "}
            <i className="fas fa-comment"></i>{" "}
            <span class="badge badge-secondary ms-2">
              {picture.commentsCount}
            </span>
          </Card.Link>
        </Col>
      </Row>

      <div className="card-footer text-center">
        <Link
          to={`/${moduleConfig.url}/picture-details/${picture._id}`}
          className="btn btn-outline-secondary  listingActionIcon mx-2 "
          title="View Item details"
        >
          <i className="fas fa-eye"></i>
        </Link>

        <span
          title="Delete Item"
          onClick={() => {
            confirmAndRemovePicture(picture._id);
          }}
          className="btn btn-outline-secondary mx-2 "
        >
          <i className="fas fa-trash"></i>
        </span>

        {(picture.status === "1" || picture.status === 1) && (
          <span
            title="Block Item"
            onClick={() => {
              confirmAndArchivePicture(picture._id);
            }}
            className="btn btn-outline-secondary  listingActionIcon mx-2 "
          >
            <i className="fas fa-ban"></i>
          </span>
        )}

        {(picture.status === "0" || picture.status === 0) && (
          <span
            title="Remove Ban"
            onClick={() => {
              confirmAndRestorePicture(picture._id);
            }}
            className="btn btn-outline-secondary listingActionIcon mx-2 "
          >
            <i className="fas fa-undo"></i>
          </span>
        )}
      </div>
      {videoShow && (
        <VideoExpanded
          videoShow={videoShow}
          setVideoShow={setVideoShow}
          src={picture.video}
        />
      )}
    </div>
  );
};

export default GalleryPicture;
