import React, { useReducer, useEffect, useContext, useState } from "react";
import moduleConfig from "./moduleConfig";
import { Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import axios from "../../../config/axios";
import { createReducer, createInitialState } from "./reducers/createReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import ModuleCreateView from "../../UI/modules/views/ModuleCreateView";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSFormController from "../../UI/forms/CMSFormController";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";
import CMSFormCheckBoxes from "../../UI/forms/CMSFormCheckBoxes";
import { listingInitialState, listingReducer } from "./reducers/listingReducer";
import { Multiselect } from "multiselect-react-dropdown";
import CMSFormMultiSelect from "../../UI/forms/CMSFormMultiSelect";
import CMSFormTextArea from "../../UI/forms/CMSFormTextArea";
let breadcrumbs = {
  listing: {
    label: `Notifications`,
    url: `/${moduleConfig.url}`,
  },
  create: {
    label: "Create",
  },
};
const NotificationsCreate = () => {
  const [createState, createDispatch] = useReducer(
    createReducer,
    createInitialState
  );
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let navigate = useNavigate();
  const onSubmit = async (data) => {
    createDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(`${moduleConfig.url}`, data); //
      console.log(res);
      createDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });

      navigate(`/${moduleConfig.url}`);
      toast.addNotification({
        ...toastNotification,
        name: "Success!",
        message: `Notification sent successfully.`,
        type: "success",
      });
    } catch (error) {
      console.log(error.response);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.message : error.message;

      createDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        createDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  const loadData = async () => {
    try {
      const res = await axios.get(`members/create-data`); //
      createDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      createDispatch({
        type: "FETCH_FAILURE",
        payload: "Date loading failed.",
      });
    }
  };
  const loadItems = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.get(`circles`); //
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };

  useEffect(() => {
    loadItems();
    loadData();
    setBreadcrumb([breadcrumbs.listing, breadcrumbs.create]);
  }, []);
  return (
    <DashboardLayout>
      {createState.status == "fetching" && createState.isLoading && (
        <ContainerLoading />
      )}
      {createState.status === "fetchFailed" ? (
        <BlockErrorMessage error={createState.error} />
      ) : (
        <>
          {/* {console.log(createState.data)} */}
          {createState.status !== "fetching" && (
            <>
              <ModuleHeader
                modulename={`Create ${moduleConfig.singleItemname}`}
                moduleUrl={moduleConfig.url}
              ></ModuleHeader>

              <section className="content">
                <Card>
                  <Card.Body>
                    {createState.isLoading && (
                      <div class="overlay position-absolute containerLoading">
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                      </div>
                    )}
                    <Formik
                      initialValues={{
                        importance: "medium",
                        target: "all-members",
                        targetedCircles: [],
                        message: "",
                        typeOfNotification: [],
                        sendEmail: "0",
                        sendNotification: "0",
                        sendPushNotification: "0",
                      }}
                      validationSchema={yup.object({
                        importance: yup.string().required("Required"),
                        target: yup.string().required("Required"),
                        message: yup
                          .string()
                          .required("Required")
                          .max(230, "maximum 230 characters allowed"),
                        targetedCircles: yup.array().when("target", {
                          is: (target) => target === "selected-circles",
                          then: yup
                            .array()
                            .required("Required")
                            .min(1, "At-least one should be selected."),
                        }),
                        typeOfNotification: yup
                          .array()
                          .min(1, "At-least one should be selected.")
                          .of(yup.string().required())
                          .required(),
                      })}
                      enableReinitialize={true}
                      validateOnChange={true}
                      validateOnBlur={false}
                      onSubmit={(values) => {
                        if (values.typeOfNotification.includes("sendEmail")) {
                          values.sendEmail = "1";
                        }
                        if (
                          values.typeOfNotification.includes("sendNotification")
                        ) {
                          values.sendNotification = "1";
                        }
                        if (
                          values.typeOfNotification.includes(
                            "sendPushNotification"
                          )
                        ) {
                          values.sendPushNotification = "1";
                        }
                        delete values.typeOfNotification;
                        console.log(values);
                        onSubmit(values);
                      }}
                    >
                      {(form) => (
                        <Form onSubmit={form.handleSubmit}>
                          {createState.error && (
                            <FormErrorMessage error={createState.error} />
                          )}

                          <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              <CMSFormSelect
                                name="importance"
                                label="Importance"
                                options={[
                                  {
                                    value: "low",
                                    label: "Low",
                                  },
                                  { value: "medium", label: "Medium" },
                                  {
                                    value: "high",
                                    label: "High",
                                  },
                                ]}
                                form={form}
                                required={true}
                              />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                              <CMSFormSelect
                                name="target"
                                label="Target"
                                options={[
                                  {
                                    value: "all-members",
                                    label: "All Members",
                                  },
                                  {
                                    value: "selected-circles",
                                    label: "Selected Circles",
                                  },
                                ]}
                                form={form}
                                required={true}
                              />
                            </Col>

                            {form.values.target === "selected-circles" && (
                              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <CMSFormMultiSelect
                                  name="targetedCircles"
                                  label="Select Circles"
                                  required={true}
                                  form={form}
                                  options={
                                    listingState.data && listingState.data.items
                                  }
                                  displayValue="name"
                                />
                              </Col>
                            )}
                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className=""
                            >
                              <CMSFormCheckBoxes
                                form={form}
                                label="Type of Notification"
                                name="typeOfNotification"
                                options={[
                                  {
                                    value: "sendEmail",
                                    label: "Send Email",
                                  },
                                  {
                                    value: "sendNotification",
                                    label: "Send Notification",
                                  },
                                  {
                                    value: "sendPushNotification",
                                    label: "Send Push Notification",
                                  },
                                ]}
                                type="array"
                                required={true}
                              />
                            </Col>
                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              xl={12}
                              className="mt-2"
                            >
                              <CMSFormTextArea
                                form={form}
                                label="Message"
                                name="message"
                                maxLength={230}
                                required={true}
                              />
                            </Col>
                          </Row>

                          <button
                            type="submit"
                            className="btn btn-primary mt-2"
                          >
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </section>
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default NotificationsCreate;
