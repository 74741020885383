import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Player } from "video-react";
const VideoExpanded = ({ src, videoShow, setVideoShow }) => {
  const handleClose = () => setVideoShow(false);
  const handleShow = () => setVideoShow(true);
  return (
    // <Modal
    //   class="modal fade  videoModal"
    //   show={videoShow}
    //   onHide={handleClose}
    //   size="lg"
    // >

    //     <div class="modal-content">
    //       <Modal.Body class="modal-body p-1">
    //         <button
    //           type="button"
    //           class="closeVideo"
    //           data-dismiss="modal"
    //           aria-label="Close"
    //         >
    //           <span aria-hidden="true" onClick={handleClose}>
    //             &times;
    //           </span>
    //         </button>

    //         <div class="embed-responsive embed-responsive-16by9">
    //           <iframe
    //             class="embed-responsive-item"
    //             src={src}
    //             id="video"
    //             allowscriptaccess="always"
    //             allow="autoplay"
    //           ></iframe>
    //         </div>
    //       </Modal.Body>
    //     </div>

    // </Modal>
    <Modal
      class="modal fade  videoModal"
      show={videoShow}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="p-1">
        <button
          type="button"
          class="closeVideo"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true" onClick={handleClose}>
            &times;
          </span>
        </button>

        <div class="embed-responsive embed-responsive-16by9">
          <iframe
            class="embed-responsive-item"
            src={src}
            id="video"
            allowscriptaccess="always"
            allow="autoplay"
          ></iframe>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VideoExpanded;
