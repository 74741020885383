export const settingInitialState = {
    data: {},
    isFetching: false,
    hasFetchingError: false,
    initialState: true,
    isSubmitting: false,
    isSubmitted: false,
    hasSubmissionError: false,
    error: ''
};


export const settingReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_REQUEST":
            return {
                ...state,
                hasError: false,
                isFetching: true,
            };
        case "FETCH_SUCCESS":
            return {
                data: action.payload,
                hasError: false,
                isFetching: false,
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                hasFetchingError: true,
                isFetching: false,
                error: action.payload
            };

        case "FORM_SUBMISSION_REQUEST":
            return {
                ...state,
                isSubmitting: true,
                hasSubmissionError: false,
            };
        case "FORM_SUBMISSION_SUCCESS":
            return {
                ...state,
                isSubmitting: false,
                isSubmitted: true,
                hasSubmissionError: false,
            };
        case "FORM_SUBMISSION_FAILURE":
            return {
                ...state,
                isSubmitting: false,
                hasSubmissionError: true,
                error: action.payload
            };
        case "FORM_INITIAL_STATE":
            return {
                ...state,
                initialState: true,
                isSubmitting: false,
                isSubmitted: false,
                hasSubmissionError: false,

            };

        default:
            return state;
    }
}