import React from 'react'
import Header from './partials/Header';
import SideBar from './partials/SideBar';

import { Container } from 'react-bootstrap';
import Footer from './partials/Footer';

const DashboardLayout = (props) => {
    return (
        <>
            <Header />
            <SideBar />
            <div className="content-wrapper" >
                <Container fluid>
                    {props.children}
                </Container>
            </div>

            <Footer />
        </>
    )
}

export default DashboardLayout
