import React from "react";
import { Col, Row, Alert,Card, Button } from "react-bootstrap";
function UnreadMessagesContent() {
  return (
    <>
    <Row>
      <Col>
      <h5 className="mt-3 px-2 text-bold">Recent Messages</h5>
      </Col>

    </Row>
      <Card className="p-0 mt-2 mb-0  messageItem">
        <Card.Body className="p-0">
            <div className="p-0 d-flex align-items-center justify-content-between">
            <div className="d-flex ImageSectionSpacing ms-2 ">
            <div className="position-relative me-2">
              <i className={`fas fa-comments position-relative usericon`}>
                {/* {
                (item.status === "0" || item.status === 0)
                    ?
                    <i className="fas fa-ban position-absolute banned"></i>
                    : null
            } */}
              </i>
            </div>

            <div className="d-flex flex-column ms-1">
              <span className="m-0 text-bold memberName">Member Name</span>

              <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                <span className="membershipId">10/12/2022</span>
              </span>
            </div>
          </div>
          <Button className="px-1 py-0 me-2" href="/chat">Chat</Button>
            </div>
      
       
          <Alert variant="secondary" className="m-0">
            Hello
          </Alert>
       
        
        </Card.Body>
      </Card>
      <Card className="p-0 mt-2 mb-0 messageItem">
        <Card.Body className="p-0">
            <div className="p-0 d-flex align-items-center justify-content-between">
            <div className="d-flex ImageSectionSpacing ms-2 ">
            <div className="position-relative me-2">
              <i className={`fas fa-comments position-relative usericon`}>
                {/* {
                (item.status === "0" || item.status === 0)
                    ?
                    <i className="fas fa-ban position-absolute banned"></i>
                    : null
            } */}
              </i>
            </div>

            <div className="d-flex flex-column ms-1">
              <span className="m-0 text-bold memberName">Member Name</span>

              <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                <span className="membershipId">10/12/2022</span>
              </span>
            </div>
          </div>
          <Button className="px-1 py-0 me-2">Chat</Button>
            </div>
      
       
          <Alert variant="secondary" className="m-0">
            Hello
          </Alert>
       
        
        </Card.Body>
      </Card>
    </>
  );
}

export default UnreadMessagesContent;
