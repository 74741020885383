import React, { useState, useEffect, useReducer } from 'react'
import * as yup from "yup"
import { Form, Formik } from 'formik';
import Modal from 'react-bootstrap/Modal';
import BlockSuccessMessage from '../../../../UI/errorMessages/BlockSuccessMessage';
import { formInitialState, formReducer } from '../../../../../reducers/formReducer';
import FormErrorMessage from '../../../../UI/errorMessages/FormErrorMessage';
import CMSFormTextArea from '../../../../UI/forms/CMSFormTextArea';
import { Button } from 'react-bootstrap';
import { ContainerLoading } from '../../../../UI/loading/ContainerLoading';
import axios from "../../../../../config/axios";
import { Store as toast } from 'react-notifications-component';
import { toastNotification } from '../../../../../config/toastNotification'
import CMSFormInput from '../../../../UI/forms/CMSFormInput';
import CMSFormSelect from '../../../../UI/forms/CMSFormSelect';


const ChangeMemberRoleModal = ({ memberId, initialValue, show, setShow, loadItem }) => {
    const [formState, formDispatch] = useReducer(formReducer, formInitialState)
    const [roles, setRoles] = useState([])


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onSubmit = async (data, resetForm) => {

        formDispatch({
            type: "FORM_SUBMISSION_REQUEST"
        });

        try {
            const res = await axios.post(`members/change-member-role`, {
                id: memberId,
                role: data.role
            });
            formDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: res.data
            });
            handleClose()
            toast.addNotification({
                ...toastNotification,
                title: "Success!",
                message: `Action performed successfully.`,
                type: "success",
            });
            resetForm();
            formDispatch({
                type: "FORM_INITIAL_STAGE"
            });
            loadItem()
        } catch (error) {
            formDispatch({
                type: "FORM_SUBMISSION_FAILED",
                payload: error
            });
            setTimeout(() => {
                formDispatch({
                    type: "FORM_INITIAL_STAGE"
                });
            }, 5000)
        }
    }

    const loadRoles = async () => {

        formDispatch({
            type: "FORM_SUBMISSION_REQUEST",
        });

        try {
            const res = await axios.get(`/general/get-member-roles`); //
           
            if (res && res.data && res.data.items) {

                let simplifiedRoles = res.data.items.map(item => {
                    return {
                        value: item.value,
                        label: item.value,
                    }
                })

                setRoles(simplifiedRoles)
            }

            formDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: res.data,
            });
        } catch (error) {
            formDispatch({
                type: "FETCH_FAILURE",
                payload: error,
            });
        }

    };




    useEffect(() => {
        loadRoles();
    }, [])

    return (
        <Formik
            initialValues={{
                role: initialValue,
            }}
            validationSchema={yup.object({
                role: yup.string().required(),
            })}
            enableReinitialize={true}
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={async (values, { resetForm }) => {
                onSubmit(values, resetForm)
            }}
        >
            {(form) => (

                <Modal show={show} onHide={handleClose}>
                    {(formState.isFormSubmitting) && <div class="overlay">
                        <i class="fas fa-2x fa-sync-alt text-white fa-spin"></i>
                    </div>}
                    <Form onSubmit={form.handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Change Member Role</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {(formState.hasSubmissionError) &&
                                <FormErrorMessage error={formState.formSubmissionError} />
                            }
                            <CMSFormSelect
                                name="role"
                                label="Role"
                                required={true}
                                form={form}
                                options={roles}
                            />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            )}
        </Formik>
    )
}

export default ChangeMemberRoleModal