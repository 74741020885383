
export const createInitialState = {
    initialState: true,
    isSubmitting: false,
    isSubmitted: false,
    hasError: false,
    error: ""
};

export const createReducer = (state, action) => {
    switch (action.type) {
        case "FORM_SUBMISSION_REQUEST":
            return {
                ...state,
                isSubmitting: true,
                hasError: false,
                error: ""
            };
        case "FORM_SUBMISSION_SUCCESS":
            return {
                ...state,
                isSubmitting: false,
                isSubmitted: true,
            };
        case "FORM_SUBMISSION_FAILURE":
            // console.log(action.payload);
            return {
                ...state,
                isSubmitting: false,
                hasError: true,
                error: action.payload
            };
        case "FORM_INITIAL_STATE":
            return {
                ...state,
                initialState: true,
                isSubmitting: false,
                isSubmitted: false,
                hasError: false,
                error: ""
            };
        default:
            return state;
    }
};