export function removeItemFromArray(item, itemArray) {
    if (!item)
        return itemArray;

    const index = itemArray.indexOf(item);
    if (index > -1) {
        itemArray.splice(index, 1);
    }

    return itemArray;
}

export function isItemInArray(itemId, itemArray) {
    if (!itemId)
        return false;

    if (itemArray.filter(e => e._id === itemId).length > 0) {
        return true
    } else {
        return false
    }
}
