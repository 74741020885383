import React from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const CircleDetailsCard = ({ circle, confirmAndRemoveCircle }) => {
    return (
        <Card className="item  mb-1 mt-1">
            <Card.Header className="ps-1 pe-2 py-0">
                <Card.Title className="m-0 p-0 w-100">
                    <div className="actionIcons memberRemove float-end">
                        <span
                            title="Remove circle"
                            onClick={() => confirmAndRemoveCircle(circle._id)}
                            className="circleMemberRemoveIcon pe-1"
                        >
                            <i className="fas fa-times mt-2"></i>
                        </span>
                    </div>

                    <div className="d-flex ImageSectionSpacing">



                        <div className="ms-1 me-2">
                            <i className="fas fa-users position-relative usericon ">
                                {
                                    (circle.status === "0" || circle.status === 0) &&
                                    <i className="fas fa-ban position-absolute banned"></i>
                                }
                            </i>
                        </div>

                        <div className="d-flex flex-column ms-1">
                            <span className="m-0 text-bold memberName" >
                                <Link to={`/circles/${circle._id}`}> {circle.name} - {circle.color}</Link>
                            </span>
                            <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                                <span className="membershipId">
                                    (Total Members : <span class="text-secondary">{circle.membersCount} </span>)
                                </span>
                            </span>
                        </div>
                    </div>



                </Card.Title>


            </Card.Header>

        </Card>
    )
}

export default CircleDetailsCard