import React, { useReducer, useEffect } from "react";
import { Table, Row, Col, Button, Alert, Card } from "react-bootstrap";
import ModuleDisplayEmail from "../modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayPhone from "../modules/fieldsDisplay/ModuleDisplayPhone";
import {
  listingInitialState,
  listingReducer,
} from "../../../reducers/module/listingReducer";
import axios from "../../../config/axios";
import moduleConfig from "../../modules/payments/moduleConfig";
import { getCurrencyCode, getFullName } from "../../../helpers/CommonHelper";
import ModuleDisplayDateTime from "../modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayBoolean from "../modules/fieldsDisplay/ModuleDisplayBoolean";
import { Link } from "react-router-dom";
import { FullPageOverlay } from "../loading/FullPageOverlay";
function PaymentContent() {
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const loadItems = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.get(`${moduleConfig.url}/stats-for-dashboard`);
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data, "2222");
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };

  useEffect(() => {
    loadItems();
  }, []);

  return (
    <>
      {listingState.isFetching == true && <FullPageOverlay />}

      {listingState.data && listingState.data.summary && (
        <>
          <Card className="mt-xl-0 mt-lg-0 mt-md-0 mt-2 mb-xl-0 mb-lg-0 mb-md-0 border-themePrimary">
            <Card.Body className="p-xl-2 p-lg-2 p-md-2 p-0">
              <Row className="showOnMobile bg-themePrimary text-center w-100 mx-auto">
                <Col>
                  <h5 className="my-3 px-1 text-bold text-themePrimary">
                    Payments
                  </h5>
                </Col>
              </Row>
              <Row className="mt-xl-3 mt-lg-3 mt-md-3 mt-1 px-1">
                <Col xl={3} lg={4} md={4} sm={6} xs={12} className="flex-1">
                  <a href="/payments?status=0" className="d-flex h-100 w-100">
                    <div class="dashboard-box bg-linear-two">
                      <div class="dashboard-box-icon">
                        <i class="fas fa-dollar-sign"></i>
                      </div>

                      <div>
                        <h5 class="dashboard-box-heading">
                          <span className="fs-4">
                            {listingState.data.summary.pending}
                          </span>{" "}
                          Pending Payment(s)
                        </h5>
                      </div>
                    </div>
                  </a>
                </Col>
              </Row>

              <Row className="hideOnMobile d-flex align-items-center justify-content-center px-0">
                <Col>
                  <h5 className="my-3  px-1 text-bold">Pending Payment(s)</h5>
                </Col>
                <Col>
                  {listingState.data &&
                    listingState.data.summary &&
                    listingState.data.summary.total > 0 && (
                      <Row className="my-1 p-xl-0 px-lg-0 px-md-0 px-1">
                        <Col>
                          <Button
                            variant="primary"
                            className="float-end"
                            size="sm"
                            href={`/payments`}
                          >
                            View All
                          </Button>
                        </Col>
                      </Row>
                    )}
                </Col>
              </Row>
              {listingState.data.items &&
                listingState.data.items.length <= 0 && (
                  <Alert variant="warning mb-0 mt-1" className="hideOnMobile">
                    No records found.
                  </Alert>
                )}
              {listingState.data.items &&
                listingState.data.items.length > 0 && (
                  <>
                    <div className="registrationTable hideOnMobile overflow-auto m-0 p-0 mt-2">
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Member</th>
                            <th>Purpose</th>
                            <th>Method</th>
                            <th>Total Amount</th>
                            <th>Payment Status</th>
                            <th>Payment Date/Time</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {listingState.data.items.map((item, index) => {
                            return (
                              <tr index={index}>
                                <td>{index + 1}</td>
                                <td>
                                  <Button
                                    href={`/members/${item.memberId}`}
                                    variant="link"
                                  >
                                    {item.member
                                      ? getFullName(item.member)
                                      : ""}
                                  </Button>
                                </td>
                                <td>{item.purpose}</td>
                                <td>{item.method}</td>
                                <td className="text-center">
                                  {item.totalAmount.$numberDecimal +
                                    " " +
                                    getCurrencyCode()}
                                </td>
                                <td className="text-center">
                                  <ModuleDisplayBoolean
                                    value={item.status}
                                    trueValue="Paid"
                                    falseValue="Pending"
                                  />
                                </td>
                                <td>
                                  <ModuleDisplayDateTime value={item.date} />
                                </td>
                                <td
                                  className={`text-center record-actions  MoreItem`}
                                >
                                  <Link
                                    to={`/${moduleConfig.url}/${item._id}`}
                                    className="btn btn-outline-secondary btn-sm"
                                  >
                                    <i className="fas fa-eye"></i>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </Table>
                    </div>
                  </>
                )}
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
}

export default PaymentContent;
