import React, { useReducer, useEffect } from "react";
import { Table, Row, Col, Button, Alert, Card } from "react-bootstrap";
import ModuleDisplayEmail from "../modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayPhone from "../modules/fieldsDisplay/ModuleDisplayPhone";
import axios from "../../../config/axios";
import moduleConfig from "../../modules/contactUs/moduleConfig";
import {
  listingInitialState,
  listingReducer,
} from "../../../reducers/module/listingReducer";
import { Link } from "react-router-dom";
import { FullPageOverlay } from "../loading/FullPageOverlay";
function ContactUsContent() {
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const loadItems = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });
    // const sleep = (ms) => {
    //   return new Promise((resolve) => {
    //     setTimeout(resolve, ms);
    //   });
    // };

    // await sleep(5000);
    try {
      const res = await axios.get(`${moduleConfig.url}?limit=10`);
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    loadItems();
  }, []);

  return (
    <>
      {listingState.isFetching == true && <FullPageOverlay />}
      <Card className="mt-xl-0 mt-lg-0 mt-md-0 mt-2 mb-xl-0 mb-lg-0 mb-md-0 border-themePrimary">
        <Card.Body className="p-xl-2 p-lg-2 p-md-2 p-0 mobileOverflow">
        
          <Row className="showOnMobile bg-themePrimary text-center w-100 mx-auto">
            <Col>
              <h5 className="my-3 px-1 text-bold text-themePrimary">
                Contact Us
              </h5>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex align-items-center justify-content-between">
             

              {listingState.data.items &&
                listingState.data.items.length > 0 && (
                  <>
                   <h5 className="my-3  px-1 text-bold">Recent Submission(s)</h5>
                   <Button className="px-2 py-0 me-2" href="/contact-us">
                    View all
                  </Button>
                  </>
                 
                )}
            </Col>
          </Row>
          {listingState.data.items && listingState.data.items.length <= 0 && (
            <Alert variant="warning mb-0 mt-1">No records found.</Alert>
          )}
          {listingState.data.items && listingState.data.items.length > 0 && (
            <>
              <Row>
                <Col className="overflow-auto">
                  <Table striped bordered>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Reason</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {listingState.data &&
                        listingState.data.items &&
                        listingState.data.items.length &&
                        listingState.data.items.map((item, itemIndex) => {
                          return (
                            <tr key={itemIndex}>
                              <td>{itemIndex + 1}</td>
                              <td>{item.name}</td>

                              <td>
                                <ModuleDisplayEmail value={item.email} />
                              </td>
                              <td>
                                <ModuleDisplayPhone value={item.mobile} />
                              </td>
                              <td>{item.reason}</td>
                              <td
                                className={`text-center record-actions  MoreItem`}
                              >
                                <Link
                                  to={`/contact-us/${item._id}`}
                                  className="btn btn-outline-secondary btn-sm"
                                >
                                  <i className="fas fa-eye"></i>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
}

export default ContactUsContent;
