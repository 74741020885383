import React, { useReducer, useEffect, useContext } from "react";
import moduleConfig from "./moduleConfig";
import { Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import axios from "../../../config/axios";
import { createReducer, createInitialState } from "./reducers/createReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import { ContainerLoading } from "../../../components/UI/loading/ContainerLoading";
import ModuleCreateView from "../../../components/UI/modules/views/ModuleCreateView";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSFormController from "../../UI/forms/CMSFormController";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import CMSFormDate from "../../UI/forms/CMSFormDate";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";

let breadcrumbs = {
  listing: {
    label: moduleConfig.title,
    url: `/${moduleConfig.url}`,
  },
  create: {
    label: "Create",
  },
};

const MemberCreate = () => {
  const [createState, createDispatch] = useReducer(
    createReducer,
    createInitialState
  );
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let navigate = useNavigate();
  const onSubmit = async (data) => {
    createDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(`${moduleConfig.url}`, data); //
      console.log(res);
      createDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });

      navigate(`/${moduleConfig.url}`);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} created successfully.`,
        type: "success",
      });
    } catch (error) {
      console.log(error.response);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.message : error.message;

      createDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        createDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  const loadData = async () => {
    try {
      const res = await axios.get(`members/create-data`); //
      createDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      createDispatch({
        type: "FETCH_FAILURE",
        payload: "Date loading failed.",
      });
    }
  };
  useEffect(() => {
    loadData();
    setBreadcrumb([breadcrumbs.listing, breadcrumbs.create]);
  }, []);
  return (
    <DashboardLayout>
      {createState.status === "fetching" && createState.isLoading && (
        <ContainerLoading />
      )}

      {createState.status === "fetchFailed" ? (
        <BlockErrorMessage error={createState.error} />
      ) : (
        <>
          {/* {console.log(createState.data)} */}
          {createState.status !== "fetching" && (
            <>
              <ModuleHeader
                moduleTitle={`Create ${moduleConfig.singleItemTitle}`}
                moduleUrl={moduleConfig.url}
              ></ModuleHeader>

              <section className="content">
                <Card>
                  <Card.Body>
                    {createState.isLoading && (
                      <div class="overlay position-absolute containerLoading">
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                      </div>
                    )}
                    <Formik
                      initialValues={{
                        title: "",
                        firstName: "",
                        lastName: "",
                        nationality: "",
                        //occupation: "",
                        dob: "",
                        email: "",
                        mobile: "",
                        oldMemberNumber: "",
                        status: "RegistrationPaymentPending",
                      }}
                      validationSchema={yup.object({
                        title: yup.string(),
                        firstName: yup
                          .string()
                          .required("Required")
                          .min(2, "First name should contain 2 characters"),
                        lastName: yup
                          .string()
                          .required("Required")
                          .min(2, "Last name should contain 2 characters"),
                        nationality: yup.string().required("Required"),
                        //occupation: yup.string().required("Required"),
                        dob: yup.string().required("Required"),
                        email: yup
                          .string()
                          .required("Required")
                          .email("Enter a valid email"),
                        mobile: yup
                          .string()
                          .required("Required")
                          .min(8, "Mobile number must be 8 characters"),
                        oldMemberNumber: yup.string().required("Required"),
                        status: yup.string().required("Required"),
                      })}
                      enableReinitialize={true}
                      validateOnChange={true}
                      validateOnBlur={false}
                      onSubmit={async (values) => {
                        console.log(values);
                        // const formData = new FormData();
                        // for (let name in values) {
                        //     if (Array.isArray(values[name])) {
                        //         formData.append(name, JSON.stringify(values[name]));
                        //     } else {
                        //         formData.append(name, values[name]);
                        //     }
                        // }

                        onSubmit(values);
                      }}
                    >
                      {(form) => (
                        <Form onSubmit={form.handleSubmit}>
                          {createState.error && (
                            <FormErrorMessage error={createState.error} />
                          )}

                          <Row>
                            {/* <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormSelect
                                name="title"
                                label="Title"
                                required={false}
                                form={form}
                                options={createState.data.personTitles}
                              />
                            </Col> */}
                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormInput
                                name="firstName"
                                label="First Name"
                                required={true}
                                form={form}
                              />
                            </Col>
                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormInput
                                name="lastName"
                                label="Last Name"
                                required={true}
                                form={form}
                              />
                            </Col>
                         
                            <Col xs={12} s={12} md={4} lg={4}>

                              <CMSFormSelect
                                name="nationality"
                                label="Nationality"
                                required={true}
                                form={form}
                                options={createState.data.countries}
                              />


                            </Col>
                            </Row>

<Row>
                            <Col xs={12} s={12} md={4} lg={4}>
                              {/* <CMSFormInput
                                name="occupation"
                                label="Occupation"
                                required={true}
                                form={form}
                              /> */}
                              <CMSFormInput
                                name="mobile"
                                label="Mobile"
                                required={true}
                                form={form}
                              />
                            </Col>

                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormInput
                                name="email"
                                label="Email"
                                required={true}
                                form={form}
                              />
                            </Col>
                          
                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormDate
                                name="dob"
                                label="Date of Birth"
                                required={true}
                                form={form}
                                minDate={new Date("1950-01-01")}
                                maxDate={new Date("2040-12-28")}
                                disabled={false}
                              />

                            </Col>
                            </Row>

                          <Row>
                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormInput
                                name="oldMemberNumber"
                                label="Membership ID"
                                required={true}
                                form={form}
                              />
                            </Col>
                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormSelect
                                name="status"
                                label="Status"
                                required={true}
                                form={form}
                                options={[
                                  {
                                    value: "RegistrationPaymentPending",
                                    label: "Registration Payment Pending",
                                  },
                                  // { value: "Member", label: "Member" },
                                ]}
                              />
                            </Col>

                            <Col xs={12} s={12} md={4} lg={4}>

                            </Col>
                          </Row>

                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </section>
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default MemberCreate;
