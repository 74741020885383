import moduleConfig from "./moduleConfig"
import React, { useContext, useEffect, useReducer } from 'react';
import DashboardLayout from '../../UI/layouts/DashboardLayout';
import axios from "../../../config/axios";
import { pageContentReducer, pageContentInitialState } from "../../../reducers/pageContentReducer"
import { ContainerLoading } from '../../UI/loading/ContainerLoading';


import { Alert, Card } from 'react-bootstrap';
import { BreadcrumbContext } from '../../../contexts/BreadcrumbContext';
import { useParams } from "react-router-dom";
import { Form, Formik } from 'formik';

import { upperCaseFirst } from "../../../helpers/CommonHelper";
import * as Yup from "yup"
import { toastNotification } from '../../../config/toastNotification'
import { Store as toast } from 'react-notifications-component';
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSFormController from "../../UI/forms/CMSFormController";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";

let breadcrumbs = {
    listing: {
        label: "Pages",
        url: "/pages"
    },
    update: {
        label: "Page Content",

    }
};


const PageContentUpdate = () => {
    const [pageContentState, pageContentDispatch] = useReducer(pageContentReducer, pageContentInitialState)
    const { setBreadcrumb } = useContext(BreadcrumbContext);
    let { id } = useParams();


    const onSubmit = async (data) => {

        pageContentDispatch({
            type: "FORM_SUBMISSION_REQUEST"
        });
        try {
            const res = await axios.post(`pages/${id}`, data)//
            pageContentDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: res.data
            });
            toast.addNotification({
                ...toastNotification,
                title: "Success!",
                message: `Content updated successfully.`,
                type: "success",
            });
        } catch (error) {
            //console.log(error.message);
            pageContentDispatch({
                type: "FORM_SUBMISSION_FAILURE",
                payload: error
            });
            setTimeout(() => {
                pageContentDispatch({
                    type: "FORM_INITIAL_STATE"
                });
            }, 5000)
        }
    }

    const getInitialValues = (items) => {
        let initialValues = {}

        if (items && items.length > 0) {
            items.map(({ name, type, value }, index) => {
                initialValues[name] = value;
            })
        }
        //console.log(initialValues);
        return initialValues
    }

    const getValidationSchema = (items) => {
        const validationSchema = {}
        if (items && items.length > 0) {
            items.map(({ name, type, is_required }, index) => {
                let validator = null
                if (type === 'image') {
                    validator = Yup.mixed()
                        .test(
                            "FILE_SIZE",
                            "File size should be less than 1 MB.",
                            (file) => {
                                if (file instanceof File) {
                                    return file.size <= 1000000
                                } else {
                                    return true
                                }
                            }
                        )
                        .test(
                            "FILE_FORMAT",
                            "File should be in JPG/PNG format.",
                            (file) => {
                                if (file instanceof File) {
                                    return (['image/jpeg', 'image/jpg', 'image/png'].includes(file.type))
                                } else {
                                    return true
                                }
                            }
                        )
                } else {
                    validator = Yup['string']();
                }

                if (is_required === 'Yes') {
                    validator = validator['required']([upperCaseFirst(name.replace("_", " ")) + ' is required.']);
                }
                validationSchema[name] = validator;
            })
        }
        return Yup.object().shape(validationSchema)
    }

    const loadItems = async () => {
        if (id) {
            pageContentDispatch({
                type: "FETCH_REQUEST"
            });
            try {
                const res = await axios.get(`${moduleConfig.url}/${id}`)//

                pageContentDispatch({
                    type: "FETCH_SUCCESS",
                    payload: res.data
                });
            } catch (error) {
                pageContentDispatch({
                    type: "FETCH_FAILURE",
                    payload: error
                });
            }
        }
    }

    useEffect(() => {
        loadItems();
        setBreadcrumb([
            breadcrumbs.listing,
            breadcrumbs.update
        ])
    }, [])
    return (
        <DashboardLayout >

            {(pageContentState.isFetching)
                ? <ContainerLoading />
                : <>

                    {(pageContentState.hasFetchingError)
                        ? <BlockErrorMessage error={pageContentState.error} />
                        : <>
                            <ModuleHeader
                                moduleTitle="Page Content"
                                moduleUrl=""
                            ></ModuleHeader>


                            {(pageContentState.hasSubmissionError) &&
                                <FormErrorMessage error={pageContentState.error} />
                            }

                            {
                                (pageContentState.data && pageContentState.data.items && pageContentState.data.items.length > 0)
                                    ? <>
                                    <Card  className="p-2">
                                     

                                       

                                
                                        <Formik
                                            initialValues={getInitialValues(pageContentState.data.items)}
                                            validationSchema={getValidationSchema(pageContentState.data.items)}
                                            enableReinitialize={true}
                                            validateOnChange={true}
                                            validateOnBlur={false}
                                            onSubmit={async (values) => {
                                                let formData = new FormData();
                                                for (let name in values) {
                                                    if (values[name] instanceof File) {
                                                        formData.append(name, values[name]);
                                                    } else {
                                                        formData.append(name, values[name]);
                                                    }
                                                }

                                                // for (let property of formData.entries()) {
                                                //     console.log(property);
                                                // }

                                                onSubmit(formData)
                                            }}
                                        >
                                            {(form) => (
                                                <Form onSubmit={form.handleSubmit}>

                                                    {pageContentState.data.items.map(({ name, display_title, type }, index) => {

                                                        return <CMSFormController
                                                            key={index}
                                                            control={(type === 'text') ? "input" : type}
                                                            type={type}
                                                            label={upperCaseFirst(display_title)}
                                                            name={name}
                                                            form={form}
                                                        />
                                                    })
                                                    }
                                                    <br />
                                                    <button className="btn btn-primary" type="submit">Submit</button>
                                                </Form>
                                            )}
                                        </Formik>
                                     
                                        </Card>
                                    </>
                                    : <Alert variant="warning" >Page Content Not Found.</Alert>
                            }
                        </>
                    }
                </>
            }


        </DashboardLayout>
    )
}
export default PageContentUpdate;
