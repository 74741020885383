import React, { useState, useReducer, useEffect, useContext } from "react";
import {
  Alert,
  Card,
  Col,
  Row,
  Table,
  Badge,
  Image,
  Dropdown,
  NavDropdown,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import queryString from "query-string";
import moduleConfig from "./moduleConfig";
import axios from "../../../config/axios";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import useConfirm from "../../../HOC/custom/useConfirm";
import ListingFooter from "./partials/listing/ListingFooter";
import SearchArea from "./partials/listing/SearchArea";
import Item from "./partials/listing/Item";
import { listingReducer, listingInitialState } from "./reducers/listingReducer";
import { OverlayLoading } from "../../UI/loading/OverlayLoading";
import SendEmailToSelected from "../../UI/mix/SendEmailToSelected";
import ModuleDisplayEmailToAll from "../../UI/modules/fieldsDisplay/ModuleDisplayEmailToAllDelete it later";
import SendEmailToAll from "../../UI/mix/SendEmailToAll";

let breadcrumbs = {
  listing: {
    label: moduleConfig.title,
    url: `/${moduleConfig.url}`,
  },
};

const customButtonsForItems = [
  // {
  //   label: "Change Password",
  //   className: "fas fa-key",
  //   url: "change-password"
  // }
];

const MemberListing = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const [isEmailSelected, setIsEmailSelected] = useState([]);
  const [isCheck, setIsCheck] = useState([]);
  const [isDropdown, setIsDropdown] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const { confirm } = useConfirm();

  const { search } = useLocation();
  const [appliedFilters, setAppliedFilters] = useState(
    queryString.parse(search)
  );
  if (appliedFilters.limit) {
    moduleConfig.perPageItems = appliedFilters.limit;
  }

  let history = useNavigate();

  const setFilter = (name, value) => {
    console.log(`setFilter called with ${name}, ${value}`);
    let newAppliedFilters = JSON.parse(JSON.stringify(appliedFilters));

    if (value) {
      newAppliedFilters[name] = value;
    } else {
      delete newAppliedFilters[name];
    }

    if (name !== "page") {
      delete newAppliedFilters["page"];
    }

    if (name === "limit" || name === "page") {
      setIsEmailSelected([]);
      setIsCheck([]);
      setIsCheckAll(false);
    }

    let newURL = queryString.stringify(newAppliedFilters);
    history({ search: newURL });
    setAppliedFilters(newAppliedFilters);
  };

  const loadItems = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.get(`${moduleConfig.url}${search}`); //
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };

  const resetFilters = () => {
    //console.log(`resetFilters called`)
    let newAppliedFilters = JSON.parse(JSON.stringify(appliedFilters));

    moduleConfig.perPageItems = 10;
    delete newAppliedFilters["limit"];
    delete newAppliedFilters["status"];
    delete newAppliedFilters["role"];
    delete newAppliedFilters["isBoardMember"];
    delete newAppliedFilters["accountDeleted"];
    delete newAppliedFilters["createdByAdmin"];
    delete newAppliedFilters["blockedByAdmin"];
    delete newAppliedFilters["q"];
    delete newAppliedFilters["expiryStatus"];
    delete newAppliedFilters["profilesType"];

    let newURL = queryString.stringify(newAppliedFilters);
    history({ search: newURL });
    //console.log(newAppliedFilters);
    setAppliedFilters(newAppliedFilters);
  };

  const performBulkAction = async (action) => {
    listingDispatch({
      type: "BULK_ACTION_REQUEST",
    });

    try {
      const res = await axios.post(`${moduleConfig.url}/bulk-action`, {
        ids: isCheck,
        action,
      });
      setIsEmailSelected([]);
      setIsCheck([]);
      setIsCheckAll(false);
      loadItems();
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Action "${action}" on selected items was performed successfully.`,
        type: "success",
      });
    } catch (error) {
      listingDispatch({
        type: "BULK_ACTION_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        listingDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  useEffect(() => {
    loadItems();
  }, [search]);

  useEffect(() => {
    setBreadcrumb([breadcrumbs.listing]);
  }, []);

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);

    if (listingState.data.items && listingState.data.items.length) {
      setIsCheck(listingState.data.items.map((li) => li._id));
    }

    if (listingState.data.items && listingState.data.items.length) {
      setIsEmailSelected(listingState.data.items.map((li) => li.email));
    }

    if (isCheckAll) {
      setIsCheck([]);
      setIsEmailSelected([])
    }
  };

  const handleCheckBoxClick = (e) => {
    //console.log()
    let email = e.target.attributes.email.value;
    const { id, checked } = e.target;

    setIsCheck([...isCheck, id]);
    setIsEmailSelected([...isEmailSelected, email]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
      setIsEmailSelected(isEmailSelected.filter((item) => item !== email));
    }
    setIsCheckAll(false);
  };

  const download = (type) => {
    axios
      .post(`/members/download`, {
        type,
        ids: isCheck,
      })
      .then(function (response) {
        console.log(response);

        let blob = new Blob([response.data], { type: "text/csv" }),
          downloadUrl = window.URL.createObjectURL(blob),
          filename = type + "-members.csv";
        let a = document.createElement("a");
        if (typeof a.download === "undefined") {
          window.location.href = downloadUrl;
        } else {
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
        }
      });
  };

  return (
    <DashboardLayout>
      {listingState.isLoading && <OverlayLoading />}
      {listingState.status !== "fetching" && (
        <>
          {listingState.error && listingState.status === "fetchFailed" ? (
            <BlockErrorMessage error={listingState.error} />
          ) : (
            <>
              <ModuleHeader
                moduleTitle={moduleConfig.title}
                moduleUrl={moduleConfig.url}
              >
                <div className="moduleHeaderTopButtons">
                  {moduleConfig.buttons &&
                    moduleConfig.buttons.create === true && (
                      <Link
                        to={`/${moduleConfig.url}/create`}
                        className="btn btn-primary ms-1 mb-1"
                      >
                        Create Member
                      </Link>
                    )}

                  {listingState.data &&
                    listingState.data.pagination &&
                    listingState.data.pagination.totalItems > 0 && (

                      <>
                        <div className="nav-item dropdown  text-white">
                          <NavDropdown
                            className="dropButton ms-1 mb-1"
                            title={
                              <>
                                <i className="fa fa-envelope me-1"> </i> Email to{" "}
                              </>
                            }
                            id="basic-nav-dropdown"
                          >
                            <p className="dropdown-item  mb-1">
                              <SendEmailToAll
                                buttonTitle="All Members"
                                apiURL="members"
                              />
                            </p>
                            <p className="dropdown-item mb-1">
                              <SendEmailToSelected
                                buttonTitle="Selected Members"
                                apiURL="members"
                                emails={isEmailSelected}
                              />
                            </p>
                          </NavDropdown>
                        </div>

                        <Dropdown className="ms-1 mb-1">
                          <Dropdown.Toggle
                            variant="primary"
                            className="border"
                            id="dropdown-basic"
                          >
                            <i className="fa fa-download me-1"> </i> Download &nbsp;
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item onClick={() => download("all")}>
                              All Members
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => download("board")}>
                              Board Members
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => download("active")}>
                              Active Members
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => download("blocked")}>
                              Blocked Members
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => download("expired")}>
                              Expired Members
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => download("expiring-soon")}>
                              Expiring Soon Members
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => download("tolerance-period")}
                            >
                              In Tolerance Period Members
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => download("account-delete-request")}>
                              Account Deletion Requested Members
                            </Dropdown.Item>
                            <Dropdown.Item
                              disabled={isCheck && isCheck.length > 0 ? false : true}
                              onClick={() => download("selected")}
                            >
                              Selected Members
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </>

                    )}
                </div>
              </ModuleHeader>

              <div className="listingPage">
                {listingState.data &&
                  listingState.data.pagination &&
                  listingState.data.pagination.totalItems > 0 ? (
                  <>
                    {console.log("isEmailSelected", isEmailSelected)}
                    {/* Search Area */}
                    <SearchArea
                      moduleConfig={moduleConfig}
                      setFilter={setFilter}
                      appliedFilters={appliedFilters}
                      resetFilters={resetFilters}
                    />

                    {/* Listing */}
                    <section className="content listing ">
                      {listingState.error && (
                        <FormErrorMessage error={listingState.error} />
                      )}

                      {!listingState.data.items ||
                        listingState.data.items.length <= 0 ? (
                        <Alert variant="warning">
                          No records found with selected filters.{" "}
                          <Alert.Link href="#" onClick={() => resetFilters()}>
                            <i className="fas fa-times" /> Reset Filters
                          </Alert.Link>
                          .
                        </Alert>
                      ) : (
                        <>
                          {listingState.data.items &&
                            listingState.data.items.length && (
                              <>
                                <Row className="m-0 p-0 mt-2">
                                  <Col className="m-0 mb-1">
                                    <input
                                      className="selectAll"
                                      type="checkbox"
                                      name="selectAll"
                                      id="selectAll"
                                      onChange={handleSelectAll}
                                      checked={isCheckAll}
                                    />
                                    <label
                                      for="selectAll"
                                      className="m-0 p-0 ml-2"
                                    >
                                      Select All
                                    </label>
                                  </Col>
                                </Row>
                                {listingState.data.items.map(
                                  (item, itemIndex) => {
                                    return (
                                      <Item
                                        moduleConfig={moduleConfig}
                                        item={item}
                                        handleCheckBoxClick={
                                          handleCheckBoxClick
                                        }
                                        isCheck={isCheck}
                                        customButtonsForItems={
                                          customButtonsForItems
                                        }
                                      />
                                    );
                                  }
                                )}
                              </>
                            )}
                        </>
                      )}
                    </section>

                    <ListingFooter
                      listingState={listingState}
                      moduleConfig={moduleConfig}
                      isCheck={isCheck}
                      performBulkAction={performBulkAction}
                      setFilter={setFilter}
                    />
                  </>
                ) : (
                  <Alert variant="warning">No records found.</Alert>
                )}
              </div>
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default MemberListing;
