import React from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayFieldsController from "../../../../UI/modules/fieldsDisplay/ModuleDisplayFieldsController";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
import memberDummyImage from "../../../../../images/memberDummyImage.jpg";
import memberStatusEmailVerificationRequired from "../../../../../images/memberStatusEmailVerificationRequired.png";
import memberStatusSignupRequest from "../../../../../images/memberStatusSignupRequest.png";
import memberStatusRejected from "../../../../../images/memberStatusRejected.png";
import memberStatusRegistrationPaymentPending from "../../../../../images/memberStatusRegistrationPaymentPending.png";
import memberStatusMember from "../../../../../images/memberStatusMember.jpg";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";

const Item = ({
  item,
  handleCheckBoxClick,
  isCheck,
  moduleConfig,
  confirmAndDelete,
  confirmAndBlock,
  confirmAndUnblock,
  customButtonsForItems,
  loading,
}) => {
  const getItems = () => {
    let items = [];

    if (item.sendEmail) {
      items = [
        ...items,
        {
          text: "Email",
          value: item.sendEmail,
          type: "boolean",
          disable: true,
        },
      ];
    }
    if (item.sendNotification) {
      items = [
        ...items,
        {
          text: "Notification",
          value: item.sendNotification,
          type: "boolean",
          disable: true,
        },
      ];
    }

    if (item.sendPushNotification) {
      items = [
        ...items,
        {
          text: "Push Notification",
          value: item.sendPushNotification,
          type: "boolean",
          disable: true,
        },
      ];
    }

    return items;
  };
  return (
    <Card className="item mb-2">
      <Card.Header className="ps-1 pe-2 py-0">
        <Card.Title className="d-flex m-0 p-0">
          <div className="d-flex ImageSectionSpacing">
            {/* <div className="checkboxCol me-2 ms-2 mt-2">
              <input
                type="checkbox"
                id={item._id}
                name={item._id}
                onChange={handleCheckBoxClick}
                checked={isCheck.includes(item._id)}
              />
            </div> */}

            <div className="position-relative ms-1 me-2">
              <i className={`fas fa-bell position-relative usericon`}></i>

              {item.status === "0" || item.status === 0 ? (
                <i className="fas fa-ban position-absolute ms-1 banned"></i>
              ) : null}
            </div>
            {console.log(item)}

            <div className="d-flex flex-column align-content-center justify-content-center ms-1">
              <span className="m-0 text-bold memberName">
                Send to -{" "}
                {item.target === "all-members"
                  ? "All Members"
                  : "Selected Circles"}{" "}
              </span>
            </div>
          </div>
        </Card.Title>

        <div class="card-tools">
          <div>
            {item.importance === "low" && (
              <span class="badge badge-dark me-1">Low Importance</span>
            )}
            {item.importance === "medium" && (
              <span class="badge badge-warning me-1">Medium Importance</span>
            )}
            {item.importance === "high" && (
              <span class="badge badge-danger me-1">High Importance</span>
            )}
            <span class="badge badge-light me-1 text-dark">
              Send Date/Time :{" "}
              <span class="text-secondary">
                <ModuleDisplayDateTime value={item.createdAt} />{" "}
              </span>
            </span>
          </div>

          <div className=" text-right me-xl-2  mt-1 mb-1">
            <Link
              to={`/${moduleConfig.url}/${item._id}`}
              className="btn btn-outline-secondary  listingActionIcon"
              title="View Item details"
            >
              <i className="fas fa-eye"></i>
            </Link>

            {/* <Link
              to={`/${moduleConfig.url}/update/${item._id}`}
              className="btn btn-outline-secondary listingActionIcon"
              title="Update Item"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>

            {(item.status === "1" || item.status === 1) && (
              <span
                title="Block Item"
                onClick={() => confirmAndBlock(item._id)}
                className="btn btn-outline-secondary  listingActionIcon"
              >
                <i className="fas fa-ban"></i>
              </span>
            )}

            {(item.status === "0" || item.status === 0) && (
              <span
                title="Remove Ban"
                onClick={() => confirmAndUnblock(item._id)}
                className="btn btn-outline-secondary listingActionIcon"
              >
                <i className="fas fa-undo"></i>
              </span>
            )} */}
          </div>
        </div>
      </Card.Header>
      <Card.Body className="p-0">
        <StatsListing items={getItems()} />
        {item.message && (
          <Alert variant="primary mb-0 mt-0 px-2 py-0">
            <span className="text-bold">Message : </span>
            {item.message}
          </Alert>
        )}
      </Card.Body>
    </Card>
  );
};

export default Item;
