import React, { useState, useReducer, useEffect, useContext } from "react";
import { Alert, Card, Col, Row, Table } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BlockErrorMessage from "../../components/UI/errorMessages/BlockErrorMessage";
import DashboardLayout from "../../components/UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../components/UI/loading/ContainerLoading";
import ModuleHeader from "../../components/UI/modules/views/partials/common/ModuleHeader";
import queryString from "query-string";
import axios from "../../config/axios";
import {
  listingReducer,
  listingInitialState,
} from "../../reducers/module/listingReducer";
import { BreadcrumbContext } from "../../contexts/BreadcrumbContext";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../config/toastNotification";
import FormErrorMessage from "../../components/UI/errorMessages/FormErrorMessage";
import ModuleListingViewInTableStyle from "../../components/UI/modules/views/ModuleListingViewInTableStyle";
import ModuleListingViewInBlockStyle from "../../components/UI/modules/views/ModuleListingViewInBlockStyle";
import ModuleRecordsPerPageDropdown from "../../components/UI/modules/views/partials/common/ModuleRecordsPerPageDropdown";
import ModuleStatusDropdown from "../../components/UI/modules/views/partials/common/ModuleStatusDropdown";
import ModuleSearchBox from "../../components/UI/modules/views/partials/common/ModuleSearchBox";

const ListingViewHOC = (breadcrumbs) => {
  const WithListingFunctions = ({ moduleConfig, customButtonsForItems }) => {
    //console.log("Listing View called");

    const { setBreadcrumb } = useContext(BreadcrumbContext);
    const [listingState, listingDispatch] = useReducer(
      listingReducer,
      listingInitialState
    );
    const { search } = useLocation();

    // console.log(listingState)

    const [isCheck, setIsCheck] = useState([]);
    const [isCheckAll, setIsCheckAll] = useState(false);

    const [appliedFilters, setAppliedFilters] = useState(
      queryString.parse(search)
    );
    if (appliedFilters.limit) {
      moduleConfig.perPageItems =
        appliedFilters.limit !== "10" ? appliedFilters.limit : 10;
    }
    let history = useNavigate();

    const setFilter = (name, value) => {
      //console.log(`setFilter called with ${name}, ${value}`)
      let newAppliedFilters = JSON.parse(JSON.stringify(appliedFilters));

      if (value) {
        newAppliedFilters[name] = value;
      } else {
        delete newAppliedFilters[name];
      }

      if (name === "limit" && value === "10") {
        delete newAppliedFilters["limit"];
      }

      if (name !== "page") {
        delete newAppliedFilters["page"];
      }

      if (name === "limit" || name === "page") {
        setIsCheck([]);
        setIsCheckAll(false);
      }

      let newURL = queryString.stringify(newAppliedFilters);
      history({ search: newURL });
      setAppliedFilters(newAppliedFilters);
    };

    const setSorting = (name, value) => {
      let newAppliedFilters = JSON.parse(JSON.stringify(appliedFilters));
      delete newAppliedFilters["sort"];

      newAppliedFilters[name] = value;
      let newURL = queryString.stringify(newAppliedFilters);

      history({ search: newURL });
      setAppliedFilters(newAppliedFilters);
    };

    const loadItems = async () => {
      listingDispatch({
        type: "FETCH_REQUEST",
      });
      try {
        const res = await axios.get(`${moduleConfig.url}${search}`); //
        listingDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        listingDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    };

    const deleteItem = async (id) => {
      listingDispatch({
        type: "DELETE_REQUEST",
      });
      try {
        const res = await axios.delete(`${moduleConfig.url}/${id}`);
        loadItems();
        // listingDispatch({
        //     type: "DELETE_SUCCESS"
        // });
        toast.addNotification({
          ...toastNotification,
          title: "Success!",
          message: `${moduleConfig.singleItemTitle} deleted successfully.`,
          type: "success",
        });
      } catch (error) {
        listingDispatch({
          type: "DELETE_FAILURE",
          payload: error,
        });

        setTimeout(() => {
          listingDispatch({
            type: "REMOVE_ERROR",
          });
        }, 5000);
      }
    };

    const resetFilters = () => {
      //console.log(`resetFilters called`)
      let newAppliedFilters = JSON.parse(JSON.stringify(appliedFilters));

      moduleConfig.perPageItems = 10;
      delete newAppliedFilters["limit"];
      delete newAppliedFilters["status"];
      delete newAppliedFilters["q"];

      let newURL = queryString.stringify(newAppliedFilters);
      history({ search: newURL });
      setAppliedFilters(newAppliedFilters);
    };

    const performBulkAction = async (action) => {
      listingDispatch({
        type: "BULK_ACTION_REQUEST",
      });

      try {
        const res = await axios.post(`${moduleConfig.url}/bulk-action`, {
          ids: isCheck,
          action,
        });
        setIsCheck([]);
        setIsCheckAll(false);
        loadItems();
        toast.addNotification({
          ...toastNotification,
          title: "Success!",
          message: `Action "${action}" on selected items was performed successfully.`,
          type: "success",
        });
      } catch (error) {
        listingDispatch({
          type: "BULK_ACTION_FAILURE",
          payload: error,
        });

        setTimeout(() => {
          listingDispatch({
            type: "REMOVE_ERROR",
          });
        }, 5000);
      }
    };

    useEffect(() => {
      loadItems();
    }, [search]);

    useEffect(() => {
      setBreadcrumb([breadcrumbs.listing]);
    }, []);

    // useEffect(() => {
    //     setIsCheckAll(false)
    // }, [isCheck])

    const handleSelectAll = () => {
      setIsCheckAll(!isCheckAll);

      if (listingState.data.items && listingState.data.items.length) {
        setIsCheck(listingState.data.items.map((li) => li._id));
      }

      if (isCheckAll) {
        setIsCheck([]);
      }
    };

    if (listingState.data.items) {
      //console.log(listingState.data.pagination);
    }

    return (
      <DashboardLayout>
        {listingState.isFetching ? (
          <ContainerLoading />
        ) : (
          <>
            {listingState.hasFetchingError === true ? (
              <BlockErrorMessage error={listingState.error} />
            ) : (
              <>
                <ModuleHeader
                  moduleTitle={moduleConfig.title}
                  moduleUrl={moduleConfig.url}
                >
                  <div className="moduleHeaderTopButtons">
                  {moduleConfig.buttons &&
                    moduleConfig.buttons.create === true && (
                      <Link
                        to={`/${moduleConfig.url}/create`}
                        className="btn btn-primary"
                      >
                        Create
                      </Link>
                    )}
                  </div>
                  
                </ModuleHeader>

                {listingState.data &&
                  listingState.data.pagination &&
                  listingState.data.pagination.totalItems > 0 && (
                    <Card className="listingPageHeader p-0 m-0 p-1 mt-2 mb-1">
                      <Card.Body className=" p-0 m-0">
                        <div className=" ">
                          {(appliedFilters.limit ||
                            appliedFilters.q ||
                            appliedFilters.status) && (
                            <div
                              div
                              className="resetFilters text-right"
                              onClick={() => resetFilters()}
                            >
                              <span>
                                <i className="fas fa-times" /> Reset Filters
                              </span>
                            </div>
                          )}

                          <Row className="header">
                            {moduleConfig.hasPagination === true &&
                              moduleConfig.perPageItemsOptions &&
                              moduleConfig.perPageItemsOptions.length > 0 && (
                                <Col
                                  className={
                                    moduleConfig.hasStatusSearch === true &&
                                    moduleConfig.hasPagination === true
                                      ? "col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-0"
                                      : "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-0"
                                  }
                                >
                                  <ModuleRecordsPerPageDropdown
                                    selectedPerPageItems={
                                      moduleConfig.perPageItems
                                    }
                                    perPageItemsOptions={
                                      moduleConfig.perPageItemsOptions
                                    }
                                    setFilter={setFilter}
                                  />
                                </Col>
                              )}

                            {moduleConfig.hasStatusSearch === true && (
                              <Col  className={
                                moduleConfig.hasStatusSearch === true &&
                                moduleConfig.hasPagination === true
                                  ? "col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1"
                                  : "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1"
                              }>
                                <ModuleStatusDropdown
                                  setFilter={setFilter}
                                  appliedFilters={appliedFilters}
                                  statusOptions={[
                                    {
                                      label: "Select Status...",
                                      value: "",
                                    },
                                    {
                                      label: "Enabled",
                                      value: "enabled",
                                    },
                                    {
                                      label: "Disabled",
                                      value: "disabled",
                                    },
                                  ]}
                                />
                              </Col>
                            )}

                            {moduleConfig.hasSearch === true && (
                              <Col  className={
                                moduleConfig.hasStatusSearch === true &&
                                moduleConfig.hasPagination === true
                                  ? "col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1"
                                  : "col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1"
                              }>
                                <ModuleSearchBox
                                  setFilter={setFilter}
                                  appliedFilters={appliedFilters}
                                />
                              </Col>
                            )}
                          </Row>
                        </div>
                      </Card.Body>
                    </Card>
                  )}

                {moduleConfig.moduleType.listing === "block" ? (
                  <ModuleListingViewInBlockStyle
                    listingState={listingState}
                    resetFilters={resetFilters}
                    moduleConfig={moduleConfig}
                    setFilter={setFilter}
                    isCheck={isCheck}
                    setIsCheck={setIsCheck}
                    isCheckAll={isCheckAll}
                    setIsCheckAll={setIsCheckAll}
                    handleSelectAll={handleSelectAll}
                    deleteItem={deleteItem}
                    customButtonsForItems={customButtonsForItems}
                    performBulkAction={performBulkAction}
                  />
                ) : (
                  <ModuleListingViewInTableStyle
                    listingState={listingState}
                    appliedFilters={appliedFilters}
                    resetFilters={resetFilters}
                    moduleConfig={moduleConfig}
                    setFilter={setFilter}
                    setSorting={setSorting}
                    isCheck={isCheck}
                    setIsCheck={setIsCheck}
                    isCheckAll={isCheckAll}
                    setIsCheckAll={setIsCheckAll}
                    handleSelectAll={handleSelectAll}
                    deleteItem={deleteItem}
                    customButtonsForItems={customButtonsForItems}
                    performBulkAction={performBulkAction}
                  />
                )}
              </>
            )}
          </>
        )}
      </DashboardLayout>
    );
  };
  return WithListingFunctions;
};

export default ListingViewHOC;
