import React from "react";
import { useParams } from "react-router-dom";
import ManageTargetedMembers from "../../UI/shared/manageTargetedMembers/ManageTargetedMembers";
import moduleConfig from "./moduleConfig";


const ActivityManageMembers = () => {
  let { id } = useParams();
  return (
    <ManageTargetedMembers
      parentId={id}
      moduleConfig={moduleConfig}
    />
  );
};
export default ActivityManageMembers;
