import React from "react";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Dropdown,
  Card,
  Image,
  Badge,
  Button,
  Carousel,
} from "react-bootstrap";
import moment from "moment";
import MailTo from "../../mix/MailTo";
import SendEmail from "../../mix/SendEmail";
import { isEmpty } from "../../../../helpers/CommonHelper";
import ModuleDisplayFieldsController from "../fieldsDisplay/ModuleDisplayFieldsController";
import image from "../../../../images/memberDummyImage.jpg";
import car1 from "../../../../images/car1.jpg";
import car2 from "../../../../images/car2.jpg";
import car3 from "../../../../images/car3.jpg";
import ModuleDisplayDateTime from "../fieldsDisplay/ModuleDisplayDateTime";
//import ReactFancyBox from 'react-fancybox';
//import Lightbox from 'react-image-lightbox';

const ModuleDetailsViewInBlockStyle = ({ item, moduleConfig }) => {
 

  return (
    <>
      <div className=" m-1 p-1 d-flex bg-light border rounded">
        <Dropdown className="me-1">
          <Dropdown.Toggle
            variant="primary"
            className="border"
            id="dropdown-basic"
          >
            Change status
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#/action-1">Expired</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Blocked</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Payment Pending</Dropdown.Item>
            <Dropdown.Item href="#/action-3">
              Sign up request Pending
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {moduleConfig.buttons && moduleConfig.buttons.update === true && (
          <a
            href={`/${moduleConfig.url}/update/${item._id}`}
            className="btn btn-primary  "
            title="Update Item"
          >
            <i className="fas fa-pencil-alt me-1  text-white "> </i>
         <span className="text-white">   Update</span>
          </a>
        )}
      </div>

      <Row className="px-1">
        <Col>
          <Card className="item mb-2">
            <Card.Header className="ps-1 pe-2 py-0">
              <Card.Title className="m-0 p-0 ">
                <div className="d-flex ImageSectionSpacing">
                  <div className="position-relative ms-1 me-2">
                    <Image
                      src={image}
                      width="45px"
                      className="border rounded-circle"
                    />
                    <div className="text-white position-absolute success profile-subimage">
                      <i className="fas fa-check icon-small"></i>
                    </div>
                  </div>

                  <div className="d-flex flex-column ms-1">
                    <span className="m-0 text-bold memberName">
                      {item.name}
                    </span>

                    <span className="font-italic font-small font-weight-normal text-success fs-8">
                      ({item.country})
                    </span>
                  </div>
                </div>
              </Card.Title>

              <div class="card-tools">
                <div>
                  <span class="badge badge-light me-1 text-dark">
                    Member since :{" "}
                    <span class="text-secondary">
                      <ModuleDisplayDateTime value={item.createdAt} />
                    </span>
                  </span>
                  <span class="badge badge-white me-1 text-dark">
                    Status:{" "}
                    {item.status === "1" || item.status === 1 ? (
                      <Badge bg="success">Enabled</Badge>
                    ) : (
                      <Badge bg="warning" text="dark">
                        Disabled
                      </Badge>
                    )}
                  </span>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="p-2">
              <div className="mb-0 details">
                {moduleConfig.fields &&
                  moduleConfig.fields.length &&
                  moduleConfig.fields.map(
                    (
                      {
                        displayName,
                        fieldName,
                        type,
                        showOnListing,
                        displayStyle,
                      },
                      index
                    ) =>
                      showOnListing === true &&
                      fieldName !== "name" &&
                      fieldName !== "status" &&
                      fieldName !== "createdAt" &&
                      fieldName !== "updatedAt" && (
                        <span
                          class="badge badge-light me-1 text-dark mt-1 mb-1 p-2 font-small d-flex"
                          key={index}
                        >
                          {displayName} :{" "}
                          <span class="text-secondary font-small ms-2 ">
                            <ModuleDisplayFieldsController
                              type={type}
                              displayStyle={displayStyle}
                              value={item[fieldName]}
                            />
                          </span>
                        </span>
                      )
                  )}
              </div>
              <Row>
              <Col className="mt-1">
                  <a
                    href=""
                    title="Click to view details"
                    class="badge badge-light me-1 text-dark text-decoration-none p-2 font-small"
                  >
                    Notification :&nbsp;
                    <Badge bg="indigo" className="ms-2" pill>
                      4
                    </Badge>
                  </a>
                  <a
                    href=""
                    title="Click to view details"
                    class="badge badge-light me-1 text-dark text-decoration-none p-2 font-small"
                  >
                    Circle :{" "}
                    <Badge bg="teal" className="ms-2" pill>
                      4
                    </Badge>
                  </a>
                  <a
                    href=""
                    title="Click to view details"
                    class="badge badge-light me-1 text-dark text-decoration-none p-2 font-small"
                  >
                    Events :{" "}
                    <Badge bg="cyan" className="ms-2" pill>
                      4
                    </Badge>
                  </a>
                  <a
                    href=""
                    title="Click to view details"
                    class="badge badge-light me-1 text-dark text-decoration-none p-2 font-small"
                  >
                    Cars :{" "}
                    <Badge bg="pink" className="ms-2" pill>
                      4
                    </Badge>
                  </a>
                  <a
                    href=""
                    title="Click to view details"
                    class="badge badge-light me-1 text-dark text-decoration-none p-2 font-small"
                  >
                    Cars :{" "}
                    <Badge bg="dark" className="ms-2"pill>
                      4
                    </Badge>
                  </a>
                  <a
                    href=""
                    title="Click to view details"
                    class="badge badge-light me-1 text-dark text-decoration-none p-2 font-small"
                  >
                    Cars :{" "}
                    <Badge bg="info" className="ms-2" pill>
                      4
                    </Badge>
                  </a>
                  <a
                    href=""
                    title="Click to view details"
                    class="badge badge-light me-1 text-dark text-decoration-none p-2 font-small"
                  >
                    Cars :{" "}
                    <Badge bg="yellow" className="ms-2"  pill>
                      4
                    </Badge>
                  </a>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="px-1 m">
        <Col xl={4} lg={4} md={12} sm={6} xs={12}>
          <div class="card bg-white mb-2">
            <div className=" card-header m-0 p-1 px-2 bg-white d-flex align-items-center justify-content-between w-100">
              <div className="font-small">
                <p class="m-0 text-bold">Car Details</p>
              </div>
            </div>

            <div class="card-body p-1 px-2">
              <Carousel>
                <Carousel.Item>
                  <img
                    className="d-block mx-auto carousel-image"
                    src={car1}
                    alt="First slide"
                  />
                  <div className="my-1">
                    <h3 className="font-weight-bold mb-0">Ferrari F40</h3>
                    <h6 className="text-primary mb-0">2020</h6>
                    <p className="font-italic mb-0">Grey</p>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block mx-auto carousel-image"
                    src={car2}
                    alt="second slide"
                  />
                  <div className="my-1">
                    <h3 className="font-weight-bold mb-0">Ferrari 458 Italia</h3>
                    <h6 className="text-primary mb-0">2021</h6>
                    <p className="font-italic mb-0">Red</p>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <img
                    className="d-block mx-auto carousel-image"
                    src={car3}
                    alt="Third slide"
                  />
                  <div className="my-1">
                    <h3 className="font-weight-bold mb-0">Ferrari Portofino</h3>
                    <h6 className="text-primary mb-0">2019</h6>
                    <p className="font-italic mb-0">Red</p>
                  </div>
                </Carousel.Item>
              </Carousel>
            </div>
          </div>
        </Col>
        <Col xl={4} lg={4} md={6} sm={6} xs={12}>
          <div class="card bg-white">
            <div className="card-header p-1 px-2 bg-white font-small">
              <p class="m-0 text-bold">Partner</p>
            </div>
            <div class="card-body p-1 px-2 d-flex">
              <div className="">
                <img
                  src={image}
                  width="65"
                  height="65"
                  class="me-2 border  rounded-circle profile-image"
                />
              </div>
              <div className="ms-3">
                <h6 className="text-bold mb-1">Full Name</h6>
                <p class="m-0 font-small text-primary">+9715844679</p>
                <p class="mb-1 font-italic font-small ">abc@abc.com </p>
              </div>
            </div>
          </div>
        </Col>
        <Col xl={4} lg={4} md={6} sm={6} xs={12}>
          <div class="card bg-white">
            <div className="card-header p-1 px-2 bg-white font-small">
              <p class="m-0 text-bold">Upcoming events</p>
            </div>

            <div class="card-body p-1 px-2 ">
              <div class="card mb-1">
                <Row>
                  <Col>
                    <p class="card-header p-1 px-3 text-primary">
                      31/Dec/2022 -{" "}
                      <span className=" text-dark">New Year Celebration</span>
                    </p>
                  </Col>
                </Row>

                <p class="card-header p-1 px-3 text-primary mt-2">
                  18/Mar/2023 -{" "}
                  <span className=" text-dark">
                    All UAE Ferrari Owners Meeting
                  </span>
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="px-1">
        <Col>
          <div class="card bg-white">
            <div className="card-header p-1 px-2 bg-white font-small">
              <p class="m-0 text-bold">Participation History</p>
            </div>

            <ul className="mb-0">
              <li class="font-small mt-2 mb-0">
                <Row>
                  <Col xl={1} lg={2} md={2} sm={2} xs={12} className=" ">
                    <p className="text-primary mb-1">1/Jan/2022</p>
                  </Col>
                  <Col>
                    <p className="ms-3 mb-1">Event 1</p>
                  </Col>
                </Row>
              </li>
              <li class="font-small ">
                <Row>
                  <Col xl={1} lg={2} md={2} sm={2} xs={12}>
                    <p className="text-primary mb-1">1/Jan/2022</p>
                  </Col>
                  <Col>
                    <p className="ms-3 mb-1">Event 2</p>
                  </Col>
                </Row>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ModuleDetailsViewInBlockStyle;
