import { isItemInArray } from "../helpers/arrayHelper";
export const lookupInitialState = {
    data: {},
    isFetching: false,
    hasFetchingError: false,
    initialState: true,
    isSubmitting: false,
    isSubmitted: false,
    hasSubmissionError: false,
    hasDeletingError: false,
    error: ''
};


export const lookupReducer = (state, action) => {
    let lookups = state.data.lookups;
    let newLookups = [];
    switch (action.type) {
        case "FETCH_REQUEST":
            return {
                ...state,
                hasError: false,
                isFetching: true,
            };
        case "FETCH_SUCCESS":
            return {
                data: action.payload,
                hasError: false,
                isFetching: false,
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                hasFetchingError: true,
                isFetching: false,
                error: action.payload
            };

        case "FORM_SUBMISSION_REQUEST":
            return {
                ...state,
                isSubmitting: true,
                hasSubmissionError: false,
            };
            break;
        case "FORM_SUBMISSION_SUCCESS":




            if (lookups && lookups.length > 0) {

                newLookups = lookups.map((lookup, index) => {

                    if (lookup._id === action.payload.parent) {

                        if (!isItemInArray(action.payload._id, lookup.items)) {

                            lookup.items.push(action.payload)
                        }
                        return lookup
                    } else {
                        return lookup
                    }
                })

            }

            return {
                ...state,
                data: { lookups: newLookups },
                isSubmitting: false,
                isSubmitted: true,
                hasSubmissionError: false,
            };
            break;
        case "FORM_SUBMISSION_FAILURE":
            return {
                ...state,
                isSubmitting: false,
                hasSubmissionError: true,
                error: action.payload
            };


        case "DELETE_FAILURE":
            return {
                ...state,
                hasDeletingError: true,
                error: action.payload
            };
        case "DELETE_SUCCESS":

            if (lookups && lookups.length > 0) {
                //console.log("Lookups ==========>", lookups);
                newLookups = lookups.map((lookup, index) => {
                    if (lookup._id === action.payload.parentId) {
                        lookup.items = lookup.items.filter(item => item._id !== action.payload.id)
                        return lookup
                    } else {
                        return lookup
                    }
                })

            }

            return {
                ...state,
                data: { lookups: newLookups },
                isSubmitting: false,
                isSubmitted: false,
                hasSubmissionError: false,
            };



        case "ORDER_UPDATE":
            if (lookups && lookups.length > 0) {

                newLookups = lookups.map((lookup, index) => {
                    if (lookup._id === action.payload.lookupId) {
                        lookup.items = action.payload.items;
                        return lookup
                    } else {
                        return lookup
                    }
                })

            }

            return {
                ...state,
                data: { lookups: newLookups },
                isSubmitting: false,
                isSubmitted: false,
                hasSubmissionError: false,
            };
        case "FORM_INITIAL_STATE":
            return {
                ...state,
                initialState: true,
                isSubmitting: false,
                isSubmitted: false,
                hasSubmissionError: false,
                hasDeletingError: false,
                error: ""

            };

        default:
            return state;
    }
}