import React, { useState, useContext, useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import moduleConfig from "./moduleConfig";
import { Store as toast } from "react-notifications-component";
import { updateInitialState, updateReducer } from "./reducers/updateReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import { toastNotification } from "../../../config/toastNotification";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import { isEmpty } from "../../../helpers/CommonHelper";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSFormDate from "../../UI/forms/CMSFormDate";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import CMSFormImageWithCrop from "../../UI/forms/CMSFormImageWithCrop";
// let breadcrumbs = {
//     listing: {
//         label: moduleConfig.title,
//         url: `/${moduleConfig.url}`
//     },
//     details: {
//         label: "",
//         url: ""
//     },
//     update: {
//         label: "Update",
//     }
// };

const MemberCarUpdate = () => {
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [formInitialValues, setFormInitialValues] = useState({});

  //console.log(updateState);

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let { id, memberId } = useParams();

  console.log(id, memberId);
  let navigate = useNavigate();

  let breadcrumbs = {
    memberListing: {
      label: "Members",
      url: `/members`,
    },
    memberDetails: {
      label: "Member Details",
      url: `/members/${memberId}`,
    },
    listing: {
      label: moduleConfig.title,
      url: `/${moduleConfig.url}/${memberId}`,
    },
    details: {
      label: "Car Details",
      url: `/member-cars/${memberId}/${id}`,
    },
    update: {
      label: "Update",
    },
  };

  const onSubmit = async (data) => {
    //console.log(data);

    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    try {
      const res = await axios.post(
        `${moduleConfig.url}/${memberId}/${id}`,
        data
      ); //
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      navigate(`/${moduleConfig.url}/${memberId}`);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} updated successfully.`,
        type: "success",
      });
    } catch (error) {
      //console.log(error.message);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      console.log(errorMessage);

      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  const loadItem = async () => {
    if (id) {
      updateDispatch({
        type: "FETCH_REQUEST",
      });

      try {
        const res = await axios.get(`${moduleConfig.url}/fetch-item-for-update/${memberId}/${id}`); //
        updateDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        updateDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };
  useEffect(() => {
    //console.log("useEffect called for [id]")
    loadItem();
    setBreadcrumb([
      breadcrumbs.memberListing,
      breadcrumbs.memberDetails,
      breadcrumbs.listing,
      breadcrumbs.details,
      breadcrumbs.update,
    ]);
  }, [id]);
  useEffect(() => {
    if (updateState.data.item && updateState.data.item._id) {
      //if (moduleConfig.fields && moduleConfig.fields.length > 0) {

      // let formFieldsWithFetchData = {}
      // moduleConfig.fields.map((field, index) => {

      //     formFieldsWithFetchData[field.fieldName] = updateState.data.item[field.fieldName]
      // })
      // console.log("Here =>>>>>>>>>>>>>>", formFieldsWithFetchData)
      setFormInitialValues(updateState.data.item);

      //};
    }
  }, [updateState.data]);

  let imageRequirement = {
    dimension: {
      width: 500,
      height: 500,
    },
    allowedExtensions: ["image/png", "image/jpg", "image/jpeg"],
    size: 1024 * 1024 * 2,
  };

  return (
    <DashboardLayout>
      {updateState.status === "fetching" && updateState.isLoading && (
        <ContainerLoading />
      )}
      {updateState.error && updateState.status === "fetchFailed" ? (
        <BlockErrorMessage error={updateState.error} />
      ) : (
        <>
          <ModuleHeader
            moduleTitle={`Update ${moduleConfig.singleItemTitle}`}
            moduleUrl={moduleConfig.url}
          ></ModuleHeader>

          {Object.keys(formInitialValues).length > 0 &&
            updateState.data &&
            updateState.data.item &&
            !isEmpty(updateState.data.item._id) && (
              <section className="content">
                <Card>
                  <Card.Body>
                    {updateState.isLoading && (
                      <div class="overlay position-absolute containerLoading">
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                      </div>
                    )}
                    <Formik
                      initialValues={formInitialValues}
                      validationSchema={yup.object({
                        status: yup.string().required("Required"),
                        dateOfPurchase: yup
                          .string()
                          .required("Required"),
                        purchaseFrom: yup.string().max(50),
                        emirate: yup.string().required("Required").max(50),
                        plateNumber: yup
                          .string()
                          .required("Required")
                          .min(2)
                          .max(10),
                        color: yup.string().required("Required").max(50),
                        otherColor: yup.string().when("color", { is: (color) => color === "Other", then: yup.string().required("Required") }),
                        year: yup
                          .number()
                          .nullable()
                          .required("Required")
                          .transform((value) => (isNaN(value) ? null : value)),
                        chassisNumber: yup
                          .string()
                          .required("Required")
                          .min(17)
                          .max(17),
                        model: yup.string().required("Required").max(50),
                        registrationCard: yup
                          .mixed()
                          .required("Please upload image.")
                          .imageTypeCheck(imageRequirement.allowedExtensions)
                          .imageSizeCheck(imageRequirement.size)
                          .imageDimensionCheck(imageRequirement.dimension),
                        registrationCardBack: yup
                          .mixed()
                          .required("Please upload image.")
                          .imageTypeCheck(imageRequirement.allowedExtensions)
                          .imageSizeCheck(imageRequirement.size)
                          .imageDimensionCheck(imageRequirement.dimension),
                      })}
                      enableReinitialize={true}
                      validateOnChange={true}
                      validateOnBlur={false}
                      onSubmit={async (values) => {
                        const formData = new FormData();
                        for (let name in values) {
                          if (Array.isArray(values[name])) {
                            formData.append(name, JSON.stringify(values[name]));
                          } else {
                            formData.append(name, values[name]);
                          }
                        }

                        onSubmit(formData);
                      }}
                    >
                      {(form) => (
                        <Form onSubmit={form.handleSubmit}>
                          {/* {console.log(form)} */}
                          {updateState.error && (
                            <FormErrorMessage error={updateState.error} />
                          )}

                          <Row>
                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormSelect
                                name="model"
                                label="Model"
                                required={true}
                                form={form}
                                options={updateState.data.models}
                              />
                            </Col>

                            {form.values.model === "Other" && (
                              <Col xs={12} s={12} md={4} lg={4}>
                                <CMSFormInput
                                  name="otherModel"
                                  label="Other Model"
                                  required={true}
                                  form={form}
                                />
                              </Col>
                            )}

                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormInput
                                name="chassisNumber"
                                label="Chassis Number"
                                required={true}
                                form={form}
                              />
                            </Col>
                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormSelect
                                name="year"
                                label="Year"
                                required={true}
                                form={form}
                                options={updateState.data.years}
                              />
                            </Col>

                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormSelect
                                name="color"
                                label="Color"
                                required={true}
                                form={form}
                                options={updateState.data.colors}
                              />
                            </Col>
                            {form.values.color === "Other" && (
                              <Col xs={12} s={12} md={4} lg={4}>
                                <CMSFormInput
                                  name="otherColor"
                                  label="Other Color"
                                  required={true}
                                  form={form}

                                />
                              </Col>
                            )}
                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormInput
                                name="plateNumber"
                                label="Plate Number"
                                required={true}
                                form={form}
                              />
                            </Col>

                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormSelect
                                name="emirate"
                                label="Emirate"
                                required={true}
                                form={form}
                                options={updateState.data.emirates}
                              />
                            </Col>

                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormInput
                                name="purchaseFrom"
                                label="Purchase From"
                                required={false}
                                form={form}
                              />
                            </Col>
                            <Col xs={12} s={12} md={4} lg={4}>
                              <div className="form-group ">
                                <CMSFormDate
                                  name="dateOfPurchase"
                                  label="Date of Purchase"
                                  form={form}
                                  required={true}
                                  value={
                                    new Date(form.values["dateOfPurchase"])
                                  }
                                  minDate={new Date("1950-12-01")}
                                  maxDate={new Date()}
                                  disabled={false}
                                />
                              </div>
                            </Col>




                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormSelect
                                name="status"
                                label="Status"
                                required={true}
                                form={form}
                                options={[
                                  {
                                    value: "",
                                    label: "Select Status ...",
                                  },
                                  { value: "1", label: "Active" },
                                  {
                                    value: "0",
                                    label: "Archived",
                                  },

                                ]}
                              />
                            </Col>


                          </Row>


                          <Row>
                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormImageWithCrop
                                label="Registration Card(Front)"
                                name="registrationCard"
                                form={form}
                                required={true}
                                aspect={1 / 1}
                                outputSize={imageRequirement.dimension}
                              />
                            </Col>
                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormImageWithCrop
                                label="Registration Card(Back)"
                                name="registrationCard"
                                form={form}
                                required={true}
                                aspect={1 / 1}
                                outputSize={imageRequirement.dimension}
                              />
                            </Col>
                          </Row>
                          <button className="btn btn-primary" type="submit">
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </section>
            )}
        </>
      )}
    </DashboardLayout>
  );
};
export default MemberCarUpdate;
