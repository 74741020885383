import React from "react";
import { Alert, Badge, Button, Card, Table } from "react-bootstrap";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import { getCurrencyCode } from "../../../../../helpers/CommonHelper";

const MemberRecentPayments = ({ items }) => {
  const payments = [...items].reverse();

  return (
    <div className="member-recent-registrations">
      <Card className="card-primary">
        <Card.Header>
          <Card.Title>
            Recent Payment(s){" "}
            {items &&
              items.length > 0 &&
              "- Total " + items.length + " item(s)"}
          </Card.Title>
        </Card.Header>
        <Card.Body className="p-0 m-0 overflow-auto">


          {!items || items.length <= 0 ? (
            <Alert variant="warning mb-0">No records found.</Alert>
          ) : (
            <>
              <div className="registrationTable ">
                <Table striped bordered hover size="sm" className="p-0 m-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Purpose</th>
                      <th>Method</th>
                      <th>Total Amount</th>
                      <th>Paid / Payable Amount</th>
                      <th>Payment Status</th>
                      <th>Payment Date/Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payments.map((item, index) => {
                      return (
                        <tr index={index}>
                          <td>{index + 1}</td>
                          <td className="text-center">
                            <Button
                              href={`/payments/${item._id}`}
                              variant="link"
                            >
                              {item.purpose}
                            </Button>
                          </td>
                          <td className="text-center">{item.method}</td>
                          <td className="text-center">
                            {item.totalAmount.$numberDecimal} {" "}
                            {getCurrencyCode()}
                          </td>
                          <td className="text-center">
                            {item.paidAmount.$numberDecimal} {" "}
                            {getCurrencyCode()}
                          </td>
                          <td className="text-center">
                            <ModuleDisplayBoolean
                              value={item.status}
                              trueValue="Paid"
                              falseValue="Pending"
                            />
                          </td>
                          <td>
                            <ModuleDisplayDateTime value={item.date} />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default MemberRecentPayments;
