import React from 'react'
import UpdateViewHOC from '../../../HOC/module/UpdateViewHOC';
import moduleConfig from "./moduleConfig"
let breadcrumbs = {
    listing: {
        label: moduleConfig.title,
        url: `/${moduleConfig.url}`
    },
    details: {
        label: "",
        url: ""
    },
    update: {
        label: "Update",
    }
};

const UsefulLinkUpdate = () => {

    const Component = UpdateViewHOC(breadcrumbs);
    return <Component
        moduleConfig={moduleConfig}

    />;
}
export default UsefulLinkUpdate;
