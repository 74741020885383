import React, { useState } from 'react';
import { Link } from 'react-router-dom';


import useConfirm from '../../../../../../HOC/custom/useConfirm';
import ModuleDisplayFieldsController from '../../../fieldsDisplay/ModuleDisplayFieldsController';

//import ReactFancyBox from 'react-fancybox';

const ModuleDataTableRows = ({ moduleConfig, items, deleteItem, customButtonsForItems, isCheck, setIsCheck, setIsCheckAll, pagination }) => {
    const { confirm } = useConfirm();
    const fields = moduleConfig.fields;
    const itemsStartNumber = (((pagination.currentPage - 1) * pagination.limit))
    // const [isCheck, setIsCheck] = useState([]);
    // const [isCheckAll, setIsCheckAll] = useState(false);

    //console.log(customButtonsForItems);

    const confirmAndDelete = async (id) => {
        const isConfirmed = await confirm(`Do you really want to delete this ${moduleConfig.singleItemTitle}?`);

        if (isConfirmed) {
            await deleteItem(id)
        }
    }

    const handleCheckBoxClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
        setIsCheckAll(false)
    };

    //console.log(isCheck);

    return <>
        {(items && items.length) && (
            items.map((item, itemIndex) => {
                return <tr key={itemIndex}>

                    {(moduleConfig.bulkActions.length > 0) &&
                        <td className="checkboxCell">
                            <input

                                type="checkbox"
                                id={item._id}
                                name={item._id}
                                onChange={handleCheckBoxClick}
                                checked={isCheck.includes(item._id)}

                            /></td>
                    }



                    <td className='text-center' >{itemsStartNumber + itemIndex + 1}</td>
                    {
                        (fields && fields.length) && (
                            fields.map(({ displayName, fieldName, type, showOnListing, displayStyle }, index) => (
                                (showOnListing === true) && (


                                    <td
                                        key={index}
                                        className={(fieldName === 'status') ? 'text-center' : ''}
                                    >
                                        <ModuleDisplayFieldsController type={type} displayStyle={displayStyle} value={item[fieldName]} />



                                    </td>

                                )

                            ))
                        )
                    }

                    <td className={`text-center record-actions  ${(customButtonsForItems && customButtonsForItems.length > 0) && "MoreItem" + customButtonsForItems.length}`}>
                        {
                            (
                                (moduleConfig.buttons && moduleConfig.buttons.view === true) ||
                                (moduleConfig.buttons && moduleConfig.buttons.update === true) ||
                                (moduleConfig.buttons && moduleConfig.buttons.delete === true)
                            ) &&
                            <>




                                {
                                    (moduleConfig.buttons && moduleConfig.buttons.view === true) &&
                                    <Link to={`/${moduleConfig.url}/${item._id}`} className="btn btn-outline-secondary btn-sm" >
                                        <i className="fas fa-eye">
                                        </i>
                                    </Link>
                                }

                                {(moduleConfig.url !== 'admins' || item._id !== '63513fa2fd0f8d2e5e5c29a5') && <>

                                    {
                                        (moduleConfig.buttons && moduleConfig.buttons.update === true) &&
                                        <Link to={`/${moduleConfig.url}/update/${item._id}`} className="btn btn-outline-secondary btn-sm" href="#">
                                            <i className="fas fa-pencil-alt">
                                            </i>
                                        </Link>
                                    }

                                    {
                                        (moduleConfig.buttons && moduleConfig.buttons.delete === true) &&
                                        <span onClick={() => confirmAndDelete(item._id)} className="btn btn-outline-secondary btn-sm">
                                            <i className="fas fa-trash">
                                            </i>
                                        </span>
                                    }

                                </>}




                                {
                                    (customButtonsForItems && customButtonsForItems.length > 0) &&

                                    customButtonsForItems.map(({ label, className, url }, index) => (
                                        <Link to={`/${moduleConfig.url}/${url}/${item._id}`} className="btn btn-outline-secondary btn-sm" href="#" key={index}>
                                            <i className={className}>
                                            </i>
                                        </Link>
                                    ))
                                }

                            </>
                        }
                    </td>

                </tr>
            })
        )}
    </>;
};

export default ModuleDataTableRows;
