import React, { useState, useReducer } from "react";
import Button from "react-bootstrap/Button";
import { Form, Formik } from "formik";
import { formReducer, formInitialState } from "../../../reducers/formReducer";
import Modal from "react-bootstrap/Modal";
import * as yup from "yup";
import axios from "../../../config/axios";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import FormErrorMessage from "../errorMessages/FormErrorMessage";
import CMSFormInput from "../forms/CMSFormInput";
import CMSFormTextArea from "../forms/CMSFormTextArea";
import BlockSuccessMessage from "../errorMessages/BlockSuccessMessage";

function SendEmail({ email }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formState, formDispatch] = useReducer(formReducer, formInitialState);

  const onSubmit = async (data, resetForm) => {
    formDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(`general/send-email`, data);

      //console.log(res)
      if (res && res.status && res.status === 201) {
        resetForm();
        formDispatch({
          type: "FORM_SUBMISSION_SUCCESS",
          payload: res.data,
        });
        handleClose();
        toast.addNotification({
          ...toastNotification,
          title: "Success!",
          message: `Email sent successfully.`,
          type: "success",
        });
        formDispatch({
          type: "FORM_INITIAL_STAGE",
        });
      } else {
        formDispatch({
          type: "FORM_SUBMISSION_FAILED",
          payload: "email sending failed.",
        });
        setTimeout(() => {
          formDispatch({
            type: "FORM_INITIAL_STAGE",
          });
        }, 5000);
      }
    } catch (error) {
      formDispatch({
        type: "FORM_SUBMISSION_FAILED",
        payload: error,
      });
      setTimeout(() => {
        formDispatch({
          type: "FORM_INITIAL_STAGE",
        });
      }, 5000);
    }
  };

  return (
    <>
      <span className="emailLink" variant="link" onClick={handleShow}>
        {email}
      </span>
      <Formik
        initialValues={{
          email: email,
          subject: "",
          message: "",
        }}
        validationSchema={yup.object({
          email: yup.string().required("Required").email(),
          subject: yup.string().required("Required").max(100),
          message: yup.string().required("Required"),
        })}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnBlur={false}
        onSubmit={async (values, { resetForm }) => {
          onSubmit(values, resetForm);
        }}
      >
        {(form) => (
          <Modal show={show} onHide={handleClose}>
            {formState.isFormSubmitting && (
              <div class="overlay">
                <i class="fas fa-2x fa-sync-alt text-white fa-spin"></i>
              </div>
            )}
            <Form onSubmit={form.handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Send Email</Modal.Title>
              </Modal.Header>
              {formState.isFormSubmitted ? (
                <Modal.Body>
                  <BlockSuccessMessage
                    heading="Email Sent!"
                    message={`Email is sent successfully to \"${email}\".`}
                  />
                </Modal.Body>
              ) : (
                <>
                  <Modal.Body>
                    {formState.hasSubmissionError && (
                      <FormErrorMessage error={formState.formSubmissionError} />
                    )}

                    <CMSFormInput
                      name="email"
                      type="email"
                      label="Email"
                      value={email}
                      form={form}
                      disabled
                    />

                    <CMSFormInput
                      name="subject"
                      type="text"
                      label="Subject"
                      form={form}
                    />

                    <CMSFormTextArea
                      name="message"
                      label="Message"
                      form={form}
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                    <Button variant="primary" type="submit">
                      Submit
                    </Button>
                  </Modal.Footer>
                </>
              )}
            </Form>
          </Modal>
        )}
      </Formik>
    </>
  );
}

export default SendEmail;
