import React from "react";
import { Alert, Col, Row, Card } from "react-bootstrap";
import StatsListing from "../../shared/infoListing/StatsListing";
import { Link } from "react-router-dom";
import ReactFancyBox from "react-fancybox";

const SameMemberCarsConflict = ({ item }) => {
  var serverUploadsUrl = process.env.REACT_APP_BACKEND_URL + "public/";
  const getItems = () => {
    let items = [];

    items = [...items, { text: "Year", value: "2023", type: "text" }];

    items = [...items, { text: "Color", value: "Other", type: "text" }];

    items = [...items, { text: "Other Color", value: "Green", type: "text" }];

    items = [
      ...items,
      {
        text: "Chassis Number",
        value: "12356791346789",
        type: "text",
        highlight: true,
      },
    ];

    return items;
  };
  return (
    <Row className="mt-3">
      <Col xl={12} lg={12} md={12} sm={12} xs={12}>
        <div className="pb-2 pt-1 px-1 mb-0 dashboardConflictContainer">
          <Row className="mt-0">
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <Alert key="info" variant="warning" className="py-1 px-3 mb-1">
                Below user has same chassis number for his/her cars.
                <br />
              </Alert>
            </Col>

            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <div class="info-box bg-gradient-white mb-0">
                <div class="info-box-content">
                  <span class="info-box-text">
                    <span className="m-0 text-bold text-dark memberName">
                      <Link
                        to={`/members/${item._id}`}
                        className="direct-chat-name text-primary btn btn-link"
                        title="View Item details"
                      >
                        {item.fullName}
                      </Link>
                    </span>
                  </span>

                  <div class="progress">
                    <div class="progress-bar"></div>
                  </div>
                  <Row>
                    {console.log(item, "------------->>>>>>>>")}
                    <Col xl={6} lg={6} md={6} sm={6} xs={12}>
                      <span class="info-box-number dashboardCarDetails">
                        <div class="mb-0 details undefined flex-column">
                          <span class=" text-dark font-small mb-1 d-flex">
                            Model :
                            <span class="text-secondary font-small ms-2">
                              {item.car1.model === "Other"
                                ? item.car1.otherModel
                                : item.car1.model}
                            </span>
                          </span>
                          <span class=" text-dark font-small mb-1 d-flex">
                            Year :
                            <span class="text-secondary font-small ms-2">
                              {item.car1.year}
                            </span>
                          </span>
                          <span class=" text-dark font-small mb-1 d-flex">
                            Plate Number :
                            <span class="text-secondary font-small ms-2">
                              {item.car1.plateNumber}
                            </span>
                          </span>
                          <span class=" text-dark font-small mb-1 d-flex">
                            Date of Purchase :
                            <span class="text-secondary font-small ms-2">
                              {item.car1.dateOfPurchase}
                            </span>
                          </span>
                          <span class=" me-1 text-dark mt-1 mb-1 p-2 font-small d-flex badge bg-yellow">
                            Chassis Number :
                            <span class="text-secondary font-small ms-2">
                              {item.car1.chassisNumber}
                            </span>
                          </span>
                        </div>
                        <div>
                          <div className="mb-2 mt-xl-0 mt-lg-0 mt-md-2 mt-2 d-flex align-items-center justify-content-start">
                            <div className=" d-flex flex-column align-items-end justify-content-end">
                              {item.car1.registrationCard && (
                                <div className="registrationCard">
                                  <ReactFancyBox
                                    thumbnail={`${serverUploadsUrl}${item.car1.registrationCard}`}
                                    image={`${serverUploadsUrl}${item.car1.registrationCard}`}
                                  />
                                </div>
                              )}

                              <span class=" text-dark end font-small ">
                                <Link
                                  to={`/member-cars/${item._id}/${item.car1._id}`}
                                  className="d-flex align-items-center justify-content-end me-1"
                                  title="View Item details"
                                >
                                  view more
                                </Link>
                              </span>
                            </div>
                          </div>
                        </div>
                      </span>
                    </Col>
                    <Col
                      xl={6}
                      lg={6}
                      md={6}
                      sm={6}
                      xs={12}
                      className="separation"
                    >
                      <span class="info-box-number dashboardCarDetails">
                        <div class="mb-0 details undefined flex-column">
                          <span class=" text-dark font-small mb-1 d-flex">
                            Model :
                            <span class="text-secondary font-small ms-2">
                              {item.car2.model}
                            </span>
                          </span>
                          <span class=" text-dark font-small mb-1 d-flex">
                            Year :
                            <span class="text-secondary font-small ms-2">
                              {item.car2.year}
                            </span>
                          </span>
                          <span class=" text-dark font-small mb-1 d-flex">
                            Plate Number :
                            <span class="text-secondary font-small ms-2">
                              {item.car2.plateNumber}
                            </span>
                          </span>
                          <span class=" text-dark font-small mb-1 d-flex">
                            Date of Purchase :
                            <span class="text-secondary font-small ms-2">
                              {item.car2.dateOfPurchase}
                            </span>
                          </span>
                          <span class=" me-1 text-dark mt-1 mb-1 p-2 font-small d-flex badge bg-yellow">
                            Chassis Number :
                            <span class="text-secondary font-small ms-2">
                              {item.car2.chassisNumber}
                            </span>
                          </span>
                        </div>

                        <div>
                          <div className="mb-2 mt-xl-0 mt-lg-0 mt-md-2 mt-2 d-flex align-items-center justify-content-start">
                            <div className="">
                              {item.car2.registrationCard && (
                                <div className="registrationCard">
                                  <ReactFancyBox
                                    thumbnail={`${serverUploadsUrl}${item.car2.registrationCard}`}
                                    image={`${serverUploadsUrl}${item.car2.registrationCard}`}
                                  />
                                </div>
                              )}

                              <span class=" text-dark end font-small ">
                                <Link
                                  to={`/member-cars/${item._id}/${item.car2._id}`}
                                  className="d-flex align-items-center justify-content-end me-1"
                                  title="View Item details"
                                >
                                  View more
                                </Link>
                              </span>
                            </div>
                          </div>
                        </div>
                      </span>
                      <p class="progress-description mb-3"> </p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default SameMemberCarsConflict;
