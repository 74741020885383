import React, { useReducer, useEffect, useContext } from "react";
import {
  Alert,
  Card,
  Col,
  Row,
  Table,
  Badge,
  Image,
  ButtonToolbar,
  OverlayTrigger,
  Button,
  Popover,
  Stack,
  CloseButton,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";
import { FaQuestion } from "react-icons/fa6";
import {
  createInitialState,
  createReducer,
} from "../../reducers/createReducer";
import axios from "../../../../../config/axios";
import { toastNotification } from "../../../../../config/toastNotification";
import { Store as toast } from "react-notifications-component";
import {
  listingInitialState,
  listingReducer,
} from "../../../members/reducers/listingReducer";
const Item = ({
  item,
  handleCheckBoxClick,
  isCheck,
  moduleConfig,
  confirmAndDelete,
  handleCreateConflict,
  confirmAndRemoveConflict,
  confirmAndDisabled,
  confirmAndEnabled,
  customButtonsForItems,
}) => {
  const [show, setShow] = React.useState(false);
  const handleToggle = () => {
    setShow((prev) => !prev);
  };
  const [addState, addDispatch] = useReducer(createReducer, createInitialState);
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const loadItems = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.post(`lookups/has-value`, {
        lookupName: "Ferrari Car Models",
        value: item.otherModel,
      });
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data,"----------->>>>>>>>>>>>>");
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    if(item){
      loadItems();
    }

  }, [item]);
  const onSubmit = async (data) => {
    addDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    console.log(data);
    try {
      const res = await axios.post(`lookups/quick-add `, data); //
      console.log(res);

      addDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      loadItems();
      toast.addNotification({
        ...toastNotification,
        model: "Success!",
        message: `added successfully.`,
        type: "success",
      });
    } catch (error) {
      console.log(error.response);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.message : error.message;

      addDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        addDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };
  const popoverHoverFocus = (
    <Popover id="popover-basic">
      <Popover.Header>
        <Stack as="Stack" direction="horizontal" className="align-items-center">
          <strong className="flex-grow-1 mb-0">New Model</strong>
          <CloseButton onClick={handleToggle} />
        </Stack>
      </Popover.Header>
      <Popover.Body>
        It seems we don't have this model in our current list. Would you like
        to include it? <br />
        <Button
          className="py-0 px-1 mt-2 font-small"
          variant="info"
          onClick={() =>
            onSubmit({
              lookupName: "Ferrari Car Models",
              value: item.otherModel,
            })
          }
        >
          Add to List
        </Button>
      </Popover.Body>
    </Popover>
  );
  const getItems = () => {
    let items = [];
    if (item.year) {
      items = [...items, { text: "Year", value: item.year, type: "text" }];
    }
    if (item.color) {
      items = [...items, { text: "Color", value: item.color, type: "text" }];
    }
    if (item.color === "Other" && item.otherColor) {
      items = [
        ...items,
        { text: "Other Color", value: item.otherColor, type: "text" },
      ];
    }
    if (item.plateNumber) {
      items = [
        ...items,
        { text: "Plate Number", value: item.plateNumber, type: "text" },
      ];
    }
    if (item.emirate) {
      items = [
        ...items,
        { text: "Emirate", value: item.emirate, type: "text" },
      ];
    }
    if (item.dateOfPurchase) {
      items = [
        ...items,
        {
          text: "Date Of Purchase",
          value: item.dateOfPurchase,
          type: "text",
        },
      ];
    }
    if (item.purchaseFrom) {
      items = [
        ...items,
        {
          text: "Purchase From",
          value: item.purchaseFrom,
          type: "text",
        },
      ];
    }

    return items;
  };
  return (
    <Card className="item mb-2">
      <Card.Header className="ps-1 pe-2 py-0 pt-1">
        <Card.Title className="d-flex m-0 p-0">
          <div className="d-flex ImageSectionSpacing">
            {/* <div className="checkboxCol me-2 ms-2 mt-2">
              <input
                type="checkbox"
                id={item._id}
                name={item._id}
                onChange={handleCheckBoxClick}
                checked={isCheck.includes(item._id)}
              />
            </div> */}
{console.log(item,"11111")}
            <div className="d-flex flex-column ms-1">
              <span className="m-0 text-bold d-flex align-items-center  memberName">
                {item.model === "Other" ? item.otherModel : item.model}{" "}
                {item.model === "Other" && "(Other)"}
                {item.model === "Other" && item.otherModel && listingState && listingState.data && listingState.data.isAvailable === false && (
                  <ButtonToolbar style={{ padding: "0px 0" }}>
                    <OverlayTrigger
                      trigger="click"
                      placement="top"
                      overlay={popoverHoverFocus}
                      show={show}
                      onToggle={handleToggle}
                    >
                      <Button className="invisibleButton" title="Add new value">
                        <FaQuestion className="text-info font-small" />
                      </Button>
                    </OverlayTrigger>
                  </ButtonToolbar>
                  // <FaQuestion className=" ms-2 text-danger " />
                )}
                <span>
                  {item.hasConflict === "1" || item.hasConflict === 1 ? (
                    <div
                      className="ms-1  bg-danger text-white statusIcon profile-subimage"
                      title="Car has conflict"
                    >
                      <i className="fas fa-question icon-small"></i>
                    </div>
                  ) : null}
                </span>
              </span>

              <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                <span className="membershipId">
                  {item.chassisNumber && item.chassisNumber}
                </span>
              </span>
            </div>
          </div>
        </Card.Title>

        <div class="card-tools  mb-1">
          <div className="d-flex align-content-center justify-content-end">
            <span class="badge badge-light me-1 text-dark">
              {item.createdAt && (
                <>
                  Created Date :{" "}
                  <span class="text-secondary">
                    <ModuleDisplayDateTime value={item.createdAt} />{" "}
                  </span>
                  
                </>
              )}
            </span>

            <span class="badge badge-white me-1 text-dark">
              Status:{" "}
              {item.status === "1" || item.status === 1 ? (
                <Badge bg="success">Active</Badge>
              ) : (
                <Badge bg="warning" text="dark">
                  Archived {item.archivedBy && <>({item.archivedBy})</>}
                </Badge>
              )}
            </span>
          </div>

          <div className=" text-right me-xl-2  mt-1">
            <Link
              title="View Item details"
              to={`/${moduleConfig.url}/${item.memberId}/${item._id}`}
              className="btn btn-outline-secondary listingActionIcon"
            >
              <i className="fas fa-eye"></i>
            </Link>

            <Link
              title="Update Item"
              to={`/${moduleConfig.url}/update/${item.memberId}/${item._id}`}
              className="btn btn-outline-secondary listingActionIcon"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>

            {(item.status === "1" || item.status === 1) && (
              <span
                title="Archive Item"
                onClick={() => confirmAndDisabled(item._id, item.memberId)}
                className="btn btn-outline-secondary listingActionIcon"
              >
                <i className="fas fa-trash"></i>
              </span>
            )}

            {(item.status === "0" || item.status === 0) && (
              <span
                title="Restore Item"
                onClick={() => confirmAndEnabled(item._id, item.memberId)}
                className="btn btn-outline-secondary listingActionIcon"
              >
                <i className="fa fa-arrow-up"></i>
              </span>
            )}

            {(item.hasConflict === "0" || item.hasConflict === 0) && (
              <span
                title="Create Conflict"
                onClick={() => handleCreateConflict(item._id, item.memberId)}
                className="btn btn-outline-secondary listingActionIcon"
              >
                <i className="fas fa-question"></i>
              </span>
            )}

            {(item.hasConflict === "1" || item.hasConflict === 1) && (
              <span
                title="Remove Conflict"
                onClick={() => confirmAndRemoveConflict(item._id)}
                className="btn btn-outline-secondary listingActionIcon"
              >
                <i className="fas fa-undo"></i>
              </span>
            )}
          </div>
        </div>
      </Card.Header>
      <Card.Body className="p-0">
        <div className="details">
          {console.log(item)}
          <StatsListing items={getItems()} />
        </div>

        {(item.hasConflict === "1" || item.hasConflict === 1) && (
          <Alert variant="danger mb-0 p-1">
            <span className="text-bold">Conflict Reason : </span>
            {item.conflictReason}
          </Alert>
        )}
      </Card.Body>
    </Card>
  );
};

export default Item;
