import React from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayFieldsController from "../../../../UI/modules/fieldsDisplay/ModuleDisplayFieldsController";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
import memberDummyImage from "../../../../../images/memberDummyImage.jpg";
import memberStatusEmailVerificationRequired from "../../../../../images/memberStatusEmailVerificationRequired.png";
import memberStatusSignupRequest from "../../../../../images/memberStatusSignupRequest.png";
import memberStatusRejected from "../../../../../images/memberStatusRejected.png";
import memberStatusRegistrationPaymentPending from "../../../../../images/memberStatusRegistrationPaymentPending.png";
import memberStatusMember from "../../../../../images/memberStatusMember.jpg";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";

import ModuleDisplayDate from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDate";
import ModuleDisplayRichText from "../../../../UI/modules/fieldsDisplay/ModuleDisplayRichText";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";

const Details = ({
  item,
  moduleConfig,
  confirmAndDelete,
  confirmAndBlock,
  confirmAndUnblock,
  customButtonsForItems,
}) => {
  const getItems = () => {
    let items = [];
    if (item.source) {
      items = [...items, { text: "Source", value: item.source, type: "text" }];
    }
    if (item.type) {
      items = [...items, { text: "Type", value: item.type, type: "text" }];
    }
    if (item.target) {
      items = [...items, { text: "Target", value: item.target, type: "text" }];
    }
    if (item.target && item.target !== "All-Members") {
      items = [
        ...items,

        {
          text:
            "Target " +
            (item.target === "Selected-Circles"
              ? " Circles "
              : item.target === "Selected-Members"
                ? " Members "
                : null) +
            "Count",
          value:
            item.target === "Selected-Circles"
              ? item.targetedCirclesCount
                ? item.targetedCirclesCount
                : "Targets not added yet"
              : item.target === "Selected-Members"
                ? item.targetedMembersCount
                  ? item.targetedMembersCount
                  : "Targets not added yet"
                : 0,
          type: "text",
        },
      ];
    }
    return items;
  };
  return (
    <>
      <Row>
        <Col>
          <Card className="item  mb-2">
            <Card.Header className="ps-1 pe-2 py-0">
              <Card.Title className="m-0 p-0 ">
                <div className="d-flex ImageSectionSpacing">
                  <div className="position-relative ms-1 me-2">
                    <div className="ms-1 me-2">
                      <i className="fas fa-newspaper position-relative usericon ">
                        {(item.status === "0" || item.status === 0) && (
                          <i className="fas fa-ban position-absolute ms-1  banned"></i>
                        )}
                      </i>
                    </div>
                  </div>

                  <div className="d-flex flex-column ms-1">
                    <span className="m-0 text-bold memberName">
                      {item.title}
                    </span>

                    <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                      <span className="membershipId">
                        <ModuleDisplayDate value={item.date} />
                      </span>
                    </span>
                  </div>
                </div>
              </Card.Title>

              <div className="card-tools">
                <div className=" text-right mt-xl-0 mt-1">
                  <span className="badge badge-light me-1 text-dark">
                    Status :{" "}
                    <span class="text-secondary">
                      <ModuleDisplayBoolean
                        value={item.status}
                        trueValue="Enabled"
                        falseValue="Disabled"
                      />{" "}
                    </span>
                  </span>

                  <span class="badge badge-light me-1 text-dark">
                    Created Date/Time :{" "}
                    <span class="text-secondary">
                      <ModuleDisplayDate value={item.createdAt} />{" "}
                    </span>
                  </span>
                </div>
              </div>
            </Card.Header>

            <Card.Body className="p-2">
              <StatsListing items={getItems()} />

              <StatsListing
                heading="Stats"
                clickable={true}
                items={[
                  {
                    title: "Likes",
                    value: item.stats.likesCount,
                    url: `/news-feed-likes/${item._id}`,
                  },
                  {
                    title: "Comments",
                    value: item.stats.commentsCount,
                    url: `/news-feed-comments/${item._id}`,
                  },
                ]}
              />

              {item.shortDescription && (
                <p>
                  <span class="badge badge-light me-1 text-dark mt-1 mb-1 p-2 font-small d-flex">
                    Short Description :{" "}
                  </span>
                  <div class="font-weight-normal  ms-2">
                    {item.shortDescription}
                  </div>
                </p>
              )}

              {item.description && (
                <p>
                  <div class="badge badge-light me-1 text-dark mt-1 mb-1 p-2 font-small d-flex">
                    Description :
                  </div>
                  <div class="me-1  mt-1 mb-1 p-2 ">
                    <ModuleDisplayRichText value={item.description} />
                  </div>
                </p>
              )}
              {console.log(item, "========")}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Details;
