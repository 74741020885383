
export const listingInitialState = {
    data: [],
    isFetching: false,
    hasFetchingError: false,
    hasError: false,
    error: ''
};

export const listingReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_REQUEST":
            return {
                ...state,
                isFetching: true,
                hasFetchingError: false
            };
        case "FETCH_SUCCESS":
            return {
                ...state,
                isFetching: false,
                data: action.payload
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                hasFetchingError: true,
                isFetching: false,
                error: action.payload
            };
        case "DELETE_REQUEST":
            return {
                ...state,
                isFetching: true,
                hasFetchingError: false,
                hasError: false,
            };
        case "DELETE_FAILURE":
            return {
                ...state,
                hasError: true,
                isFetching: false,
                error: action.payload
            };



        case "BULK_ACTION_REQUEST":
            return {
                ...state,
                isFetching: true,
                hasFetchingError: false,
                hasError: false,
            };
        case "BULK_ACTION_FAILURE":
            return {
                ...state,
                hasError: true,
                isFetching: false,
                error: action.payload
            };




        case "REMOVE_ERROR":
            return {
                ...state,
                hasError: false,
                hasFetchingError: false,
                error: ''
            };
        default:
            return state;
    }
};