import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import ContentSelectionMenu from "./ContentSelectionMenu";
import { ContainerLoading } from "../loading/ContainerLoading";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import ActivityTimeline from "./ActivityTimeline";
import CollapseActivity from "./CollapseActivity";
import MemberContent from "./MemberContent";
import QuickTabs from "./QuickTabs";
import PaymentContent from "./PaymentContent";
import ContactUsContent from "./ContactUsContent";
import ImportantNotifications from "./ImportantNotifications";

function DashboardContent() {
  const [loading, setLoading] = useState(false);
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [view, setView] = useState(false);
  useEffect(() => {
    setBreadcrumb([]);
  }, []);
  return (
    <>
      {loading === true && <ContainerLoading />}
      {loading === false && (
        <>
          <Row className="hideOnMobile">
            <Col>
              <Card className="mt-2">
                <Card.Body className="p-0">
                  <ContentSelectionMenu
                    activeKey="quick"
                    setParent={setLoading}
                    parent={loading}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="showOnMobile">
            <Col xs={12}>
              <QuickTabs view={view} setView={setView} />
            </Col>
            {view && (
              <>
                {/* <Col xs={12} >
                  <CollapseActivity  />
                </Col>
                <Col xs={12}>
                  <MemberContent />
                </Col>
                <Col xs={12}>
                  <PaymentContent />
                </Col>
                <Col xs={12}>
                  <ContactUsContent />
                </Col> */}
                <Col xs={12}>
                  <ImportantNotifications />
                </Col>
              </>
            )}
          </Row>
        </>
      )}
    </>
  );
}

export default DashboardContent;
