import React, { useState, useReducer } from "react";
import {
  Alert,
  Card,
  Col,
  Row,
  Table,
  Badge,
  Image,
  Button,
  Modal,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayFieldsController from "../../../../UI/modules/fieldsDisplay/ModuleDisplayFieldsController";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
import memberDummyImage from "../../../../../images/memberDummyImage.jpg";
import memberStatusEmailVerificationRequired from "../../../../../images/memberStatusEmailVerificationRequired.png";
import memberStatusSignupRequest from "../../../../../images/memberStatusSignupRequest.png";
import memberStatusRejected from "../../../../../images/memberStatusRejected.png";
import memberStatusRegistrationPaymentPending from "../../../../../images/memberStatusRegistrationPaymentPending.png";
import memberStatusMember from "../../../../../images/memberStatusMember.jpg";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";

import useConfirm from "../../../../../HOC/custom/useConfirm";
import axios from "../../../../../config/axios";
import moduleConfig from "../../moduleConfig";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../../../config/toastNotification";
import { ContainerLoading } from "../../../../UI/loading/ContainerLoading";
import BlockSuccessMessage from "../../../../UI/errorMessages/BlockSuccessMessage";
import FormErrorMessage from "../../../../UI/errorMessages/FormErrorMessage";
import CMSFormInput from "../../../../UI/forms/CMSFormInput";
import { Multiselect } from "multiselect-react-dropdown";

import image from "../../../../../images/memberDummyImage.jpg";
import ReactFancyBox from "react-fancybox";
import VideoExpanded from "../details/VideoExpanded";
import { MdOutlinePlayCircleFilled } from "react-icons/md";

const Details = ({ picture }) => {
  const [videoShow, setVideoShow] = useState(false);
  return (
    <>
      <div className="card">
        <Card.Header className="ps-1 pe-2 py-0">
          <Card.Title className="m-0 p-0 ">
            <div className="d-flex ImageSectionSpacing">
              <div className="d-flex flex-column ms-1">
                <h3 className="card-title px-0 pictureTitle">
                  {picture.title}
                </h3>
                <h2 className="uploadedBy mb-0 px-0">
                  Uploaded By {picture.memberId ? picture.memberName : "Admin"}
                </h2>
              </div>
            </div>
          </Card.Title>

          <div className="card-tools">
            <div className=" text-right  mt-0">
              {picture.createdAt && (
                <span class="badge badge-light me-1 float-end text-dark">
                  Created Time :{" "}
                  <span class="text-secondary">
                    <ModuleDisplayDateTime value={picture.createdAt} />{" "}
                  </span>
                </span>
              )}
            </div>
          </div>
        </Card.Header>

        <div className="card-body p-1">

          {picture.type === "video" ? (
            <div class="galleryVideo">
              <div class="holdsTheIframe" onClick={() => setVideoShow(true)}>
                <div className="videoIcon"><MdOutlinePlayCircleFilled className="videoIconSmall" /></div>
                <img src={process.env.REACT_APP_BACKEND_URL + "public/" + picture.image} onClick={() => setVideoShow(true)} className="galleryImageVideo" />
              </div>
            </div>

          ) :

            <div
              className="galleryImage noMargin"
              style={{ width: "200px", height: "auto", maxHeight: "200px" }}
            >
              <ReactFancyBox
                thumbnail={
                  process.env.REACT_APP_BACKEND_URL + "public/" + picture.image
                }
                image={
                  process.env.REACT_APP_BACKEND_URL + "public/" + picture.image
                }
                caption={picture.title}
              />
            </div>}

          {(picture.status === "0" || picture.status === 0) && (
            <div class="ribbon-wrapper">
              <div class="ribbon bg-danger">Archived</div>
            </div>
          )}
        </div>
        {videoShow && (
          <VideoExpanded
            videoShow={videoShow}
            setVideoShow={setVideoShow}
            src={picture.video}
          />
        )}

        {/* <ListGroup variant="flush">
                <ListGroup.Item>
                    <span>Like(s)</span>  <span class="badge badge-primary">23</span>
                </ListGroup.Item>
                <ListGroup.Item>Comment(s) 23</ListGroup.Item>
            </ListGroup> */}
      </div>
    </>
  );
};

export default Details;
