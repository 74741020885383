import React,{useReducer,useEffect,useContext} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createReducer } from "../../reducers/module/createReducer";
import { createInitialState } from "./reducer/createReducer";
import axios from "../../config/axios";
import { OverlayLoading } from "../../components/UI/loading/OverlayLoading";
import { FullPageLoading } from "../../components/UI/loading/FullPageLoading";
import { FullPageOverlay } from "../../components/UI/loading/FullPageOverlay";
import { AuthContext } from "../../contexts/AuthContext";
import { authReducer, userAuthInitialState } from "../../reducers/authReducer";

const ParameterLogin = () => {
  const { userAuthState } = useContext(AuthContext);
  const [userAuthStates, userAuthDispatch] = useReducer(authReducer, userAuthInitialState);
  const [createState, createDispatch] = useReducer(
    createReducer,
    createInitialState
  );
  let navigate = useNavigate();
  const { email, slug } = useParams();
  
  const login = async () => {
    //console.log('login function called');
    userAuthDispatch({
        type: 'LOGIN_REQUEST'
    })
    const apiResult = await axios.post(`parameter-signin`, {
      email: email,
      authenticationToken: slug,
    });
   // console.log('apiResult', apiResult)
    if (apiResult.status && apiResult.data.user && apiResult.data.user.token) {
        userAuthDispatch({
            type: "LOGIN_SUCCESS",
            payload: { user: apiResult.data.user }
        })
        window.location.reload();
        return true;
    } else {
        userAuthDispatch({
            type: "LOGIN_FAILURE",
            payload: apiResult.error
        })

        setTimeout(() => {
            userAuthDispatch({
                type: "LOGIN_RESET"
            })
        }, 4000);
        return false;
    }
};
  // const onSubmit = async () => {
  //   createDispatch({
  //     type: "FORM_SUBMISSION_REQUEST",
  //   });

  //   try {
  //     const res = await axios.post(`parameter-signin`, {
  //       email: email,
  //       authenticationToken: slug,
  //     }); //
  //     console.log(res,"111");

  //     createDispatch({
  //       type: "FORM_SUBMISSION_SUCCESS",
  //       payload: res.data,
  //     });
    
  //     // navigate(`/`);
  //   } catch (error) {
  //     console.log(error.response);
  //     const status = error.response ? error.response.status : 500;
  //     const errorMessage =
  //       status === 400 ? error.response.data.message : error.message;

  //     createDispatch({
  //       type: "FORM_SUBMISSION_FAILURE",
  //       payload: errorMessage,
  //     });
  //     setTimeout(() => {
  //       createDispatch({
  //         type: "FORM_INITIAL_STATE",
  //       });
  //     }, 5000);
  //   }
  // };
  useEffect(() => {
    if (email && slug) {
      login();
    }
  }, [email, slug]);

  useEffect(() => {
      if (userAuthState.isLoggedIn) {
       // console.log("navigate")
          //window.location.href = 'http://localhost:4000/'
          window.location.href = '/'
          //return navigate("/")
      }
  }, [userAuthState.isLoggedIn])
  return (
    <div>
      <FullPageOverlay/>
    </div>
  );
};

export default ParameterLogin;
