import { moduleDefaultConfig } from "../../../config/moduleDefaultConfig"
import * as yup from "yup"
let moduleConfig = {}
moduleConfig = {
    ...moduleDefaultConfig
};

moduleConfig.title = 'Useful Links';
moduleConfig.singleItemTitle = 'Useful Link';
moduleConfig.url = 'useful-links';

moduleConfig.fields = [
    {
        displayName: "ID",
        fieldName: "_id",
        type: "text",
        control: "hidden",
    },
    {
        displayName: "Title",
        fieldName: "title",
        type: "text",
        control: "input",
        required:true,
        showOnListing: true,
        sortable: true,
    },
    {
        displayName: "Link",
        fieldName: "link",
        required:true,
        type: "url",
        control: "input",
    },
    {
        displayName: "Description",
        fieldName: "description",
        required:true,
        control: "textArea",
        type: "text"
    },
    {
        displayName: "Image",
        fieldName: "image",
        type: "image",
        control: "image",
        required: false
    },

    {
        displayName: "Sort Order",
        fieldName: "sortOrder",
        type: "number",
        control: "input",
    },
    {
        displayName: "Status",
        fieldName: "status",
        control: "select",
        options: [
            { label: "Enabled", value: 1 },
            { label: "Disabled", value: 0 },
        ],
        type: "boolean",
        showOnListing: true,
        sortable: true,
    },
    {
        displayName: "Created Date/Time",
        fieldName: "createdAt",
        type: "dateTime",
    },
    {
        displayName: "Updated Date/Time",
        fieldName: "updatedAt",
        type: "dateTime",
    }
];
moduleConfig.initialValues = {
    title: "",
    link: "",
    description: "",
    image: null,
    sortOrder: 999,
    status: 1
}
moduleConfig.validationSchema = yup.object({
    title: yup.string().required("Required").max(100),
    link: yup.string()
        .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Enter a valid URL!'
        )
        .required('Required'),
    description: yup.string().max(300).required("Required"),
    image: yup.mixed()
        .test(
            "FILE_SIZE",
            "File size should be less than 1 MB.",
            (file) => {
                if (file instanceof File) {
                    return file.size <= 1000000
                } else {
                    return true
                }
            }
        )
        .test(
            "FILE_FORMAT",
            "File should be in JPG/PNG format.",
            (file) => {
                if (file instanceof File) {
                    return (['image/jpeg', 'image/jpg', 'image/png'].includes(file.type))
                } else {
                    return true
                }
            }
        ),
    sortOrder: yup.number().required("Required"),
    status: yup.number().required("Required"),
})

export default moduleConfig;