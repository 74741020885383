import React from "react";
import { Spinner } from "react-bootstrap";

export const OverlayLoading = () => {
  return (
    <div className="loadingContainer  hasLightBg">
      <div className="loadingContainerOverlay"></div>
      <Spinner animation="border" role="status" className="loadingSpinner">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};
