import { ErrorMessage } from 'formik';
import React, { useState, useEffect, useCallback } from 'react'
import ReactFancyBox from 'react-fancybox'
import { OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import Cropper from 'react-easy-crop'
import { getCroppedImg } from '../../../helpers/canvasUtils';

const CMSFormImageWithCrop = (props) => {

    const { name, label, form, aspect, outputSize ,required} = props

    let initialImageURL = form.values[name] ? process.env.REACT_APP_BACKEND_URL + 'public/' + form.values[name] : null;

    const [image, setImage] = useState(null);
    const [imageURL, setImageURL] = useState(initialImageURL);

    const [showCropping, setShowCropping] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        //console.log(croppedArea, croppedAreaPixels)
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    useEffect(() => {
        console.log(image)
        if (!image || !image.name) return;
        setImageURL(URL.createObjectURL(image))
    }, [image])

    const onImageChange = (e) => {
        console.log("Image change calleddddddddddddddddddddddddddddddd")
        if (e.target && e.target.files[0]) {
            //form.handleChange(e)
            setImage(e.target.files[0])
            form.setFieldValue(name, e.target.files[0])
        } else {
            //form.handleChange(e)
            clearImage()
        }

    }


    const clearImage = async () => {
        setImage(null)
        setImageURL(null)
        //setFileName(null)

        await form.setFieldValue(name, '', true)
        form.setFieldTouched(name, true);

    }



    const cropImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                imageURL,
                croppedAreaPixels,
                outputSize,
                form.values[name].type
            )

            setImageURL(croppedImage.fileUrl)
            setShowCropping(false)

            const newImage = new File([croppedImage.blob], "croppedImage." + (image.type === 'image/png' ? 'png' : 'jpeg'), { type: image.type }, { fileUrl: croppedImage.fileUrl });
            form.setFieldValue(name, newImage)

        } catch (e) {
            console.error(e)
        }
    }, [imageURL, croppedAreaPixels])

    return (
        <>
            {/* {console.log("Touched", form.touched[name])} */}
            {/* {console.log(form.values[name].type,"1111111111")} */}
            <div className="form-group ">
            {
                (label !== "") &&
                <>
                    {
                        (required === true)
                            ? <OverlayTrigger
                                key={`tooltip-${name}`}
                                placement='right'
                                overlay={
                                    <Tooltip id={`tooltip-right`}>
                                        Required field.
                                    </Tooltip>
                                }
                            ><label htmlFor={name} className={`required ' ${(form.touched[name] && form.errors[name]) ? "is-invalidText" : ""}`}> {label}</label></OverlayTrigger>
                            : <label htmlFor={name} className={` ${(form.touched[name] && form.errors[name]) ? "is-invalidText" : ""}`}> {label}</label>
                    }
                </>
            }
                {/* <label htmlFor={name} className={` ${(form.touched[name] && form.errors[name]) ? "is-invalidText required" : "required"}`}> {label}</label> */}
                {showCropping ? <Row >
                    <div className="croppingArea">
                        <Cropper
                            image={imageURL}
                            crop={crop}
                            zoom={zoom}
                            aspect={aspect}
                            //restrictPosition={false}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                    </div>
                    <div className="imageActionsContainerBack">
                        <div className="imageAction">
                            <i className=" fas fa-arrow-left" onClick={() => setShowCropping(false)} />
                        </div>
                        <div className="imageAction ">
                            <i className=" fas fa-crop " onClick={() => cropImage()} />
                    </div>
                    </div>
                </Row> :
                    <>
                        <div className={`input-group ${(form.touched[name] && form.errors[name]) ? "is-invalid" : ""}`}>
                            <div className="input-group-prepend">
                                <span className="input-group-text" id={`${(form.touched[name] && form.errors[name]) ? "is-invalidInputGroupFileAddon01" : "inputGroupFileAddon01"}`}>
                                    Upload
                                </span>
                            </div>
                            <div className="custom-file">
                                <input

                                    type="file"
                                    className="custom-file-input"
                                    id="image"
                                    aria-describedby="inputGroupFileAddon01"
                                    accept="image/*"
                                    onChange={(e) => onImageChange(e)}
                                    onBlur={form.handleBlur}
                                />
                                <label className={` ${(form.touched[name] && form.errors[name]) ? "is-invalid custom-file-label" : "custom-file-label"}`} htmlFor="inputGroupFile01">
                                    {(image && image.name) ? image.name : "choose file"}
                                </label>
                            </div>
                        </div>
                        {(form.touched[name] && form.errors[name]) &&
                            <span id={`${name}-error"`} className="error invalid-feedback"  >
                                <ErrorMessage name={name} />
                            </span>
                        }

                    </>
                }
            </div>



            {(imageURL && !showCropping) && <Row >

                <div className="formImagePreview ">
                    {/* <Image src={imageURL} thumbnail /> */}
                    <ReactFancyBox
                        thumbnail={imageURL}
                        image={imageURL} />

                </div>

                <div className="imageActionsContainer">
                    <div className="imageAction">
                        <i className=" fas fa-times" onClick={() => clearImage()} />
                    </div>
                    <div className="imageAction">
                        <i className=" fas fa-crop" onClick={() => setShowCropping(true)} />
                    </div>
                </div>

            </Row>}
        </>
    )
}

export default CMSFormImageWithCrop