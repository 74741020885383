import React, { useReducer, useEffect } from "react";
import { Col, Row, Accordion, Button, Alert, Card } from "react-bootstrap";
import StatsListing from "../shared/infoListing/StatsListing";
import ActivityRecentRegistrations from "../../modules/activities/partials/details/ActivityRecentRegistrations";
import axios from "axios";
import {
  dashboardInitialState,
  dashboardReducer,
} from "../../modules/activities/reducers/dashboardReducer";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../config/axios";
import { ContainerLoading } from "../loading/ContainerLoading";
import { FullPageOverlay } from "../loading/FullPageOverlay";
import { getCurrencyCode } from "../../../helpers/CommonHelper";

function CollapseActivity() {
  const [detailsState, detailsDispatch] = useReducer(
    dashboardReducer,
    dashboardInitialState
  );
  const sleep = (ms) => {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  };
  const loadItem = async () => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axiosInstance.get(`activities/stats-for-dashboard`);
      detailsDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data, "77777");
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    loadItem();
  }, []);

  return (
    <>
      {detailsState.isFetching === true && <FullPageOverlay />}

      {detailsState.isFetching !== true &&
        detailsState.data &&
        detailsState.data.recentItems &&
        detailsState.data.recentItems.map((item, index) => {
          <p>{index}</p>;
        })}

      {detailsState.data && detailsState.data.summary && (
        <>
          <Card className="mt-xl-0 mt-lg-0 mt-md-0 mt-2 mb-xl-0 mb-lg-0 mb-md-0 border-themePrimary">
            <Card.Body className="p-xl-2 px-lg-2 px-md-2 p-0">
              <Row className="showOnMobile bg-themePrimary text-center w-100 mx-auto">
                <Col>
                  <h5 className="my-3  px-1 text-bold text-themePrimary">
                    Activities
                  </h5>
                </Col>
              </Row>
              <Row className="mt-xl-3 mt-lg-3 mt-md-3 mt-1 px-1 ">
                {/* <Col xl={3} lg={6} md={6} sm={6} xs={12}>
                  <a href="/activities">
                    <div class="info-box bg-gradient-blue mb-1">
                      <span class="info-box-icon">
                        <i class="far fa-calendar-alt"></i>
                      </span>
                      <div class="info-box-content">
                        <span class="info-box-text">Total Activities</span>
                        <span class="info-box-number">
                          {detailsState.data.summary.total}
                        </span>
                        <div class="progress">
                          <div class="progress-bar"></div>
                        </div>
                        <p class="progress-description mb-3"> </p>
                      </div>
                    </div>
                  </a>
                </Col> */}
                <Col xl={3} lg={4} md={4} sm={6} xs={12}>
                  <a
                    href="/activities?pastActivity=0"
                    className="d-flex h-100 w-100"
                  >
                    <div class="dashboard-box bg-linear-one ">
                      <div class="dashboard-box-icon">
                        <i class="fa fa-calendar"></i>
                      </div>
                      <div>
                        <h5 class="dashboard-box-heading">
                          <span className="fs-4">
                            {detailsState.data.summary.upcoming}
                          </span>{" "}
                          Upcoming Activity(s)
                        </h5>
                      </div>
                    </div>
                  </a>
                </Col>
                <Col xl={3} lg={4} md={4} sm={6} xs={12}>
                  <div class="dashboard-box bg-linear-two">
                    <div class="dashboard-box-icon">
                      <i
                        class="fa fa-exclamation-circle"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div>
                      {detailsState.data.summary
                        .registrationsCountNotConfirmed > 0 ? (
                        <h5 class="dashboard-box-heading">
                          <span className="fs-4">
                            {
                              detailsState.data.summary
                                .registrationsCountNotConfirmed
                            }
                          </span>{" "}
                          Not confirmed registration(s)
                        </h5>
                      ) : (
                        <h5 class="dashboard-box-heading">
                          No Registration(s) in Pending.
                        </h5>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              {/* {detailsState.data &&
                detailsState.data.summary &&
                detailsState.data.summary.total > 0 && (
                  <Row className="my-2 p-xl-0 px-lg-0 px-md-0 px-1">
                    <Col>
                      <Button
                        variant="primary"
                        className="float-end"
                        size="sm"
                        href={`/activities`}
                      >
                        View All
                      </Button>
                    </Col>
                  </Row>
                )} */}
              {detailsState.data.summary.upcoming > 0 && (
                <Row className="hideOnMobile d-flex align-items-center justify-content-center px-1">
                  <Col>
                    <h5 className="my-3  px-1 text-bold">
                      {" "}
                      Upcoming Activity(s)
                    </h5>
                  </Col>
                  <Col>
                    {detailsState.data &&
                      detailsState.data.summary &&
                      detailsState.data.summary.total > 0 && (
                        <Row className="my-1 p-xl-0 px-lg-0 px-md-0 px-1">
                          <Col>
                            <Button
                              variant="primary"
                              className="float-end"
                              size="sm"
                              href={`/activities`}
                            >
                              View All
                            </Button>
                          </Col>
                        </Row>
                      )}
                  </Col>
                </Row>
              )}
              {detailsState.data &&
                detailsState.data.recentItems &&
                detailsState.data.recentItems.length <= 0 && (
                  <Alert variant="warning mb-0 mt-1">No records found.</Alert>
                )}

              {detailsState.data &&
                detailsState.data.recentItems &&
                detailsState.data.recentItems.length > 0 && (
                  <>
                    {detailsState.data.recentItems.map((item, index) => {
                      return (
                        <Card className="borderTopRed hideOnMobile">
                          <Card.Header className="py-0 px-1">
                            <div className="card-title">
                              <p className="mb-0 text-bold text-white">
                                <a
                                  href={`/activities/${item._id}`}
                                  className="hoveredText "
                                >
                                  {item.title}
                                </a>
                              </p>
                              <p className="mb-0 font-small text-success">
                                {item.date}
                              </p>
                            </div>
                            <div className="card-tools"></div>
                          </Card.Header>
                          <Card.Body className="p-0">
                            <StatsListing
                              heading="Stats"
                              items={[
                                {
                                  text: "Registrations Count",
                                  value: item.stats.registrationsCount,
                                },
                                {
                                  text: "Confirmed Registrations",
                                  value: item.stats.registrationsCountConfirmed,
                                },
                                {
                                  text: "Not Confirmed Registrations",
                                  value:
                                    item.stats.registrationsCountNotConfirmed,
                                },
                              ]}
                            />
                          </Card.Body>
                        </Card>
                      );
                    })}
                  </>
                )}
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
}

export default CollapseActivity;
