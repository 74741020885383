import React, { useContext, useEffect } from 'react'
import { AuthContext } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import LoginLayout from './../components/UI/layouts/LoginLayout';
import LoginForm from '../components/UI/mix/LoginForm';

const Login = () => {
    const navigate = useNavigate();
    const { userAuthState } = useContext(AuthContext);
    useEffect(() => {
        if (userAuthState.isLoggedIn) {
            //window.location.href = 'http://localhost:4000/'
            window.location.href = '/'
            //return navigate("/")
        }
    }, [userAuthState.isLoggedIn])

    return (
        <>
            <LoginLayout>

                <LoginForm />
            </LoginLayout>
        </>
    )
}

export default Login
