import React, { useReducer, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";

import DashboardLayout from "../../components/UI/layouts/DashboardLayout";



import axios from "../../config/axios";
import { detailsReducer, detailsInitialState } from "../../reducers/module/detailsReducer"
import { BreadcrumbContext } from '../../contexts/BreadcrumbContext';
import { ContainerLoading } from "../../components/UI/loading/ContainerLoading";
import ModuleHeader from "../../components/UI/modules/views/partials/common/ModuleHeader";
import BlockErrorMessage from "../../components/UI/errorMessages/BlockErrorMessage";
import ModuleDetailsViewInTableStyle from "../../components/UI/modules/views/ModuleDetailsViewInTableStyle";
import ModuleDetailsViewInBlockStyle from "../../components/UI/modules/views/ModuleDetailsViewInBlockStyle";

const DetailsViewHOC = (breadcrumbs) => {
    const WithDetailsViewFunctions = ({ moduleConfig }) => {
        const { setBreadcrumb } = useContext(BreadcrumbContext);
        const [detailsState, detailsDispatch] = useReducer(detailsReducer, detailsInitialState)
        let { id } = useParams();

        const loadItem = async () => {
            if (id) {
                detailsDispatch({
                    type: "FETCH_REQUEST"
                });
                try {
                    const res = await axios.get(`${moduleConfig.url}/${id}`)//
                    detailsDispatch({
                        type: "FETCH_SUCCESS",
                        payload: res.data
                    });
                } catch (error) {
                    detailsDispatch({
                        type: "FETCH_FAILURE",
                        payload: error
                    });
                }
            }
        }
        useEffect(() => {
            loadItem();
            setBreadcrumb([
                breadcrumbs.listing,
                breadcrumbs.details
            ])
        }, [id])
        return (<DashboardLayout >


            {(detailsState.isFetching)
                ? <ContainerLoading />
                : <>


                    {(detailsState.hasError)

                        ? <BlockErrorMessage error={detailsState.error} />
                        : <>

                            {
                                (detailsState.data.item) &&
                                <>

                                    <ModuleHeader
                                        moduleTitle={`View ${moduleConfig.singleItemTitle}`}
                                        moduleUrl={moduleConfig.url}
                                    >

                                        {(moduleConfig.url !== 'admins' || detailsState.data.item._id !== '63513fa2fd0f8d2e5e5c29a5') && <>

                                            {
                                                (moduleConfig.buttons && moduleConfig.buttons.update === true && detailsState.data.item && detailsState.data.item._id) &&
                                                <Link to={`/${moduleConfig.url}/update/${detailsState.data.item._id}`} className="btn btn-primary">Update</Link>
                                            }

                                        </>}



                                    </ModuleHeader>


                                    {(moduleConfig.moduleType.view === 'block')
                                        ? <ModuleDetailsViewInBlockStyle
                                            item={detailsState.data.item}
                                            moduleConfig={moduleConfig}

                                        />
                                        : <ModuleDetailsViewInTableStyle
                                            item={detailsState.data.item}
                                            moduleConfig={moduleConfig}


                                        />

                                    }
                                </>
                            }


                        </>
                    }
                </>
            }
        </DashboardLayout>);
    }
    return WithDetailsViewFunctions;
}

export default DetailsViewHOC;