import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';


const BlockSuccessMessage = ({ heading, message }) => {
    //console.log(error);




    return (
        <div className="success-msg ">
            <Alert show={true} variant="success">
                <Alert.Heading>{heading}</Alert.Heading>
                <hr />
                <br />
                <p>
                    {message}
                </p>
                <br />
            </Alert>
        </div>
    );

};

export default BlockSuccessMessage;
