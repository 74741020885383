import React, { useState, useContext, useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as yup from "yup";
import moduleConfig from "./moduleConfig";
import { Store as toast } from "react-notifications-component";
import { updateInitialState, updateReducer } from "./reducers/updateReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import { toastNotification } from "../../../config/toastNotification";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import { isEmpty } from "../../../helpers/CommonHelper";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import CMSFormDate from "../../UI/forms/CMSFormDate";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";
import CMSFormRichText from "../../UI/forms/CMSFormRichText";

const ActivityUpdate = () => {
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [formInitialValues, setFormInitialValues] = useState({});

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let { id } = useParams();
  let navigate = useNavigate();

  let breadcrumbs = {
    listing: {
      label: moduleConfig.title,
      url: `/${moduleConfig.url}`,
    },
    details: {
      label: "Details",
      url: `/${moduleConfig.url}/${id}`,
    },
    update: {
      label: "Update",
    },
  };

  const onSubmit = async (data) => {
    //console.log(data);

    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(`${moduleConfig.url}/${id}`, data); //
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      navigate(`/${moduleConfig.url}`);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} updated successfully.`,
        type: "success",
      });
    } catch (error) {
      //console.log(error.message);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      console.log(errorMessage);

      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  const loadItem = async () => {
    if (id) {
      updateDispatch({
        type: "FETCH_REQUEST",
      });

      try {
        const res = await axios.get(`${moduleConfig.url}/${id}`); //
        updateDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        updateDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };
  useEffect(() => {
    loadItem();
    setBreadcrumb([
      breadcrumbs.listing,
      breadcrumbs.details,
      breadcrumbs.update,
    ]);
  }, [id]);
  useEffect(() => {
    if (updateState.data.item && updateState.data.item._id) {
      setFormInitialValues(updateState.data.item);
    }
  }, [updateState.data]);

  return (
    <DashboardLayout>
      {Object.keys(formInitialValues).length <= 0 && updateState.isLoading && (
        <ContainerLoading />
      )}

      {updateState.error && updateState.status === "fetchFailed" ? (
        <BlockErrorMessage error={updateState.error} />
      ) : (
        <>
          <ModuleHeader
            moduleTitle={`Update ${moduleConfig.singleItemTitle}`}
            moduleUrl={moduleConfig.url}
          ></ModuleHeader>

          {Object.keys(formInitialValues).length > 0 &&
            updateState.data &&
            updateState.data.item &&
            !isEmpty(updateState.data.item._id) && (
              <section className="content">
                <Card>
                  <Card.Body>
                    {updateState.isLoading && (
                      <div class="overlay position-absolute containerLoading">
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                      </div>
                    )}
                    <Formik
                      initialValues={formInitialValues}
                      validationSchema={yup.object({
                        title: yup.string().required("Required").max(100),
                        date: yup.string().required("Required"),
                        location: yup.string().max(100),
                        time: yup.string().max(50),
                        paidActivity: yup.string().required("Required"),
                        participationFee: yup
                          .number()
                          .transform((cv) => (isNaN(cv) ? undefined : cv))
                          .positive()
                          .integer()
                          .when("paidActivity", {
                            is: "1",
                            then: yup
                              .number()
                              .transform((cv) => (isNaN(cv) ? undefined : cv))
                              .positive()
                              .integer()
                              .required(),
                          }),
                        shortDescription: yup
                          .string()
                          .max(200),
                        target: yup.string().required("Required"),
                        status: yup.string().required("Required"),
                      })}
                      enableReinitialize={true}
                      validateOnChange={true}
                      validateOnBlur={true}
                      onSubmit={async (values) => {
                        onSubmit(values);
                      }}
                    >
                      {(form) => (
                        <Form onSubmit={form.handleSubmit}>
                          {updateState.error && (
                            <FormErrorMessage error={updateState.error} />
                          )}

                          <CMSFormInput
                            name="title"
                            label="Title"
                            form={form}
                            required={true}
                          />

                          <CMSFormDate
                            name="date"
                            label="Date"
                            required={true}
                            form={form}
                            value={new Date(form.values["date"])}
                            minDate={new Date()}
                            maxDate={new Date("2040-12-28")}
                            disabled={false}
                          />
                          <CMSFormInput
                            name="location"
                            required={false}
                            label="Location"
                            form={form}
                          />
                          <CMSFormInput name="time" label="Time" form={form} />

                          <CMSFormSelect
                            name="paidActivity"
                            label="Paid Activity"
                            required={true}
                            form={form}
                            options={[
                              { value: "1", label: "Yes" },
                              { value: "0", label: "No" },
                            ]}
                          />

                          {(form.values["paidActivity"] === 1 ||
                            form.values["paidActivity"] === "1") && (
                              <CMSFormInput
                                name="participationFee"
                                label="Participation Fee (AED)"
                                type="number"
                                form={form}
                              />
                            )}

                          <CMSFormInput
                            name="shortDescription"
                            label="Short Description"
                            required={false}
                            form={form}
                          />

                          <CMSFormSelect
                            name="target"
                            required={true}
                            label="Target"
                            form={form}
                            options={[
                              { value: "All-Members", label: "All-Members" },
                              {
                                value: "Selected-Members",
                                label: "Selected-Members",
                              },
                              {
                                value: "Selected-Circles",
                                label: "Selected-Circles",
                              },
                            ]}
                          />

                          <CMSFormRichText
                            name="description"
                            label="Description"
                            form={form}
                          />

                          <CMSFormSelect
                            name="status"
                            label="Status"
                            required={true}
                            form={form}
                            options={[
                              { value: "1", label: "Enabled" },
                              { value: "0", label: "Disabled" },
                            ]}
                          />

                          <button className="btn btn-primary" type="submit">
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </section>
            )}
        </>
      )}
    </DashboardLayout>
  );
};
export default ActivityUpdate;
