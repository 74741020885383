import { moduleDefaultConfig } from "../../../config/moduleDefaultConfig"
import * as yup from "yup"
import "../../../helpers/yupAdditionalValidations";
let moduleConfig = {}
moduleConfig = {
    ...moduleDefaultConfig
};

moduleConfig.title = 'Samples';
moduleConfig.singleItemTitle = 'Sample';
moduleConfig.url = 'samples';

moduleConfig.fields = [
    {
        displayName: "ID",
        fieldName: "_id",
        type: "text",
        control: "hidden",
        required: false,
    },
    {
        displayName: "Name",
        fieldName: "name",
        type: "text",
        control: "input",
        showOnListing: true,
        sortable: true,
        required: false,
        helpMessage: {
            heading: "Information!",
            message: <ul>
                <li>Required field</li>
                <li>50 Characters allowed</li>
            </ul>
        }
    },
    {
        displayName: "Email",
        fieldName: "email",
        type: "email",
        control: "input",
        showOnListing: true,
        sortable: true,
        required: true,
    },
    {
        displayName: "Password",
        fieldName: "password",
        type: "password",
        control: "input",
        required: true,
    },
    {
        displayName: "Model Number",
        fieldName: "model",
        type: "text",
        control: "input",
        required: true,
    },
    {
        displayName: "Date",
        fieldName: "date",
        type: "date",
        control: "date",
        required: true,
    },
    {
        displayName: "Description",
        fieldName: "description",
        control: "textArea",
        type: "text",
        required: true,
    },
    {
        displayName: "Content",
        fieldName: "content",
        control: "richText",
        type: "richText",
        required: true,
    },
    {
        displayName: "Country",
        fieldName: "country",
        control: "select",
        options: [
            { label: "Pakistan", value: "Pakistan" },
            { label: "UAE", value: "UAE" },
        ],
        type: "text",
        showOnListing: true,
        sortable: true,
        required: true,
    },
    {
        displayName: "Gender",
        fieldName: "gender",
        control: "radio",
        options: [
            { label: "Male label", value: "Male" },
            { label: "Female label", value: "Female" },
        ],
        type: "text",
        showOnListing: true,
        sortable: true,
        required: true,
    },
    {
        displayName: "Vehicle",
        fieldName: "vehicle",
        control: "checkbox",
        options: [
            { label: "Car label", value: "Car" },
            { label: "Bike label", value: "Bike" },
        ],
        type: "array",
        required: true,
    },
    {
        displayName: "Image",
        fieldName: "image",
        control: "image",
        type: "image",
        required: true,
    },
    {
        displayName: "Status",
        fieldName: "status",
        control: "select",
        options: [
            { label: "Enabled", value: 1 },
            { label: "Disabled", value: 0 },
        ],
        type: "boolean",
        showOnListing: true,
        sortable: true,
        required: true,
    },
    {
        displayName: "Created Date/Time",
        fieldName: "createdAt",
        type: "dateTime",
        required: true,
    },
    {
        displayName: "Updated Date/Time",
        fieldName: "updatedAt",
        type: "dateTime",
        required: true,
    }
];
moduleConfig.initialValues = {
    name: "",
    email: "",
    password: "",
    model: "",
    date: "",
    description: "",
    content: '',
    country: "",
    gender: "",
    vehicle: [],
    image: null,
    status: 1
}

moduleConfig.validationSchema = yup.object({
    name: yup.string().required("Required"),
    email: yup.string().required("Required").email(),
    password: yup.string().required("Required").min(6).max(12),
    model: yup.number().required("Required"),
    date: yup.date().required("Required"),
    description: yup.string().required("Required"),
    content: yup.string().required("Required"),
    country: yup.string().required("Required"),
    gender: yup.string().required("Required"),
    vehicle: yup.array().min(1).of(yup.string().required()).required(),
    status: yup.number().required("Required"),
    image: yup.mixed()
        .required("Please upload image.")
        .imageTypeCheck(['image/png', 'image/jpg', 'image/jpeg'])
        .imageSizeCheck(1024 * 1024 * 2)
        .imageDimensionCheck({
            width: 500,
            height: 500
        }),
})

export default moduleConfig;