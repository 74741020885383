import React, { useReducer, useEffect, useContext } from "react";
import moduleConfig from "./moduleConfig";
import { Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import axios from "../../../config/axios";
import { createReducer, createInitialState } from "./reducers/createReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import ModuleCreateView from "../../UI/modules/views/ModuleCreateView";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSFormController from "../../UI/forms/CMSFormController";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";

let breadcrumbs = {
  listing: {
    label:`${moduleConfig.url}`,
    url: `/${moduleConfig.url}`,
  },
  create: {
    label: "Create",
  },
};
const CircleCreate = () => {
  const [createState, createDispatch] = useReducer(
    createReducer,
    createInitialState
  );
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let navigate = useNavigate();
  const onSubmit = async (data) => {
    createDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(`${moduleConfig.url}`, data); //
      console.log(res);
      createDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });

      navigate(`/${moduleConfig.url}`);
      toast.addNotification({
        ...toastNotification,
        name: "Success!",
        message: `${moduleConfig.singleItemname} created successfully.`,
        type: "success",
      });
    } catch (error) {
      console.log(error.response);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.message : error.message;

      createDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        createDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  const loadData = async () => {
    try {
      const res = await axios.get(`members/create-data`); //
      createDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      createDispatch({
        type: "FETCH_FAILURE",
        payload: "Date loading failed.",
      });
    }
  };
  useEffect(() => {
    loadData();
    setBreadcrumb([breadcrumbs.listing, breadcrumbs.create]);
  }, []);
  return (
    <DashboardLayout>
        {createState.status == "fetching" &&createState.isLoading && (
                   <ContainerLoading/>
                    )}
      {createState.status === "fetchFailed" ? (
        <BlockErrorMessage error={createState.error} />
      ) : (
        <>
          {/* {console.log(createState.data)} */}
          {createState.status !== "fetching" && (
            <>
              <ModuleHeader
                modulename={`Create ${moduleConfig.singleItemname}`}
                moduleUrl={moduleConfig.url}
              ></ModuleHeader>

              <section className="content">
                <Card>
                  <Card.Body>
                    {createState.isLoading && (
                      <div class="overlay position-absolute containerLoading">
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                      </div>
                    )}
                    <Formik
                      initialValues={{
                        name: "",
                        color: "",
                        status: "1",
                      }}
                      validationSchema={yup.object({
                        name: yup.string().required("Required"),
                        color: yup.string().required("Required"),
                        status: yup.string().required("Required"),
                      })}
                      enableReinitialize={true}
                      validateOnChange={true}
                      validateOnBlur={false}
                      onSubmit={async (values) => {
                        console.log(values);
                        onSubmit(values);
                      }}
                    >
                      {(form) => (
                        <Form onSubmit={form.handleSubmit}>
                          {createState.error && (
                            <FormErrorMessage error={createState.error} />
                          )}

                          <Row>
                            <Col xs={12} s={12} md={6} lg={6}>
                              <CMSFormInput name="name" label="Name" form={form} required={true} />
                             
                            </Col>
                            <Col xs={12} s={12} md={6} lg={6}></Col>
                          </Row>

                          <Row>
                            <Col xs={12} s={12} md={6} lg={6}>
                            <CMSFormSelect
                                name="color"
                                label="Color"
                                required={true}
                                form={form}
                                options={[
                                  {
                                    value: "Blue",
                                    label: "Blue",
                                  },
                                  { value: "Green", label: "Green" },
                                  {
                                    value: "Navy",
                                    label: "Navy",
                                  },
                                  {
                                    value: "Orange",
                                    label: "Orange",
                                  },
                                  { value: "Pink", label: "Pink" },
                                  {
                                    value: "Purple",
                                    label: "Purple",
                                  },
                                  {
                                    value: "Red",
                                    label: "Red",
                                  },
                                  { value: "Teal", label: "Teal" },
                                  {
                                    value: "Tomato",
                                    label: "Tomato",
                                  },
                                  {
                                    value: "Orange",
                                    label: "Orange",
                                  },
                                  { value: "Violet", label: "Violet" },
                                  {
                                    value: "Yellow",
                                    label: "Yellow",
                                  },

                                ]}
                              />
                           
                            </Col>
                            <Col xs={12} s={12} md={6} lg={6}></Col>
                          </Row>

                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </section>
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default CircleCreate;
