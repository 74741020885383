import React, { useState, useContext, useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import moduleConfig from "./moduleConfig";
import { Store as toast } from "react-notifications-component";
import { updateInitialState, updateReducer } from "./reducers/updateReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import { toastNotification } from "../../../config/toastNotification";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import { isEmpty } from "../../../helpers/CommonHelper";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSFormDate from "../../UI/forms/CMSFormDate";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";

const MemberUpdate = () => {
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [formInitialValues, setFormInitialValues] = useState({});



  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let { id } = useParams();
  let navigate = useNavigate();

  let breadcrumbs = {
    listing: {
      label: moduleConfig.title,
      url: `/${moduleConfig.url}`,
    },
    details: {
      label: "Details",
      url: `/${moduleConfig.url}/${id}`,
    },
    update: {
      label: "Update",
    },
  };

  const onSubmit = async (data) => {
    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    try {
      const res = await axios.post(`${moduleConfig.url}/${id}`, data); //
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      navigate(`/${moduleConfig.url}`);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} updated successfully.`,
        type: "success",
      });
    } catch (error) {
      //console.log(error.message);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      console.log(errorMessage);

      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  const loadItem = async () => {
    if (id) {
      updateDispatch({
        type: "FETCH_REQUEST",
      });

      try {
        const res = await axios.get(`${moduleConfig.url}/${id}`); //
        updateDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        updateDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };
  useEffect(() => {
    //console.log("useEffect called for [id]")
    loadItem();

    setBreadcrumb([
      breadcrumbs.listing,
      breadcrumbs.details,
      breadcrumbs.update,
    ]);
  }, [id]);
  useEffect(() => {
    if (updateState.data.item && updateState.data.item._id) {
      //if (moduleConfig.fields && moduleConfig.fields.length > 0) {

      // let formFieldsWithFetchData = {}
      // moduleConfig.fields.map((field, index) => {

      //     formFieldsWithFetchData[field.fieldName] = updateState.data.item[field.fieldName]
      // })
      // console.log("Here =>>>>>>>>>>>>>>", formFieldsWithFetchData)
      setFormInitialValues(updateState.data.item);

      //};
    }
  }, [updateState.data]);
  return (
    <DashboardLayout>
      {updateState.status === "fetching" && updateState.isLoading && (
        <ContainerLoading />
      )}

      {updateState.error && updateState.status === "fetchFailed" ? (
        <BlockErrorMessage error={updateState.error} />
      ) : (
        <>
          <ModuleHeader
            moduleTitle={`Update ${moduleConfig.singleItemTitle}`}
            moduleUrl={moduleConfig.url}
          ></ModuleHeader>

          {Object.keys(formInitialValues).length > 0 &&
            updateState.data &&
            updateState.data.item &&
            !isEmpty(updateState.data.item._id) && (
              <section className="content">
                <Card>
                  <Card.Body>
                    {updateState.isLoading && (
                      <div class="overlay position-absolute containerLoading">
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                      </div>
                    )}
                    <Formik
                      initialValues={formInitialValues}
                      validationSchema={yup.object({
                        title: yup.string(),
                        firstName: yup
                          .string()
                          .required("Required")
                          .min(2, "First name should contain 2 characters"),
                        lastName: yup
                          .string()
                          .required("Required")
                          .min(2, "Last name should contain 2 characters"),
                        nationality: yup.string().required("Required"),
                        //occupation: yup.string().required("Required"),
                        dob: yup.string().required("Required"),
                        email: yup
                          .string()
                          .required("Required")
                          .email("Enter a valid email"),
                        mobile: yup.string().required("Required"),
                        status: yup.string().required("Required"),
                      })}
                      enableReinitialize={true}
                      validateOnChange={true}
                      validateOnBlur={false}
                      onSubmit={async (values) => {
                        const formData = new FormData();
                        for (let name in values) {
                          if (Array.isArray(values[name])) {
                            formData.append(name, JSON.stringify(values[name]));
                          } else {
                            formData.append(name, values[name]);
                          }
                        }

                        onSubmit(formData);
                      }}
                    >
                      {(form) => (
                        <Form onSubmit={form.handleSubmit}>
                          {/* {console.log(form)} */}
                          {updateState.error && (
                            <FormErrorMessage error={updateState.error} />
                          )}

                          <Row>
                            {/* <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormSelect
                                name="title"
                                label="Title"
                                required={false}
                                form={form}
                                options={updateState.data.personTitles}
                              />
                            </Col> */}
                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormInput
                                name="firstName"
                                label="First Name"
                                required={true}
                                form={form}
                              />
                            </Col>
                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormInput
                                name="lastName"
                                label="Last Name"
                                required={true}
                                form={form}
                              />
                            </Col>
                          
                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormSelect
                                name="nationality"
                                label="Nationality"
                                required={true}
                                form={form}
                                options={updateState.data.countries}
                              />

                            </Col>
                            </Row>

                          <Row>
                            <Col xs={12} s={12} md={4} lg={4}>
                              {/* <CMSFormInput
                                name="occupation"
                                label="Occupation"
                                required={true}
                                form={form}
                              /> */}
                              <CMSFormInput
                                name="mobile"
                                label="Mobile"
                                required={true}
                                form={form}
                              />
                            </Col>

                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormDate
                                name="dob"
                                label="Date of Birth"
                                form={form}
                                required={true}
                                value={new Date(form.values["dob"])}
                                minDate={new Date("1950-01-01")}
                                maxDate={new Date("2040-12-28")}
                                disabled={false}
                              />
                            </Col>
                          
                            <Col xs={12} s={12} md={4} lg={4}>

                            </Col>
                            </Row>

                          <Row>
                            {/* <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormInput
                                name="email"
                                label="Email"
                                disabled
                                required={true}
                                form={form}
                              />
                            </Col> */}

                            {/* <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormSelect
                                name="status"
                                label="Status"
                                required={true}
                                form={form}
                                options={[
                                  {
                                    value: "EmailVerificationRequired",
                                    label: "Email Verification Required",
                                  },
                                  {
                                    value: "RegistrationPaymentPending",
                                    label: "Registration Payment Pending",
                                  },
                                  { value: "Member", label: "Member" },
                                ]}
                              />
                            </Col> */}
                          </Row>

                          <button className="btn btn-primary" type="submit">
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </section>
            )}
        </>
      )}
    </DashboardLayout>
  );
};
export default MemberUpdate;
