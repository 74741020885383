import React, { createContext, useEffect, useState } from "react";

export const BreadcrumbContext = createContext({});

const BreadcrumbContextProvider = (props) => {
    const [breadcrumb, setBreadcrumbState] = useState({})

    const setBreadcrumb = (data) => {
        let breadcrumb = [{
            label: "Dashboard",
            url: "/",
            icon: "fas fa-home"
        }]
        //console.log("inside setBreadcrumb ", data);
        if (data && data.length > 0) {

            breadcrumb = [...breadcrumb, ...data]
        }
        setBreadcrumbState(breadcrumb)
    };

    return (<BreadcrumbContext.Provider value={{
        breadcrumb,
        setBreadcrumb
    }}>
        {props.children}
    </BreadcrumbContext.Provider>);
};



export default BreadcrumbContextProvider;
