import React from 'react'
import { Button } from 'react-bootstrap'
import { getFullName } from '../../../../helpers/CommonHelper'

import ModuleDisplayTimeAgo from '../../modules/fieldsDisplay/ModuleDisplayTimeAgo'

const LikeItem = ({ like }) => {
    return (
        <div className="direct-chat-msg right">
 {/* href={`/members/${like.memberId}`} */}

            <div className="direct-chat-text clearfix d-flex align-items-center justify-content-between w-100">
            <Button className="direct-chat-name float-right m-0 p-0 text-primary" href={`/members/${like.memberId}`}  variant="link">
            {(like.member && like.member.fullName) ? like.member.fullName : getFullName(like.member)}
          </Button>
              
                <span className="direct-chat-timestamp float-left"><ModuleDisplayTimeAgo value={like.createdAt} /></span>
            </div>
        </div>
    )
}

export default LikeItem