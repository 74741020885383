import React from 'react'
import ListingViewHOC from '../../../HOC/module/ListingViewHOC';
import moduleConfig from "./moduleConfig"
let breadcrumbs = {
    listing: {
        label: moduleConfig.title,
        url: `/${moduleConfig.url}`
    }
};

const ContactUsListing = () => {
    const Component = ListingViewHOC(breadcrumbs);
    return <Component moduleConfig={moduleConfig} />;
}

export default ContactUsListing;
