import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import useConfirm from '../../../../../../HOC/custom/useConfirm';
const ModuleBulkActionsDropdown = ({ bulkActions, isCheck, performBulkAction }) => {

    const { confirm } = useConfirm();


    const handleChange = async (e) => {
        //console.log(e.target.value)
        if (e.target.value) {
            const isConfirmed = await confirm(`Do you really want to "${e.target.value}" selected items?`);
            if (isConfirmed) {
                //console.log("Yes, I confirmed");
                //await deleteItem(id)
                await performBulkAction(e.target.value)
            }
        }
    }

    return <>
        <Form.Group className="listing-count-options ">
            {/* <Form.Label>Recrods Per Page</Form.Label> */}
            <Form.Select
                disabled={isCheck.length ? false : true}
                onChange={(e) => handleChange(e)}
            >
                <option value="">Select an action...</option>
                {bulkActions.map(action => (
                    <option key={action.value} value={action.value} >{action.displayName}</option>
                ))}
            </Form.Select>
        </Form.Group>
    </>
};

export default ModuleBulkActionsDropdown;
