import React, { useContext } from "react";
import { Formik, Form } from "formik";

import { AuthContext } from "../../../contexts/AuthContext";
import { FullPageLoading } from "./../../UI/loading/FullPageLoading";
import * as yup from "yup";
import { ContainerLoading } from "../loading/ContainerLoading";

const LoginForm = () => {
  const { userAuthState, login } = useContext(AuthContext);
  return (
    <>
      {userAuthState.isLoggingIn && (
        <div class="overlay position-absolute containerLoading">
          <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
      )}
      <Formik
        enableReinitialize={true}
        initialValues={{
          email: "",
          password: "",
        }}
        validationSchema={yup.object().shape({
          email: yup
            .string()
            .required("Please enter your email address")
            .email("Please enter a valid email address"),
          password: yup
            .string()
            .required("Please enter your password")
            .min(6, "Password should be at least 6 characters long")
            .max(20, "Password should be at most 20 characters long"),
        })}
        validateOnChange={true}
        validateOnBlur={false}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          login(values).then((loginResult) => {
            //console.log("loginResult", loginResult);
            setSubmitting(false);
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <div
                className={`${errors.email && touched.email ? "form-group" : "input-group"
                  } mb-3 `}
              >
                <input
                  id="email"
                  name="email"
                  type="email"
                  className={`form-control ${errors.email && touched.email ? "is-invalid" : ""
                    }`}
                  placeholder="Email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email ? (
                  <span id="email-error" className="error invalid-feedback">
                    {errors.email}
                  </span>
                ) : (
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope" />
                    </div>
                  </div>
                )}
              </div>

              <div
                className={`${errors.password && touched.password
                  ? "form-group"
                  : "input-group"
                  } mb-3 `}
              >
                <input
                  id="password"
                  name="password"
                  type="password"
                  className={`form-control ${errors.password && touched.password ? "is-invalid" : ""
                    }`}
                  placeholder="Password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.password && touched.password ? (
                  <span className="error invalid-feedback">
                    {errors.password}
                  </span>
                ) : (
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                )}
              </div>

              <div className="row">
                <div className="col-8">
                  {userAuthState.loginError && (
                    <div className="errorMessage">
                      {userAuthState.loginError}
                    </div>
                  )}
                </div>
                {/* /.col */}
                <div className="col-4">
                  <button
                    type="submit"
                    className={`btn btn-primary btn-block ${userAuthState.isLoggingIn ? "disabled" : ""
                      }`}
                  >
                    Log In
                  </button>
                </div>
                {/* /.col */}
              </div>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
};

export default LoginForm;
