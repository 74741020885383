import React, { useState } from 'react'
import { Row, Col, Alert } from 'react-bootstrap'
import useConfirm from '../../../../HOC/custom/useConfirm';
import { ContainerLoading } from '../../../UI/loading/ContainerLoading';
import { SortableElement } from 'react-sortable-hoc';

const LookupValueItem = ({ item, deleteItem }) => {
    const { confirm } = useConfirm();
    const [isDeleting, setIsDeleting] = useState(false)
    //console.log(item);
    const confirmAndDelete = async (id, parentId) => {
        const isConfirmed = await confirm(`Do you really want to delete this value?`);

        if (isConfirmed) {
            setIsDeleting(true)
            let res = await deleteItem(id, parentId)
            console.log(res);
            setIsDeleting(false)
        }
    }

    return (
        <li className="listStyle " >
            <Row >
                <Col sm={12} md={8} lg={8} >
                    <Alert variant="light" className="dragableContent" onClose={() => { confirmAndDelete(item._id, item.parent) }} dismissible>
                        {isDeleting && <ContainerLoading />}
                        {item.value}

                    </Alert>
                </Col>
            </Row>
        </li>


    )
}

export default SortableElement(LookupValueItem)