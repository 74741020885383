import { moduleDefaultConfig } from "../../../config/moduleDefaultConfig"
import * as Yup from "yup"


let moduleConfig = {}
moduleConfig = {
    ...moduleDefaultConfig
};

moduleConfig.title = 'Pages';
moduleConfig.singleItemTitle = 'Page';
moduleConfig.url = 'pages';

moduleConfig.hasStatusSearch = false;

moduleConfig.buttons = {
    create: false,
    view: false,
    update: true,
    delete: false,
};

moduleConfig.bulkActions = [];

moduleConfig.fields = [
    {
        displayName: "Name",
        fieldName: "name",
        type: "text",
        control: "input",
        showOnListing: true,
        sortable: true,
    },
    {
        displayName: "Slug",
        fieldName: "slug",
        type: "text",
        control: "input",
        showOnListing: true,
        sortable: true,
    }
];
moduleConfig.initialValues = {
    name: "",
    slug: "",
}
moduleConfig.validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    status: Yup.number().required("Required"),
})

export default moduleConfig;