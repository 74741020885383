import React, { useContext, useEffect, useReducer, useState } from "react";
import { BreadcrumbContext } from "../../../../contexts/BreadcrumbContext";
import DashboardLayout from "../../layouts/DashboardLayout";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import axios from "../../../../config/axios";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../../config/toastNotification";
import { ContainerLoading } from "../../loading/ContainerLoading";
import ModuleHeader from "../../modules/views/partials/common/ModuleHeader";
import { initialState, manageCirclesReducer } from "./manageCirclesReducer";
import BlockErrorMessage from "../../errorMessages/BlockErrorMessage";
import useConfirm from "../../../../HOC/custom/useConfirm";

import AddActivityCircles from "./AddCircles";
import CircleDetailsCard from "./CircleItem";
import AddCircles from "./AddCircles";
import { Link } from "react-router-dom";

const ManageTargetedCircles = ({ parentId, moduleConfig }) => {
    const { confirm } = useConfirm();

    const [show, setShow] = useState(false);
    const { setBreadcrumb } = useContext(BreadcrumbContext);
    const [updateState, updateDispatch] = useReducer(
        manageCirclesReducer,
        initialState
    );




    const confirmAndRemoveCircle = async (circleId) => {
        //console.log(circleId, item._id)
        const isConfirmed = await confirm(
            `Do you really want to remove this circle from activity?`
        );

        if (isConfirmed) {
            await removeCircle(circleId);
        }
    };

    const removeCircle = async (circleId) => {
        updateDispatch({
            type: "BLOCK_REQUEST",
        });

        try {
            const res = await axios.post(
                `${moduleConfig.url}/remove-circle-from-target/${parentId}`,
                { circleId }
            );

            loadData();

            // updateDispatch({
            //     type: "DELETE_SUCCESS"
            // });
            toast.addNotification({
                ...toastNotification,
                title: "Success!",
                message: `Circle removed successfully.`,
                type: "success",
            });
        } catch (error) {
            updateDispatch({
                type: "BLOCK_FAILURE",
                payload: error,
            });

            setTimeout(() => {
                updateDispatch({
                    type: "REMOVE_ERROR",
                });
            }, 5000);
        }
    };

    const loadData = async () => {
        if (parentId) {
            updateDispatch({
                type: "FETCH_REQUEST",
            });

            try {
                const res = await axios.get(
                    `${moduleConfig.url}/targeted-circles/${parentId}`
                ); //
                updateDispatch({
                    type: "FETCH_SUCCESS",
                    payload: res.data,
                });
            } catch (error) {
                updateDispatch({
                    type: "FETCH_FAILURE",
                    payload: error,
                });
            }
        }
    };
    useEffect(() => {
        //console.log("useEffect called for [parentId]")
        loadData();

        let breadcrumbs = {
            listing: {
                label: moduleConfig.title,
                url: `/${moduleConfig.url}`,
            },
            details: {
                label: "Details",
                url: `/${moduleConfig.url}/${parentId}`,
            },
            featuresSettings: {
                label: "Manage Targeted Circles",
            },
        };

        setBreadcrumb([
            breadcrumbs.listing,
            breadcrumbs.details,
            breadcrumbs.featuresSettings,
        ]);
    }, [parentId]);




    return (
        <DashboardLayout>
            {updateState.isLoading && <ContainerLoading />}

            {updateState.error && updateState.status === "fetchFailed" ? (
                <BlockErrorMessage error={updateState.error} />
            ) : (
                <>
                    <ModuleHeader
                        moduleTitle={`Manage Targeted Circles`}
                        moduleUrl={moduleConfig.url}
                    >
                             <Link  onClick={() => setShow(true)} className="btn btn-primary me-1" title="Update Item">
                                        <i className="fas fa-plus text-white"></i> <span className="text-white">Add Circle(s)</span> 
                                    </Link>

                        {/* <Button
                            variant="primary"
                            size="sm"
                            //className="rounded-circle roundButton"
                            onClick={() => setShow(true)}
                        >
                            <i className="fas fa-plus"> Add Circle(s)</i>
                        </Button> */}
                    </ModuleHeader>

                    <Card>
                        <Card.Body className="px-1 py-0 ">
                            <Row className="circleMembersList">
                                {updateState.data && updateState.data.items && updateState.data.items.length > 0 ? (
                                    <>
                                        {updateState.data.items.map((circle, index) => {
                                            return (
                                                <Col
                                                    className="mb-0"
                                                    key={index}
                                                    xs={12}
                                                    sm={12}
                                                    md={4}
                                                    lg={3}
                                                    xl={3}
                                                >

                                                    <CircleDetailsCard
                                                        circle={circle}
                                                        confirmAndRemoveCircle={confirmAndRemoveCircle}
                                                    />{" "}
                                                </Col>
                                            );
                                        })}
                                    </>
                                ) : (
                                    <Alert variant="warning mb-0">No records found.</Alert>
                                )}
                            </Row>
                        </Card.Body>
                    </Card>
                </>
            )}

            <AddCircles
                show={show}
                setShow={setShow}
                parentId={parentId}
                loadData={loadData}
                moduleConfig={moduleConfig}

            />
        </DashboardLayout>
    );
};
export default ManageTargetedCircles;
