
export const messageInitialState = {
    data: {},
    isLoading: true,
    status: 'fetching',//fetching,fetched,fetchFailed, actionPerforming,actionPerformed,actionFailed
    error: "",
};

export const messageReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_REQUEST":
            return {
                ...state,
                isLoading: true,
                status: 'fetching',//fetching,fetched,fetchFailed, actionPerforming,actionPerformed,actionFailed

            };

        case "FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                status: 'fetched',//fetching,fetched,fetchFailed, actionPerforming,actionPerformed,actionFailed
                data: action.payload
            };
        case "FETCH_FAILURE":
            return {
                ...state,
                isLoading: false,
                status: 'fetchFailed',//fetching,fetched,fetchFailed, actionPerforming,actionPerformed,actionFailed
                error: action.payload
            };



        case "SEND_MESSAGE_REQUEST":
            return {
                ...state,
                isLoading: true,
                status: 'actionPerforming',//fetching,fetched,fetchFailed, actionPerforming,actionPerformed,actionFailed
            };


        case "SEND_MESSAGE_FAILURE":
            return {
                ...state,
                isLoading: false,
                status: 'actionFailed',//fetching,fetched,fetchFailed, actionPerforming,actionPerformed,actionFailed
            };

        case "SEND_MESSAGE_SUCCESS":
            return {
                ...state,
                isLoading: false,
                status: 'actionPerformed',//fetching,fetched,fetchFailed, actionPerforming,actionPerformed,actionFailed
            };

        case "FORM_INITIAL_STATE":
            return {
                ...state,
                isLoading: false,
                status: 'fetched',//fetching,fetched,fetchFailed, actionPerforming,actionPerformed,actionFailed
            };


        default:
            return state;
    }
};