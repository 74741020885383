import React from 'react'

import ReactFancyBox from 'react-fancybox';
import { isEmpty } from '../../../../helpers/CommonHelper';
const ModuleDisplayImage = ({ value }) => {
    return (
        <div className="cmsModuleImageView">
            {
                (!isEmpty(value)) ?
                    (<ReactFancyBox
                        thumbnail={process.env.REACT_APP_BACKEND_URL + 'public/' + value}
                        image={process.env.REACT_APP_BACKEND_URL + 'public/' + value} />)
                    : null



            }

        </div>
    )
}

export default ModuleDisplayImage