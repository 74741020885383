export const formInitialState = {
    isFormSubmitting: false,
    isFormSubmitted: false,
    hasSubmissionError: false,
    inFormInitialStage: true,
    formSubmissionError: ''
};


export const formReducer = (state, action) => {
    switch (action.type) {
        case "FORM_SUBMISSION_REQUEST":
            return {
                ...state,
                
                hasSubmissionError: false,
                isFormSubmitting: true,
            };
        case "FORM_SUBMISSION_SUCCESS":
            return {
                ...state,
                isFormSubmitting: false,
                isFormSubmitted: true,
                hasSubmissionError: false,
                formSubmissionError: ''

            };
        case "FORM_SUBMISSION_FAILED":
            return {
                ...state,
                isFormSubmitting: false,
                isFormSubmitted: false,
                hasSubmissionError: true,
                formSubmissionError: action.payload
            };
        case "FORM_INITIAL_STAGE":
            return {
                inFormInitialStage: true,
                isFormSubmitting: false,
                isFormSubmitted: false,
                hasSubmissionError: false,
                formSubmissionError: ''
            };
        default:
            return state;
    }
}