export const moduleDefaultConfig = {
    title: 'Items',
    singleItemTitle: 'Item',
    url: 'items',
    moduleType: {
        listing: 'table', //block
        view: 'table' //block
    },
    buttons: {
        create: true,
        view: true,
        update: true,
        delete: true,
    },
    fields: [],
    breadcrumbs: {},
    bulkActions: [
        {
            displayName: "Enabled Selected",
            value: "Enabled"
        },
        {
            displayName: "Disabled Selected",
            value: "Disabled"
        },
        {
            displayName: "Delete Selected",
            value: "Delete"
        },
    ],


    //For Listing Page
    hasSearch: true,
    hasStatusSearch: true,
    hasPagination: true,
    perPageItems: ((process.env.REACT_APP_MODULE_PER_PAGE_COUNT) ? process.env.REACT_APP_MODULE_PER_PAGE_COUNT : 10),
    perPageItemsOptions: process.env.REACT_APP_MODULE_PER_PAGE_COUNT_OPTIONS ? process.env.REACT_APP_MODULE_PER_PAGE_COUNT_OPTIONS.split(",") : [10, 20, 50],
}