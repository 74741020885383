import React, { useReducer, useEffect, useState } from "react";
import { Field, Form, Formik, useFormikContext } from "formik";
import * as yup from "yup";
import {
  Alert,
  Card,
  Col,
  Row,
  Table,
  Badge,
  Image,
  Tooltip,
  Button,
  Modal,
  OverlayTrigger,
} from "react-bootstrap";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../../../config/toastNotification";
import { ContainerLoading } from "../../../../UI/loading/ContainerLoading";
import BlockSuccessMessage from "../../../../UI/errorMessages/BlockSuccessMessage";
import FormErrorMessage from "../../../../UI/errorMessages/FormErrorMessage";
import CMSFormInput from "../../../../UI/forms/CMSFormInput";
import { Multiselect } from "multiselect-react-dropdown";
import moduleConfig from "../../moduleConfig";
import axios from "../../../../../config/axios";
import {
  startChatInitialState,
  startChatReducer,
} from "../../reducers/startChatReducer";
import BlockErrorMessage from "../../../../UI/errorMessages/BlockErrorMessage";
import { useNavigate, useParams } from "react-router-dom";

const StartChat = ({ show, setShow, loadItems }) => {
  const [state, dispatch] = useReducer(startChatReducer, startChatInitialState);
  let navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const loadMembers = async () => {
    try {
      const res = await axios.get(`${moduleConfig.url}/members-circles`); //
      dispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      //  console.log(res);
    } catch (error) {
      dispatch({
        type: "FETCH_FAILURE",
        payload: "Members loading failed.",
      });
    }
  };

  // const getMembersListForDropDown = () =>{
  //     let objArr = [];

  //     return objArr[];
  // }

  useEffect(() => {
    if (show) {
      // console.log("useEffect called");
      loadMembers();
    }
  }, [show]);

  return (
    <Formik
      initialValues={{
        id: "",
        communicationType: "",
      }}
      validationSchema={yup.object().shape({
        id: yup.string().required("Select a member or circle."),
        communicationType: yup.string().required("Required."),
      })}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
      onSubmit={async (values, { resetForm }) => {
        console.log("values ==> ", values);
        navigate(
          `/${moduleConfig.url}/${values.communicationType}/${values.id}`
        );
        //onSubmit(values, resetForm)
      }}
    >
      {(form) => (
        <Modal show={show} onHide={handleClose}>
          {/* {console.log(form.errors)} */}
          {state.isLoading && (
            <div class="overlay">
              <i class="fas fa-2x fa-sync-alt text-white fa-spin"></i>
            </div>
          )}

          {state.error && state.status === "fetchFailed" ? (
            <BlockErrorMessage error={state.error} />
          ) : (
            <>
              <Form onSubmit={form.handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>Start Chat </Modal.Title>
                </Modal.Header>
                {state.status === "submitted" ? (
                  <Modal.Body>
                    <BlockSuccessMessage
                      heading="Done!"
                      message={`Members added to circle successfully.`}
                    />
                  </Modal.Body>
                ) : (
                  <>
                    <Modal.Body>
                      {state.error && <FormErrorMessage error={state.error} />}

                      <div className="form-group ">
                        <OverlayTrigger
                          key={`tooltip-"id"`}
                          placement="right"
                          overlay={
                            <Tooltip id={`tooltip-right`}>
                              Required field.
                            </Tooltip>
                          }
                        >
                          <label
                            htmlFor="id"
                            className={`required ' ${
                              form.touched["id"] && form.errors["id"]
                                ? "is-invalidText"
                                : ""
                            }`}
                          >
                            Select Member/Circle
                          </label>
                        </OverlayTrigger>

                        <Multiselect
                          className={`form-control ${
                            form.errors["id"] ? "is-invalid" : ""
                          }`}
                          name="id"
                          isObject={true}
                          // httpsonKeyPressFn={function noRefCheck() { }}
                          //onRemove={function noRefCheck() { }}
                          //onSearch={function noRefCheck() { }}
                          onSelect={(selectedList, selectedItem) => {
                            form.setValues({
                              id: selectedItem._id,
                              communicationType: selectedItem.communicationType,
                            });
                            //console.log(selectedItem);
                            // let values = [];
                            // if (selectedList && selectedList.length) {
                            //     values = selectedList.map(el => el._id)
                            // }
                            // form.setFieldValue("id", values)
                          }}
                          onRemove={(selectedList, selectedItem) => {
                            form.setValues({
                              id: "",
                              communicationType: "",
                            });
                            // let values = [];
                            // if (selectedList && selectedList.length) {
                            //     values = selectedList.map(el => el._id)
                            // }
                            // form.setFieldValue("id", values)
                          }}
                          selectionLimit={1}
                          options={state.data.items}
                          displayValue="fullName"
                          
                          // singleSelect
                        />

                        {form.errors["id"] !== undefined && (
                          <span
                            id="id-error"
                            class="error invalid-feedback "
                            style={{ display: "block" }}
                          >
                            {form.errors["id"]}
                          </span>
                        )}
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button variant="primary" type="submit">
                        Start
                      </Button>
                    </Modal.Footer>
                  </>
                )}
              </Form>
            </>
          )}
        </Modal>
      )}
    </Formik>
  );
};

export default StartChat;
