import React, { useState, useReducer } from 'react'
import * as yup from "yup"
import { Form, Formik } from 'formik';
import Modal from 'react-bootstrap/Modal';
import BlockSuccessMessage from '../../../../UI/errorMessages/BlockSuccessMessage';
import { formInitialState, formReducer } from '../../../../../reducers/formReducer';
import FormErrorMessage from '../../../../UI/errorMessages/FormErrorMessage';
import CMSFormTextArea from '../../../../UI/forms/CMSFormTextArea';
import { Button } from 'react-bootstrap';
import { ContainerLoading } from '../../../../UI/loading/ContainerLoading';
import axios from "../../../../../config/axios";
import { Store as toast } from 'react-notifications-component';
import { toastNotification } from '../../../../../config/toastNotification'
import CMSFormInput from '../../../../UI/forms/CMSFormInput';
import CMSFormCheckBoxes from '../../../../UI/forms/CMSFormCheckBoxes';
import { getFullName } from '../../../../../helpers/CommonHelper';


const PaymentAcceptModal = ({ paymentId, memberId, show, setShow, loadItem, item }) => {
    const [formState, formDispatch] = useReducer(formReducer, formInitialState)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onSubmit = async (data, resetForm) => {

        formDispatch({
            type: "FORM_SUBMISSION_REQUEST"
        });

        try {
            const res = await axios.post(`payments/accept-payment`, data);
            formDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: res.data
            });
            handleClose()
            toast.addNotification({
                ...toastNotification,
                title: "Success!",
                message: `Action done successfully.`,
                type: "success",
            });
            resetForm();
            formDispatch({
                type: "FORM_INITIAL_STAGE"
            });
            loadItem()
        } catch (error) {
            formDispatch({
                type: "FORM_SUBMISSION_FAILED",
                payload: error
            });

            setTimeout(() => {
                formDispatch({
                    type: "FORM_INITIAL_STAGE"
                });
            }, 5000)
        }
    }


    const getTenureInitialValueForForm = () => {
        if (item.purpose === 'Signup') {

            let tenureArray = item.tenure.split("-")
            let array = []
            if (tenureArray[0] === tenureArray[1]) {
                //single year

                array.push(tenureArray[0])
                return array
            } else {
                array.push(tenureArray[0])
                array.push(tenureArray[1])
                return array
            }
        } else {
            return []
        }
    }

    const getTenureFieldOptionsForForm = () => {
        if (item.purpose === 'Signup') {


            let tenureArray = item.tenure.split("-")
            //console.log(tenureArray)

            if (tenureArray[0] === tenureArray[1]) {
                //single year
                return [
                    { label: tenureArray[0], value: tenureArray[0] },
                ]
            } else {
                return [
                    { label: tenureArray[0], value: tenureArray[0] },
                    { label: tenureArray[1], value: tenureArray[1] },
                ]
            }
        } else {
            return []
        }
    }

    const getValidationSchema = () => {
        if (item.purpose === 'Signup') {
            return yup.object({
                comments: yup.string().required("Required").max(200),
                paidAmount: yup
                    .number()
                    .transform((cv) => (isNaN(cv) ? undefined : cv))
                    .positive()
                    .integer()
                    .required("Actual paid amount is required."),
                tenure: yup.array().min(1, "At-least one year should be selected.").of(yup.string().required()).required(),

            })
        } else {
            return yup.object({
                comments: yup.string().max(200),
                notes: yup.string().max(200),
                paidAmount: yup
                    .number()
                    .transform((cv) => (isNaN(cv) ? undefined : cv))
                    .positive()
                    .integer()
                    .required("Actual paid amount is required."),

            })
        }
    }

    const getInitialValues = () => {

        let returnObj = {
            id: paymentId,
            memberId,
            member: getFullName(item.member),
            purpose: item.purpose,
            totalAmount: item.totalAmount,
            comments: item.adminComments,
            notes: item.adminNotes,
            paidAmount: item.paidAmount ? item.paidAmount : item.totalAmount,
        }

        if (item.purpose === 'Signup') {
            returnObj = {
                ...returnObj,
                tenure: getTenureInitialValueForForm()
            }
        }

        return returnObj;
    }

    return (
        <Formik
            initialValues={getInitialValues()}
            validationSchema={getValidationSchema()}
            enableReinitialize={true}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={async (values, { resetForm }) => {
                console.log(values);
                onSubmit(values, resetForm)
            }}
        >
            {(form) => (

                <Modal show={show} onHide={handleClose}>
                    {(formState.isFormSubmitting) && <div class="overlay">
                        <i class="fas fa-2x fa-sync-alt text-white fa-spin"></i>
                    </div>}
                    {/* {(formState.isFormSubmitting) && <ContainerLoading />} */}
                    <Form onSubmit={form.handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Accept Payment</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>

                            {(formState.hasSubmissionError) &&
                                <FormErrorMessage error={formState.formSubmissionError} />
                            }


                            <CMSFormInput
                                name="member"
                                label="Member"
                                required={false}
                                form={form}
                                disabled={true}
                            />

                            <CMSFormInput
                                name="purpose"
                                label="Purpose"
                                required={false}
                                form={form}
                                disabled={true}
                            />

                            <CMSFormInput
                                name="totalAmount"
                                label="Total Amount"
                                required={false}
                                form={form}
                                disabled={true}
                            />




                            <CMSFormInput
                                name="paidAmount"
                                label="Paid Amount"
                                required={true}
                                form={form}
                                type="number"
                            />


                            <CMSFormTextArea
                                name="comments"
                                label="Comments For Member"
                                form={form}
                                required={false}
                            />

                            <CMSFormTextArea
                                name="notes"
                                label="Notes For Admin Records"
                                form={form}
                                required={false}
                            />





                            {(item.purpose === 'Signup') &&
                                <CMSFormCheckBoxes
                                    form={form}
                                    label="Membership Tenure"
                                    name="tenure"
                                    options={getTenureFieldOptionsForForm()}
                                    type="array"
                                    required={true}
                                />
                            }






                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>


                        </Modal.Footer>


                    </Form>


                </Modal>

            )}
        </Formik >
    )
}

export default PaymentAcceptModal