import React, { useState } from "react";
import { Alert, Card, Col, ListGroup, Row } from "react-bootstrap";
import ReactFancyBox from "react-fancybox";
import { Player } from "video-react";
import "video-react/dist/video-react.css";
import VideoExpanded from "./VideoExpanded";
import { BsFillPlayCircleFill } from "react-icons/bs"
const GalleryPicture = ({
  picture,
  loading,
  confirmAndRemovePicture,
  confirmAndArchivePicture,
  confirmAndRestorePicture,
  updatePictureHandler,
}) => {
  const [videoShow, setVideoShow] = useState(false);
  return (
    <div className="card ">
      {/* <div className="card-header d-flex flex-column">
                <h3 className="card-title pictureTitle">{picture.title ? picture.title : '(No Title)'}</h3>

            </div> */}

      <div className={`card-body d-flex align-items-center justify-content-center ${picture.source != "Instagram" ? "pb-0" : ""}`}>

        {picture.video ? (
          <div class="galleryVideo">

            {/* <Player
              playsInline
              poster={picture.source && picture.source === "Instagram"
                ? picture.image
                : process.env.REACT_APP_BACKEND_URL +
                "public/" +
                picture.image}
              src={picture.source && picture.source === "Instagram"
              ? picture.video
              : process.env.REACT_APP_BACKEND_URL +
              "public/" +
              picture.video}
            /> */}
            <div class="holdsTheIframe" onClick={() => setVideoShow(true)}>
              <div class="videoIcon">
                <BsFillPlayCircleFill />
              </div>

              <img src={picture.source && picture.source === "Instagram"
                ? picture.image
                : process.env.REACT_APP_BACKEND_URL +
                "public/" +
                picture.image} onClick={() => setVideoShow(true)} className="galleryImageVideo" />
            </div>
          </div>
        ) : (
          <div className="galleryImage photoInsideAlbum">
            <ReactFancyBox
              thumbnail={
                picture.source && picture.source === "Instagram"
                  ? picture.image
                  : process.env.REACT_APP_BACKEND_URL +
                  "public/" +
                  picture.image
              }
              image={
                picture.source && picture.source === "Instagram"
                  ? picture.image
                  : process.env.REACT_APP_BACKEND_URL +
                  "public/" +
                  picture.image
              }
              caption={picture.title}
            />
            
          </div>
        )}


        {(picture.status === "0" || picture.status === 0) && (
          <div class="ribbon-wrapper">
            <div class="ribbon bg-danger">Archived</div>
          </div>
        )}
      </div>
      {videoShow && (
        <VideoExpanded
          videoShow={videoShow}
          setVideoShow={setVideoShow}
          src={picture.source && picture.source === "Instagram"
          ? picture.video
          : process.env.REACT_APP_BACKEND_URL +
          "public/" +
          picture.video}
        />
      )}
      {(!picture.source || picture.source !== "Instagram") && (
        <div className="card-footer text-center">
          <span
            title="Edit Item"
            onClick={() => {
              updatePictureHandler(picture);
            }}
            className="btn btn-outline-secondary mx-2 "
          >
            <i className="fas fa-pencil-alt"></i>
          </span>

          <span
            title="Delete Item"
            onClick={() => {
              confirmAndRemovePicture(picture._id);
            }}
            className="btn btn-outline-secondary mx-2 "
          >
            <i className="fas fa-trash"></i>
          </span>

          {(picture.status === "1" || picture.status === 1) && (
            <span
              title="Block Item"
              onClick={() => {
                confirmAndArchivePicture(picture._id);
              }}
              className="btn btn-outline-secondary  listingActionIcon mx-2 "
            >
              <i className="fas fa-ban"></i>
            </span>
          )}

          {(picture.status === "0" || picture.status === 0) && (
            <span
              title="Remove Ban"
              onClick={() => {
                confirmAndRestorePicture(picture._id);
              }}
              className="btn btn-outline-secondary listingActionIcon mx-2 "
            >
              <i className="fas fa-undo"></i>
            </span>
          )}

        </div>
      )}
    </div>
  );
};

export default GalleryPicture;
