import React, { useState, useReducer } from "react";
import {
  Alert,
  Card,
  Col,
  Row,
  Table,
  Badge,
  Image,
  Button,
  Modal,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayFieldsController from "../../../../UI/modules/fieldsDisplay/ModuleDisplayFieldsController";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
import memberDummyImage from "../../../../../images/memberDummyImage.jpg";
import memberStatusEmailVerificationRequired from "../../../../../images/memberStatusEmailVerificationRequired.png";
import memberStatusSignupRequest from "../../../../../images/memberStatusSignupRequest.png";
import memberStatusRejected from "../../../../../images/memberStatusRejected.png";
import memberStatusRegistrationPaymentPending from "../../../../../images/memberStatusRegistrationPaymentPending.png";
import memberStatusMember from "../../../../../images/memberStatusMember.jpg";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";

import useConfirm from "../../../../../HOC/custom/useConfirm";
import axios from "../../../../../config/axios";
import moduleConfig from "../../moduleConfig";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../../../config/toastNotification";
import { ContainerLoading } from "../../../../UI/loading/ContainerLoading";
import BlockSuccessMessage from "../../../../UI/errorMessages/BlockSuccessMessage";
import FormErrorMessage from "../../../../UI/errorMessages/FormErrorMessage";
import CMSFormInput from "../../../../UI/forms/CMSFormInput";
import { Multiselect } from "multiselect-react-dropdown";
import AddGalleryItem from "./AddGalleryPicture";
import GalleryItem from "./GalleryPicture";
import ReactFancyBox from "react-fancybox";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";

const Details = ({ item, pictures, detailsDispatch, loadItem, loading }) => {
  const { confirm } = useConfirm();
  const [show, setShow] = useState(false);

  const confirmAndRemovePicture = async (pictureId) => {
    const isConfirmed = await confirm(
      `Do you really want to remove this picture?`
    );

    if (isConfirmed) {
      await removePicture(pictureId);
    }
  };

  const removePicture = async (pictureId) => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.post(
        `gallery-pictures/remove-picture/${pictureId}`,
        { albumId: item._id }
      );
      loadItem();
      // detailsDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Picture removed successfully.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "BLOCK_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  const confirmAndArchivePicture = async (pictureId) => {
    const isConfirmed = await confirm(
      `Do you really want to archive this picture?`
    );

    if (isConfirmed) {
      await changeStatusPicture(pictureId, "0");
    }
  };

  const confirmAndRestorePicture = async (pictureId) => {
    const isConfirmed = await confirm(
      `Do you really want to restore this picture?`
    );

    if (isConfirmed) {
      await changeStatusPicture(pictureId, "1");
    }
  };

  const changeStatusPicture = async (pictureId, status) => {
    console.log("In block Item Function");
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.post(
        `gallery-pictures/change-status/${pictureId}`,
        { status, albumId: item._id }
      );
      loadItem();
      // detailsDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Picture ${status === "1" ? "restored" : "archived"
          } successfully.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "BLOCK_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <Card className="item  mb-2">
            <Card.Header className="ps-1 pe-2 py-0">
              <Card.Title className="m-0 p-0 ">
                <div className="d-flex ImageSectionSpacing">
                  <div className="ms-1 me-2">
                    <i className="fas fa-folder-open position-relative usericon ">
                      {(item.status === "0" || item.status === 0) && (
                        <i className="fas fa-ban position-absolute ms-1 banned"></i>
                      )}
                    </i>
                  </div>

                  <div className="d-flex flex-column ms-1">
                    <span className="m-0 text-bold memberName">
                      {item.title}
                    </span>
                    <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                      <span className="membershipId">
                        (Created By {item.albumId ? item.memberName : "Admin"})
                      </span>
                    </span>
                  </div>
                </div>
              </Card.Title>

              <div className="card-tools">
                <div className=" text-right  mt-0">
                  <span className="badge badge-light me-1 text-dark">
                    Uploaded Date/Time :{" "}
                    <span class="text-secondary">
                      <ModuleDisplayDateTime value={item.createdAt} />{" "}
                    </span>
                  </span>
                </div>
              </div>
            </Card.Header>

            <Card.Body className="p-2 ">
              <StatsListing
                heading="Stats"
                clickable={true}
                items={[
                  {
                    title: "Pictures Count",
                    value: (item.stats && item.stats.picturesCount) ? item.stats.picturesCount : 0,
                    // url: `/gallery-albums/${item._id}`,

                  },
                  {
                    title: "Videos Count",
                    value: (item.stats && item.stats.videosCount) ? item.stats.videosCount : 0,
                    // url: `/gallery-albums/${item._id}`,

                  },
                ]}
              />
              <div className="galleryImage mt-1">
                <ReactFancyBox
                  thumbnail={
                    process.env.REACT_APP_BACKEND_URL + "public/" + item.image
                  }
                  image={
                    process.env.REACT_APP_BACKEND_URL + "public/" + item.image
                  }
                  caption="Album Cover"
                />
              </div>
            </Card.Body>
            <Card.Body className="px-2 pb-0">
              {loading && (
                <div class="overlay position-absolute containerLoading">
                  <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                </div>
              )}
              <div className="d-flex my-2">
                <h5>Media(s) </h5> &nbsp; &nbsp;{" "}
                <Button
                  variant="primary"
                  size="sm"
                  className="rounded-circle roundButton"
                  onClick={() => setShow(true)}
                >
                  <i className="fas fa-plus"></i>
                </Button>
              </div>

              <Row className="circleMembersList">
                {pictures && pictures.length > 0 ? (
                  <>
                    {pictures.map((picture, index) => {
                      return (
                        <Col key={index} xs={12} sm={6} md={3} lg={3}>
                          <GalleryItem
                            picture={picture}
                            confirmAndRemovePicture={confirmAndRemovePicture}
                            confirmAndArchivePicture={confirmAndArchivePicture}
                            confirmAndRestorePicture={confirmAndRestorePicture}
                          />
                        </Col>
                      );
                    })}
                  </>
                ) : (
                  <Alert variant="warning" className="mb-0">
                    No records found.
                  </Alert>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddGalleryItem
        show={show}
        setShow={setShow}
        albumId={item._id}
        loadItem={loadItem}
      />
    </>
  );
};

export default Details;
