import React from "react"
import { Field, ErrorMessage } from "formik"
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
const CMSFormSelect = (props) => {
    const { label, name, form, options, required, ...rest } = props
    let showEmptyOption = props.showEmptyOption ? props.showEmptyOption : false;
    return (
        <div className="form-group ">
            {
                (label !== "") &&
                <>
                    {
                        (required === true)
                            ? <OverlayTrigger
                                key={`tooltip-${name}`}
                                placement='right'
                                overlay={
                                    <Tooltip id={`tooltip-right`}>
                                        Required field.
                                    </Tooltip>
                                }
                            ><label htmlFor={name} className={`required ' ${(form.touched[name] && form.errors[name]) ? "is-invalidText" : ""}`}> {label}</label></OverlayTrigger>
                            : <label htmlFor={name} className={` ${(form.touched[name] && form.errors[name]) ? "is-invalidText" : ""}`}> {label}</label>
                    }
                </>
            }

            {/* <label htmlFor={name} className={` ${(form.touched[name] && form.errors[name]) ? "is-invalidText required" : "required"}`}>{label}</label> */}
            <Field
                component="select"
                id={name}
                name={name}

                className={`form-control select  ${(form.touched[name] && form.errors[name]) ? "is-invalid" : ""}`}
                {...rest}
            >
                {(name !== 'status' && showEmptyOption) && <option value="">Select ....</option>}
                {options.map(option => {
                    return (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    )
                })}
            </Field>
            {(form.touched[name] && form.errors[name]) &&
                <span id={`${name}-error"`} className="error invalid-feedback"  >
                    <ErrorMessage name={name} />
                </span>
            }
        </div>
    )

}

export default CMSFormSelect