import { moduleDefaultConfig } from "../../../config/moduleDefaultConfig"
import * as yup from "yup"
let moduleConfig = {}
moduleConfig = {
    ...moduleDefaultConfig
};

moduleConfig.title = 'Payments';
moduleConfig.singleItemTitle = 'Payments';
moduleConfig.url = 'payments';
moduleConfig.moduleType = {
    listing: 'block',
    view: 'block'
};


moduleConfig.bulkActions = [
    // {
    //     displayName: "Disabled Selected",
    //     value: "Disabled"
    // },
    // {
    //     displayName: "Enabled Selected",
    //     value: "Enabled"
    // }
];


export default moduleConfig;