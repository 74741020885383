import React, { useState } from 'react';

const ModuleDataTableHeader = ({ moduleConfig, setSorting, appliedSorting, isCheckAll, handleSelectAll }) => {
    let appliedSortingField = ''
    let appliedSortingOrder = ''

    if (appliedSorting) {
        if (appliedSorting.startsWith("-")) {
            appliedSortingField = appliedSorting.substring(1);
            appliedSortingOrder = '-'
        } else {
            appliedSortingField = appliedSorting
        }
    }

    const [sortingField, setSortingField] = useState(appliedSortingField);
    const [sortingOrder, setSortingOrder] = useState(appliedSortingOrder);

    const onSortingChange = field => {
        let order = ''
        if (field === sortingField) {
            order = (appliedSortingOrder === '-') ? '' : '-'
        }
        setSorting('sort', order + field)
    }



    return <thead>
        <tr>
            {(moduleConfig.bulkActions.length > 0) &&
                <td className="checkboxCell" >
                    <input

                        type="checkbox"
                        name="selectAll"
                        id="selectAll"
                        onChange={handleSelectAll}
                        checked={isCheckAll}

                    /></td>
            }
            <th style={{ width: "60px" }} className="text-center">No.</th>
            {
                (moduleConfig && moduleConfig.fields && moduleConfig.fields.length > 0) && (


                    moduleConfig.fields.map(({ displayName, fieldName, sortable, showOnListing }) => {

                        return (showOnListing === true) && <th
                            key={fieldName}
                            onClick={() => sortable ? onSortingChange(fieldName) : null}
                            className="text-center"

                        >
                            {displayName}&nbsp;
                            {(sortingField && fieldName === sortingField) && <i className={`fas fa-caret-${(sortingOrder === '-') ? "down" : "up"} text-primary`} />}
                        </th>
                    })
                )
            }
            {
                (
                    (moduleConfig.buttons && moduleConfig.buttons.view === true) ||
                    (moduleConfig.buttons && moduleConfig.buttons.update === true) ||
                    (moduleConfig.buttons && moduleConfig.buttons.delete === true)
                ) &&
                <>
                    <th></th>
                </>
            }
        </tr>
    </thead >;
};

export default ModuleDataTableHeader;



