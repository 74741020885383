import React from 'react';

const ModulePaginationSummary = ({ pagination, currentItemsCount }) => {
    const itemsStartNumber = (((pagination.currentPage - 1) * pagination.limit + 1))
    const itemsEndNumber = Math.min((((pagination.currentPage - 1) * pagination.limit) + currentItemsCount), pagination.totalFilteredItems)
    return <span className="paginationSummary">
        <small>Showing&nbsp;
            {(itemsStartNumber > 0) &&
                <>from {itemsStartNumber} to </>
            }
            {itemsEndNumber} records out of {pagination.totalFilteredItems}
        </small>
    </span>
};

export default ModulePaginationSummary;
