import React from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import ModuleDynamicFilterDropdown from "../../../../UI/modules/views/partials/common/ModuleDynamicFilterDropdown";
import ModuleRecordsPerPageDropdown from "../../../../UI/modules/views/partials/common/ModuleRecordsPerPageDropdown";
import ModuleSearchBox from "../../../../UI/modules/views/partials/common/ModuleSearchBox";
import ModuleStatusDropdown from "../../../../UI/modules/views/partials/common/ModuleStatusDropdown";

const SearchArea = ({
  moduleConfig,
  setFilter,
  appliedFilters,
  resetFilters,
}) => {
  //console.log(appliedFilters);
  return (
    <div className="position-relative">
      <Card className="listingPageHeader p-0 m-0 px-1 pt-1 mt-0 mb-1">
        <Card.Body className=" p-0 m-0">
          {(appliedFilters.limit ||
            appliedFilters.q ||
            appliedFilters.status ||
            appliedFilters.paidActivity ||
            appliedFilters.pastActivity ||
            appliedFilters.target) && (
            <div
              div
              className="resetFilters text-right"
              onClick={() => resetFilters()}
            >
              <span>
                <i className="fas fa-times" /> Reset Filters
              </span>
            </div>
          )}

          <Row className="mb-0">
            {moduleConfig.hasPagination === true &&
              moduleConfig.perPageItemsOptions &&
              moduleConfig.perPageItemsOptions.length > 0 && (
                <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-0">
                  <ModuleRecordsPerPageDropdown
                    selectedPerPageItems={moduleConfig.perPageItems}
                    perPageItemsOptions={moduleConfig.perPageItemsOptions}
                    setFilter={setFilter}
                  />
                </Col>
              )}
            {moduleConfig.hasStatusSearch === true && (
              <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1">
                <ModuleStatusDropdown
                  setFilter={setFilter}
                  appliedFilters={appliedFilters}
                  statusOptions={[
                    {
                      label: "Select Status...",
                      value: "all",
                    },
                    {
                      label: "Enabled",
                      value: "1",
                    },
                    {
                      label: "Disabled",
                      value: "0",
                    },
                  ]}
                />
              </Col>
            )}
            <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1">
              <ModuleDynamicFilterDropdown
                filterField="target"
                setFilter={setFilter}
                appliedFilters={appliedFilters}
                options={[
                  {
                    label: "Select Targets...",
                    value: "all",
                  },
                  {
                    label: "All-Members",
                    value: "All-Members",
                  },
                  {
                    label: "Selected-Members",
                    value: "Selected-Members",
                  },
                  {
                    label: "Selected-Circles",
                    value: "Selected-Circles",
                  },
                ]}
              />
            </Col>
          </Row>

          <Row className="mb-0">
            <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 mt-1">
              <ModuleDynamicFilterDropdown
                filterField="pastActivity"
                setFilter={setFilter}
                appliedFilters={appliedFilters}
                options={[
                  {
                    label: "Select Upcoming/Past...",
                    value: "all",
                  },
                  {
                    label: "Upcoming Activities",
                    value: "0",
                  },
                  {
                    label: "Past Activities",
                    value: "1",
                  },
                ]}
              />
            </Col>

            <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 mt-1">
              <ModuleDynamicFilterDropdown
                filterField="paidActivity"
                setFilter={setFilter}
                appliedFilters={appliedFilters}
                options={[
                  {
                    label: "Select Paid/Unpaid...",
                    value: "all",
                  },
                  {
                    label: "Paid Activities",
                    value: "1",
                  },
                  {
                    label: "Free Activities",
                    value: "0",
                  },
                ]}
              />
            </Col>

            {moduleConfig.hasSearch === true && (
              <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 mt-1 mb-1">
                <ModuleSearchBox
                  setFilter={setFilter}
                  appliedFilters={appliedFilters}
                />
              </Col>
            )}
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SearchArea;
