import React from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ModuleDisplayDate from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDate";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayFieldsController from "../../../../UI/modules/fieldsDisplay/ModuleDisplayFieldsController";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
import memberDummyImage from "../../../../../images/memberDummyImage.jpg";
import memberStatusEmailVerificationRequired from "../../../../../images/memberStatusEmailVerificationRequired.png";
import memberStatusSignupRequest from "../../../../../images/memberStatusSignupRequest.png";
import memberStatusRejected from "../../../../../images/memberStatusRejected.png";
import memberStatusRegistrationPaymentPending from "../../../../../images/memberStatusRegistrationPaymentPending.png";
import memberStatusMember from "../../../../../images/memberStatusMember.jpg";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";
import { getCurrencyCode } from "../../../../../helpers/CommonHelper";

const Item = ({
  item,
  handleCheckBoxClick,
  isCheck,
  moduleConfig,
  confirmAndDelete,
  confirmAndDisabled,
  confirmAndEnabled,
  customButtonsForItems,
}) => {
  const fields = moduleConfig.fields;
  const getItems = () => {
    let items = [];
    if (item.target) {
      items = [...items, { text: "Target", value: item.target, type: "text" }];
    }
    if (item.target && item.target !== "All-Members") {
      items = [
        ...items,

        {
          text:
            "Target " +
            (item.target === "Selected-Circles"
              ? " Circles "
              : item.target === "Selected-Members"
                ? " Members "
                : null) +
            "Count",
          value:
            item.target === "Selected-Circles"
              ? item.targetedCirclesCount
                ? item.targetedCirclesCount
                : "Targets not added yet"
              : item.target === "Selected-Members"
                ? item.targetedMembersCount
                  ? item.targetedMembersCount
                  : "Targets not added yet"
                : 0,
          type: "text",
        },
      ];
    }
    if (item.location) {
      items = [
        ...items,
        {
          text: "Location",
          value: item.location,
          type: "text",
          disable: true,
        },
      ];
    }
    if (item.paidActivity) {
      items = [
        ...items,
        {
          text: "Paid Activity",
          value: item.paidActivity,
          type: "boolean",
        },
      ];
    }
    if (item.participationFee) {
      items = [
        ...items,
        {
          text: "Participation Fee ",
          value: item.participationFee + " " + getCurrencyCode(),
          type: "text",
        },
      ];
    }
    if (item.partnerFeaturesSettings && item.partnerFeaturesSettings.allowed) {
      items = [
        ...items,
        {
          text: "Partner Allowed",
          value:
            item.partnerFeaturesSettings &&
            item.partnerFeaturesSettings.allowed == "1" &&
            item.partnerFeaturesSettings.allowed,
          type: "boolean",
        },
      ];
    }
    if (
      item.partnerFeaturesSettings &&
      item.partnerFeaturesSettings.allowed == "1" &&
      item.partnerFeaturesSettings.paidParticipation
    ) {
      items = [
        ...items,
        {
          text: "Paid Partner Participation",
          value:
            item.partnerFeaturesSettings &&
            item.partnerFeaturesSettings.allowed == "1" &&
            item.partnerFeaturesSettings.paidParticipation,
          type: "boolean",
        },
      ];
    }
    if (
      item.partnerFeaturesSettings &&
      item.partnerFeaturesSettings.allowed == "1" &&
      item.partnerFeaturesSettings.participationFee
    ) {
      items = [
        ...items,
        {
          text: "Partner Participation Fee ",
          value:
            item.partnerFeaturesSettings &&
            item.partnerFeaturesSettings.allowed == "1" &&
            item.partnerFeaturesSettings.participationFee + " " + getCurrencyCode(),
          type: "text",
        },
      ];
    }



    if (item.guestFeaturesSettings && item.guestFeaturesSettings.allowed) {
      items = [
        ...items,
        {
          text: "Guests Allowed",
          value:
            item.guestFeaturesSettings &&
            item.guestFeaturesSettings.allowed == "1" &&
            item.guestFeaturesSettings.allowed,
          type: "boolean",
        },
      ];
    }
    if (
      item.guestFeaturesSettings &&
      item.guestFeaturesSettings.allowed == "1" &&
      item.guestFeaturesSettings.paidParticipation
    ) {
      items = [
        ...items,
        {
          text: "Paid Guest Participation",
          value:
            item.guestFeaturesSettings &&
            item.guestFeaturesSettings.allowed == "1" &&
            item.guestFeaturesSettings.paidParticipation,
          type: "boolean",
        },
      ];
    }
    if (
      item.guestFeaturesSettings &&
      item.guestFeaturesSettings.allowed == "1" &&
      item.guestFeaturesSettings.participationFee
    ) {
      items = [
        ...items,
        {
          text: "Guest Participation Fee",
          value:
            item.guestFeaturesSettings &&
            item.guestFeaturesSettings.allowed == "1" &&
            item.guestFeaturesSettings.participationFee + " " + getCurrencyCode(),
          type: "text",
        },
      ];
    }
    if (
      item.guestFeaturesSettings &&
      item.guestFeaturesSettings.allowed !== "0" &&
      item.guestFeaturesSettings.allowedNumbers
    ) {
      items = [
        ...items,
        {
          text: "Allowed Number of Guests",
          value:
            item.guestFeaturesSettings &&
            item.guestFeaturesSettings.allowed !== "0" &&
            item.guestFeaturesSettings.allowedNumbers,
        },
      ];
    }


    ///Kids Feature Settings

    if (item.kidsFeaturesSettings && item.kidsFeaturesSettings.allowed) {
      items = [
        ...items,
        {
          text: "Kids Allowed",
          value:
            item.kidsFeaturesSettings &&
            item.kidsFeaturesSettings.allowed == "1" &&
            item.kidsFeaturesSettings.allowed,
          type: "boolean",
        },
      ];
    }
    if (
      item.kidsFeaturesSettings &&
      item.kidsFeaturesSettings.allowed == "1" &&
      item.kidsFeaturesSettings.paidParticipation
    ) {
      items = [
        ...items,
        {
          text: "Paid Kids Participation",
          value:
            item.kidsFeaturesSettings &&
            item.kidsFeaturesSettings.allowed == "1" &&
            item.kidsFeaturesSettings.paidParticipation,
          type: "boolean",
        },
      ];
    }
    if (
      item.kidsFeaturesSettings &&
      item.kidsFeaturesSettings.allowed == "1" &&
      item.kidsFeaturesSettings.participationFee
    ) {
      items = [
        ...items,
        {
          text: "Kids Participation Fee",
          value:
            item.kidsFeaturesSettings &&
            item.kidsFeaturesSettings.allowed == "1" &&
            item.kidsFeaturesSettings.participationFee + " " + getCurrencyCode(),
          type: "text",
        },
      ];
    }
    if (
      item.kidsFeaturesSettings &&
      item.kidsFeaturesSettings.allowed !== "0" &&
      item.kidsFeaturesSettings.allowedNumbers
    ) {
      items = [
        ...items,
        {
          text: "Allowed Number of Kids",
          value:
            item.kidsFeaturesSettings &&
            item.kidsFeaturesSettings.allowed !== "0" &&
            item.kidsFeaturesSettings.allowedNumbers,
        },
      ];
    }



    return items;
  };
  return (
    <Card className="item mb-2">
      <Card.Header className="ps-1 pe-2 py-0">
        <Card.Title className="d-flex m-0 p-0">
          <div className="d-flex ImageSectionSpacing">
            <div className="checkboxCol me-2 ms-2 mt-2">
              <input
                type="checkbox"
                id={item._id}
                name={item._id}
                onChange={handleCheckBoxClick}
                checked={isCheck.includes(item._id)}
              />
            </div>

            <div className="ms-1 me-2 ">
              <i className="fas fa-calendar position-relative usericon ">
                {(item.status === "0" || item.status === 0) && (
                  <i className="fas fa-ban position-absolute banned"></i>
                )}
              </i>
            </div>

            <div className="d-flex flex-column ms-1">
              <span className="m-0 text-bold memberName">{item.title}</span>

              <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                <span className="membershipId">
                  <ModuleDisplayDate value={item.date} />{" "}
                  {item.time && " - " + item.time}
                </span>
              </span>
            </div>
          </div>
        </Card.Title>

        <div class="card-tools mb-1">
          <div>
            {(item.pastActivity === "1" || item.pastActivity === 1) && (
              <span className="badge pastActivity me-1 text-dark">
                Past Activity
              </span>
            )}
            {item.status === "1" || item.status === 1 ? (
              <span className="badge badge-success  me-1 text-light">
                Enabled
              </span>
            ) : (
              <span className="badge badge-warning  me-1 text-dark">
                Disabled
              </span>
            )}

            {/* <span className="badge badge-light me-1 text-dark">
              Status :{" "}
              <span class="text-secondary">
                <ModuleDisplayBoolean
                  value={item.status}
                  trueValue="Enabled"
                  falseValue="Disabled"
                />{" "}
              </span>
            </span> */}

            <span class="badge badge-light me-1 text-dark">
              Created Date/Time :{" "}
              <span class="text-secondary">
                <ModuleDisplayDateTime value={item.createdAt} />{" "}
              </span>
            </span>
          </div>

          <div className=" text-right me-xl-2  mt-1">
            <Link
              to={`/${moduleConfig.url}/${item._id}`}
              className="btn btn-outline-secondary  listingActionIcon"
              title="View Item details"
            >
              <i className="fas fa-eye"></i>
            </Link>

            <Link
              to={`/${moduleConfig.url}/update/${item._id}`}
              className="btn btn-outline-secondary listingActionIcon"
              title="Update Item"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>

            {(item.status === "1" || item.status === 1) && (
              <span
                title="Disable Activity"
                onClick={() => confirmAndDisabled(item._id)}
                className="btn btn-outline-secondary  listingActionIcon"
              >
                <i className="fas fa-ban"></i>
              </span>
            )}

            {(item.status === "0" || item.status === 0) && (
              <span
                title="Enable Activity"
                onClick={() => confirmAndEnabled(item._id)}
                className="btn btn-outline-secondary listingActionIcon"
              >
                <i className="fas fa-undo"></i>
              </span>
            )}
          </div>
        </div>
      </Card.Header>
      <Card.Body className="p-2">
        <StatsListing items={getItems()} />

        <StatsListing
          border="border"
          heading="Stats"
          clickable={true}
          items={[
            {
              title: "Registrations Count",
              value: item.registrationsCount,
              url: `/activity-registrations/${item._id}`,
            },
            {
              title: "Confirmed Registrations",
              value: item.registrationsCountConfirmed,
              url: `/activity-registrations/${item._id}?status=1`,
            },
            {
              title: "Not Confirmed Registrations",
              value:
                item.registrationsCountNotConfirmed,
              url: `/activity-registrations/${item._id}?status=0`,
            },
            {
              title: "Comments Count",
              value: item.commentsCount,
              url: `/activity-comments/${item._id}`,
            },
            {
              title: "Likes Count",
              value: item.likesCount,
              url: `/activity-likes/${item._id}`,
            },
          ]}
        />
      </Card.Body>
    </Card>
  );
};

export default Item;
