import { moduleDefaultConfig } from "../../../config/moduleDefaultConfig"
import * as Yup from "yup"
let moduleConfig = {}
moduleConfig = {
    ...moduleDefaultConfig
};

moduleConfig.hasStatusSearch = false;

moduleConfig.title = 'Contact Us Submissions';
moduleConfig.singleItemTitle = 'Contact Us';
moduleConfig.url = 'contact-us';

moduleConfig.buttons = {
    create: false,
    view: true,
    update: false,
    delete: true,
};

moduleConfig.bulkActions = [

    {
        displayName: "Delete Selected",
        value: "Delete"
    }
]

moduleConfig.fields = [
    {
        displayName: "ID",
        fieldName: "_id",
        type: "text",
        control: "hidden",
    },
    {
        displayName: "Name",
        fieldName: "name",
        type: "text",
        control: "input",
        showOnListing: true,
        sortable: true,
    },

    {
        displayName: "Email",
        fieldName: "email",
        type: "email",
        control: "input",
        showOnListing: true,
        sortable: true,
    },

    {

        displayName: "Mobile",
        fieldName: "mobile",
        type: "text",
        control: "input",
        showOnListing: true,
        sortable: true,
        displayStyle: "phone"
    },

    {
        displayName: "Reason",
        fieldName: "reason",
        type: "text",
        control: "input",
        showOnListing: true,
    },

    {
        displayName: "Message",
        fieldName: "message",
        type: "text",
        control: "input",
    },
    {
        displayName: "Created Date/Time",
        fieldName: "createdAt",
        type: "dateTime",
    },


];
moduleConfig.initialValues = {

}
moduleConfig.validationSchema = Yup.object({

})

export default moduleConfig;