import React, { useEffect, useContext, useReducer, useState } from 'react'
import DashboardLayout from '../../UI/layouts/DashboardLayout'

import { BreadcrumbContext } from '../../../contexts/BreadcrumbContext';
import { lookupReducer, lookupInitialState } from "../../../reducers/lookupReducer"
import axios from "../../../config/axios";
import { ContainerLoading } from '../../UI/loading/ContainerLoading';

import { Alert, Col, Row, Tab } from 'react-bootstrap';
import { Store as toast } from 'react-notifications-component';
import { toastNotification } from '../../../config/toastNotification'
import FormErrorMessage from '../../UI/errorMessages/FormErrorMessage';
import TabHeadings from './partials/TabHeadings';
import TabContent from './partials/TabContent';
import ModuleHeader from '../../UI/modules/views/partials/common/ModuleHeader';
import BlockErrorMessage from '../../UI/errorMessages/BlockErrorMessage';


const LookupListing = () => {
    const { setBreadcrumb } = useContext(BreadcrumbContext);
    const [lookupState, lookupDispatch] = useReducer(lookupReducer, lookupInitialState)
    //console.log(lookupState)

    const loadItems = async () => {
        lookupDispatch({
            type: "FETCH_REQUEST"
        });
        try {
            const res = await axios.get(`lookups`)//
            lookupDispatch({
                type: "FETCH_SUCCESS",
                payload: res.data
            });
        } catch (error) {
            lookupDispatch({
                type: "FETCH_FAILURE",
                payload: error
            });
        }
    }

    useEffect(() => {
        loadItems();
        setBreadcrumb([{
            label: "Lookups",
            url: `/lookups`
        }])
    }, [])

    const submitLookupValue = async (data) => {
        console.log("submit lookup value");

        // lookupDispatch({
        //     type: "FORM_SUBMISSION_REQUEST"
        // });


        try {
            const res = await axios.post(`lookups`, data)//
            lookupDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: res.data.item
            });
            toast.addNotification({
                ...toastNotification,
                title: "Success!",
                message: `Lookup value added successfully.`,
                type: "success",
            });
            return true;
        } catch (error) {
            //console.log(error);
            lookupDispatch({
                type: "FORM_SUBMISSION_FAILURE",
                payload: (error.response && error.response.data && error.response.data.message) ? error.response.data.message : 'Action failed, try again later.'
            });
            setTimeout(() => {
                lookupDispatch({
                    type: "FORM_INITIAL_STATE"
                });
            }, 5000)
            return false;
        }
    }

    const deleteItem = async (id, parentId) => {
        console.log("Delete clicked with ID => " + id + " and parent ID => ", parentId)




        try {
            const res = await axios.delete(`lookups/${id}`)

            lookupDispatch({
                type: "DELETE_SUCCESS",
                payload: { id, parentId }
            });
            toast.addNotification({
                ...toastNotification,
                title: "Success!",
                message: `Lookup value deleted successfully.`,
                type: "success",
            });
        } catch (error) {

            toast.addNotification({
                ...toastNotification,
                title: "Success!",
                message: `Action failed, try again later.`,
                type: "danger",
            });

            return false
        }
    }


    const submitNewOrder = async (lookupId, items) => {


        //console.log("updateOrder called with ==>", lookupId, items)
        lookupDispatch({
            type: "ORDER_UPDATE",
            payload: { lookupId, items }
        });

        try {
            const res = await axios.post(`lookups/update-order`, { lookupId, items })

        } catch (error) {

        }
    }

    return (
        <DashboardLayout >

            {(lookupState.isFetching)
                ? <ContainerLoading />
                : <>

                    {(lookupState.hasFetchingError)
                        ? <BlockErrorMessage error={lookupState.error} />
                        : <>
                            <ModuleHeader
                                moduleTitle="Lookups"
                                moduleUrl="lookups"
                            ></ModuleHeader>
                            {
                                (lookupState.data && lookupState.data.lookups && lookupState.data.lookups.length > 0)
                                    ? <>
                                        {(lookupState.hasDeletingError) &&
                                            <FormErrorMessage error="Action failed, try again later." />
                                        }

                                        <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                                            <Row >
                                                <Col sm={6} md={4} lg={2} className="pe-xl-0 pe-lg-0 pe-md-0">
                                                    <TabHeadings lookups={lookupState.data.lookups} />
                                                </Col>
                                                <Col sm={6} md={8} lg={8}>
                                                    <TabContent
                                                        lookupState={lookupState}
                                                        submitLookupValue={submitLookupValue}
                                                        deleteItem={deleteItem}
                                                        submitNewOrder={submitNewOrder}
                                                    />

                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </>
                                    : <Alert variant="warning" >Lookup Data Not Found.</Alert>
                            }
                        </>
                    }
                </>
            }
        </DashboardLayout>
    )
}

export default LookupListing