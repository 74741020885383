import React, { useReducer, useEffect, useContext } from "react";
import moduleConfig from "./moduleConfig";
import { Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import axios from "../../../config/axios";
import { createReducer, createInitialState } from "./reducers/createReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import ModuleCreateView from "../../UI/modules/views/ModuleCreateView";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSFormController from "../../UI/forms/CMSFormController";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import CMSFormImageWithCrop from "../../UI/forms/CMSFormImageWithCrop";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";

let breadcrumbs = {
  listing: {
    label: moduleConfig.url,
    url: `/${moduleConfig.url}`,
  },
  create: {
    label: "Create",
  },
};

const GalleryAlbumCreate = () => {
  const [createState, createDispatch] = useReducer(
    createReducer,
    createInitialState
  );
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let navigate = useNavigate();
  const onSubmit = async (data) => {
    createDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(`${moduleConfig.url}`, data); //
      console.log(res);
      createDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });

      navigate(`/${moduleConfig.url}`);
      toast.addNotification({
        ...toastNotification,
        name: "Success!",
        message: `${moduleConfig.singleItemTitle} created successfully.`,
        type: "success",
      });
    } catch (error) {
      console.log(error.response);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.message : error.message;

      createDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        createDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  // const loadData = async () => {
  //     try {
  //         const res = await axios.get(`members/create-data`)//
  //         createDispatch({
  //             type: "FETCH_SUCCESS",
  //             payload: res.data
  //         });
  //     } catch (error) {
  //         createDispatch({
  //             type: "FETCH_FAILURE",
  //             payload: "Date loading failed."
  //         });
  //     }
  // }
  useEffect(() => {
    //loadData()
    setBreadcrumb([breadcrumbs.listing, breadcrumbs.create]);
    createDispatch({
      type: "FETCH_SUCCESS",
      payload: [],
    });
  }, []);

  let imageRequirement = {
    dimension: {
      width: 500,
      height: 500,
    },
    allowedExtensions: ["image/png", "image/jpg", "image/jpeg"],
    size: 1024 * 1024 * 2,
  };
  return (
    <DashboardLayout>
      {createState.status === "fetchFailed" ? (
        <BlockErrorMessage error={createState.error} />
      ) : (
        <>
          {/* {console.log(createState.data)} */}
          {createState.status !== "fetching" && (
            <>
              <ModuleHeader
                modulename={`Create ${moduleConfig.singleItemTitle}`}
                moduleUrl={moduleConfig.url}
              ></ModuleHeader>

              <section className="content">
                <Card>
                  <Card.Body>
                    {createState.isLoading && (
                      <div class="overlay position-absolute containerLoading">
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                      </div>
                    )}
                    <Formik
                      initialValues={{
                        title: "",
                        image: null,
                        status: "1",
                      }}
                      validationSchema={yup.object({
                        title: yup.string().required("Required"),
                        image: yup
                          .mixed()
                          .required("Please upload image.")
                          .imageTypeCheck(imageRequirement.allowedExtensions)
                          .imageSizeCheck(imageRequirement.size)
                          .imageDimensionCheck(imageRequirement.dimension),
                        status: yup.string().required("Required"),
                      })}
                      enableReinitialize={true}
                      validateOnChange={true}
                      validateOnBlur={true}
                      onSubmit={async (values, { resetForm }) => {
                        //console.log(values);
                        ///onSubmit(values, resetForm)

                        const formData = new FormData();
                        for (let name in values) {
                          if (Array.isArray(values[name])) {
                            formData.append(name, JSON.stringify(values[name]));
                          } else {
                            formData.append(name, values[name]);
                          }
                        }
                        //console.log(formData);
                        onSubmit(formData, resetForm);
                      }}
                    >
                      {(form) => (
                        <Form onSubmit={form.handleSubmit}>
                          {createState.error && (
                            <FormErrorMessage error={createState.error} />
                          )}

                          <CMSFormInput
                            name="title"
                            label="Title"
                            form={form}
                            required={true}
                          />

                          <CMSFormImageWithCrop
                            label="image"
                            name="image"
                            required={true}
                            form={form}
                            aspect={1 / 1}
                            outputSize={imageRequirement.dimension}
                          />

                          <CMSFormSelect
                            name="status"
                            label="Status"
                            required={true}
                            form={form}
                            options={[
                              {
                                value: "",
                                label: "Select Status ...",
                              },
                              { value: "1", label: "Active" },
                              {
                                value: "0",
                                label: "Archived",
                              },
                            ]}
                          />

                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </section>
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default GalleryAlbumCreate;
