import React from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayFieldsController from "../../../../UI/modules/fieldsDisplay/ModuleDisplayFieldsController";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
import memberDummyImage from "../../../../../images/memberDummyImage.jpg";
import memberStatusEmailVerificationRequired from "../../../../../images/memberStatusEmailVerificationRequired.png";
import memberStatusSignupRequest from "../../../../../images/memberStatusSignupRequest.png";
import memberStatusRejected from "../../../../../images/memberStatusRejected.png";
import memberStatusRegistrationPaymentPending from "../../../../../images/memberStatusRegistrationPaymentPending.png";
import memberStatusMember from "../../../../../images/memberStatusMember.jpg";
import accountDeleted from "../../../../../images/accountDelete.png";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";
import { getFullName } from "../../../../../helpers/CommonHelper";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayDate from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDate";

const Details = ({
  item,
  moduleConfig,
  confirmAndDelete,
  confirmAndBlock,
  confirmAndUnblock,
  customButtonsForItems,
}) => {
  const fields = moduleConfig.fields;
  const getItems = () => {
    let items = [];
    if (item.email) {
      items = [...items, { text: "Email", value: item.email, type: "email" }];
    }
    if (item.mobile) {
      items = [...items, { text: "Mobile", value: item.mobile, type: "phone" }];
    }
    if (item.nationality) {
      items = [
        ...items,
        { text: "Nationality", value: item.nationality, type: "text" },
      ];
    }
    if (item.dob) {
      items = [
        ...items,
        { text: "Date of Birth", value: item.dob, type: "text" },
      ];
    }

    if (item.referredBy) {
      items = [
        ...items,
        { text: "Referred By", value: item.referredBy, type: "text" },
      ];
    }
    if (item.referredName) {
      items = [
        ...items,
        { text: "Referred Name", value: item.referredName, type: "text" },
      ];
    }
    if (item.status === "Member" && item.joiningDate) {
      items = [
        ...items,
        { text: "Member Since", value: item.joiningDate, type: "text" },
      ];
    }

    if (item.createdAt) {
      items = [
        ...items,
        { text: "Profile Created On", value: item.createdAt, type: "text" },
      ];
    }

    if (
      item.otherClub &&
      item.otherClub.enrolled === "1" &&
      item.otherClub.otherClub
    ) {
      items = [
        ...items,
        {
          text: "Other Club Membership",
          value: item.otherClub.otherClub,
          type: "text",
        },
      ];
    }

    if (item.about) {
      items = [...items, { text: "About", value: item.about, type: "text" }];
    }

    return items;
  };
  const getCounts = () => {
    let items = [
      {
        title: "Cars",
        value: item.stats.cars,
        url: `/member-cars/${item._id}`,
      },
      {
        title: "Registration(s)",
        value: item.stats.registrationCount,
        url: `/members/${item._id}`,
        click: false,
      },
      {
        title: "Circle(s)",
        value: item.stats.circlesCount,
        url: `/circles/`,
      },
      // {
      //   title: " Unread Messages",
      //   value: item.stats.unreadMessages,
      //   url: `/chat/member/${item._id}`,
      // },
      // {
      //   title: "Pending Payments",
      //   value: item.stats.pendingPaymentCount,
      //   url: `/payments/`,
      // },
    ];
    if (item.stats.unreadMessages > 0) {
      items = [
        ...items,
        { text: "Referred Name", value: item.referredName, type: "text" },
      ];
    }
    return items;
  };
  const getSocialMedia = () => {
    let items = [];
    if (item.socialAccounts.facebook) {
      items = [
        ...items,
        {
          text: "Facebook",
          value: item.socialAccounts.facebook,
          type: "link",
        },
      ];
    }
    if (item.socialAccounts.instagram) {
      items = [
        ...items,
        {
          text: "Instagram",
          value: item.socialAccounts.instagram,
          type: "link",
        },
      ];
    }
    if (item.socialAccounts.linkedin) {
      items = [
        ...items,
        {
          text: "Linked In",
          value: item.socialAccounts.linkedin,
          type: "link",
        },
      ];
    }
    if (item.socialAccounts.twitter) {
      items = [
        ...items,
        {
          text: "Twitter",
          value: item.socialAccounts.twitter,
          type: "link",
        },
      ];
    }

    if (item.socialAccounts.youtube) {
      items = [
        ...items,
        {
          text: "You Tube",
          value: item.socialAccounts.youtube,
          type: "link",
        },
      ];
    }

    return items;
  };
  const getAddressItems = () => {
    let items = [];
    if (item.address.city) {
      items = [
        ...items,
        { text: "City", value: item.address.city, type: "text" },
      ];
    }
    if (item.address.state) {
      items = [
        ...items,
        { text: "State", value: item.address.state, type: "text" },
      ];
    }
    if (item.address.country) {
      items = [
        ...items,
        { text: "Country", value: item.address.country, type: "text" },
      ];
    }
    if (item.address.address) {
      items = [
        ...items,
        {
          text: "Address",
          value: item.address.address,
          type: "text",
        },
      ];
    }

    return items;
  };
  const getJobItems = () => {
    let items = [];
    if (item.job.occupation) {
      items = [
        ...items,
        {
          text: "Occupation",
          value: item.job.occupation,
          type: "text",
        },
      ];
    }
    if (item.job.company) {
      items = [
        ...items,
        { text: "Company Name", value: item.job.company, type: "text" },
      ];
    }

    if (item.job.address) {
      items = [
        ...items,
        {
          text: "Comapny address",
          value: item.job.address,
          type: "text",
        },
      ];
    }

    return items;
  };
  const getPartnerItems = () => {
    let items = [];
    if (item.partner.fullName) {
      items = [
        ...items,
        {
          text: "Full Name",
          value: item.partner.fullName,
          type: "text",
        },
      ];
    }
    if (item.partner.email) {
      items = [
        ...items,
        { text: "email", value: item.partner.email, type: "email" },
      ];
    }
    if (item.partner.mobile) {
      items = [
        ...items,
        {
          text: "Mobile",
          value: item.partner.mobile,
          type: "phone",
        },
      ];
    }
    if (item.partner.clubCommunication) {
      items = [
        ...items,
        {
          text: "Club Communication",
          value: item.partner.clubCommunication,
          type: "boolean",
        },
      ];
    }

    return items;
  };
  const getConsentItems = () => {
    let items = [];
    if (item.consent && item.consent.agreeToShareDetails) {
      items = [
        ...items,
        {
          text: "Agree to share details",
          value: item.consent.agreeToShareDetails,
          type: "boolean",
        },
      ];
    }
    if (item.consent && item.consent.agreeToTakePictures) {
      items = [
        ...items,
        {
          text: "Agree to take pictures",
          value: item.consent.agreeToTakePictures,
          type: "boolean",
        },
      ];
    }

    if (item.consent && item.consent.agreeToSharePicturesOnSocial) {
      items = [
        ...items,
        {
          text: "Agree to share pictures on social media",
          value: item.consent.agreeToSharePicturesOnSocial,
          type: "boolean",
        },
      ];
    }

    return items;
  };
  const getPermissions = (permission) => {
    let items = [];
    if (permission.canManageAlbums === 1 || permission.canManageAlbums === "1") {
      items = [
        ...items,
        {
          text: "Manage Album",
          value: permission.canManageAlbums,
          type: "boolean",
        },
      ];
    }
    if (
      permission.canManageComments === 1 ||
      permission.canManageComments === "1"
    ) {
      items = [
        ...items,
        {
          text: "Manage Comments",
          value: permission.canManageComments,
          type: "boolean",
        },
      ];
    }
    if (
      permission.canManagePhotos === 1 ||
      permission.canManagePhotos === "1"
    ) {
      items = [
        ...items,
        {
          text: "Manage Photos",
          value: permission.canManagePhotos,
          type: "boolean",
        },
      ];
    }
    if (
      permission.canSendNotifications === 1 ||
      permission.canSendNotifications === "1"
    ) {
      items = [
        ...items,
        {
          text: "Send Notifications",
          value: permission.canSendNotifications,
          type: "boolean",
        },
      ];
    }
    if (
      permission.canViewMemberDetails === 1 ||
      permission.canViewMemberDetails === "1"
    ) {
      items = [
        ...items,
        {
          text: "View Member Details",
          value: permission.canViewMemberDetails,
          type: "boolean",
        },
      ];
    }
    if (permission.hasCMSAccess === 1 || permission.hasCMSAccess === "1") {
      items = [
        ...items,
        {
          text: "CMS Access",
          value: permission.hasCMSAccess,
          type: "boolean",
        },
      ];
    }
    return items;
  };
  return (
    <>
      <Card className={`item mb-2 memberItem`}>
        {item.accountDeletionRequested === "1" ||
        item.accountDeletionRequested === 1 ||
        item.accountDeletionRequested === true ? (
          <>
            <div
              className={` ${
                item.accountDeletionRequested === "1" ||
                item.accountDeletionRequested === 1 ||
                item.accountDeletionRequested === true
                  ? "accountDeleted"
                  : ""
              }`}
            ></div>
          </>
        ) : null}
        <Card.Header
          className={`ps-1 pe-xl-0 pe-2 py-0  ${
            item.accountDeletionRequested === "1" ||
            item.accountDeletionRequested === 1 ||
            item.accountDeletionRequested === true
              ? "accountDeletedBg"
              : ""
          }`}
        >
          <Card.Title className="m-0 p-0 ">
            <div className="d-flex ImageSectionSpacing">
              <div className="position-relative ms-1 me-2">
                {/* {item.isChairman === "1" ||
                  item.isChairman === 1 ||
                  item.isChairman === true ? (
                  <div className="chairmanStatus">
                    <i
                      class="fa fa-star"
                      aria-hidden="true"
                      title={`${getFullName(item)
                        } is Chairman`}
                    ></i>
                  </div>
                ) : null} */}
                {item.accountDeletionRequested === "1" ||
                item.accountDeletionRequested === 1 ||
                item.accountDeletionRequested === true ? (
                  <div className="accountDeleteIcon">
                    <i
                      class="fa fa-trash"
                      aria-hidden="true"
                      title={`${getFullName(
                        item
                      )} requested for account deletion`}
                    ></i>
                  </div>
                ) : null}

                {item.accountDeletionRequested === "1" ||
                item.accountDeletionRequested === 1 ||
                item.accountDeletionRequested === true ? null : (
                  <>
                    {item.isLoggedIn === "1" ||
                    item.isLoggedIn === 1 ||
                    item.isLoggedIn === true ? (
                      <div className="activeStatus"></div>
                    ) : (
                      <div className="notActiveStatus"></div>
                    )}
                  </>
                )}
                <>
                  {item.image ? (
                    <Image
                      src={
                        process.env.REACT_APP_BACKEND_URL +
                        "public/" +
                        item.image
                      }
                      width="52px"
                      height="52px"
                      className="border rounded-circle"
                    />
                  ) : (
                    <Image
                      src={memberDummyImage}
                      width="45px"
                      className="border rounded-circle"
                    />
                  )}
                </>

                {item.blockedByAdmin === "1" && (
                  <i className="fas fa-ban position-absolute  banned2"> </i>
                )}
              </div>

              <div className="d-flex flex-column ms-1">
                <span className="m-0 text-bold memberName ">
                  {getFullName(item)}
                  {console.log(item)}

                  {item.role === "Board Member" ? (
                    <span className="boardMemberText">{item.role}</span>
                  ) : item.role === "Club President" ? (
                    <span className="greenBackgroundText">{item.role}</span>
                  ) : item.role === "Club Vice President" ? (
                    <span className="greenBackgroundText">{item.role}</span>
                  ) : item.role === "Member" ? null : (
                    <span className="normalText">({item.role})</span>
                  )}

                  {/* {item.hasCMSAccess === "1" && (
                    <span className="hasCMSAccessText">Has CMS Access</span>
                  )}

                  {item.allowedToViewMemberDetails === "1" && (
                    <span className="hasMemberDetailsAccessText"> Has Member Details Access</span>
                  )} */}
                </span>

                <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                  {item.oldMemberNumber && (
                    <span className="membershipId">
                      ({item.oldMemberNumber})
                    </span>
                  )}

                  {item.accountDeletionRequested === "1" ||
                  item.accountDeletionRequested === 1 ||
                  item.accountDeletionRequested === true ? (
                    <span className="memberStatusImageContainer">
                      <Image
                        src={accountDeleted}
                        title="Account Deletion Requested"
                        className="memberStatusImage"
                      />
                    </span>
                  ) : (
                    <>
                      {item.status === "EmailVerificationRequired" && (
                        <span className="memberStatusImageContainer">
                          <Image
                            src={memberStatusEmailVerificationRequired}
                            title="Email Verification Pending"
                            className="memberStatusImage"
                          />
                        </span>
                      )}

                      {item.status === "Member" && (
                        <span className="memberStatusImageContainer">
                          <Image
                            src={memberStatusMember}
                            title="Registration Payment Pending"
                            className="memberStatusImage"
                          />
                        </span>
                      )}
                      {item.status === "SignupRequest" && (
                        <span className="memberStatusImageContainer">
                          <Image
                            src={memberStatusSignupRequest}
                            title="Signup Request"
                            className="memberStatusImage"
                          />
                        </span>
                      )}

                      {item.status === "SignupRequestRejected" && (
                        <span className="memberStatusImageContainer">
                          <Image
                            src={memberStatusRejected}
                            title="Signup Request Rejected"
                            className="memberStatusImage"
                          />
                        </span>
                      )}

                      {item.status === "RegistrationPaymentPending" && (
                        <span className="memberStatusImageContainer">
                          <Image
                            src={memberStatusRegistrationPaymentPending}
                            title="Registration Payment Pending"
                            className="memberStatusImage"
                          />
                        </span>
                      )}

                      {item.status === "SignupRequest" &&
                        item.resubmission === "1" && (
                          <Badge bg="pink" className="ms-2 resubmission">
                            Resubmission
                          </Badge>
                        )}
                    </>
                  )}
                </span>
              </div>
            </div>
          </Card.Title>

          <div className="card-tools">
            <div className=" text-right  mt-0 me-xl-2">
              {item.expiryStatus === "membershipIsExpired" && (
                <>
                  <Badge bg="danger">Expired</Badge>{" "}
                </>
              )}
              {item.expiryStatus === "membershipIsInTolerancePeriod" && (
                <>
                  <Badge bg="warning">Tolerance Period</Badge>{" "}
                </>
              )}

              {item.expiryStatus === "membershipIsExpiringSoon" && (
                <>
                  <Badge bg="secondary">Expiring Soon</Badge>{" "}
                </>
              )}

              {item.migratedMember === "1" && (
                <>
                  <Badge bg="info" className="me-1">
                    Migrated Member
                  </Badge>{" "}
                </>
              )}

              {item.createdByAdmin === "1" && (
                <>
                  <Badge bg="primary" className="me-1">
                    Admin Created Profile
                  </Badge>{" "}
                </>
              )}
               {item.createdByAdmin === "0" &&item.migratedMember === "0" && (
              <>
                <Badge bg="purple" className="me-0">
                Mobile App Signup
                </Badge>{" "}
              </>
            )}
              {item.accountDeletionRequested === "1" ||
              item.accountDeletionRequested === 1 ||
              item.accountDeletionRequested === true ? (
                <Badge bg="dark" className="ms-2 me-xl-1 resubmission">
                  Account Deletion Requested
                </Badge>
              ) : null}
              {/* {item.status === "Member" && item.joiningDate && (
                <>
                  <Badge bg="success">Member Since: {item.joiningDate}</Badge>{" "}
                </>
              )}

              {item.createdAt && (
                <>
                  <Badge bg="warning">
                    Profile Created On: {item.createdAt}
                  </Badge>{" "}
                </>
              )} */}
            </div>
            {console.log(item, "///////////////////")}
            <div className=" text-right  mt-0 me-xl-2">
              <span className="badge badge-light me-1 text-dark">
                Agreed With Terms :{" "}
                <span class="text-secondary">
                  <ModuleDisplayBoolean value={item.agreedWithTerms} />{" "}
                </span>
              </span>
              <span className="badge badge-light me-1 text-dark">
                Email Verified :{" "}
                <span class="text-secondary">
                  <ModuleDisplayBoolean value={item.emailVerified} />{" "}
                </span>
              </span>
            </div>
          </div>
        </Card.Header>
        <Card.Body className="px-0 pb-0 pt-xl-1 pt-0">
          {item.accountDeletionRequested === "1" ||
          item.accountDeletionRequested === 1 ||
          item.accountDeletionRequested === true ? (
            <>
              {item.accountDeletionReason && (
                <Alert variant="dark mt-1 mb-0 p-1 font-small">
                  {" "}
                  <p className="mb-0 text-bold font-small">
                    Account Deletion Reason :
                    <span className="mb-0 text-normal ps-2">
                      {item.accountDeletionReason}
                    </span>
                  </p>
                </Alert>
              )}
            </>
          ) : null}
          {item.rejectionReason &&
            (item.status === "SignupRequest" ||
              item.status === "SignupRequestRejected") && (
              <Alert variant="danger mx-1 mt-1 mb-0 p-1 font-small">
                {" "}
                <p className="mb-0 text-bold font-small">
                  {(item.resubmission === "1" ? "Previous " : "") +
                    "Rejection Reason"}{" "}
                  :
                  <span className="mb-0 text-normal ps-2">
                    {item.rejectionReason}
                  </span>
                </p>
              </Alert>
            )}
          {item.blockedByAdmin === "1" && item.blockReason && (
            <Alert variant="danger mx-1 mt-1 mb-0 p-1 font-small">
              {" "}
              <p className="mb-0 text-bold font-small">
                Block Reason :
                <span className="mb-0 text-normal ps-2">
                  {item.blockReason}
                </span>
              </p>
            </Alert>
          )}
          {item.stats && item.stats.cars <= 0 && (
            <Alert variant="warning mx-1 mt-1 mb-0 p-1 font-small">
              {" "}
              <p className="mb-0 text-bold font-small">
                <span className="mb-0 text-normal ps-2">
                  This member doesn't have an active car.{" "}
                  {item.lastCarDeletedOn &&
                    item.lastCarDeletedOn.deletionDate && (
                      <>
                        Last car deleted on{" "}
                        <span className="text-bold">
                          <ModuleDisplayDate
                            value={item.lastCarDeletedOn.deletionDate}
                          />
                        </span>
                      </>
                    )}
                </span>
              </p>
            </Alert>
          )}
          {item.carConflicts && item.carConflicts.length == 1 && (
            <div className="warningDark mx-1 mt-1 mb-0 p-1 font-small">
              {" "}
              <p className="mb-0 text-bold font-small">
                <span className="mb-0 text-normal ps-2">
                  This member has a conflict with his/her car "
                  {item.carConflicts[0].model}"
                  <>
                    {item.carConflicts[0] &&
                      item.carConflicts[0].conflictReason && (
                        <span className="text-bold ps-2 mb-0">
                          Conflict Reason:
                          <span className="mb-0 text-normal ps-2">
                            {item.carConflicts[0].conflictReason}
                          </span>
                        </span>
                      )}
                  </>
                </span>
              </p>
            </div>
          )}
          {item.carConflicts && item.carConflicts.length > 1 && (
            <div className="warningDark mx-1 mt-1 mb-0 p-1 font-small">
              {" "}
              <p className="mb-0 text-bold font-small">
                <span className="mb-0 text-normal ps-2">
                  This member has a conflict with his/her car.
                </span>
              </p>
            </div>
          )}
          <StatsListing items={getItems()} />
          {item.address && (
            <StatsListing
              heading="Address"
              border="border"
              items={getAddressItems()}
            />
          )}
          {item.partner && (
            <StatsListing
              border="border"
              heading="Partner Details"
              items={getPartnerItems()}
            />
          )}
          {item.job && (
            <StatsListing
              border="border"
              heading="Job Details"
              items={getJobItems()}
            />
          )}
          {item.consent && (
            <StatsListing
              border="border"
              heading="Consent"
              items={getConsentItems()}
            />
          )}
          {item.modeOfComm && (
            <StatsListing
              border="border"
              heading="Mode of Communication"
              items={[
                {
                  text: "Email",
                  value: item.modeOfComm.email,
                  type: "boolean",
                },
                {
                  text: "Whatsapp",
                  value: item.modeOfComm.whatsApp,
                  type: "boolean",
                },
                {
                  text: "Mobile App",
                  value: item.modeOfComm.mobileApp,
                  type: "boolean",
                },
              ]}
            />
          )}
          {item.inAppComm && (
            <StatsListing
              border="border"
              heading="In App Communication"
              items={[
                { text: "Call", value: item.inAppComm.call, type: "boolean" },
                { text: "Chat", value: item.inAppComm.chat, type: "boolean" },
                {
                  text: "Email",
                  value: item.inAppComm.email,
                  type: "boolean",
                },
              ]}
            />
          )}
          {item.socialAccounts && (
            <StatsListing
              heading="Social Media"
              border="border"
              items={getSocialMedia()}
            />
          )}
          {item.hobbies && (
            <StatsListing
              heading={item.hobbies.length > 0 && "Hobbies"}
              border="border"
              items={item.hobbies}
            />
          )}
          <StatsListing
            border="border"
            heading="Stats"
            clickable={true}
            items={getCounts()}
          />
          {item.permissions &&
            item.permissions.map((permission, index) => {
              if (
                permission.canManageAlbum === "1" ||
                permission.canManagePhotos === "1" ||
                permission.canManageComments === "1" ||
                permission.canSendNotifications === "1" ||
                permission.canViewMemberDetails === "1" ||
                permission.hasCMSAccess === "1" ||
                permission.canManageAlbum === 1 ||
                permission.canManagePhotos === 1 ||
                permission.canManageComments === 1 ||
                permission.canSendNotifications === 1 ||
                permission.canViewMemberDetails === 1 ||
                permission.hasCMSAccess === 1
              )
                return (
                  <StatsListing
                    border="border"
                    heading="Permissions"
                    items={getPermissions(permission)}
                  />
                );
            })}
        </Card.Body>
      </Card>
    </>
  );
};

export default Details;
