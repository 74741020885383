import { moduleDefaultConfig } from "../../../config/moduleDefaultConfig"
let moduleConfig = {}
moduleConfig = {
    ...moduleDefaultConfig
};

moduleConfig.title = 'Activities';
moduleConfig.singleItemTitle = 'Activity';
moduleConfig.url = 'activities';
moduleConfig.moduleType = {
    listing: 'block',
    view: 'block'
};


moduleConfig.bulkActions = [
    {
        displayName: "Enable Selected",
        value: "Enable"
    },
    {
        displayName: "Disable Selected",
        value: "Disable"
    },

];


export default moduleConfig;