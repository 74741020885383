import React from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ModuleDisplayDate from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDate";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayFieldsController from "../../../../UI/modules/fieldsDisplay/ModuleDisplayFieldsController";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
import memberDummyImage from "../../../../../images/memberDummyImage.jpg";
import memberStatusEmailVerificationRequired from "../../../../../images/memberStatusEmailVerificationRequired.png";
import memberStatusSignupRequest from "../../../../../images/memberStatusSignupRequest.png";
import memberStatusRejected from "../../../../../images/memberStatusRejected.png";
import memberStatusRegistrationPaymentPending from "../../../../../images/memberStatusRegistrationPaymentPending.png";
import memberStatusMember from "../../../../../images/memberStatusMember.jpg";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";

const Item = ({
  item,
  handleCheckBoxClick,
  isCheck,
  moduleConfig,
  confirmAndDelete,
  confirmAndDisabled,
  confirmAndEnabled,
  customButtonsForItems,
}) => {
  const fields = moduleConfig.fields;
  const getItems = () => {
    let items = [];
    if (item.source) {
      items = [...items, { text: "Source", value: item.source, type: "text" }];
    }
    if (item.type) {
      items = [...items, { text: "Type", value: item.type, type: "text" }];
    }
    if (item.target) {
      items = [...items, { text: "Target", value: item.target, type: "text" }];
    }
    if (item.target && item.target !== "All-Members") {
      items = [
        ...items,

        {
          text:
            "Target " +
            (item.target === "Selected-Circles"
              ? " Circles "
              : item.target === "Selected-Members"
              ? " Members "
              : null) +
            "Count",
          value:
            item.target === "Selected-Circles"
              ? item.targetedCirclesCount
                ? item.targetedCirclesCount
                : "Targets not added yet"
              : item.target === "Selected-Members"
              ? item.targetedMembersCount
                ? item.targetedMembersCount
                : "Targets not added yet"
              : 0,
          type: "text",
        },
      ];
    }
    return items;
  };
  return (
    <Card className="item mb-2">
      <Card.Header className="ps-1 pe-2 py-0">
        <Card.Title className="d-flex m-0 p-0">
          <div className="d-flex ImageSectionSpacing">
            <div className="checkboxCol me-2 ms-2 mt-2">
              <input
                type="checkbox"
                id={item._id}
                name={item._id}
                onChange={handleCheckBoxClick}
                checked={isCheck.includes(item._id)}
              />
            </div>

            <div className="ms-1 me-2">
              <i className="fas fa-newspaper pt-1 position-relative usericon ">
                {(item.status === "0" || item.status === 0) && (
                  <i className="fas fa-ban position-absolute ms-1 mb-2 banned"></i>
                )}
              </i>
            </div>

            <div className="d-flex flex-column ms-1">
              <span className="m-0 text-bold memberName">{item.title}</span>

              <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                <span className="membershipId">
                  <ModuleDisplayDate value={item.date} />
                </span>
              </span>
            </div>
          </div>
        </Card.Title>

        <div class="card-tools mb-1">
          <div>
            <span className="badge badge-light me-1 pt-0 text-dark">
              Status :{" "}
              <span class="text-secondary">
                <ModuleDisplayBoolean
                  value={item.status}
                  trueValue="Enabled"
                  falseValue="Disabled"
                />{" "}
              </span>
            </span>

            <span class="badge badge-light me-1 pt-0 text-dark">
              Created Date :{" "} 
              <span class="text-secondary">
                <ModuleDisplayDate value={item.createdAt} />{" "}
              </span>
            </span>
          </div>

          <div className=" text-right me-xl-2  mt-1">
            <Link
              to={`/${moduleConfig.url}/${item._id}`}
              className="btn btn-outline-secondary  listingActionIcon"
              title={`View ${moduleConfig.singleItemTitle}`}
            >
              <i className="fas fa-eye"></i>
            </Link>

            <Link
              to={`/${moduleConfig.url}/update/${item._id}`}
              className="btn btn-outline-secondary listingActionIcon"
              title={`Update ${moduleConfig.singleItemTitle}`}
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>

            {(item.status === "1" || item.status === 1) && (
              <span
                title={`Disabled ${moduleConfig.singleItemTitle}`}
                onClick={() => confirmAndDisabled(item._id)}
                className="btn btn-outline-secondary  listingActionIcon"
              >
                <i className="fas fa-ban"></i>
              </span>
            )}

            {(item.status === "0" || item.status === 0) && (
              <span
                title={`Enabled ${moduleConfig.singleItemTitle}`}
                onClick={() => confirmAndEnabled(item._id)}
                className="btn btn-outline-secondary listingActionIcon"
              >
                <i className="fas fa-undo"></i>
              </span>
            )}
          </div>
        </div>
      </Card.Header>
      <Card.Body className="px-1 py-0">
        <Row>
          <Col className="mt-1 mb-1 ">
            <StatsListing items={getItems()} />

            <StatsListing
              heading="Stats"
              clickable={true}
              items={[
                {
                  title: "Likes",
                  value: item.stats.likesCount,
                  url: `/news-feed-likes/${item._id}`,
                },
                {
                  title: "Comments",
                  value: item.stats.commentsCount,
                  url: `/news-feed-comments/${item._id}`,
                },
              ]}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Item;
