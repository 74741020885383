import React, { useState, useReducer, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import moduleConfig from "./moduleConfig";
import {
  detailsReducer,
  memberDetailsInitialState,
} from "./reducers/detailsReducer";
import Details from "./partials/details/Details";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import useConfirm from "../../../HOC/custom/useConfirm";
import CreateConflict from "./partials/CreateConflict";
import { Button, Card } from "react-bootstrap";
import CarGallery from "./partials/details/CarGallery";
import {FaQuestion} from "react-icons/fa6"
const MemberCarView = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [detailsState, detailsDispatch] = useReducer(
    detailsReducer,
    memberDetailsInitialState
  );
  let { id, memberId } = useParams();
  const [itemId, setItemId] = useState();
  const [showCreateConflictModal, setShowCreateConflictModal] = useState(false);
  const { confirm } = useConfirm();
  let breadcrumbs = {
    memberListing: {
      label: "Members",
      url: `/members`,
    },
    memberDetails: {
      label: "Member Details",
      url: `/members/${memberId}`,
    },
    listing: {
      label: moduleConfig.title,
      url: `/${moduleConfig.url}/${memberId}`,
    },
    details: {
      label: "Car Details",
    },
  };

  const loadItem = async () => {
    if (id) {
      detailsDispatch({
        type: "FETCH_REQUEST",
      });
      try {
        const res = await axios.get(`${moduleConfig.url}/${memberId}/${id}`); //
        detailsDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        detailsDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };
  useEffect(() => {
    loadItem();
    setBreadcrumb([
      breadcrumbs.memberListing,
      breadcrumbs.memberDetails,
      breadcrumbs.listing,
      breadcrumbs.details,
    ]);
  }, [id]);

  const handleCreateConflict = (id, memberId) => {
    //console.log("createConflictModal", id, memberId);
    setItemId(id);
    setShowCreateConflictModal(true);
  };

  const resolveConflict = async (id) => {
    //console.log("In resolveConflict Function");
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.post(
        `${moduleConfig.url}/resolve-conflict/${memberId}/${id}`,
        { hasConflict: "0" }
      );
      loadItem();
      // detailsDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Conflict resolved successfully.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "RESOLVE_CONFLICT_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  const confirmAndRemoveConflict = async (id) => {
    const isConfirmed = await confirm(
      `Do you really want to remove conflict for this car?`
    );

    if (isConfirmed) {
      await resolveConflict(id);
    }
  };

  const disabledItem = async (id, memberId) => {
    //console.log("In resolveConflict Function");
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.post(
        `${moduleConfig.url}/disabled/${memberId}/${id}`,
        { disabled: "1" }
      );
      loadItem();
      // detailsDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Car archived successfully.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  const confirmAndDisabled = async (id, memberId) => {
    const isConfirmed = await confirm(
      `Do you really want to archive this car?`
    );

    if (isConfirmed) {
      await disabledItem(id, memberId);
    }
  };

  const enabledItem = async (id, memberId) => {
    console.log("In resolveConflict Function");
    detailsDispatch({
      type: "RESOLVE_CONFLICT_REQUEST",
    });

    try {
      const res = await axios.post(
        `${moduleConfig.url}/enabled/${memberId}/${id}`,
        { enabled: "1" }
      );
      loadItem();
      // detailsDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Car restore successfully.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "RESOLVE_CONFLICT_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  const confirmAndEnabled = async (id, memberId) => {
    const isConfirmed = await confirm(`Do you really want to enable this car?`);

    if (isConfirmed) {
      await enabledItem(id, memberId);
    }
  };

  return (
    <DashboardLayout>
      {detailsState.isFetching ? (
        <ContainerLoading />
      ) : (
        <>
          {detailsState.hasError ? (
            <BlockErrorMessage error={detailsState.error} />
          ) : (
            <>
              {detailsState.data.item && (
                <>
                  <ModuleHeader
                    moduleTitle={`View ${moduleConfig.singleItemTitle}`}
                    moduleUrl={moduleConfig.url}
                  ></ModuleHeader>

                  <div className="border mb-1 p-1 bg-light rounded text-white">
                    <Link
                      to={`/${moduleConfig.url}/update/${detailsState.data.item.memberId}/${detailsState.data.item._id}`}
                      className="btn btn-primary me-1"
                      title="Update Item"
                    >
                      <i className="fas fa-pencil-alt text-white"></i>{" "}
                      <span className="text-white">Update</span>
                    </Link>
                    {(detailsState.data.item.status === "1" ||
                      detailsState.data.item.status === 1) && (
                      <Link
                        title="Archive Item"
                        onClick={() =>
                          confirmAndDisabled(
                            detailsState.data.item._id,
                            detailsState.data.item.memberId
                          )
                        }
                        className="btn btn-primary  border me-1"
                      >
                        <i className="fas fa-trash text-white me-1"></i>{" "}
                        <span className="text-white">Archive</span>
                      </Link>
                    )}
                    {(detailsState.data.item.status === "0" ||
                      detailsState.data.item.status === 0) && (
                      <Link
                        title="Restore Item"
                        onClick={() =>
                          confirmAndEnabled(
                            detailsState.data.item._id,
                            detailsState.data.item.memberId
                          )
                        }
                        className="btn btn-primary  border me-1"
                      >
                        <i className="fas fa-arrow-up text-white me-1"></i>{" "}
                        <span className="text-white">Restore</span>
                      </Link>
                    )}
                    {(detailsState.data.item.hasConflict === "0" ||
                      detailsState.data.item.hasConflict === 0) && (
                      <Link
                        title="Create Conflict"
                        onClick={() =>
                          handleCreateConflict(
                            detailsState.data.item._id,
                            detailsState.data.item.memberId
                          )
                        }
                        className="btn btn-primary  border me-1"
                      >
                        <i className="fas fa-question text-white me-1"></i>{" "}
                        <span className="text-white">Create Conflict</span>
                      </Link>
                    )}
                    
                    {(detailsState.data.item.hasConflict === "1" ||
                      detailsState.data.item.hasConflict === 1) && (
                      <Button
                        variant="primary"
                        title="Remove Conflict"
                        onClick={() =>
                          confirmAndRemoveConflict(detailsState.data.item._id)
                        }
                      >
                         <i className="fas fa-undo text-white me-1"></i>{" "}
                        <span className="text-white">Resolve Conflict</span>
                       
                      </Button>
                    )}
                    {/* {console.log(process.env.PUBLIC_URL)}
                                    {" "}
                                    <Link to={`${process.env.PUBLIC_URL}/member-cars/${detailsState.data.detailsState.data.item._id}`} className="btn btn-primary" title="Update Item">
                                        <i className="fas fa-car"></i> Carssss
                                    </Link> */}
                  </div>

                  <Details
                    item={detailsState.data.item}
                    moduleConfig={moduleConfig}
                    handleCreateConflict={handleCreateConflict}
                    confirmAndRemoveConflict={confirmAndRemoveConflict}
                    confirmAndDisabled={confirmAndDisabled}
                    confirmAndEnabled={confirmAndEnabled}
                  />

                  {/* {console.log(detailsState.data,"---------")} */}

                  <CarGallery items={detailsState.data.gallery} />
                </>
              )}
            </>
          )}

          <CreateConflict
            id={itemId}
            memberId={memberId}
            showCreateConflictModal={showCreateConflictModal}
            setShowCreateConflictModal={setShowCreateConflictModal}
            loadAgain={loadItem}
          />
        </>
      )}
    </DashboardLayout>
  );
};

export default MemberCarView;
