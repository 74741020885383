import React, { useState, useReducer } from "react";
import {
  Alert,
  Card,
  Col,
  Row,
  Table,
  Badge,
  Image,
  Button,
  Modal,
} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";

import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayFieldsController from "../../../../UI/modules/fieldsDisplay/ModuleDisplayFieldsController";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
import memberDummyImage from "../../../../../images/memberDummyImage.jpg";
import memberStatusEmailVerificationRequired from "../../../../../images/memberStatusEmailVerificationRequired.png";
import memberStatusSignupRequest from "../../../../../images/memberStatusSignupRequest.png";
import memberStatusRejected from "../../../../../images/memberStatusRejected.png";
import memberStatusRegistrationPaymentPending from "../../../../../images/memberStatusRegistrationPaymentPending.png";
import memberStatusMember from "../../../../../images/memberStatusMember.jpg";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";



import useConfirm from "../../../../../HOC/custom/useConfirm";
import axios from "../../../../../config/axios";
import moduleConfig from "../../moduleConfig";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../../../config/toastNotification";
import { ContainerLoading } from "../../../../UI/loading/ContainerLoading";
import BlockSuccessMessage from "../../../../UI/errorMessages/BlockSuccessMessage";
import FormErrorMessage from "../../../../UI/errorMessages/FormErrorMessage";
import CMSFormInput from "../../../../UI/forms/CMSFormInput";
import { Multiselect } from "multiselect-react-dropdown";
import AddCircleMembers from "./AddCircleMembers";

import MemberItem from "../../../../UI/shared/manageTargetedMembers/MemberItem";

const Details = ({ item, members, detailsDispatch, loadItem, loading }) => {
  const { confirm } = useConfirm();
  const [show, setShow] = useState(false);

  const confirmAndRemoveMember = async (memberId) => {
    //console.log(memberId, item._id)
    const isConfirmed = await confirm(
      `Do you really want to remove this member from circle?`
    );

    if (isConfirmed) {
      await removeMember(memberId);
    }
  };

  const removeMember = async (memberId) => {
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.post(
        `${moduleConfig.url}/remove-member/${item._id}`,
        { memberId }
      );

      loadItem();

      // detailsDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Member removed successfully.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "BLOCK_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <Card className="item mb-2">
            {loading && (
              <div class="overlay">
                <i class="fas fa-2x fa-sync-alt fa-spin"></i>
              </div>
            )}
            <Card.Header className="ps-1 pe-2 py-0">
              <Card.Title className="m-0 p-0 ">
                <div className="d-flex ImageSectionSpacing">
                  <div className="position-relative ms-1 me-2">

                  <i className={`fas fa-users position-relative usericon`}></i>

                    {item.status === "0" || item.status === 0 ? (
                      <i className="fas fa-ban position-absolute banned ms-1"></i>
                    ) : null}
                  </div>

                  <div className="d-flex flex-column ms-1">
                    <span className="m-0 text-bold memberName">
                      {item.name} - {item.color}
                    </span>
                    <span className="font-italic font-small font-weight-normal text-success fs-8  ">

                      <span className="membershipId">
                        ({members && members.length > 0 ? members.length : 0}{" "}
                        members)
                      </span>
                    </span>
                  </div>
                </div>
              </Card.Title>

              <div className="card-tools">
                <div className=" text-right  mt-1">
                  <span className="badge badge-light me-1 text-dark">
                    Created Date/Time :{" "}
                    <span class="text-secondary">
                      <ModuleDisplayDateTime value={item.createdAt} />{" "}
                    </span>
                  </span>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="px-2 py-0">
              <div className="d-flex my-2">
                <h5>Circle Member(s) </h5> &nbsp; &nbsp;{" "}
                <Button
                  variant="primary"
                  size="sm"
                  className="rounded-circle roundButton"
                  onClick={() => setShow(true)}
                >
                  <i className="fas fa-plus"></i>
                </Button>
              </div>

              <Row className="circleMembersList">
                {members.length > 0 ? (
                  <>
                    {members.map((member, index) => {
                      return (
                        <Col
                          className="mb-2"
                          key={index}
                          xs={12}
                          sm={12}
                          md={4}
                          lg={3}
                          xl={3}
                        >
                          <MemberItem
                            member={member}
                            confirmAndRemoveMember={confirmAndRemoveMember}
                          />{" "}
                        </Col>
                      );
                    })}
                  </>
                ) : (
                  <Alert variant="warning mb-0">No records found.</Alert>
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <AddCircleMembers
        show={show}
        setShow={setShow}
        circleId={item._id}
        loadItem={loadItem}
      />
    </>
  );
};

export default Details;
