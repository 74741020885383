import React from 'react'
import { Card, Alert, Row, Col, Table, Container } from 'react-bootstrap'
import FormErrorMessage from '../../errorMessages/FormErrorMessage'
import ModuleBulkActionsDropdown from './partials/common/ModuleBulkActionsDropdown'
import ModulePagination from './partials/common/ModulePagination'
import ModulePaginationSummary from './partials/common/ModulePaginationSummary'
import ModuleRecordsPerPageDropdown from './partials/common/ModuleRecordsPerPageDropdown'
import ModuleSearchBox from './partials/common/ModuleSearchBox'

import ModuleStatusDropdown from './partials/common/ModuleStatusDropdown'

import ModuleBlockStyleListing from './partials/blockStyle/ModuleBlockStyleListing'




const ModuleListingViewInBlockStyle = ({ listingState, resetFilters, moduleConfig, setFilter, isCheck, setIsCheck, isCheckAll, setIsCheckAll, handleSelectAll, deleteItem, customButtonsForItems, performBulkAction }) => {
    return (
        <div className="listingPage">
            {(listingState.data && listingState.data.pagination && listingState.data.pagination.totalItems > 0)
                ? <>



                    {/* Listing */}
                    <section className="content listing">

                        {
                            (listingState.hasError === true)
                            && <FormErrorMessage error={listingState.error} />
                        }






                        {(!listingState.data.items || listingState.data.items.length <= 0)
                            ? <Alert variant="warning" >No records found with selected filters. <Alert.Link href="#" onClick={() => resetFilters()}> Reset Filters</Alert.Link>.</Alert>
                            :
                            <ModuleBlockStyleListing
                                items={(listingState.data && listingState.data.items) ? listingState.data.items : []}
                                moduleConfig={moduleConfig}
                                deleteItem={deleteItem}
                                customButtonsForItems={customButtonsForItems}
                                isCheck={isCheck}
                                setIsCheck={setIsCheck}
                                setIsCheckAll={setIsCheckAll}
                                isCheckAll={isCheckAll}
                                handleSelectAll={handleSelectAll}
                            />

                        }





                    </section>

                    {/* Bulk Action & Pagination */}
                    {(listingState.data && listingState.data.items && listingState.data.items.length > 0) && (
                        <Card className="bg-light  footerArea m-0 p-0 mb-1 p-1">

                            <Card.Body className="m-0 p-0">
                                <Row className="footer">
                                    <Col>
                                        {(moduleConfig.bulkActions.length > 0) &&
                                            <ModuleBulkActionsDropdown
                                                bulkActions={moduleConfig.bulkActions}
                                                isCheck={isCheck}
                                                performBulkAction={performBulkAction}
                                            />
                                        }
                                    </Col>
                                    <Col className="text-center">
                                        {
                                            (moduleConfig.hasPagination === true) &&
                                            <ModulePagination
                                                pagination={(listingState.data && listingState.data.pagination) ? listingState.data.pagination : {}}
                                                setFilter={setFilter}
                                            />
                                        }
                                    </Col>
                                    <Col className="text-right">
                                        {
                                            (moduleConfig.hasPagination === true) &&
                                            <ModulePaginationSummary
                                                pagination={(listingState.data && listingState.data.pagination) ? listingState.data.pagination : {}}
                                                currentItemsCount={listingState.data.items.length}
                                            />
                                        }
                                    </Col>
                                    <div className="clearfix"></div>
                                </Row>
                            </Card.Body>
                        </Card>

                    )}


                </>
                : <Alert variant="warning" className="m-0">No records found.</Alert>
            }
        </div>
    )
}

export default ModuleListingViewInBlockStyle