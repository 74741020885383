import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Badge, Image } from 'react-bootstrap';
import useConfirm from '../../../../../../HOC/custom/useConfirm';
import MailTo from '../../../../mix/MailTo';
import SendEmail from '../../../../mix/SendEmail';
import moment from 'moment';
import { isEmpty } from '../../../../../../helpers/CommonHelper';

//import ReactFancyBox from 'react-fancybox';
import image from "../../../../../../images/memberDummyImage.jpg";
import ModuleDisplayDateTime from '../../../fieldsDisplay/ModuleDisplayDateTime';
import ModuleDisplayFieldsController from '../../../fieldsDisplay/ModuleDisplayFieldsController';


const ModuleBlockStyleListItem = ({ item, moduleConfig, deleteItem, customButtonsForItems, isCheck, setIsCheck, setIsCheckAll }) => { // moduleConfig, items, deleteItem, customButtonsForItems, isCheck, setIsCheck, setIsCheckAll, pagination
    const { confirm } = useConfirm();
    const fields = moduleConfig.fields;

    const confirmAndDelete = async (id) => {
        const isConfirmed = await confirm(`Do you really want to delete this ${moduleConfig.singleItemTitle}?`);

        if (isConfirmed) {
            await deleteItem(id)
        }
    }

    const handleCheckBoxClick = e => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter(item => item !== id));
        }
        setIsCheckAll(false)
    };

    return <Card className="item mb-2">
        <Card.Header className="ps-1 pe-2 py-0">
            <Card.Title className="m-0 p-0 ">
                <div className="d-flex ImageSectionSpacing">
                    <div className="checkboxCol me-2 ms-2 mt-2 ">
                        <input
                            type="checkbox"
                            id={item._id}
                            name={item._id}
                            onChange={handleCheckBoxClick}
                            checked={isCheck.includes(item._id)}
                        />
                    </div>


                    <div className="ms-1 me-2">
                        <i className="fas fa-user position-relative usericon ">
                            {
                                (item.status === "0" || item.status === 0) &&
                                <i className="fas fa-ban position-absolute banned"></i>
                            }
                        </i>
                    </div>

                    <div className="d-flex flex-column ms-1">
                        <Link
                            to={`/${moduleConfig.url}/${item._id}`}
                            className="m-0 text-bold memberName"
                            title="View Item details"
                        >
                            {item.name}
                        </Link>

                        <span className="font-italic font-small font-weight-normal text-success fs-8">
                            ({item.country})
                        </span>

                    </div>

                </div>
                {/* <div className="mt-2  d-flex">
        <a
              href=""
              title="Click to write an email"
              class="badge badge-light me-1 text-dark text-decoration-none"
            >
                 <Badge bg="light border d-flex align-items-center justify-content-center w-100"> <i className="fas fa-envelope me-2 fs-6 text-primary"></i>Email</Badge>
            </a>
            <a
              href=""
              title="Click to make call"
              class="badge badge-light me-1 text-dark text-decoration-none"
            >
                <Badge bg="light border d-flex align-items-center justify-content-center w-100 ">  <i className="fa fa-phone fs-6 me-2 text-primary"></i>Phone</Badge>
            </a>
       
               
              
              </div> */}


            </Card.Title>


            <div class="card-tools mb-1">
                <div>
                    <span class="badge badge-light me-1 text-dark ">
                        Member since : <span class="text-secondary"><ModuleDisplayDateTime value={item.createdAt} /> </span>
                    </span>
                    <span class="badge badge-white me-1 text-dark">
                        Status:  {((item.status === '1' || item.status === 1) ? <Badge bg="success">Enabled</Badge> : <Badge bg="warning" text="dark">Disabled</Badge>)}

                    </span>
                </div>

                <div className=" text-right  mt-1 me-2">
                    {
                        (moduleConfig.buttons && moduleConfig.buttons.view === true) &&
                        <Link to={`/${moduleConfig.url}/${item._id}`} className="btn btn-outline-secondary listingActionIcon" title="View Item details">
                            <i className="fas fa-eye">
                            </i>
                        </Link>
                    }

                    {
                        (moduleConfig.buttons && moduleConfig.buttons.update === true) &&
                        <Link to={`/${moduleConfig.url}/update/${item._id}`} className="btn btn-outline-secondary listingActionIcon" title="Update Item">
                            <i className="fas fa-pencil-alt">
                            </i>
                        </Link>
                    }

                    {
                        (moduleConfig.buttons && moduleConfig.buttons.delete === true) &&
                        <span title="Delete Item" onClick={() => confirmAndDelete(item._id)} className="btn btn-outline-secondary listingActionIcon">
                            <i className="fas fa-trash">
                            </i>
                        </span>
                    }

                    {

                        (customButtonsForItems && customButtonsForItems.length > 0) &&

                        customButtonsForItems.map(({ label, className, url }, index) => (
                            <Link to={`/${moduleConfig.url}/${url}/${item._id}`} className="btn btn-outline-secondary listingActionIcon" key={index} title={label}>
                                <i className={className}>
                                </i>
                            </Link>
                        ))
                    }
                </div>

            </div>
        </Card.Header>
        <Card.Body className="p-2">
            <div className="mb-0 details">


                {
                    (fields && fields.length) && (
                        fields.map(({ displayName, fieldName, type, showOnListing, displayStyle }, index) => (
                            (showOnListing === true && (fieldName !== 'name' && fieldName !== 'status' && fieldName !== 'createdAt' && fieldName !== 'updatedAt')) && (
                                <span class="badge badge-light me-1 text-dark mt-1 mb-1 p-2 font-small d-flex" key={index}>
                                    {displayName} : <span class="text-secondary font-small ms-2"><ModuleDisplayFieldsController type={type} displayStyle={displayStyle} value={item[fieldName]} /></span>
                                </span>

                            )

                        ))
                    )
                }





            </div>
            <Row>
                <Col className="mt-1">
                    <a href="" title="Click to view details" class="badge badge-light me-1 text-dark text-decoration-none p-2 font-small">
                        Notification :&nbsp;<Badge bg="indigo" className="ms-2" pill>4</Badge>
                    </a>
                    {/* <a href="" title="Click to view details" class="badge badge-light me-1 text-dark text-decoration-none">
                        Circle :{" "}
                        <Badge bg="teal" pill>
                            4
                        </Badge>
                    </a>
                    <a href="" title="Click to view details" class="badge badge-light me-1 text-dark text-decoration-none">
                        Events :{" "}
                        <Badge bg="cyan" pill>
                            4
                        </Badge>
                    </a>
                    <a href="" title="Click to view details" class="badge badge-light me-1 text-dark text-decoration-none">
                        Cars :{" "}
                        <Badge bg="pink" pill>
                            4
                        </Badge>
                    </a>
                    <a href="" title="Click to view details" class="badge badge-light me-1 text-dark text-decoration-none">
                        Cars :{" "}
                        <Badge bg="dark" pill>
                            4
                        </Badge>
                    </a>
                    <a href="" title="Click to view details" class="badge badge-light me-1 text-dark text-decoration-none">
                        Cars :{" "}
                        <Badge bg="info" pill>
                            4
                        </Badge>
                    </a>
                    <a href="" title="Click to view details" class="badge badge-light me-1 text-dark text-decoration-none">
                        Cars :{" "}
                        <Badge bg="yellow" pill>
                            4
                        </Badge>
                    </a> */}
                </Col>
            </Row>
        </Card.Body>
    </Card>;
};

export default ModuleBlockStyleListItem;
