import React from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayFieldsController from "../../../../UI/modules/fieldsDisplay/ModuleDisplayFieldsController";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
import memberDummyImage from "../../../../../images/memberDummyImage.jpg";
import memberStatusEmailVerificationRequired from "../../../../../images/memberStatusEmailVerificationRequired.png";
import memberStatusSignupRequest from "../../../../../images/memberStatusSignupRequest.png";
import memberStatusRejected from "../../../../../images/memberStatusRejected.png";
import memberStatusRegistrationPaymentPending from "../../../../../images/memberStatusRegistrationPaymentPending.png";
import memberStatusMember from "../../../../../images/memberStatusMember.jpg";
import accountDeleted from "../../../../../images/accountDelete.png";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";
import { getFullName } from "../../../../../helpers/CommonHelper";
import ModuleDisplayDate from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDate";

const Item = ({
  item,
  handleCheckBoxClick,
  isCheck,
  moduleConfig,
  confirmAndDelete,
  customButtonsForItems,
}) => {
  const fields = moduleConfig.fields;
  const getItems = () => {
    let items = [];
    if (item.email) {
      items = [...items, { text: "Email", value: item.email, type: "email" }];
    }
    if (item.mobile) {
      items = [...items, { text: "Mobile", value: item.mobile, type: "phone" }];
    }
    if (item.nationality) {
      items = [
        ...items,
        { text: "Nationality", value: item.nationality, type: "text" },
      ];
    }
    if (item.dob) {
      items = [
        ...items,
        { text: "Date of Birth", value: item.dob, type: "text" },
      ];
    }
    if (item.occupation) {
      items = [
        ...items,
        { text: "Occupation", value: item.occupation, type: "text" },
      ];
    }
    if (item.referredBy) {
      items = [
        ...items,
        { text: "Referred By", value: item.referredBy, type: "text" },
      ];
    }
    if (item.referredName) {
      items = [
        ...items,
        { text: "Referred Name", value: item.referredName, type: "text" },
      ];
    }
    return items;
  };
  const getCounts = () => {
    console.log(item.stats)
    let items = [
      {
        title: "Cars",
        value: item.stats.cars,
        url: `/member-cars/${item._id}`,
      },
    ];
    if (item.stats.registrationCount > 0) {
      items = [
        ...items,
        {
          title: "Registration(s)",
          value: item.stats.registrationCount,
          url: `/members/${item._id}`,
        },
      ];
    }
    if (item.stats.circlesCount > 0) {
      items = [
        ...items,
        {
          title: "Circle(s)",
          value: item.stats.circlesCount,
          url: `/circles/`,
        },
      ];
    }
    if (item.stats.unreadMessages > 0) {
      items = [
        ...items,
        {
          title: " Unread Message(s)",
          value: item.stats.unreadMessages,
          url: `/chat/member/${item._id}`,
        },
      ];
    }
    if (item.stats.pendingPaymentCount > 0) {
      items = [
        ...items,
        {
          title: "Pending Payment(s)",
          value: item.stats.pendingPaymentCount,
          url: `/payments/`,
        },
      ];
    }
    return items;
  };
  return (
    <Card className={`item mb-2 memberItem`}>
      {item.accountDeletionRequested === "1" ||
        item.accountDeletionRequested === 1 ||
        item.accountDeletionRequested === true ? (
        <>
          <div
            className={` ${item.accountDeletionRequested === "1" ||
                item.accountDeletionRequested === 1 ||
                item.accountDeletionRequested === true
                ? "accountDeleted"
                : ""
              }`}
          ></div>
        </>
      ) : null}

      <Card.Header
        className={`ps-1 pe-xl-0 pe-2 py-0  ${item.accountDeletionRequested === "1" ||
            item.accountDeletionRequested === 1 ||
            item.accountDeletionRequested === true
            ? "accountDeletedBg"
            : ""
          }`}
      >
        <Card.Title className="d-flex m-0 p-0">
          {console.log(item)}
          <div className="d-flex ImageSectionSpacing">
            <div className="checkboxCol me-2 ms-2 mt-2">
              <input
                type="checkbox"
                id={item._id}
                email={item.email}
                name={item._id}
                onChange={handleCheckBoxClick}
                checked={isCheck.includes(item._id)}
              />
            </div>
            <div className="ms-1 me-2 position-relative">
              {/* {item.isChairman === "1" ||
                item.isChairman === 1 ||
                item.isChairman === true ? (
                <div className="chairmanStatus">
                  <i
                    class="fa fa-star"
                    aria-hidden="true"
                    title={`${item.fullName ? item.fullName : getFullName(item)
                      } is Chairman`}
                  ></i>
                </div>
              ) : null} */}

              {item.accountDeletionRequested === "1" ||
                item.accountDeletionRequested === 1 ||
                item.accountDeletionRequested === true ? (
                <div className="accountDeleteIcon">
                  <i
                    class="fa fa-trash"
                    aria-hidden="true"
                    title={`${item.fullName ? item.fullName : getFullName(item)
                      } requested for account deletion`}
                  ></i>
                </div>
              ) : null}

              {item.accountDeletionRequested === "1" ||
                item.accountDeletionRequested === 1 ||
                item.accountDeletionRequested === true ? null : (
                <>
                  {item.isLoggedIn === "1" ||
                    item.isLoggedIn === 1 ||
                    item.isLoggedIn === true ? (
                    <div className="activeStatus"></div>
                  ) : (
                    <div className="notActiveStatus"></div>
                  )}
                </>
              )}

              <>
                {item.image ? (
                  <Image
                    src={
                      process.env.REACT_APP_BACKEND_URL + "public/" + item.image
                    }
                    width="52px"
                    height="52px"
                    className="border rounded-circle"
                  />
                ) : (
                  <Image
                    src={memberDummyImage}
                    width="45px"
                    className="border rounded-circle"
                  />
                )}
              </>

              {item.blockedByAdmin === "1" && (
                <i className="fas fa-ban position-absolute banned2"> </i>
              )}
            </div>

            <div className="d-flex flex-column ms-1">
              <span className="m-0 text-bold memberName">
                {console.log(item)}
                {getFullName(item)} 
                {item.accountDeletionRequested === "1" ||
                  item.accountDeletionRequested === 1 ||
                  item.accountDeletionRequested === true ? null : (
                  <>
                  {item.role === "Board Member" ?    <span className="boardMemberText">{item.role}</span>: item.role === "Club President" ?   <span className="greenBackgroundText">{item.role}</span> :item.role === "Club Vice President" ?<span className="greenBackgroundText">{item.role}</span> :item.role === "Member"? null :<span className="normalText">({item.role})</span> }
                  
                   
                  
                    {/* {item.hasCMSAccess === "1" && (
                      <span className="hasCMSAccessText">Has CMS Access</span>
                    )} */}
                  </>
                )}
              </span>

              <span className="font-italic font-small font-weight-normal text-success fs-8 mt-xl-1 mt-lg-0 mt-md-0 mt-1 ">
                {item.oldMemberNumber && (
                  <span className="membershipId">({item.oldMemberNumber})</span>
                )}
                {item.accountDeletionRequested === "1" ||
                  item.accountDeletionRequested === 1 ||
                  item.accountDeletionRequested === true ? (
                  <span className="memberStatusImageContainer">
                    <Image
                      src={accountDeleted}
                      title="Account Deletion Requested"
                      className="memberStatusImage"
                    />
                  </span>
                ) : (
                  <>
                    {item.status === "EmailVerificationRequired" && (
                      <span className="memberStatusImageContainer">
                        <Image
                          src={memberStatusEmailVerificationRequired}
                          title="Email Verification Pending"
                          className="memberStatusImage"
                        />
                      </span>
                    )}
                   
                    {item.status === "Member" && (
                        <span className="memberStatusImageContainer">
                          <Image
                            src={memberStatusMember}
                            title="Registration Payment Pending"
                            className="memberStatusImage"
                          />
                        </span>
                      )}
                    {item.status === "SignupRequest" && (
                      <span className="memberStatusImageContainer">
                        <Image
                          src={memberStatusSignupRequest}
                          title="Signup Request"
                          className="memberStatusImage"
                        />
                      </span>
                    )}

                    {item.status === "SignupRequestRejected" && (
                      <span className="memberStatusImageContainer">
                        <Image
                          src={memberStatusRejected}
                          title="Signup Request Rejected"
                          className="memberStatusImage"
                        />
                      </span>
                    )}

                    {item.status === "RegistrationPaymentPending" && (
                      <span className="memberStatusImageContainer">
                        <Image
                          src={memberStatusRegistrationPaymentPending}
                          title="Registration Payment Pending"
                          className="memberStatusImage"
                        />
                      </span>
                    )}

                    {item.status === "SignupRequest" &&
                      item.resubmission === "1" && (
                        <Badge bg="pink" className="ms-2 resubmission">
                          Resubmission
                        </Badge>
                      )}
                  </>
                )}
              </span>
            </div>
          </div>
        </Card.Title>

        <div class="card-tools mb-1 me-xl-0">
          <div>
            {item.accountDeletionRequested === "1" ||
              item.accountDeletionRequested === 1 ||
              item.accountDeletionRequested === true ? (
              <Badge bg="dark" className="ms-2 resubmission">
                Account Deletion Requested
              </Badge>
            ) : null}
            {item.expiryStatus === "membershipIsExpired" && (
              <>
                <Badge bg="danger">Expired</Badge>{" "}
              </>
            )}
            {item.expiryStatus === "membershipIsInTolerancePeriod" && (
              <>
                <Badge bg="warning">Tolerance Period</Badge>{" "}
              </>
            )}

            {item.expiryStatus === "membershipIsExpiringSoon" && (
              <>
                <Badge bg="secondary">Expiring Soon</Badge>{" "}
              </>
            )}

            {item.migratedMember === "1" && (
              <>
                <Badge bg="info">
                  Migrated Member
                </Badge>{" "}
              </>
            )}
            {item.createdByAdmin === "1" && (
              <>
                <Badge bg="primary" className="me-0">
                  Admin Created Profile
                </Badge>{" "}
              </>
            )}
             {item.createdByAdmin === "0" &&item.migratedMember === "0" && (
              <>
                <Badge bg="purple" className="me-0">
                  Mobile App Signup
                </Badge>{" "}
              </>
            )}
            {/* {item.status === "Member" && item.joiningDate && (
              <>
                <Badge bg="success">Member Since: {item.joiningDate}</Badge>{" "}
              </>
            )} */}
            {/* {(item.status !== "Member" && item.createdAt) &&
              <> */}
            {/* <Badge bg="warning">Profile Created On: {item.createdAt}</Badge>{" "} */}
            {/* </>
            } */}
          </div>

          <div className=" text-right me-xl-0  mt-1">
            <Link
              to={`/${moduleConfig.url}/${item._id}`}
              className="btn btn-outline-secondary  listingActionIcon"
              title="View Item details"
            >
              <i className="fas fa-eye"></i>
            </Link>

            <Link
              to={`/${moduleConfig.url}/update/${item._id}`}
              className="btn btn-outline-secondary listingActionIcon"
              title="Update Item"
            >
              <i className="fas fa-pencil-alt"></i>
            </Link>

            {/* <span title="Delete Item" onClick={() => confirmAndDelete(item._id)} className="btn btn-outline-secondary btn-sm">
                            <i className="fas fa-trash">
                            </i>
                        </span> */}
          </div>
        </div>
      </Card.Header>
      <Card.Body className={`p-2 `}>
        {item.accountDeletionRequested === "1" ||
          item.accountDeletionRequested === 1 ||
          item.accountDeletionRequested === true ? (
          <>
            {item.accountDeletionReason && (
              <Alert variant="dark mt-1 mb-0 p-1 font-small">
                {" "}
                <p className="mb-0 text-bold font-small">
                  Account Deletion Reason :
                  <span className="mb-0 text-normal ps-2">
                    {item.accountDeletionReason}
                  </span>
                </p>
              </Alert>
            )}
          </>
        ) : null}
        {item.rejectionReason &&
          (item.status === "SignupRequest" ||
            item.status === "SignupRequestRejected") && (
            <Alert variant="danger mt-1 mb-0 p-1 font-small">
              {" "}
              <p className="mb-0 text-bold font-small">
                {(item.resubmission === "1" ? "Previous " : "") +
                  "Rejection Reason"}{" "}
                :
                <span className="mb-0 text-normal ps-2">
                  {item.rejectionReason}
                </span>
              </p>
            </Alert>
          )}
        {item.blockedByAdmin === "1" && item.blockReason && (
          <Alert variant="danger mt-1 mb-0 p-1 font-small">
            {" "}
            <p className="mb-0 text-bold font-small">
              Block Reason :
              <span className="mb-0 text-normal ps-2">{item.blockReason}</span>
            </p>
          </Alert>
        )}
        {item.stats && item.stats.cars <= 0 && (
          <Alert variant="warning mt-1 mb-0 p-1 font-small">
            {" "}
            <p className="mb-0 text-bold font-small">
              <span className="mb-0 text-normal ps-2">
                This member doesn't have an active car.{" "}
                {item.lastCarDeletedOn &&
                  item.lastCarDeletedOn.deletionDate && (
                    <>
                      Last car deleted on{" "}
                      <span className="text-bold">
                        <ModuleDisplayDate
                          value={item.lastCarDeletedOn.deletionDate}
                        />
                        .
                      </span>
                    </>
                  )}
              </span>
            </p>
          </Alert>
        )}
        {item.carConflicts && item.carConflicts.length == 1 && (
          <div className="warningDark mt-1 mb-0 p-1 font-small">
            {" "}
            <p className="mb-0 text-bold font-small">
              <span className="mb-0 text-normal ps-2">
                This member has a conflict with his/her car "
                {item.carConflicts[0].model}"
                <>
                  {item.carConflicts[0] &&
                    item.carConflicts[0].conflictReason && (
                      <span className="text-bold ps-2 mb-0">
                        Conflict Reason:
                        <span className="mb-0 text-normal ps-2">
                          {item.carConflicts[0].conflictReason}
                        </span>
                      </span>
                    )}
                </>
              </span>
            </p>
          </div>
        )}
        {item.carConflicts && item.carConflicts.length > 1 && (
          <div className="warningDark mt-1 mb-0 p-1 font-small">
            {" "}
            <p className="mb-0 text-bold font-small">
              <span className="mb-0 text-normal ps-2">
                This member has conflict with his/her car.
              </span>
            </p>
          </div>
        )}
        <StatsListing items={getItems()} />{" "}
        <Row>
          <Col className="mt-1">
            <StatsListing
              border="border"
              heading="Stats"
              clickable={true}
              items={getCounts()}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Item;
