import { moduleDefaultConfig } from "../../../config/moduleDefaultConfig"
import * as yup from "yup"
let moduleConfig = {}
moduleConfig = {
    ...moduleDefaultConfig
};

moduleConfig.title = 'Notifications';
moduleConfig.singleItemTitle = 'Notification';
moduleConfig.url = 'notifications';
moduleConfig.moduleType = {
    listing: 'block',
    view: 'block'
};


moduleConfig.bulkActions = [
    {
        displayName: "Archived Selected",
        value: "Archived"
    },
    {
        displayName: "Restore Selected",
        value: "Restore"
    }
];


export default moduleConfig;