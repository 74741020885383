import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const FormErrorMessage = ({ error }) => {
    //console.log(error);
    const [errorMessage, setErrorMessage] = useState('');
    const [status, setStatus] = useState(null);

    useEffect(() => {

        if (!error) {
            setErrorMessage("Action failed, try again later.")
        } else {

            if (typeof error === 'string' || error instanceof String) {
                setErrorMessage(error)
            } else if (typeof error === 'Object' || error instanceof Error) {


                if (error.response && error.response.data && error.response.data.message) {
                    setErrorMessage(error.response.data.message)
                } else {
                    setErrorMessage(error.message)
                }
            }
        }
    }, [error])




    return (
        <>

            {(errorMessage && errorMessage !== '') && (
                <div className="error errorMsg text-danger">
                    <i className="fas fa-exclamation-triangle " /> {errorMessage}
                </div>
            )
            }

        </>
    );

};

export default FormErrorMessage;
