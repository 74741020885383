import React from "react";
import { Alert, Button, Card, Image } from "react-bootstrap";
import { getFullName } from "../../../../helpers/CommonHelper";
import image from "../../../../images/memberDummyImage.jpg";
import ModuleDisplayBoolean from "../../modules/fieldsDisplay/ModuleDisplayBoolean";
import ModuleDisplayEmail from "../../modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayPhone from "../../modules/fieldsDisplay/ModuleDisplayPhone";
const MemberItem = ({ member, confirmAndRemoveMember }) => {
  return (
    <Card className="p-0 m-0">
      <Card.Body className="p-0">
        <div class="memberItemHolder">
          <div class="memberItemLeft">
            <div className="position-relative rounded-circle">
              {console.log(member)}
              {member.isLoggedIn === "1" ||
                member.isLoggedIn === 1 ||
                member.isLoggedIn === true ? (
                <div className="activeStatusBig"></div>
              ) : (
                <div className="notActiveStatusBig"></div>
              )}
              {member.image ? (
                <Image
                  src={
                    process.env.REACT_APP_BACKEND_URL + "public/" + member.image
                  }
                  width="75px"
                  height="75px"
                  className="border rounded-circle"
                />
              ) : (
                <Image
                  src={image}
                  width="75px"
                  height="75px"
                  className="border rounded-circle"
                />
              )}
            </div>
          </div>
          <div class="memberItemRight">
            

            <div className="content mt-1">
            <div className="actionIcons memberRemove float-end">
              <span
                title="Remove member"
                onClick={() => confirmAndRemoveMember(member._id)}
                className="circleMemberRemoveIcon"
              >
                <i className="fas fa-times"></i>
              </span>
            </div>
              <Button
                className="direct-chat-name text-left m-0 p-0 text-info"
                href={`/members/${member._id}`}
                variant="link"
              >
                {member.fullName ? member.fullName : getFullName(member)}
              </Button>
              {/* <h6 className="text-bold mb-1">
                {member.firstName} {member.lastName}
              </h6> */}
              <p class="m-0 font-small text-info">
                <ModuleDisplayPhone value={member.mobile} />
              </p>
              <p class="mb-1 font-italic emailDisplay font-small ">
                <ModuleDisplayEmail value={member.email} />
              </p>
            </div>
          </div>
        </div>

   

        {/* <Alert variant="info" className="m-0" >Member requested to leave the circle.</Alert> */}
      </Card.Body>
    </Card>
  );
};

export default MemberItem;
