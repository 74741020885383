import React from 'react'
import { Badge } from 'react-bootstrap'
const ModuleDisplayArray = ({ value }) => {
    return (
        <div className="cmsModuleArrayListing">
            {
                (value && value.length > 0)
                    ? value.map((item, index) => {
                        return <Badge bg="secondary" key={index}>{item}</Badge>;
                    })
                    : null}
        </div>
    )
}

export default ModuleDisplayArray