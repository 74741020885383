import React, { useReducer, useEffect, useContext, useRef } from "react";
import { Link, useNavigate, useNavigation, useParams } from "react-router-dom";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import image from "../../../images/memberDummyImage.jpg";
import {
  messageReducer,
  messageInitialState,
} from "../members/reducers/messageReducer";

import * as yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import AdminMessageItem from "./partials/thread/AdminMessageItem";
import MemberMessageItem from "./partials/thread/MemberMessageItem";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import moduleConfig from "./moduleConfig";
import { Alert, Button, Col, Row, Image } from "react-bootstrap";
import socket from "../../../config/socket";

const MessagesThread = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [messageState, messageDispatch] = useReducer(
    messageReducer,
    messageInitialState
  );
  let { id, communicationType } = useParams();

  // console.log(id, communicationType)
  const messagesEndRef = useRef(null);
  //console.log(id);
  let breadcrumbs = {
    listing: {
      label: moduleConfig.title,
      url: `/${moduleConfig.url}`,
    },
    chat: {
      label: "Chat Messages ",
    },
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const loadItems = async () => {
    if (id) {
      messageDispatch({
        type: "FETCH_REQUEST",
      });
      try {
        const res = await axios.get(
          `${moduleConfig.url}/${communicationType}/${id}`
        ); //
        messageDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
        console.log(res.data, "-------messages");
        scrollToBottom();
      } catch (error) {
        messageDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };

  const reloadItems = async () => {
    if (id) {
      try {
        const res = await axios.get(
          `${moduleConfig.url}/${communicationType}/${id}`
        ); //
        messageDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
        scrollToBottom();
      } catch (error) {}
    }
  };
  const navigate = useNavigate();
  const onSubmit = async (data, resetForm) => {
    messageDispatch({
      type: "SEND_MESSAGE_REQUEST",
    });

    try {
      const res = await axios.post(`${moduleConfig.url}`, data); //
      console.log(res);
      messageDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      resetForm();
      reloadItems();
    } catch (error) {
      console.log(error.response);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.message : error.message;

      messageDispatch({
        type: "SEND_MESSAGE_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        messageDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  useEffect(() => {
    loadItems();
    const interval = setInterval(async () => {
      reloadItems();
    }, 10000);

    return () => clearInterval(interval);
    setBreadcrumb([breadcrumbs.listing, breadcrumbs.details, breadcrumbs.chat]);
  }, [id]);

  useEffect(() => {
    // console.log("UseEffect messageState")
    if (
      messageState.data &&
      messageState.data.conversation &&
      messageState.data.conversation._id
    ) {
      // console.log("messageState.data.conversation._id ==> ", messageState.data.conversation._id)

      socket.emit("joinRoom", messageState.data.conversation._id);
      socket.on("disconnect", () => {
        console.log("socket disconnected: ");
      });

      socket.on("roomJoined", (data) => {
        //console.log('roomJoined:', data);
      });

      socket.on("roomLeft", (data) => {
        //  console.log('roomLeft:', data);
      });

      socket.on("newMessage", (newMessage) => {
        // console.log('on NewMessage ==> :');

        let newMessagesArr = [...messageState.data.messages, ...[newMessage]];
        let newData = {
          ...messageState.data,
          messages: newMessagesArr,
        };

        messageDispatch({
          type: "FETCH_SUCCESS",
          payload: newData,
        });

        setTimeout(() => {
          scrollToBottom();
          // if (scrollViewRef && scrollViewRef.current) {
          //     scrollViewRef.current.scrollToEnd({ animated: true });
          // }
        }, 400);
      });
    }
    return () => {
      socket.emit(
        "leaveRoom",
        messageState.data &&
          messageState.data.conversation &&
          messageState.data.conversation._id
          ? messageState.data.conversation._id
          : "dummy"
      );
      socket.off("roomJoined");
      socket.off("roomLeft");
      socket.off("newMessage");
    };
  }, [messageState.data]);

  return (
    <DashboardLayout>
      {messageState.isLoading && messageState.status === "fetching" ? (
        <ContainerLoading />
      ) : (
        <>
          {messageState.error && messageState.status === "fetchFailed" ? (
            <BlockErrorMessage error={messageState.error} />
          ) : (
            <>
              {
                <>
                  <Row>
                    <Col className="mx-xl-4 m-2">
                      <Button onClick={() => navigate(-1)}>
                        <i class="fa fa-arrow-left me-1" aria-hidden="true"></i>{" "}
                        Back
                      </Button>
                    </Col>
                  </Row>
                  <div className="memberMessages ">
                    <div className="card m-2 mb-3 mx-xl-4 card-primary direct-chat direct-chat-info">
                      <div className="card-header d-flex">
                        <div className="position-relative avatar rounded-circle">
                          {communicationType === "member" && (
                            <>
                              {messageState.data.member.isLoggedIn === "1" ||
                              messageState.data.member.isLoggedIn === 1 ||
                              messageState.data.member.isLoggedIn === true ? (
                                <div className="activeStatus"></div>
                              ) : (
                                <div className="notActiveStatus"></div>
                              )}
                              {messageState.data.member.image ? (
                            <Image
                              src={
                                process.env.REACT_APP_BACKEND_URL +
                                "public/" +
                                messageState.data.member.image
                              }
                              width="45px"
                              height="45px"
                              className="border rounded-circle"
                            />
                          ) : (
                            <Image
                              src={image}
                              width="45px"
                              height="45px"
                              className="border rounded-circle"
                            />
                          )}
                            </>
                          )}

                          
                        </div>
                        <h3 className="card-title d-flex align-items-center justify-content-start flex-wrap ms-2">
                          Chat with{" "}
                          <b className="ms-1 me-1">
                            {communicationType === "member"
                              ? messageState.data.member.fullName
                              : messageState.data.circle.fullName}
                          </b>{" "}
                          {communicationType === "circle" && " circle members"}
                        </h3>
                      </div>
                      <div className="card-body availableHeight">
                        <div className="direct-chat-messages   px-4 ">
                          {messageState.data.messages &&
                            messageState.data.messages.length > 0 && (
                              <>
                                {messageState.data.messages.map(
                                  (message, index) => {
                                    return message.sentByAdmin === "1" ? (
                                      <AdminMessageItem
                                        message={message}
                                        key={index}
                                      />
                                    ) : (
                                      <MemberMessageItem
                                        message={message}
                                        key={index}
                                        member={message.sender}
                                      />
                                    );
                                  }
                                )}
                              </>
                            )}
                          <div ref={messagesEndRef} />
                        </div>
                      </div>

                      <div className="card-footer">
                        <Formik
                          initialValues={{
                            message: "",
                            memberId: id,
                            circleId: id,
                            communicationType:
                              communicationType === "member"
                                ? "Member-Admin"
                                : "Circle",
                          }}
                          validationSchema={yup.object({
                            message: yup
                              .string()
                              .required("Please enter a message.")
                              .max(1000),
                            memberId: yup.string().required("Required"),
                          })}
                          enableReinitialize={true}
                          validateOnChange={true}
                          validateOnBlur={false}
                          onSubmit={async (values, { resetForm }) => {
                            //  console.log(values)

                            onSubmit(values, resetForm);
                          }}
                        >
                          {(form) => (
                            <Form onSubmit={form.handleSubmit}>
                              {(communicationType === "member" &&
                                messageState.data.member &&
                                messageState.data.member.blockedByAdmin ===
                                  "1") ||
                              (communicationType === "circle" &&
                                messageState.data.circle &&
                                messageState.data.circle.status === "0") ? (
                                <Alert variant="dark">
                                  {communicationType === "member" ? (
                                    <>
                                      Chat feature is not available for blocked
                                      members.
                                    </>
                                  ) : (
                                    <>
                                      Chat feature is not available for archived
                                      circles.
                                    </>
                                  )}
                                </Alert>
                              ) : (
                                <>
                                  <div className="input-group ">
                                    <Field
                                      name="message"
                                      className={`form-control ${
                                        form.errors["message"]
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      readOnly={
                                        messageState.isLoading === true &&
                                        messageState.status ===
                                          "actionPerforming"
                                          ? true
                                          : false
                                      }
                                    />
                                    <span className="input-group-append">
                                      <button
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={
                                          messageState.isLoading === true &&
                                          messageState.status ===
                                            "actionPerforming"
                                            ? true
                                            : false
                                        }
                                      >
                                        Send
                                      </button>
                                    </span>
                                    {form.errors["message"] !== undefined && (
                                      <span
                                        id="message-error"
                                        className="error invalid-feedback"
                                      >
                                        {form.errors["message"]}
                                      </span>
                                    )}

                                    {/* <span className="error errorMessage">Failed to send message.</span> */}
                                  </div>
                                  {messageState.status === "actionFailed" && (
                                    <FormErrorMessage error="Failed to send message." />
                                  )}
                                </>
                              )}
                            </Form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </>
              }
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default MessagesThread;
