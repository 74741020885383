let user = localStorage.getItem('user');
if (user && user !== undefined && user !== 'undefined') {
    user = JSON.parse(user)
}

export const userAuthInitialState = {
    user: (user && user.token) ? user : null,
    isLoggingIn: false,
    isLoggedIn: (user && user.token) ? true : false,
    loginError: "",
    isLoggingOut: false,
};

export const authReducer = (state, action) => {
    switch (action.type) {
        case "LOGIN_REQUEST":
            return {
                ...state,
                isLoggingIn: true,
                isLoggedIn: false,
                loginError: "",
            }
        case "LOGIN_SUCCESS":
            localStorage.setItem("user", JSON.stringify(action.payload.user));
            return {
                ...state,
                user: action.payload.user,
                isLoggingIn: false,
                isLoggedIn: true,
            }
        case "LOGIN_FAILURE":
            return {
                ...state,
                isLoggingIn: false,
                isLoggedIn: false,
                loginError: action.payload,
            }

        case "LOGIN_RESET":
            return userAuthInitialState;

        case "LOGOUT_REQUEST":
            return {
                ...state,
                isLoggingOut: true,
            }
        case "LOGOUT_SUCCESS":
            localStorage.clear();
            return {
                user: null,
                isLoggingIn: false,
                isLoggedIn: false,
                loginError: "",
                isLoggingOut: false,
            };
        case "LOGOUT_FAILURE":
            return {
                ...state,
                isLoggingOut: false,
            }
        default:
            return state;
    }
}