import React, { useState, useReducer } from 'react'
import * as yup from "yup"
import { Form, Formik } from 'formik';
import Modal from 'react-bootstrap/Modal';
import BlockSuccessMessage from '../../../../UI/errorMessages/BlockSuccessMessage';
import { formInitialState, formReducer } from '../../../../../reducers/formReducer';
import FormErrorMessage from '../../../../UI/errorMessages/FormErrorMessage';
import CMSFormTextArea from '../../../../UI/forms/CMSFormTextArea';
import { Button } from 'react-bootstrap';
import { ContainerLoading } from '../../../../UI/loading/ContainerLoading';
import axios from "../../../../../config/axios";
import { Store as toast } from 'react-notifications-component';
import { toastNotification } from '../../../../../config/toastNotification'


const SignupRequestRejectModal = ({ memberId, show, setShow, loadItem }) => {
    const [formState, formDispatch] = useReducer(formReducer, formInitialState)


    //  console.log("CreateConflict ==============> ", id, memberId);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const onSubmit = async (data, resetForm) => {

        formDispatch({
            type: "FORM_SUBMISSION_REQUEST"
        });

        try {
            const res = await axios.post(`members/respond-to-signup-request`, {
                id: memberId,
                accept: false,
                comments: data.comments
            });
            formDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: res.data
            });
            handleClose()
            toast.addNotification({
                ...toastNotification,
                title: "Success!",
                message: `Signup request rejected.`,
                type: "success",
            });
            resetForm();
            formDispatch({
                type: "FORM_INITIAL_STAGE"
            });
            loadItem()
        } catch (error) {
            formDispatch({
                type: "FORM_SUBMISSION_FAILED",
                payload: error
            });
            setTimeout(() => {
                formDispatch({
                    type: "FORM_INITIAL_STAGE"
                });
            }, 5000)
        }
    }

    return (
        <Formik
            initialValues={{
                comments: '',
            }}
            validationSchema={yup.object({
                comments: yup.string().required("Required").max(200),
            })}
            enableReinitialize={true}
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={async (values, { resetForm }) => {
                console.log(values);
                onSubmit(values, resetForm)
            }}
        >
            {(form) => (

                <Modal show={show} onHide={handleClose}>
                          {(formState.isFormSubmitting) && <div class="overlay">
            <i class="fas fa-2x fa-sync-alt text-white fa-spin"></i>
          </div>}
                    <Form onSubmit={form.handleSubmit}>
                        <Modal.Header closeButton>
                            <Modal.Title>Reject Signup Request</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>

                            {(formState.hasSubmissionError) &&
                                <FormErrorMessage error={formState.formSubmissionError} />
                            }

                            <CMSFormTextArea
                                name="comments"
                                label="Comments"
                                form={form}
                                required={true}
                            />





                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>


                        </Modal.Footer>


                    </Form>


                </Modal>

            )}
        </Formik>
    )
}

export default SignupRequestRejectModal