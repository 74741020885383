import React from 'react'
import { Row, Col } from 'react-bootstrap'
import ModuleBlockStyleListItem from './ModuleBlockStyleListItem'



const ModuleBlockStyleListing = ({ items, moduleConfig, deleteItem, customButtonsForItems, isCheck, setIsCheck, isCheckAll, setIsCheckAll, handleSelectAll }) => {
    return (
        <>
            {(items && items.length) && (
                <>
                    <Row className="m-0 p-0 mt-2">
                        <Col className="m-0 mb-1">
                            <input
                                className="selectAll"
                                type="checkbox"
                                name="selectAll"
                                id="selectAll"
                                onChange={handleSelectAll}
                                checked={isCheckAll}

                            />
                            <label for="selectAll" className="m-0 p-0 ml-2">Select All</label>
                        </Col>
                    </Row>
                    
                    {
                        items.map((item, itemIndex) => {
                            return <ModuleBlockStyleListItem
                                key={itemIndex}
                                item={item}
                                moduleConfig={moduleConfig}
                                deleteItem={deleteItem}
                                customButtonsForItems={customButtonsForItems}
                                isCheck={isCheck}
                                setIsCheck={setIsCheck}
                                setIsCheckAll={setIsCheckAll}
                            />
                        })
                    }

                </>
            )}
        </>
    )
}

export default ModuleBlockStyleListing