import React from "react";
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import ModuleDisplayEmail from "../../../../UI/modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayFieldsController from "../../../../UI/modules/fieldsDisplay/ModuleDisplayFieldsController";
import ModuleDisplayPhone from "../../../../UI/modules/fieldsDisplay/ModuleDisplayPhone";
import memberDummyImage from "../../../../../images/memberDummyImage.jpg";
import memberStatusEmailVerificationRequired from "../../../../../images/memberStatusEmailVerificationRequired.png";
import memberStatusSignupRequest from "../../../../../images/memberStatusSignupRequest.png";
import memberStatusRejected from "../../../../../images/memberStatusRejected.png";
import memberStatusRegistrationPaymentPending from "../../../../../images/memberStatusRegistrationPaymentPending.png";
import memberStatusMember from "../../../../../images/memberStatusMember.jpg";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";

const Item = ({
  item,
  handleCheckBoxClick,
  isCheck,
  moduleConfig,
  customButtonsForItems,
}) => {
  const getName = () => {
    let name = "";
    if (item.communicationType === "Member-Admin") {
      name =
        item.member && item.member[0] && item.member[0].fullName
          ? item.member[0].fullName
          : "Member";
    } else if (item.communicationType === "Circle") {
      name =
        item.circle && item.circle[0] && item.circle[0].fullName
          ? item.circle[0].fullName
          : "Circle";
    }
    return name;
  };

  const getId = () => {
    let id = "";
    if (item.communicationType === "Member-Admin") {
      id =
        item.member && item.member[0] && item.member[0]._id
          ? item.member[0]._id
          : "null";
    } else if (item.communicationType === "Circle") {
      id =
        item.circle && item.circle[0] && item.circle[0]._id
          ? item.circle[0]._id
          : "null";
    }
    return id;
  };

  return (
    <Card
      className={`item mb-3 chatCard ${
        item.unreadMessagesCount > 0 ? "redBorder" : ""
      } `}
    >
      <Card.Header className="ps-1 pe-2 py-0">
        <Card.Title className="m-0 p-0 title100">
          <div className="d-flex align-items-flex-start ImageSectionSpacing w-100">
            {/* <div className="checkboxCol me-2 ms-2 mt-2">
                            <input
                                type="checkbox"
                                id={item._id}
                                name={item._id}
                                onChange={handleCheckBoxClick}
                                checked={isCheck.includes(item._id)}
                            />
                        </div> */}
          
            <div className="position-relative me-2 background">
              {item.communicationType !== "Circle" && (
                <>
                  {item.member.length > 0 &&
                  (item.member[0].isLoggedIn === "1" ||
                    item.member[0].isLoggedIn === 1 ||
                    item.member[0].isLoggedIn === true) ? (
                    <div className="activeStatus"></div>
                  ) : (
                    <div className="notActiveStatus"></div>
                  )}
                </>
              )}
              {console.log(item)}

              {item.member.length > 0 && item.member[0].image ? (
                <Image
                  src={
                    process.env.REACT_APP_BACKEND_URL +
                    "public/" +
                    item.member[0].image
                  }
                  width="52px"
                  height="52px"
                  className="border rounded-circle"
                />
              ) : (
                <i
                  className={`fas fa-${
                    item.communicationType === "Circle" ? "users" : "user"
                  }  position-relative usericon`}
                ></i>
              )}
            </div>

            <div className="d-flex flex-column w-100 ms-1">
              <span className="m-0 text-bold memberName mb-1">
                {getName()}{" "}
                <span className="font-italic font-small font-weight-normal text-themePrimary fs-8  mb-1">
                  <span className="membershipId text-bold ">
                    (
                    {item.communicationType === "Member-Admin"
                      ? "Member"
                      : "Circle"}
                    )
                  </span>
                </span>
                {item.unreadMessagesCount > 0 && (
                  <Link
                    to={`/${moduleConfig.url}/${
                      item.communicationType === "Circle" ? "circle" : "member"
                    }/${getId()}`}
                    title="View Chat"
                    className="text-secondary"
                  >
                    {" "}
                    (Unread Messages
                    <Badge bg="success" className="ms-2" pill>
                      {item.unreadMessagesCount}
                    </Badge>
                    )
                  </Link>
                )}
              </span>

              {item.lastMessage && item.lastMessage.message && (
                <p className="m-0 p-0 fs-9 text-secondary font-italic lastMessage w-xl-100 w-75">
                  {item.lastMessage.message}
                </p>
              )}
            </div>
          </div>
        </Card.Title>

        <div class="card-tools mb-xl-0 mb-lg-0 mb-md-0 mb-sm-1  mb-1">
          <div className="mt-1">
            {/* {item.lastMessage && item.lastMessage.createdAt && (
              <span class="badge badge-light me-1  text-dark">
                Last Message Date/Time :{" "}
                <span class="text-secondary">
                  <ModuleDisplayDateTime value={item.lastMessage.createdAt} />{" "}
                </span>
              </span>
            )} */}
            {item.lastMessageTime && (
              <span class="badge badge-light me-1  text-dark">
                <span class="text-secondary">{item.lastMessageTime}</span>
              </span>
            )}
          </div>

          <div className=" text-right me-xl-2  mt-1">
            <Link
              to={`/${moduleConfig.url}/${
                item.communicationType === "Circle" ? "circle" : "member"
              }/${getId()}`}
              className="btn btn-outline-secondary  listingActionIcon"
              title="View Chat"
            >
              <i className="fas fa-comments"></i>
            </Link>
          </div>
        </div>
      </Card.Header>
    </Card>
  );
};

export default Item;
