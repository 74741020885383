import React, { useState } from "react";
import { Field, ErrorMessage } from "formik";
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import Switch from "react-switch";
function CMSFormSwitchWithSubmission(props) {
  const { label, name, initialValue, ...rest } = props;
  const [checked, setChecked] = useState(initialValue);
  const handleChange = async nextChecked => {
    setChecked(nextChecked);
    const res = await rest.handlePermissionChange(name, nextChecked)
    if (!res) {
      setChecked(!nextChecked);
    }
  };

  return (
    <div>
      {/* {label !== "" && (
        <>

          <label
            htmlFor={name}
            className=""
          >

          </label>
        </>
      )} */}
      <div className="example">

        <label class="switchLabel"
          htmlFor={name}
        >
          {" "}
          {label}
          <Switch
            onChange={handleChange}
            checked={checked}
            uncheckedIcon={false}
            checkedIcon={false}
            height={22}
            width={40}
          
            className="react-switch"
          />
        </label>
      </div>
    </div>
  );
}

export default CMSFormSwitchWithSubmission;
