import axios from "../config/axios";
import { isValidEmail } from "../helpers/CommonHelper"


export const loginAPI = async (data) => {
    try {
        const res = await axios.post(
            'signin',
            data,
        )
        return { "status": true, "data": res.data };
    } catch (error) {
        //console.log(error.response);
        if (error && error.response && error.response.status === 401) {
            return { "status": false, "error": ((error.response && error.response.data && error.response.data.message) ? error.response.data.message : 'Authentication failed.') };
        }
        return { "status": false, "error": "Something went wrong, try again later." };
    }
}

export const logoutAPI = async () => {
    const res = await axios.post('signout')
    if (res.status === 200) {
        return { "status": true };
    } else {
        return { "status": false };
    }
}

export const isloggedInAPI = async (headers) => {
    if (!headers)
        return { "status": false };

    try {
        const res = await axios.get(
            `${process.env.REACT_APP_BASE_URL}api/buyers/is-logged-in`,
            headers
        )
        //console.log(res);
        return { "status": true, "data": res.data };
    } catch (error) {
        return { "status": false };
    }
}