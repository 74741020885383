export const getCurrencyCode = (member) => {
    return "AED"
}

export const getFullName = (member) => {
    let fullName = '';

    if (member && member.firstName) {
        fullName =  ''
        fullName += ((fullName) ? " " + member.firstName : member.firstName)
        fullName += ((fullName) ? " " + member.lastName : member.lastName)
    }

    return fullName
}


export const isTrue = (value) => {
    if (value === 1 || value === '1' || value === true || value === "true") return true;
    return false;
}

export const isEmpty = (data) => {
    if (data === undefined || data === 'undefined' || data === null || data === 'null' || data === '') return true;
    return false;
}


export const isValidEmail = (email) => {
    if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
        return true;
    }
    return false;
}

export function upperCaseFirst(str) {
    if (!str)
        return '';

    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

    }
    return arr.join(" ");
}



