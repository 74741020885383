import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { BreadcrumbContext } from '../../../../contexts/BreadcrumbContext';


const Breadcrumb = () => {
    const { breadcrumb } = useContext(BreadcrumbContext);
    return (
        <>
            {(breadcrumb && breadcrumb.length > 0) ?
                <ol className="breadcrumb" style={{ margin: "7px 0 0 15px", padding: "0" }}>
                    {breadcrumb.map((item, index) => (

                        (item)
                            ?
                            <li key={index} className={`breadcrumb-item ${(index ===
                                breadcrumb.length - 1) ? 'active' : ''}`}>
                                {
                                    ((item.url === undefined) || (index === (breadcrumb.length - 1)))
                                        ?
                                        (item.icon !== undefined)
                                            ? <i className={`${item.icon}`} title={item.label} />
                                            : <> {item.label}</>
                                        :
                                        <Link key={index} to={`${item.url}`}>
                                            {
                                                (item.icon !== undefined) ?
                                                    <i className={`${item.icon}`} title={item.label} />
                                                    : <> {item.label}</>
                                            }
                                        </Link>
                                }
                            </li>
                            : null



                    ))}
                </ol>
                : null
            }
        </>
    );


};

export default Breadcrumb;
