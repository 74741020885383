import React, { useEffect, useReducer } from "react";
import { Col, Row, Alert, Badge, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import StatsListing from "../shared/infoListing/StatsListing";

import {
  listingInitialState,
  listingReducer,
} from "../../../reducers/module/listingReducer";
import axios from "../../../config/axios";
import { FullPageOverlay } from "../loading/FullPageOverlay";
import SameMemberCarsConflict from "./partials/SameMemberCarsConflict";
import OtherMemberCarsConflict from "./partials/OtherMemberCarsConflict";
const ImportantNotifications = () => {
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const loadItems = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });
    try {
      const res = await axios.get(`member-cars/car-conflicts-for-dashboard`);
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    loadItems();
  }, []);

  return (
    <>
      {listingState.isFetching == true && <FullPageOverlay />}
      <Card className="mt-xl-0 mt-lg-0 mt-md-0 mt-2 mb-xl-0 mb-lg-0 mb-md-0 border-themePrimary">
        <Card.Body className="p-xl-2 p-lg-2 p-md-2 p-0">
          <Row className="showOnMobile bg-themePrimary text-center w-100 mx-auto">
            <Col>
              <h5 className="my-3 px-1 text-bold text-themePrimary">
                Car Conflicts
              </h5>
            </Col>
          </Row>

          {listingState.data.items && listingState.data.items.length <= 0 && (
            <>
              <Alert variant="warning mb-0 mt-1">No records found.</Alert>
            </>
          )}
          {listingState.data.items && listingState.data.items.length > 0 && (
            <>
              <Row className="hideOnMobile ">
                <Col className="d-flex align-items-center justify-content-between">
                  <h5 className="my-1  px-1 text-bold">Car Conflicts</h5>
                </Col>
              </Row>
             
            </>
          )}

          {listingState.data.items && listingState.data.items.length > 0 && (
            <>
              {listingState.data.items.map((item) => {
                if (item.type === "sameMemberCarsConflict")
                  return <SameMemberCarsConflict item={item} />;

                if (item.type === "otherMemberCarsConflict")
                  return <OtherMemberCarsConflict item={item} />;
              })}
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default ImportantNotifications;
