import React from "react";

import ModuleDisplayTimeAgo from "../../../../UI/modules/fieldsDisplay/ModuleDisplayTimeAgo";
import { BsCheckAll } from "react-icons/bs";
const AdminMessageItem = ({ message }) => {
  return (
    <div className="direct-chat-msg right">
      {/* <img className="direct-chat-img" src="/docs/3.1//assets/img/user3-128x128.jpg" alt="message user image"> */}

      <div className="direct-chat-text float-right text-right">
        <div className="direct-chat-infos clearfix">
          <span className="direct-chat-name text-purple float-right">You</span>
          {/* <span className="direct-chat-timestamp float-left"><ModuleDisplayTimeAgo value={message.createdAt} /></span> */}
        </div>
        {message.message}
        <p className="direct-chat-timestamp chatTime fs-10 text-right mb-0">
          {message.createdAt}
          {""}
          {message.isRead === 1 || message.isRead === "1" ||message.isRead === true ? (
            <BsCheckAll className="readMessage ms-1" />
          ) : (
            <BsCheckAll className="unreadMessage ms-1" />
          )}
        </p>
      </div>
    </div>
  );
};

export default AdminMessageItem;
