import React, { useState, useContext, useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as yup from "yup";
import moduleConfig from "./moduleConfig";
import { Store as toast } from "react-notifications-component";
import { updateInitialState, updateReducer } from "./reducers/updateReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import { toastNotification } from "../../../config/toastNotification";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import { getFullName, isEmpty } from "../../../helpers/CommonHelper";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import CMSFormTextArea from "../../UI/forms/CMSFormTextArea";
import CMSFormCheckBoxes from "../../UI/forms/CMSFormCheckBoxes";


const PaymentUpdate = () => {
    const [updateState, updateDispatch] = useReducer(
        updateReducer,
        updateInitialState
    );
    const [formInitialValues, setFormInitialValues] = useState({});

    const { setBreadcrumb } = useContext(BreadcrumbContext);
    let { id } = useParams();
    let navigate = useNavigate();

    let breadcrumbs = {
        listing: {
            label: moduleConfig.title,
            url: `/${moduleConfig.url}`,
        },
        details: {
            label: "Details",
            url: `/${moduleConfig.url}/${id}`,
        },
        update: {
            label: "Update",
        },
    };

    const onSubmit = async (data) => {
        //console.log(data);

        updateDispatch({
            type: "FORM_SUBMISSION_REQUEST",
        });

        try {
            const res = await axios.post(`payments/update-payment`, data); //
            updateDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: res.data,
            });
            navigate(`/${moduleConfig.url}`);
            toast.addNotification({
                ...toastNotification,
                title: "Success!",
                message: `${moduleConfig.singleItemTitle} updated successfully.`,
                type: "success",
            });
        } catch (error) {
            //console.log(error.message);
            const status = error.response ? error.response.status : 500;
            const errorMessage =
                status === 400 ? error.response.data.errorMessage : error.message;
            console.log(errorMessage);

            updateDispatch({
                type: "FORM_SUBMISSION_FAILURE",
                payload: errorMessage,
            });
            setTimeout(() => {
                updateDispatch({
                    type: "FORM_INITIAL_STATE",
                });
            }, 5000);
        }
    };

    const loadItem = async () => {
        if (id) {
            updateDispatch({
                type: "FETCH_REQUEST",
            });

            try {
                const res = await axios.get(`${moduleConfig.url}/details/${id}`); //
                updateDispatch({
                    type: "FETCH_SUCCESS",
                    payload: res.data,
                });
            } catch (error) {
                updateDispatch({
                    type: "FETCH_FAILURE",
                    payload: error,
                });
            }
        }
    };

    const getTenureInitialValueForForm = () => {
        if (updateState.data.item && updateState.data.item.purpose === 'Signup') {

            let tenureArray = updateState.data.item.tenure.split("-")
            let array = []
            if (tenureArray[0] === tenureArray[1]) {
                //single year

                array.push(tenureArray[0])
                return array
            } else {
                array.push(tenureArray[0])
                array.push(tenureArray[1])
                return array
            }
        } else {
            return []
        }
    }

    const getTenureFieldOptionsForForm = () => {
        if (updateState.data.item && updateState.data.item.purpose === 'Signup') {


            let tenureArray = updateState.data.item.tenure.split("-")
            //console.log(tenureArray)

            if (tenureArray[0] === tenureArray[1]) {
                //single year
                return [
                    { label: tenureArray[0], value: tenureArray[0] },
                ]
            } else {
                return [
                    { label: tenureArray[0], value: tenureArray[0] },
                    { label: tenureArray[1], value: tenureArray[1] },
                ]
            }
        } else {
            return []
        }
    }

    const getValidationSchema = () => {
        if (updateState.data.item && updateState.data.item._id) {
            if (updateState.data.item.purpose === 'Signup') {
                return yup.object({
                    comments: yup.string().max(200),
                    paidAmount: yup
                        .number()
                        .transform((cv) => (isNaN(cv) ? undefined : cv))

                        .integer()
                        .required("Actual paid amount is required."),
                    tenure: yup.array().min(1, "At-least one year should be selected.").of(yup.string().required()).required(),

                })
            } else {
                return yup.object({
                    comments: yup.string().max(200),
                    notes: yup.string().max(200),
                    paidAmount: yup
                        .number()
                        .transform((cv) => (isNaN(cv) ? undefined : cv))

                        .integer()
                        .required("Actual paid amount is required."),

                })
            }
        } else {
            return {}
        }

    }

    // const getInitialValues = () => {

    //     let returnObj = {
    //         id: paymentId,
    //         memberId,
    //         member: getFullName(item.member),
    //         purpose: item.purpose,
    //         totalAmount: item.totalAmount,
    //         comments: item.adminComments,
    //         paidAmount: item.paidAmount ? item.paidAmount : item.totalAmount,
    //     }

    //     if (item.purpose === 'Signup') {
    //         returnObj = {
    //             ...returnObj,
    //             tenure: getTenureInitialValueForForm()
    //         }
    //     }

    //     return returnObj;
    // }

    useEffect(() => {
        loadItem();
        setBreadcrumb([
            breadcrumbs.listing,
            breadcrumbs.details,
            breadcrumbs.update,
        ]);
    }, [id]);
    useEffect(() => {
        if (updateState.data.item && updateState.data.item._id) {
            let obj = {
                id: updateState.data.item._id,
                memberId: updateState.data.item.memberId,
                member: getFullName(updateState.data.item.member),
                purpose: updateState.data.item.purpose,
                totalAmount: updateState.data.item.totalAmount,
                comments: updateState.data.item.adminComments ? updateState.data.item.adminComments : '',
                notes: updateState.data.item.adminNotes ? updateState.data.item.adminNotes : '',
                paidAmount: updateState.data.item.paidAmount ? updateState.data.item.paidAmount : updateState.data.item.totalAmount,
            }

            if (updateState.data.item.purpose === 'Signup') {
                obj = {
                    ...obj,
                    tenure: getTenureInitialValueForForm()
                }
            }
            setFormInitialValues(obj);
        }
    }, [updateState.data]);

    return (
        <DashboardLayout>
            {Object.keys(formInitialValues).length <= 0 && updateState.isLoading && (
                <ContainerLoading />
            )}

            {updateState.error && updateState.status === "fetchFailed" ? (
                <BlockErrorMessage error={updateState.error} />
            ) : (
                <>
                    <ModuleHeader
                        moduleTitle={`Update ${moduleConfig.singleItemTitle}`}
                        moduleUrl={moduleConfig.url}
                    ></ModuleHeader>

                    {Object.keys(formInitialValues).length > 0 &&
                        updateState.data &&
                        updateState.data.item &&
                        !isEmpty(updateState.data.item._id) && (
                            <section className="content">
                                <Card>
                                    <Card.Body>
                                        {updateState.isLoading && (
                                            <div class="overlay position-absolute containerLoading">
                                                <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                                            </div>
                                        )}
                                        <Formik
                                            initialValues={formInitialValues}
                                            validationSchema={getValidationSchema()}
                                            enableReinitialize={true}
                                            validateOnChange={true}
                                            validateOnBlur={true}
                                            onSubmit={async (values) => {
                                                console.log(values)
                                                onSubmit(values);
                                            }}
                                        >
                                            {(form) => (
                                                <Form onSubmit={form.handleSubmit}>
                                                    {updateState.error && (
                                                        <FormErrorMessage error={updateState.error} />
                                                    )}
                                                    {form.errors && console.log(form.errors)}

                                                    <CMSFormInput
                                                        name="member"
                                                        label="Member"
                                                        required={false}
                                                        form={form}
                                                        disabled={true}
                                                    />

                                                    <CMSFormInput
                                                        name="purpose"
                                                        label="Purpose"
                                                        required={false}
                                                        form={form}
                                                        disabled={true}
                                                    />

                                                    <CMSFormInput
                                                        name="totalAmount"
                                                        label="Total Amount"
                                                        required={false}
                                                        form={form}
                                                        disabled={true}
                                                    />




                                                    <CMSFormInput
                                                        name="paidAmount"
                                                        label={(form.values.status === "1") ? "Paid Amount" : "Payable Amount"}
                                                        required={true}
                                                        form={form}
                                                        type="number"
                                                    />

                                                    <CMSFormTextArea
                                                        name="comments"
                                                        label="Comments For Member"
                                                        form={form}
                                                        required={false}
                                                    />

                                                    <CMSFormTextArea
                                                        name="notes"
                                                        label="Notes For Admin Records"
                                                        form={form}
                                                        required={false}
                                                    />


                                                    {(updateState.data.item.purpose === 'Signup') &&
                                                        <CMSFormCheckBoxes
                                                            form={form}
                                                            label="Membership Tenure"
                                                            name="tenure"
                                                            options={getTenureFieldOptionsForForm()}
                                                            type="array"
                                                            required={true}
                                                        />
                                                    }



                                                    <button className="btn btn-primary" type="submit">
                                                        Submit
                                                    </button>
                                                </Form>
                                            )}
                                        </Formik>
                                    </Card.Body>
                                </Card>
                            </section>
                        )}
                </>
            )}
        </DashboardLayout>
    );
};
export default PaymentUpdate;
