import React from 'react'
import { Alert, Card, Col, Row, Table, Badge, Image } from "react-bootstrap";
import ModuleRecordsPerPageDropdown from '../../../../UI/modules/views/partials/common/ModuleRecordsPerPageDropdown';
import ModuleSearchBox from '../../../../UI/modules/views/partials/common/ModuleSearchBox';
import ModuleStatusDropdown from '../../../../UI/modules/views/partials/common/ModuleStatusDropdown';
import ModuleFilterDropdown from './ModuleFilterDropdown';

const SearchArea = ({ moduleConfig, setFilter, appliedFilters, resetFilters }) => {
    //console.log(appliedFilters);
    return (
        <Card className="listingPageHeader p-0 m-0 p-1 mt-2 mb-1">
            <Card.Body className=" p-0 m-0">
                {
                    (
                        (appliedFilters.limit)
                        || (appliedFilters.q)
                        || (appliedFilters.status)
                        || (appliedFilters.purpose)
                        || (appliedFilters.type)
                    ) &&
                    <div div className="resetFilters text-right" onClick={() => resetFilters()}>
                        <span>
                            <i className="fas fa-times" /> Reset Filters
                        </span>
                    </div>
                }


                <Row className="mb-1">


                    <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1">
                        <ModuleFilterDropdown
                            name="purpose"
                            setFilter={setFilter}
                            appliedFilters={appliedFilters}
                            options={[
                                {
                                    label: "Select Purpose...",
                                    value: "all"
                                },
                                {
                                    label: "Signup",
                                    value: "Signup"
                                },
                                {
                                    label: "Renewal",
                                    value: "Renewal"
                                },
                                {
                                    label: "Activity Registration",
                                    value: "Activity-Registration"
                                }
                            ]}
                        />
                    </Col>


                    <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1">
                        <ModuleFilterDropdown
                            name="type"
                            setFilter={setFilter}
                            appliedFilters={appliedFilters}
                            options={[
                                {
                                    label: "Select Type...",
                                    value: "all"
                                },
                                {
                                    label: "Offline",
                                    value: "Offline"
                                },
                                {
                                    label: "Online",
                                    value: "Online"
                                }
                            ]}
                        />
                    </Col>

              
                    {
                        (moduleConfig.hasPagination === true && moduleConfig.perPageItemsOptions && moduleConfig.perPageItemsOptions.length > 0) &&
                        <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 pt-1">
                            <ModuleRecordsPerPageDropdown
                                selectedPerPageItems={moduleConfig.perPageItems}
                                perPageItemsOptions={moduleConfig.perPageItemsOptions}
                                setFilter={setFilter}

                            />
                        </Col>
                    }
                    {
                        (moduleConfig.hasStatusSearch === true) &&
                        <Col className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 mt-1">
                            <ModuleStatusDropdown
                                setFilter={setFilter}
                                appliedFilters={appliedFilters}
                                statusOptions={[
                                    {
                                        label: "Select Payment Status...",
                                        value: "all"
                                    },
                                    {
                                        label: "Pending",
                                        value: "0"
                                    },
                                    {
                                        label: "Paid",
                                        value: "1"
                                    }
                                ]}
                            />
                        </Col>
                    }
                    {
                        (moduleConfig.hasSearch === true) &&

                        <Col className="col-xl-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 col-12 pt-xl-0 pt-lg-0 pt-md-0 mt-1">
                            <ModuleSearchBox
                                setFilter={setFilter}
                                appliedFilters={appliedFilters}
                            />
                        </Col>

                    }
                </Row>



            </Card.Body>
        </Card>
    )
}

export default SearchArea