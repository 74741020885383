import React, { useReducer, useEffect, useContext } from "react";
import moduleConfig from "./moduleConfig";
import { Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import axios from "../../../config/axios";
import { createReducer, createInitialState } from "./reducers/createReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import ModuleCreateView from "../../UI/modules/views/ModuleCreateView";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSFormDate from "../../UI/forms/CMSFormDate";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import CMSRichTextEditor from "../../UI/forms/CMSRichTextEditor";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";
import CMSFormRichText from "../../UI/forms/CMSFormRichText";
import CMSFormMultiSelect from "../../UI/forms/CMSFormMultiSelect";


let breadcrumbs = {
  listing: {
    label: moduleConfig.title,
    url: `/${moduleConfig.url}`,
  },
  create: {
    label: "Create",
  },
};

const ActivityCreate = () => {
  const [createState, createDispatch] = useReducer(
    createReducer,
    createInitialState
  );

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let navigate = useNavigate();
  const onSubmit = async (data) => {
    createDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });


    try {
      const res = await axios.post(`${moduleConfig.url}`, data); //
      console.log(res);
      createDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });

      navigate(`/${moduleConfig.url}`);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} created successfully.`,
        type: "success",
      });
    } catch (error) {
      console.log(error.response);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.message : error.message;

      createDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        createDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  // const loadData = async () => {
  //     try {
  //         const res = await axios.get(`members/create-data`)//
  //         createDispatch({
  //             type: "FETCH_SUCCESS",
  //             payload: res.data
  //         });
  //     } catch (error) {
  //         createDispatch({
  //             type: "FETCH_FAILURE",
  //             payload: "Date loading failed."
  //         });
  //     }
  // }

  // const loadData = async () => {
  //   createDispatch({
  //     type: "FETCH_REQUEST",
  //   });

  //   try {
  //     const res = await axios.get(`${moduleConfig.url}/get-circles-members`); //
  //     createDispatch({
  //       type: "FETCH_SUCCESS",
  //       payload: res.data,
  //     });
  //   } catch (error) {
  //     createDispatch({
  //       type: "FETCH_FAILURE",
  //       payload: error,
  //     });
  //   }
  // };
  useEffect(() => {
    // loadData();
    setBreadcrumb([breadcrumbs.listing, breadcrumbs.create]);
    createDispatch({
      type: "FETCH_SUCCESS",
      payload: [],
    });
  }, []);
  return (
    <DashboardLayout>
      {createState.status === "fetchFailed" ? (
        <BlockErrorMessage error={createState.error} />
      ) : (
        <>
          {createState.status !== "fetching" && (
            <>
              <ModuleHeader
                moduleTitle={`Create ${moduleConfig.singleItemTitle}`}
                moduleUrl={moduleConfig.url}
              ></ModuleHeader>

              <section className="content">
                <Card>
                  <Card.Body>
                    {createState.isLoading && (
                      <div class="overlay position-absolute containerLoading">
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                      </div>
                    )}
                    <Formik
                      initialValues={{
                        title: "",
                        date: new Date(),
                        location: "",
                        time: "",
                        paidActivity: "0",
                        participationFee: null,
                        shortDescription: "",
                        target: "All-Members",
                        // targetedMembers: ["123"],
                        // targetedCircles: ["123"],
                        status: "0",
                        description: "",
                      }}
                      validationSchema={yup.object({
                        title: yup.string().required("Required").max(100),
                        date: yup.string().required("Required"),
                        location: yup.string().max(100),
                        time: yup.string().max(50),
                        paidActivity: yup.string().required("Required"),
                        participationFee: yup
                          .number()
                          .transform((cv) => (isNaN(cv) ? undefined : cv))
                          .positive()
                          .integer()
                          .nullable()
                          .when("paidActivity", {
                            is: "1",
                            then: yup
                              .number()
                              .transform((cv) => (isNaN(cv) ? undefined : cv))
                              .positive()
                              .integer()
                              .required(),
                          }),
                        shortDescription: yup
                          .string()
                          .max(200),
                        target: yup.string().required("Required"),
                        // targetedMembers: yup.mixed()
                        //   .when('target', {
                        //     is: 'Selected-Members',
                        //     then: yup.array().of(yup.string()).min(1, "Select Members"),
                        //   }),
                        // targetedCircles: yup.mixed()
                        //   .when('target', {
                        //     is: 'Selected-Circles',
                        //     then: yup.array().of(yup.string()).min(1, "Select Circles"),
                        //   }),
                        status: yup.string().required("Required"),
                      })}
                      enableReinitialize={true}
                      validateOnChange={true}
                      validateOnBlur={true}
                      onSubmit={async (values) => {
                        //console.log(values)
                        onSubmit(values);
                      }}
                    >
                      {(form) => (
                        <Form onSubmit={form.handleSubmit}>
                          {createState.error && (
                            <FormErrorMessage error={createState.error} />
                          )}

                          <CMSFormInput
                            name="title"
                            label="Title"
                            form={form}
                            required={true}
                          />

                          <CMSFormDate
                            name="date"
                            label="Date"
                            required={true}
                            form={form}
                            value={new Date(form.values["date"])}
                            minDate={new Date()}
                            maxDate={new Date("2040-12-28")}
                            disabled={false}
                          />
                          <CMSFormInput
                            name="location"
                            label="Location"
                            required={false}
                            form={form}
                          />

                          <CMSFormInput name="time" label="Time" form={form} />

                          <CMSFormSelect
                            name="paidActivity"
                            label="Paid Activity"
                            required={true}
                            form={form}
                            options={[
                              { value: "1", label: "Yes" },
                              { value: "0", label: "No" },
                            ]}
                          />

                          {(form.values["paidActivity"] === 1 ||
                            form.values["paidActivity"] === "1") && (
                              <CMSFormInput
                                name="participationFee"
                                label="Participation Fee (AED)"
                                type="number"
                                form={form}
                              />
                            )}

                          <CMSFormInput
                            name="shortDescription"
                            label="Short Description"
                            form={form}
                            required={false}
                          />

                          <CMSFormSelect
                            name="target"
                            label="Target"
                            required={true}
                            form={form}
                            options={[
                              { value: "All-Members", label: "All-Members" },
                              {
                                value: "Selected-Members",
                                label: "Selected-Members",
                              },
                              {
                                value: "Selected-Circles",
                                label: "Selected-Circles",
                              },
                            ]}
                          />

                          {/* {(form.values["target"] === "Selected-Members") && (
                            <CMSFormMultiSelect
                              name="targetedMembers"
                              label="Select Members"
                              required={true}
                              form={form}
                              options={createState.data.members}
                              displayValue="name"
                            />
                          )} */}

                          {/* {(form.values["target"] === "Selected-Circles") && (
                            <CMSFormMultiSelect
                              name="targetedCircles"
                              label="Select Circles"
                              required={true}
                              form={form}
                              displayValue="cat"
                              options={[
                                {
                                  cat: 'Group 1',
                                  _id: '1'
                                },
                                {
                                  cat: 'Group 2',
                                  _id: '2'
                                },
                                {
                                  cat: 'Group 3',
                                  _id: '3'
                                },
                                {
                                  cat: 'Group 4',
                                  _id: '4'
                                },

                              ]}
                              selectedValues={[
                                {
                                  cat: 'Group 1',
                                  _id: '1'
                                },
                                {
                                  cat: 'Group 2',
                                  _id: '2'
                                }
                              ]}
                            />
                          )} */}


                          <CMSFormRichText
                            name="description"
                            label="Description"
                            form={form}
                          />

                          <CMSFormSelect
                            name="status"
                            label="Status"
                            required={true}
                            form={form}
                            options={[
                              { value: "1", label: "Enabled" },
                              { value: "0", label: "Disabled" },
                            ]}
                          />
                          {console.log(form.errors)}
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </section>
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default ActivityCreate;
