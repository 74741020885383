import React from 'react';
import { Modal, Button } from 'react-bootstrap';

import useConfirm from '../../../HOC/custom/useConfirm';

const ConfirmDialog = () => {
    const { onConfirm, onCancel, confirmState } = useConfirm();
    return (
        <Modal
            show={confirmState.show}
            onHide={onCancel}
            backdrop="static"
            keyboard={false}

        >
            <Modal.Header closeButton>
                <Modal.Title><i className="fas fa-exclamation-triangle text-warning" /> Confirm Action!</Modal.Title>
            </Modal.Header>
            <Modal.Body>{confirmState?.text && confirmState.text}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={onConfirm}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default ConfirmDialog;