import React from 'react';
import CMSFormHidden from './CMSFormHidden';
import CMSFormImage from './CMSFormImage';
import CMSFormDate from './CMSFormDate';
import CMSFormInput from './CMSFormInput';
import CMSFormTextArea from './CMSFormTextArea';
import CMSFormRichText from './CMSFormRichText';
import CMSFormSelect from './CMSFormSelect';
import CMSFormRadioButtons from './CMSFormRadioButtons';
import CMSFormCheckBoxes from './CMSFormCheckBoxes';


const CMSFormController = (props) => {
    const { control } = props
    switch (control) {
        case "hidden":
            return <CMSFormHidden {...props} />
        case "input":
            return <CMSFormInput {...props} />
        case "date":
            return <CMSFormDate {...props} />
        case "image":
            return <CMSFormImage {...props} />
        case "textArea":
            return <CMSFormTextArea {...props} />
        case "richText":
            return <CMSFormRichText {...props} />
        case "select":
            return <CMSFormSelect {...props} />
        case "radio":
            return <CMSFormRadioButtons {...props} />
        case "checkbox":
            return <CMSFormCheckBoxes {...props} />

        default:
            return null
    }
};

export default CMSFormController;
