import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const BlockErrorMessage = ({ error }) => {
    //console.log(error);
    const [errorMessage, setErrorMessage] = useState('');
    const [status, setStatus] = useState(500);

    useEffect(() => {

        if (!error) {
            setErrorMessage("Action failed, try again later.")
        } else {

            if (typeof error === 'string' || error instanceof String) {
                setErrorMessage(error)
            } else if (typeof error === 'Object' || error instanceof Error) {


                if (error.response && error.response.data && error.response.data.message) {
                    setErrorMessage(error.response.data.message)
                } else {
                    setStatus(500)
                    setErrorMessage("Action failed.")
                }
            }
        }



    }, [error])

    return (
        <div className="error-page inDashboardErrorPage">
            <h2 className="headline text-danger">{status}</h2>
            <div className="error-content">
                <h3><i className="fas fa-exclamation-triangle text-danger" /> {errorMessage}</h3>
                <p>
                    We will work on fixing that right away.
                    Meanwhile, you may <Link to="/">return to dashboard</Link>.
                </p>
            </div>
        </div>
    );

};

export default BlockErrorMessage;
