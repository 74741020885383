import React from 'react'
import CreateViewHOC from '../../../HOC/module/CreateViewHOC';
import moduleConfig from "./moduleConfig"
let breadcrumbs = {
    listing: {
        label: moduleConfig.title,
        url: `/${moduleConfig.url}`
    },
    create: {
        label: "Create",
    }
};


const UsefulLinkCreate = () => {

    const Component = CreateViewHOC(breadcrumbs);
    return <Component moduleConfig={moduleConfig} />;
}

export default UsefulLinkCreate;
