import React from 'react'

import DashboardLayout from './../components/UI/layouts/DashboardLayout';
import { Alert, Button, Row, Col } from 'react-bootstrap'
import { ContainerLoading } from '../components/UI/loading/ContainerLoading';
import DashboardContent from '../components/UI/dashboardDisplay/DashboardContent';




const Home = () => {


    return (
        <DashboardLayout >
 <DashboardContent />
            {/* <Button>Try Me</Button>
            <Row className='mt-3'>
                <Col>
                    <Alert variant='success'>
                        This is a success alert-light
                    </Alert>
                </Col>
                <Col>
                    <Alert variant='primary'>
                        This is a primary alert—light
                    </Alert>
                </Col>
                <Col>
                    <Alert variant='danger'>
                        This is a danger alert—light
                    </Alert>
                </Col>
                <Col>
                    <Alert variant='warning'>
                        This is a warning alert—light
                    </Alert>
                </Col>

            </Row>

            <Row className='mt-3'>

                <Col>
                    <Alert variant='info'>
                        This is a info  alert—light
                    </Alert>
                </Col>
                <Col>
                    <Alert variant='light'>
                        This is a light alert—light
                    </Alert>
                </Col>
                <Col>
                    <Alert variant='dark'>
                        This is a dark alert—light
                    </Alert>
                </Col>
                <Col>
                    <Alert variant='secondary'>
                        This is a secondary alert—light
                    </Alert>
                </Col>
            </Row>


            <Row className='mt-3'>
                <Col>
                    <Alert variant='success-dark'>
                        This is a success alert-dark
                    </Alert>
                </Col>
                <Col>
                    <Alert variant='primary-dark'>
                        This is a primary alert-dark
                    </Alert>
                </Col>
                <Col>
                    <Alert variant='danger-dark'>
                        This is a danger alert-dark
                    </Alert>
                </Col>
                <Col>
                    <Alert variant='warning-dark'>
                        This is a warning alert-dark
                    </Alert>
                </Col>

            </Row>

            <Row className='mt-3'>

                <Col>
                    <Alert variant='info-dark'>
                        This is a info  alert-dark
                    </Alert>
                </Col>
                <Col>
                    <Alert variant='light-dark'>
                        This is a light alert-dark
                    </Alert>
                </Col>
                <Col>
                    <Alert variant='dark-dark'>
                        This is a dark alert-dark
                    </Alert>
                </Col>
                <Col>
                    <Alert variant='secondary-dark'>
                        This is a secondary alert-dark
                    </Alert>
                </Col>
            </Row> */}



        </DashboardLayout>
    )
}

export default Home
