import React, { useReducer, useEffect } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Button, Modal } from "react-bootstrap";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../../config/toastNotification";
import { ContainerLoading } from "../../../UI/loading/ContainerLoading";
import BlockSuccessMessage from "../../../UI/errorMessages/BlockSuccessMessage";
import FormErrorMessage from "../../../UI/errorMessages/FormErrorMessage";
import CMSFormInput from "../../../UI/forms/CMSFormInput";
import axios from "../../../../config/axios";
import BlockErrorMessage from "../../../UI/errorMessages/BlockErrorMessage";
import CMSFormImageWithCrop from "../../../UI/forms/CMSFormImageWithCrop";
import { galleryInitialState, galleryReducer } from "./galleryReducer";
import CMSFormSelect from "../../../UI/forms/CMSFormSelect";

const AddPicture = ({ moduleUrl, show, setShow, parentId, loadItem }) => {
  const [state, dispatch] = useReducer(galleryReducer, galleryInitialState);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    dispatch({
      type: "FETCH_SUCCESS",
      payload: {},
    });
  }, [show]);

  const onSubmit = async (data, resetForm) => {
    dispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(
        `${moduleUrl}/add-picture/${parentId}`,
        data
      ); //

      resetForm();
      handleClose();
      dispatch({
        type: "FORM_INITIAL_STAGE",
      });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Picture added successfully.`,
        type: "success",
      });
      loadItem();
    } catch (error) {
      dispatch({
        type: "FORM_SUBMISSION_FAILED",
        payload: error,
      });
      setTimeout(() => {
        dispatch({
          type: "FORM_INITIAL_STAGE",
        });
      }, 5000);
    }
  };

  let imageRequirement = {
    dimension: {
      width: 500,
      height: 500,
    },
    allowedExtensions: ["image/png", "image/jpg", "image/jpeg"],
    size: 1024 * 1024 * 2,
  };
  return (
    <Formik
      initialValues={{
        //title: "",
        image: "",
        sortOrder: 999,
        status: "1",
      }}
      validationSchema={yup.object({
        // title: yup.string(),
        sortOrder: yup.number()
          .transform((cv) => (isNaN(cv) ? null : cv))
          .positive()
          .integer()
          .nullable(),
        image: yup
          .mixed()
          .required("Please upload image.")
          .imageTypeCheck(imageRequirement.allowedExtensions)
          .imageSizeCheck(imageRequirement.size)
          .imageDimensionCheck(imageRequirement.dimension),
        status: yup.string().required("Required"),
      })}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={async (values, { resetForm }) => {
        //  console.log(values);
        ///onSubmit(values, resetForm)

        const formData = new FormData();
        for (let name in values) {
          if (Array.isArray(values[name])) {
            formData.append(name, JSON.stringify(values[name]));
          } else {
            formData.append(name, values[name]);
          }
        }

        onSubmit(formData, resetForm);
      }}
    >
      {(form) => (
        <Modal show={show} onHide={handleClose} size="lg">
          {state.isLoading && (
            <div class="overlay">
              <i class="fas fa-2x fa-sync-alt text-white fa-spin"></i>
            </div>
          )}

          {state.error && state.status === "fetchFailed" ? (
            <BlockErrorMessage error={state.error} />
          ) : (
            <>
              <Form onSubmit={form.handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Picture </Modal.Title>
                </Modal.Header>
                {state.status === "submitted" ? (
                  <Modal.Body>
                    <BlockSuccessMessage
                      heading="Done!"
                      message={`Picture added successfully.`}
                    />
                  </Modal.Body>
                ) : (
                  <>
                    <Modal.Body>
                      {state.error && <FormErrorMessage error={state.error} />}

                      <CMSFormImageWithCrop
                        label="Image"
                        name="image"
                        required={true}
                        form={form}
                        aspect={1 / 1}
                        outputSize={imageRequirement.dimension}


                      />
                      <CMSFormInput
                        label="Sort Order"
                        name="sortOrder"
                        type="number"
                        form={form}
                      />
                      <CMSFormSelect
                        name="status"
                        label="Status"
                        form={form}
                        required={true}
                        options={[
                          { label: "Select Status...", value: "" },
                          { label: "Enabled", value: "1" },
                          { label: "Disabled", value: "0" },
                        ]}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button variant="primary" type="submit">
                        Submit
                      </Button>
                    </Modal.Footer>
                  </>
                )}
              </Form>
            </>
          )}
        </Modal>
      )}
    </Formik>
  );
};

export default AddPicture;
