import React from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ModuleDisplayDateTime from '../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime'

const CircleItem = ({ item }) => {
    return (
        <Card className="item  mb-1 mt-1">
            <Card.Header className="ps-1 pe-2 py-0">
                <Card.Title className="m-0 p-0 ">

                    <div className="d-flex ImageSectionSpacing">
                        <div className="ms-1 me-2">
                            <i className="fas fa-users position-relative usericon ">
                                {
                                    (item.status === "0" || item.status === 0) &&
                                    <i className="fas fa-ban position-absolute ms-2 banned"></i>
                                }
                            </i>
                        </div>

                        <div className="d-flex flex-column ms-1">
                            <span className="m-0 text-bold memberName" >
                                <Link to={`/circles/${item._id}`}> {item.name} - {item.color}</Link>
                            </span>
                            <span className="font-italic font-small font-weight-normal text-success fs-8  ">
                                <span className="membershipId">
                                    (Member Since  : <span class="text-secondary"><ModuleDisplayDateTime value={item.createdAt} /> </span>)
                                </span>
                            </span>
                        </div>
                    </div>



                </Card.Title>


            </Card.Header>

        </Card>
    )
}

export default CircleItem