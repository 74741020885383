import React from 'react'
import MailTo from '../mix/MailTo'
//import MailTo from '../partials/mix/MailTo';

const LoginLayout = (props) => {
    return (
        <div className="login-page">
            <div className="login-box">
                <div className="login-logo">
                    <a href="../../index2.html"><b>{process.env.REACT_APP_WEBSITE_NAME} </b>CMS</a>
                </div>
                {/* /.login-logo */}
                <div className="card">
                    <div className="card-body login-card-body">
                        <p className="login-box-msg">Sign in to start your session</p>
                        {props.children}

                        {/* /.social-auth-links */}
                        <p className="mb-1">
                            <MailTo email="support@ahead.pro" subject={`Forgot Admin Panel Password "${process.env.REACT_APP_WEBSITE_NAME}"`} body={`Hello AHEAD, I forgot the admin panel password for the website "${process.env.REACT_APP_WEBSITE_NAME}"`}>
                                forgot password
                            </MailTo>

                        </p>

                    </div>
                    {/* /.login-card-body */}
                </div>
            </div>
            {/* /.login-box */}
        </div>

    )
}

export default LoginLayout
