import { moduleDefaultConfig } from "../../../config/moduleDefaultConfig"
let moduleConfig = {}
moduleConfig = {
    ...moduleDefaultConfig
};

moduleConfig.title = 'Chat';
moduleConfig.singleItemTitle = 'Chat';
moduleConfig.url = 'chat';
moduleConfig.moduleType = {
    listing: 'block',
    view: 'block'
};


moduleConfig.bulkActions = [
    // {
    //     displayName: "Archived Selected",
    //     value: "Archived"
    // },
    // {
    //     displayName: "Restore Selected",
    //     value: "Restore"
    // }
];


export default moduleConfig;