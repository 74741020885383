import { io } from "socket.io-client";

let socket = null
try {

    //https://api.ferrariownersclubuae.com/
    //wss://api.ferrariownersclubuae.com/
    //process.env.REACT_APP_BACKEND_URL
    //https://198.50.125.139:9000/
    //ws://api.ferrariownersclubuae.com:9000/
    socket = io("http://198.50.125.139:9000/"
        , {
            transports: ['websocket'],
            //allowEIO3: true,
            //secure: true,
        }
    );
} catch (error) {
    console.log("Socket try catch: ", error)
}

export default socket;
