import React, { useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../containers/Home";
import Login from "../containers/Login";
import PrivateRoute from "../HOC/PrivateRoute";

import NotFound from "./../containers/NotFound";
import SettingsListing from "../components/modules/settings/SettingsListing";
import LookupListing from "../components/modules/lookups/LookupListing";
import PagesListing from "../components/modules/pages/PagesListing";
import PageContentUpdate from "../components/modules/pages/PageContentUpdate";

import AdminListing from "../components/modules/admins/AdminListing";
import AdminCreate from "../components/modules/admins/AdminCreate";
import AdminView from "../components/modules/admins/AdminView";
import AdminUpdate from "../components/modules/admins/AdminUpdate";
import AdminChangePassword from "../components/modules/admins/AdminChangePassword";

import ContactUsListing from "../components/modules/contactUs/ContactUsListing";
import ContactUsView from "../components/modules/contactUs/ContactUsView";

import UsefulLinkListing from "../components/modules/usefulLinks/UsefulLinkListing";
import UsefulLinkView from "../components/modules/usefulLinks/UsefulLinkView";
import UsefulLinkCreate from "../components/modules/usefulLinks/UsefulLinkCreate";
import UsefulLinkUpdate from "../components/modules/usefulLinks/UsefulLinkUpdate";

import NationalityListing from "../components/modules/nationalities/NationalityListing";

import CountryListing from "../components/modules/countries/CountryListing";
import CountryCreate from "../components/modules/countries/CountryCreate";
import CountryView from "../components/modules/countries/CountryView";
import CountryUpdate from "../components/modules/countries/CountryUpdate";

import ActivityListing from "../components/modules/activities/ActivityListing";
import ActivityCreate from "../components/modules/activities/ActivityCreate";
import ActivityView from "../components/modules/activities/ActivityView";
import ActivityUpdate from "../components/modules/activities/ActivityUpdate";
import ActivityFeaturesSettings from "../components/modules/activities/ActivityFeaturesSettings";
import ActivityPartnerParticipationSettings from "../components/modules/activities/ActivityPartnerParticipationSettings";
import ActivityGuestParticipationSettings from "../components/modules/activities/ActivityGuestParticipationSettings";
import ActivityKidsParticipationSettings from "../components/modules/activities/ActivityKidsParticipationSettings";
import ActivityManageMembers from "../components/modules/activities/ActivityManageMembers";

import MemberListing from "../components/modules/members/MemberListing";
import MemberCreate from "../components/modules/members/MemberCreate";
import MemberView from "../components/modules/members/MemberView";
import MemberUpdate from "../components/modules/members/MemberUpdate";
import MemberPermissions from "../components/modules/members/MemberPermissions";

import MemberCarListing from "../components/modules/memberCars/MemberCarListing";
import MemberCarCreate from "../components/modules/memberCars/MemberCarCreate";
import MemberCarUpdate from "../components/modules/memberCars/MemberCarUpdate";
import MemberCarView from "../components/modules/memberCars/MemberCarView";

import CircleListing from "../components/modules/circles/CircleListing";
import CircleCreate from "../components/modules/circles/CircleCreate";
import CircleUpdate from "../components/modules/circles/CircleUpdate";
import CircleView from "../components/modules/circles/CircleView";

import MessageListing from "../components/modules/messages/MessageListing";
import MessagesThread from "../components/modules/messages/MessagesThread";

import SampleListing from "../components/modules/samples/SampleListing";
import SampleCreate from "../components/modules/samples/SampleCreate";
import SampleView from "../components/modules/samples/SampleView";
import SampleUpdate from "../components/modules/samples/SampleUpdate";

import Sample2Listing from "../components/modules/samples2/Sample2Listing";
import Sample2Create from "../components/modules/samples2/Sample2Create";
import Sample2View from "../components/modules/samples2/Sample2View";
import Sample2Update from "../components/modules/samples2/Sample2Update";
import ActivityCommentsListing from "../components/modules/activityComments/ActivityCommentsListing";
import ActivityLikesListing from "../components/modules/activityLikes/ActivityLikesListing";
import ActivityRegistrationsListing from "../components/modules/activityRegistrations/ActivityRegistrationsListing";
import PaymentListing from "../components/modules/payments/PaymentListing";
import PaymentView from "../components/modules/payments/PaymentView";
import PaymentUpdate from "../components/modules/payments/PaymentUpdate";
import GalleryAlbumListing from "../components/modules/galleryAlbum/GalleryAlbumListing";
import GalleryAlbumCreate from "../components/modules/galleryAlbum/GalleryAlbumCreate";
import GalleryAlbumUpdate from "../components/modules/galleryAlbum/GalleryAlbumUpdate";
import GalleryAlbumView from "../components/modules/galleryAlbum/GalleryAlbumView";
import GalleryItemDetails from "../components/modules/galleryAlbum/GalleryPictureDetails";
import GalleryPictureCommentsListing from "../components/modules/galleryPictureComments/GalleryPictureCommentsListing";
import GalleryPictureLikesListing from "../components/modules/galleryPictureLikes/GalleryPictureLikesListing";
import NewsFeedListing from "../components/modules/newsFeed/NewsFeedListing";
import NewsFeedCreate from "../components/modules/newsFeed/NewsFeedCreate";
import NewsFeedUpdate from "../components/modules/newsFeed/NewsFeedUpdate";
import NewsFeedView from "../components/modules/newsFeed/NewsFeedView";
import NewsFeedCommentsListing from "../components/modules/newsFeedComments/NewsFeedCommentsListing";
import NewsFeedLikesListing from "../components/modules/newsFeedLikes/NewsFeedLikesListing";
import ActivityManageCircles from "../components/modules/activities/ActivityManageCircles";
import NewsFeedManageMembers from "../components/modules/newsFeed/NewsFeedManageMembers";
import NewsFeedManageCircles from "../components/modules/newsFeed/NewsFeedManageCircles";
import NationalityCreate from "../components/modules/nationalities/NationalityCreate";
import NationalityUpdate from "../components/modules/nationalities/NationalityUpdate";
import NationalityView from "../components/modules/nationalities/NationalityView";
import ParameterLogin from "../containers/parameterLogin/ParameterLogin";
import NotificationsListing from "../components/modules/notifications/NotificationsListing";
import NotificationsCreate from "../components/modules/notifications/NotificationsCreate";
import NotificationsView from "../components/modules/notifications/NotificationsView";
import { AuthContext } from "../contexts/AuthContext";

const ApplicationRoutes = () => {
  const { userAuthState } = useContext(AuthContext);
  useEffect(() => {
    // console.log("ApplicationRoutes");
  }, []);

  return (
    <div>
      <Router>
        <Routes>
          {/* element={userAuthState.isLoggedIn ? <Navigate to="/" /> : <Login />} */}
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/auth-login/:email/:slug"
            element={<ParameterLogin />}
          />
          <Route
            exact
            path="/"
            forceRefresh={true}
            element={
              <PrivateRoute>
                <Home />
              </PrivateRoute>
            }
          />
          {userAuthState.user && userAuthState.user.role === "Admin" && (
            <>
              <Route
                exact
                path="/settings"
                element={
                  <PrivateRoute>
                    <SettingsListing />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/lookups"
                element={
                  <PrivateRoute>
                    <LookupListing />
                  </PrivateRoute>
                }
              />
            </>
          )}
          <Route
            exact
            path="/pages"
            element={
              <PrivateRoute>
                <PagesListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/pages/update/:id"
            element={
              <PrivateRoute>
                <PageContentUpdate />
              </PrivateRoute>
            }
          />
          {userAuthState.user && userAuthState.user.role === "Admin" && (
            <>
              <Route
                exact
                path="/admins"
                element={
                  <PrivateRoute>
                    <AdminListing />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/admins/create"
                element={
                  <PrivateRoute>
                    <AdminCreate />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/admins/:id"
                element={
                  <PrivateRoute>
                    <AdminView />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/admins/update/:id"
                element={
                  <PrivateRoute>
                    <AdminUpdate />
                  </PrivateRoute>
                }
              />
              <Route
                exact
                path="/admins/change-password/:id"
                element={
                  <PrivateRoute>
                    <AdminChangePassword />
                  </PrivateRoute>
                }
              />
            </>
          )}


          <Route
            exact
            path="/contact-us"
            element={
              <PrivateRoute>
                <ContactUsListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/contact-us/:id"
            element={
              <PrivateRoute>
                <ContactUsView />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/useful-links"
            element={
              <PrivateRoute>
                <UsefulLinkListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/useful-links/create"
            element={
              <PrivateRoute>
                <UsefulLinkCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/useful-links/:id"
            element={
              <PrivateRoute>
                <UsefulLinkView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/useful-links/update/:id"
            element={
              <PrivateRoute>
                <UsefulLinkUpdate />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/nationalities"
            element={
              <PrivateRoute>
                <NationalityListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/nationalities/create"
            element={
              <PrivateRoute>
                <NationalityCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/nationalities/:id"
            element={
              <PrivateRoute>
                <NationalityView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/nationalities/update/:id"
            element={
              <PrivateRoute>
                <NationalityUpdate />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/countries"
            element={
              <PrivateRoute>
                <CountryListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/countries/create"
            element={
              <PrivateRoute>
                <CountryCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/countries/:id"
            element={
              <PrivateRoute>
                <CountryView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/countries/update/:id"
            element={
              <PrivateRoute>
                <CountryUpdate />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/news-feed"
            element={
              <PrivateRoute>
                <NewsFeedListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/news-feed/create"
            element={
              <PrivateRoute>
                <NewsFeedCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/news-feed/:id"
            element={
              <PrivateRoute>
                <NewsFeedView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/news-feed/update/:id"
            element={
              <PrivateRoute>
                <NewsFeedUpdate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/news-feed/manage-members/:id"
            element={
              <PrivateRoute>
                <NewsFeedManageMembers />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/news-feed/manage-circles/:id"
            element={
              <PrivateRoute>
                <NewsFeedManageCircles />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/news-feed-comments/:id"
            element={
              <PrivateRoute>
                <NewsFeedCommentsListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/news-feed-likes/:id"
            element={
              <PrivateRoute>
                <NewsFeedLikesListing />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/activities"
            element={
              <PrivateRoute>
                <ActivityListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/activities/create"
            element={
              <PrivateRoute>
                <ActivityCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/activities/:id"
            element={
              <PrivateRoute>
                <ActivityView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/activities/update/:id"
            element={
              <PrivateRoute>
                <ActivityUpdate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/activities/features-settings/:id"
            element={
              <PrivateRoute>
                <ActivityFeaturesSettings />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/activities/partner-participation-settings/:id"
            element={
              <PrivateRoute>
                <ActivityPartnerParticipationSettings />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/activities/guest-participation-settings/:id"
            element={
              <PrivateRoute>
                <ActivityGuestParticipationSettings />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/activities/kids-participation-settings/:id"
            element={
              <PrivateRoute>
                <ActivityKidsParticipationSettings />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/activities/manage-members/:id"
            element={
              <PrivateRoute>
                <ActivityManageMembers />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/activities/manage-circles/:id"
            element={
              <PrivateRoute>
                <ActivityManageCircles />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/activity-registrations/:id"
            element={
              <PrivateRoute>
                <ActivityRegistrationsListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/activity-comments/:id"
            element={
              <PrivateRoute>
                <ActivityCommentsListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/activity-likes/:id"
            element={
              <PrivateRoute>
                <ActivityLikesListing />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/gallery-albums"
            element={
              <PrivateRoute>
                <GalleryAlbumListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/gallery-albums/create"
            element={
              <PrivateRoute>
                <GalleryAlbumCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/gallery-albums/:id"
            element={
              <PrivateRoute>
                <GalleryAlbumView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/gallery-albums/update/:id"
            element={
              <PrivateRoute>
                <GalleryAlbumUpdate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/gallery-albums/picture-details/:id"
            element={
              <PrivateRoute>
                <GalleryItemDetails />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/gallery-picture-comments/:id"
            element={
              <PrivateRoute>
                <GalleryPictureCommentsListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/gallery-picture-likes/:id"
            element={
              <PrivateRoute>
                <GalleryPictureLikesListing />
              </PrivateRoute>
            }
          />

          {userAuthState.user &&
            (userAuthState.user.role === "Admin" ||
              userAuthState.user.role === "Club President" ||
              userAuthState.user.role === "Club Vice President") && (
              <>
                <Route
                  exact
                  path="/payments"
                  element={
                    <PrivateRoute>
                      <PaymentListing />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/payments/:id"
                  element={
                    <PrivateRoute>
                      <PaymentView />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/payments/update/:id"
                  element={
                    <PrivateRoute>
                      <PaymentUpdate />
                    </PrivateRoute>
                  }
                />
              </>
            )}

          <Route
            exact
            path="/members"
            element={
              <PrivateRoute>
                <MemberListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/members/create"
            element={
              <PrivateRoute>
                <MemberCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/members/:id"
            element={
              <PrivateRoute>
                <MemberView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/members/update/:id"
            element={
              <PrivateRoute>
                <MemberUpdate />
              </PrivateRoute>
            }
          />
          {userAuthState.user && userAuthState.user.role === "Admin" && (
            <Route
              exact
              path="/members/permissions/:id"
              element={
                <PrivateRoute>
                  <MemberPermissions />
                </PrivateRoute>
              }
            />
          )}

          <Route
            exact
            path="/member-cars/:memberId"
            element={
              <PrivateRoute>
                <MemberCarListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/member-cars/create/:memberId"
            element={
              <PrivateRoute>
                <MemberCarCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/member-cars/:memberId/:id"
            element={
              <PrivateRoute>
                <MemberCarView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/member-cars/update/:memberId/:id"
            element={
              <PrivateRoute>
                <MemberCarUpdate />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/circles"
            element={
              <PrivateRoute>
                <CircleListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/circles/create"
            element={
              <PrivateRoute>
                <CircleCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/circles/:id"
            element={
              <PrivateRoute>
                <CircleView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/circles/update/:id"
            element={
              <PrivateRoute>
                <CircleUpdate />
              </PrivateRoute>
            }
          />
          {userAuthState.user &&
            (userAuthState.user.role === "Admin" ||
              userAuthState.user.role === "Club President" ||
              userAuthState.user.role === "Club Vice President") && (
              <>
                <Route
                  exact
                  path="/notifications"
                  element={
                    <PrivateRoute>
                      <NotificationsListing />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/notifications/create"
                  element={
                    <PrivateRoute>
                      <NotificationsCreate />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/notifications/:id"
                  element={
                    <PrivateRoute>
                      <NotificationsView />
                    </PrivateRoute>
                  }
                />
              </>
            )}

          <Route
            exact
            path="/chat"
            element={
              <PrivateRoute>
                <MessageListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/chat/:communicationType/:id"
            element={
              <PrivateRoute>
                <MessagesThread />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/samples"
            element={
              <PrivateRoute>
                <SampleListing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/samples/create"
            element={
              <PrivateRoute>
                <SampleCreate />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/samples/:id"
            element={
              <PrivateRoute>
                <SampleView />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/samples/update/:id"
            element={
              <PrivateRoute>
                <SampleUpdate />
              </PrivateRoute>
            }
          />

          <Route
            exact
            path="/samples2"
            element={
              <PrivateRoute>
                <Sample2Listing />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/samples2/create"
            element={
              <PrivateRoute>
                <Sample2Create />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/samples2/:id"
            element={
              <PrivateRoute>
                <Sample2View />
              </PrivateRoute>
            }
          />
          <Route
            exact
            path="/samples2/update/:id"
            element={
              <PrivateRoute>
                <Sample2Update />
              </PrivateRoute>
            }
          />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
};

export default ApplicationRoutes;
