import React, { useState, useReducer, useEffect } from 'react'
import { Field, Form, Formik } from 'formik';
import * as yup from "yup"
import { Button, Modal } from "react-bootstrap";
import { Store as toast } from 'react-notifications-component';
import { toastNotification } from "../../../../config/toastNotification";
import { ContainerLoading } from '../../../UI/loading/ContainerLoading';
import BlockSuccessMessage from '../../../UI/errorMessages/BlockSuccessMessage';
import FormErrorMessage from '../../../UI/errorMessages/FormErrorMessage';
import CMSFormInput from '../../../UI/forms/CMSFormInput';
import axios from "../../../../config/axios";
import BlockErrorMessage from '../../../UI/errorMessages/BlockErrorMessage';
import CMSFormImageWithCrop from '../../../UI/forms/CMSFormImageWithCrop';
import CMSFormSelect from '../../../UI/forms/CMSFormSelect';
import { galleryInitialState, galleryReducer } from './galleryReducer';
import { isEmpty } from '../../../../helpers/CommonHelper';


const UpdatePicture = ({ moduleUrl, show, setShow, picture, loadItem }) => {
    const [updateState, updateDispatch] = useReducer(galleryReducer, galleryInitialState)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [formInitialValues, setFormInitialValues] = useState({})


    let imageRequirement = {
        dimension: {
            width: 500,
            height: 500
        },
        allowedExtensions: ['image/png', 'image/jpg', 'image/jpeg'],
        size: 1024 * 1024 * 2
    }

    const onSubmit = async (data) => {
      //  console.log("In Update on Submit", data);
        updateDispatch({
            type: "FORM_SUBMISSION_REQUEST"
        });
        try {
            const res = await axios.post(`${moduleUrl}/${picture._id}`, data)//
            updateDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: res.data
            });
            loadItem();
            toast.addNotification({
                ...toastNotification,
                title: "Success!",
                message: `Picture updated successfully.`,
                type: "success",
            });
            setShow(false)
        } catch (error) {

            const status = error.response ? error.response.status : 500;
            const errorMessage = (status === 400) ? error.response.data.errorMessage : error.message


            updateDispatch({
                type: "FORM_SUBMISSION_FAILURE",
                payload: errorMessage
            });
            setTimeout(() => {
                updateDispatch({
                    type: "FORM_INITIAL_STATE"
                });
            }, 5000)
        }
    }

    useEffect(() => {

        if (show) {
            if (picture && picture._id) {

                //if (moduleConfig.fields && moduleConfig.fields.length > 0) {


                // let formFieldsWithFetchData = {}
                // moduleConfig.fields.map((field, index) => {

                //     formFieldsWithFetchData[field.fieldName] = updateState.data.item[field.fieldName]
                // })
                // console.log("Here =>>>>>>>>>>>>>>", formFieldsWithFetchData)
                setFormInitialValues(picture)

                //};
            }
        } else {
            setFormInitialValues({})
        }

    }, [show, picture])
    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Update Picture</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    (Object.keys(formInitialValues).length > 0) &&
                    <Formik
                        initialValues={formInitialValues}
                        validationSchema={yup.object({
                            //title: yup.string(),
                            sortOrder: yup.number()
                                .transform((cv) => (isNaN(cv) ? null : cv))
                                .positive()
                                .integer()
                                .nullable(),
                            image: yup.mixed()
                                .required("Please upload image.")
                                .imageTypeCheck(imageRequirement.allowedExtensions)
                                .imageSizeCheck(imageRequirement.size)
                                .imageDimensionCheck(imageRequirement.dimension),
                            status: yup.string().required("Required"),
                        })}
                        enableReinitialize={true}
                        validateOnChange={true}
                        validateOnBlur={true}
                        onSubmit={async (values, { resetForm }) => {
                           
                            ///onSubmit(values, resetForm)
                            const formData = new FormData();
                            for (let name in values) {
                                if (Array.isArray(values[name])) {
                                    formData.append(name, JSON.stringify(values[name]));
                                } else {
                                    formData.append(name, values[name]);
                                }
                            }
                            //console.log(formData);
                            onSubmit(formData, resetForm)
                        }}
                    >
                        {(form) => (
                            <Form onSubmit={form.handleSubmit}>
                             
                                {(updateState.error) &&
                                    <FormErrorMessage error={updateState.error} />
                                }


                                {/* <CMSFormInput label="Title" name='title' type="text" form={form} /> */}
                                <CMSFormImageWithCrop label="image" name="image" required={true} form={form} aspect={1 / 1} outputSize={imageRequirement.dimension} />
                                <CMSFormInput label="Sort Order" name='sortOrder' type="number" form={form} />
                                <CMSFormSelect name="status" label="Status" required={true} form={form} options={[{ label: "Select Status...", value: "" }, { label: "Enabled", value: "1" }, { label: "Disabled", value: "0" }]} />


                                <button className="btn btn-primary" type="submit">Submit</button>
                            </Form>
                        )}
                    </Formik>
                }
            </Modal.Body>
        </Modal>
    )
}

export default UpdatePicture