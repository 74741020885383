import React from "react";
import { Field, ErrorMessage } from "formik";
import { OverlayTrigger, Popover, Tooltip } from "react-bootstrap";

const CMSFormTextArea = (props) => {
  const { label, name, required, maxLength, form, ...rest } = props;
  return (
    <div className="form-group ">
      {label !== "" && (
        <>
          {required === true ? (
            <OverlayTrigger
              key={`tooltip-${name}`}
              placement="right"
              overlay={<Tooltip id={`tooltip-right`}>Required field.</Tooltip>}
            >
              <label
                htmlFor={name}
                className={`required ' ${
                  form.touched[name] && form.errors[name]
                    ? "is-invalidText"
                    : ""
                }`}
              >
                {" "}
                {label}
              </label>
            </OverlayTrigger>
          ) : (
            <label
              htmlFor={name}
              className={` ${
                form.touched[name] && form.errors[name] ? "is-invalidText" : ""
              }`}
            >
              {" "}
              {label}
            </label>
          )}
        </>
      )}
        {maxLength && <div className="float-end">{form.values[name].length}/{maxLength}</div>}
      <Field
        as="textarea"
        id={name}
        name={name}
        maxLength={maxLength}
        className={`form-control ${
          form.touched[name] && form.errors[name] ? "is-invalid" : ""
        }`}
        {...rest}
      />
      {form.touched[name] && form.errors[name] && (
        <span id={`${name}-error"`} className="error invalid-feedback">
          <ErrorMessage name={name} />
        </span>
      )}
    </div>
  );
};

export default CMSFormTextArea;
