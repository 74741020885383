import React, { useReducer, useEffect, useContext } from "react";
import moduleConfig from "./moduleConfig";
import { Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";

import * as yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import axios from "../../../config/axios";
import { createReducer, createInitialState } from "./reducers/createReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import ModuleCreateView from "../../UI/modules/views/ModuleCreateView";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSFormController from "../../UI/forms/CMSFormController";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import CMSFormDate from "../../UI/forms/CMSFormDate";
import CMSFormImage from "../../UI/forms/CMSFormImage";
import CMSFormImageWithCrop from "../../UI/forms/CMSFormImageWithCrop";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";

const MemberCarCreate = () => {
  const [createState, createDispatch] = useReducer(
    createReducer,
    createInitialState
  );
  let imageRequirement = {
    dimension: {
      width: 700,
      height: 500,
    },
    allowedExtensions: ["image/png", "image/jpg", "image/jpeg"],
    size: 1024 * 1024 * 2,
  };
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let { memberId } = useParams();

  let breadcrumbs = {
    memberListing: {
      label: "Members",
      url: `/members`,
    },
    memberDetails: {
      label: "Member Details",
      url: `/members/${memberId}`,
    },
    listing: {
      label: moduleConfig.title,
      url: `/${moduleConfig.url}/${memberId}`,
    },
    create: {
      label: "Create",
    },
  };

  let navigate = useNavigate();
  const onSubmit = async (data) => {
    createDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(`${moduleConfig.url}/${memberId}`, data); //
      console.log(res);

      createDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });

      navigate(`/${moduleConfig.url}/${memberId}`);
      toast.addNotification({
        ...toastNotification,
        model: "Success!",
        message: `${moduleConfig.singleItemTitle} created successfully.`,
        type: "success",
      });
    } catch (error) {
      console.log(error.response);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.message : error.message;

      createDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        createDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  const loadData = async () => {
    // createDispatch({
    //   type: "FETCH_SUCCESS",
    //   payload: {},
    // });

    try {
      const res = await axios.get(`member-cars/car-years-models-colors`); //
      createDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      createDispatch({
        type: "FETCH_FAILURE",
        payload: "Date loading failed.",
      });
    }
  };
  useEffect(() => {
    loadData();
    setBreadcrumb([
      breadcrumbs.memberListing,
      breadcrumbs.memberDetails,
      breadcrumbs.listing,
      breadcrumbs.create,
    ]);
  }, []);
  return (
    <DashboardLayout>
      {createState.status === "fetchFailed" ? (
        <BlockErrorMessage error={createState.error} />
      ) : (
        <>
          {/* {console.log(createState.data)} */}
          {createState.status !== "fetching" && (
            <>
              <ModuleHeader
                moduleTitle={`Create ${moduleConfig.singleItemTitle}`}
                moduleUrl={moduleConfig.url}
              ></ModuleHeader>

              <section className="content">
                <Card>
                  <Card.Body>
                    {createState.isLoading && (
                      <div class="overlay position-absolute containerLoading">
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                      </div>
                    )}
                    <Formik
                      initialValues={{
                        model: "",
                        otherModel: "",
                        chassisNumber: "",
                        year: "",
                        color: "",
                        otherColor: "",
                        plateNumber: "",
                        emirate: "",
                        purchaseFrom: "",
                        dateOfPurchase: "",
                        registrationCard: "",
                        registrationCardBack: "",
                        status: "",
                      }}
                      validationSchema={yup.object({
                        status: yup.string().required("Required"),
                        dateOfPurchase: yup.string().required("Required"),
                        purchaseFrom: yup.string().max(50),
                        emirate: yup.string().required("Required").max(50),
                        plateNumber: yup
                          .string()
                          .required("Required")
                          .min(2)
                          .max(10),
                        color: yup.string().required("Required").max(50),
                        otherColor: yup.string().when("color", { is: (color) => color === "Other", then: yup.string().required("Required") }),
                        year: yup
                          .number()
                          .nullable()
                          .required("Required")
                          .transform((value) => (isNaN(value) ? null : value)),
                        chassisNumber: yup
                          .string()
                          .required("Required")
                          .min(17)
                          .max(17),
                        model: yup.string().required("Required").max(50),
                        otherModel: yup.string().when("model", { is: (model) => model === "Other", then: yup.string().required("Required") }),
                        registrationCard: yup
                          .mixed()
                          .required("Please upload image.")
                          .imageTypeCheck(imageRequirement.allowedExtensions)
                          .imageSizeCheck(imageRequirement.size)
                          .imageDimensionCheck(imageRequirement.dimension),
                        registrationCardBack: yup
                          .mixed()
                          .required("Please upload image.")
                          .imageTypeCheck(imageRequirement.allowedExtensions)
                          .imageSizeCheck(imageRequirement.size)
                          .imageDimensionCheck(imageRequirement.dimension),
                      })}
                      enableReinitialize={true}
                      validateOnChange={true}
                      validateOnBlur={false}
                      onSubmit={async (values) => {
                        // console.log(values);
                        // onSubmit(values);
                        const formData = new FormData();
                        for (let name in values) {
                          if (Array.isArray(values[name])) {
                            formData.append(name, JSON.stringify(values[name]));
                          } else {
                            formData.append(name, values[name]);
                          }
                        }
                        //console.log(formData);
                        onSubmit(formData);
                      }}
                    >
                      {(form) => (
                        <Form onSubmit={form.handleSubmit}>
                          {createState.error && (
                            <FormErrorMessage error={createState.error} />
                          )}

                          <Row>
                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormSelect
                                name="model"
                                label="Model"
                                required={true}
                                form={form}
                                options={createState.data.models}
                              />
                            </Col>

                            {form.values.model === "Other" && (
                              <Col xs={12} s={12} md={4} lg={4}>
                                <CMSFormInput
                                  name="otherModel"
                                  label="Other Model"
                                  required={true}
                                  form={form}

                                />
                              </Col>
                            )}
                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormInput
                                name="chassisNumber"
                                label="Chassis Number"
                                required={true}
                                form={form}
                              />
                            </Col>
                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormSelect
                                name="year"
                                label="Year"
                                required={true}
                                form={form}
                                options={createState.data.years}
                              />
                            </Col>



                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormSelect
                                name="color"
                                label="Color"
                                required={true}
                                form={form}
                                options={createState.data.colors}
                              />
                            </Col>
                            {form.values.color === "Other" && (
                              <Col xs={12} s={12} md={4} lg={4}>
                                <CMSFormInput
                                  name="otherColor"
                                  label="Other Color"
                                  required={true}
                                  form={form}

                                />
                              </Col>
                            )}
                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormInput
                                name="plateNumber"
                                label="Plate Number"
                                required={true}
                                form={form}
                              />
                            </Col>

                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormSelect
                                name="emirate"
                                label="Emirate"
                                required={true}
                                form={form}
                                options={createState.data.emirates}
                              />
                            </Col>

                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormInput
                                name="purchaseFrom"
                                label="Purchase From"
                                required={false}
                                form={form}
                              />
                            </Col>
                            <Col xs={12} s={12} md={4} lg={4}>
                              <div className="form-group ">
                                <CMSFormDate
                                  name="dateOfPurchase"
                                  label="Date of Purchase"
                                  form={form}
                                  required={true}
                                  minDate={new Date("1950-12-01")}
                                  maxDate={new Date()}
                                  disabled={false}
                                />

                                {form.errors["dateOfPurchase"] !==
                                  undefined && (
                                    <span
                                      id="dateOfPurchase-error"
                                      class="error invalid-feedback"
                                    >
                                      {form.errors["dateOfPurchase"]}
                                    </span>
                                  )}
                              </div>
                            </Col>

                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormSelect
                                name="status"
                                label="Status"
                                required={true}
                                form={form}
                                options={[
                                  {
                                    value: "",
                                    label: "Select Status ...",
                                  },
                                  { value: "1", label: "Active" },
                                  {
                                    value: "0",
                                    label: "Archived",
                                  },
                                ]}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormImageWithCrop
                                label="Registration Card(Front)"
                                name="registrationCard"
                                required={true}
                                form={form}
                                aspect={4 / 3}
                                outputSize={imageRequirement.dimension}
                              />
                            </Col>
                            <Col xs={12} s={12} md={4} lg={4}>
                              <CMSFormImageWithCrop
                                label="Registration Card(Back)"
                                name="registrationCardBack"
                                required={true}
                                form={form}
                                aspect={4 / 3}
                                outputSize={imageRequirement.dimension}
                              />
                            </Col>
                          </Row>

                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </section>
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default MemberCarCreate;
