import { ErrorMessage } from "formik";
import React, { useState, useEffect } from "react";
import ReactFancyBox from "react-fancybox";
import {
  Row,
  Col,
  Image,
  Button,
  OverlayTrigger,
  Popover,
  Tooltip,
} from "react-bootstrap";

const CMSFormImage = (props) => {
  const { name, label, form ,required} = props;
  let initialImageURL = form.values[name] != "null"
    ? process.env.REACT_APP_BACKEND_URL + "public/" + form.values[name]
    : null;

  const [image, setImage] = useState(null);
  const [imageURL, setImageURL] = useState(initialImageURL);
  const [fileName, setFileName] = useState("Choose file");
  useEffect(() => {
    if (!image || !image.name) return;
    setImageURL(URL.createObjectURL(image));
    setFileName(image.name);
  }, [image]);

  const onImageChange = (e) => {
    if (e.target && e.target.files[0]) {
      setImage(e.target.files[0]);
      form.setFieldValue(name, e.target.files[0]);
    } else {
      clearImage();
    }
  };

  const clearImage = async () => {
    console.log("clearImage callled");
    setImage(null);
    await form.setFieldValue(name, "", true);
    setImageURL(null);
    setFileName(null);
    form.setFieldTouched(name, true);
  };

  return (
    <>
    {console.log(required)}
      <div className="form-group ">
        {label !== "" && (
          <>
            {required === true ? (
              <OverlayTrigger
                key={`tooltip-${name}`}
                placement="right"
                overlay={
                  <Tooltip id={`tooltip-right`}>Required field.</Tooltip>
                }
              >
                <label
                  htmlFor={name}
                  className={`required ' ${
                    form.touched[name] && form.errors[name]
                      ? "is-invalidText"
                      : ""
                  }`}
                >
                  {" "}
                  {label}
                </label>
              </OverlayTrigger>
            ) : (
              <label
                htmlFor={name}
                className={` ${
                  form.touched[name] && form.errors[name]
                    ? "is-invalidText"
                    : ""
                }`}
              >
                {" "}
                {label}
              </label>
            )}
          </>
        )}

        {/* <label
          htmlFor={name}
          className={` ${
            form.touched[name] && form.errors[name]
              ? "is-invalidText required"
              : "required"
          }`}
        >
          {" "}
          {label}
        </label> */}
        <div
          className={`input-group ${
            form.touched[name] && form.errors[name] ? "is-invalid" : ""
          }`}
        >
          <div className="input-group-prepend">
            <span
              className="input-group-text"
              id={`${
                form.touched[name] && form.errors[name]
                  ? "is-invalidInputGroupFileAddon01"
                  : "inputGroupFileAddon01"
              }`}
            >
              Upload
            </span>
          </div>
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input"
              id="image"
              aria-describedby="inputGroupFileAddon01"
              accept="image/*"
              onChange={(e) => onImageChange(e)}
              onBlur={form.handleBlur}
            />
            <label
              className={` ${
                form.touched[name] && form.errors[name]
                  ? "is-invalid custom-file-label"
                  : "custom-file-label"
              }`}
              htmlFor="inputGroupFile01"
            >
              {fileName}
            </label>
          </div>
        </div>
        {form.touched[name] && form.errors[name] && (
          <span id={`${name}-error"`} className="error invalid-feedback">
            <ErrorMessage name={name} />
          </span>
        )}
      </div>

      {form.values[name] != null && (
        <Row>
          <Col xs={6} md={2} lg={1} className="pageContent ms-2 p-0 ">
            {/* <Image src={imageURL} thumbnail /> */}
            <div className="iconDiv ">
              <i
                className=" fas fa-times position-absolute"
                onClick={() => clearImage()}
              />
            </div>

            <ReactFancyBox thumbnail={imageURL} image={imageURL} />
          </Col>
          <Col></Col>
        </Row>
      )}
    </>
  );
};

export default CMSFormImage;
