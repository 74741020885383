import React from "react";
import { Spinner } from "react-bootstrap";

export const ContainerLoading = () => {
  return (
    <div className="loadingContainer hasLightBg">
      <div className="loadingContainerOverlay"></div>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};
