import React, { useState, useEffect } from "react";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import axios from "../../../../config/axios";
import useConfirm from "../../../../HOC/custom/useConfirm";
import AddPicture from "./AddPicture";
import GalleryPicture from "./GalleryPicture";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../../config/toastNotification";
import UpdatePicture from "./UpdatePicture";

const GalleryPictures = ({ moduleUrl, parentId, detailsDispatch, disabledAddPictureButton }) => {
  //console.log("parentId", parentId);
  const { confirm } = useConfirm();
  const [state, setState] = useState({
    isLoading: true,
    status: "fetching", //fetching, fetched, fetchFailed
    error: "",
    data: {},
    showAddPictureModal: false,
    showEditPictureModal: false,
    editItem: null,
  });

  //const [showAddPictureModal, setShowAddPictureModal] = useState(false);
  //const [showEditPictureModal, setShowEditPictureModal] = useState(false);

  const confirmAndRemovePicture = async (pictureId) => {
    const isConfirmed = await confirm(
      `Do you really want to remove this picture?`
    );

    if (isConfirmed) {
      await removePicture(pictureId);
    }
  };

  const removePicture = async (pictureId) => {
    detailsDispatch({
      type: "BLOCK_REQUEST",
    });
    setState({
      ...state,
      isLoading: true,
    });
    try {
      const res = await axios.post(`${moduleUrl}/remove-picture/${pictureId}`, {
        parentId,
      });
      loadData();
      // detailsDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Picture removed successfully.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "BLOCK_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  const confirmAndArchivePicture = async (pictureId) => {
    const isConfirmed = await confirm(
      `Do you really want to archive this picture?`
    );

    if (isConfirmed) {
      await changeStatusPicture(pictureId, "0");
    }
  };

  const confirmAndRestorePicture = async (pictureId) => {
    const isConfirmed = await confirm(
      `Do you really want to restore this picture?`
    );

    if (isConfirmed) {
      await changeStatusPicture(pictureId, "1");
    }
  };

  const changeStatusPicture = async (pictureId, status) => {
    // console.log("In block Item Function");
    detailsDispatch({
      type: "BLOCK_REQUEST",
    });

    try {
      const res = await axios.post(`${moduleUrl}/change-status/${pictureId}`, {
        status,
        parentId,
      });
      loadData();
      // detailsDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Picture ${status === "1" ? "restored" : "archived"
          } successfully.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "BLOCK_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  const loadData = async () => {
    setState({
      ...state,
      isLoading: true,
      showAddPictureModal: false,
    });
    try {
      const res = await axios.get(`${moduleUrl}/${parentId}`); //
      //console.log(res);
      setState({
        isLoading: false,
        status: "fetched", //fetching, fetched, fetchFailed
        error: "",
        data: res.data,
      });
    } catch (error) {
      setState({
        isLoading: false,
        status: "fetchFailed", //fetching, fetched, fetchFailed
        error: error,
        data: {},
      });
    }
  };

  const updatePictureHandler = (picture) => {
    //console.log("updatePictureHandler called ", picture);
    setState({ ...state, showEditPictureModal: true, editItem: picture });
  };
  useEffect(() => {
    if (parentId) {
      loadData();
    }
  }, [parentId]);

  return (
    <>
      <Row>
        <Col>
          <Card className="item  mb-2">
            <Card.Body className="px-2 pt-2 pb-0">
              {state.isLoading && (
                <div class="overlay position-absolute containerLoading">
                  <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                </div>
              )}
              <div className="d-flex my-2">
                {(!disabledAddPictureButton) ? <><h5>Picture(s) </h5> &nbsp; &nbsp;{" "}</> : <>
                  <h5>Picture(s) / Video(s) </h5> &nbsp; &nbsp;{" "}
                </>}


                {
                  (!disabledAddPictureButton) &&
                  <Button
                    variant="primary"
                    size="sm"
                    className="rounded-circle roundButton"
                    onClick={() =>
                      setState({ ...state, showAddPictureModal: true })
                    }
                  >
                    <i className="fas fa-plus"></i>
                  </Button>
                }

              </div>

              <Row className="circleMembersList">
                {state.status === "fetchFailed" && state.error ? (
                  <Alert variant="danger" className="m-0">
                    <b>Error!</b> Fetching pictures failed.
                  </Alert>
                ) : (
                  <>
                    {state.data &&
                      state.data.items &&
                      state.data.items.length > 0 ? (
                      <>
                        {state.data.items.map((picture, index) => {
                          return (
                            <Col key={index} xs={12} sm={6} md={3} lg={3}>
                              <GalleryPicture
                                moduleUrl={moduleUrl}
                                loading={state.isLoading}
                                picture={picture}
                                confirmAndRemovePicture={
                                  confirmAndRemovePicture
                                }
                                confirmAndArchivePicture={
                                  confirmAndArchivePicture
                                }
                                confirmAndRestorePicture={
                                  confirmAndRestorePicture
                                }
                                updatePictureHandler={updatePictureHandler}
                              />
                            </Col>
                          );
                        })}
                      </>
                    ) : (
                      <Alert variant="warning" className="mb-0">
                        No pictures found.
                      </Alert>
                    )}
                  </>
                )}
              </Row>
            </Card.Body>

          </Card>
        </Col>
      </Row>
      <AddPicture
        moduleUrl={moduleUrl}
        show={state.showAddPictureModal}
        setShow={(status) =>
          setState({ ...state, showAddPictureModal: status })
        }
        parentId={parentId}
        loadItem={loadData}
      />
      <UpdatePicture
        moduleUrl={moduleUrl}
        show={state.showEditPictureModal}
        setShow={(status) =>
          setState({ ...state, showEditPictureModal: status })
        }
        picture={state.editItem}
        loadItem={loadData}
      />
    </>
  );
};

export default GalleryPictures;
