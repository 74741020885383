import React from "react";
import { Alert, Button, Card, Carousel, Col, Row } from "react-bootstrap";
import ReactFancyBox from "react-fancybox";

const CarGallery = ({ items }) => {
  var serverUploadsUrl = process.env.REACT_APP_BACKEND_URL + "public/";
  return (
    <Card>
      <Card.Body className="mb-0 pb-0 pt-1 px-2 ">
        <Row>
          <Card.Title>
            <h4>Car Gallery</h4>
          </Card.Title>
        </Row>

        <Row>
          {items && items.length > 0 ? (
            <>
              {items.map((item, index) => {
                return (
                  <Col key={index} xs={12} md={6} lg={3}>
                    <Card
                      className="align-items-center carImage justify-content-center"
                      style={{ height: "100%" }}
                    >
                      <ReactFancyBox
                        thumbnail={`${serverUploadsUrl}${item.image}`}
                        image={`${serverUploadsUrl}${item.image}`}
                      />
                    </Card>
                  </Col>
                );
              })}
            </>
          ) : (
            <Alert variant="warning mb-0">No records found.</Alert>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default CarGallery;
