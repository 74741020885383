import { moduleDefaultConfig } from "../../../config/moduleDefaultConfig"
import * as yup from "yup"
let moduleConfig = {}
moduleConfig = {
    ...moduleDefaultConfig
};

moduleConfig.title = 'Samples2';
moduleConfig.singleItemTitle = 'Sample2';
moduleConfig.url = 'samples2';
moduleConfig.moduleType = {
    listing: 'block',
    view: 'block'
};
moduleConfig.fields = [
    {
        displayName: "ID",
        fieldName: "_id",
        type: "text",
        control: "hidden",
        showOnListing: true,
    },
    {
        displayName: "Name",
        fieldName: "name",
        type: "text",
        control: "input",
        showOnListing: true,
        sortable: true,
    },
    {
        displayName: "Email",
        fieldName: "email",
        type: "email",
        control: "input",
        showOnListing: true,
        sortable: true,
    },
    {
        displayName: "Password",
        fieldName: "password",
        type: "password",
        control: "input",
        showOnListing: true,
    },
    {
        displayName: "Model Number",
        fieldName: "model",
        type: "text",
        control: "input",
        showOnListing: true,
    },
    {
        displayName: "Date",
        fieldName: "date",
        type: "date",
        control: "input",
        showOnListing: true,
    },
    {
        displayName: "Description",
        fieldName: "description",
        control: "textArea",
        type: "text",
        showOnListing: true,
    },
    {
        displayName: "Content",
        fieldName: "content",
        control: "richText",
        type: "richText",
    },
    {
        displayName: "Country",
        fieldName: "country",
        control: "select",
        options: [
            { label: "Pakistan", value: "Pakistan" },
            { label: "UAE", value: "UAE" },
        ],
        type: "text",
        showOnListing: true,
        sortable: true,
    },
    {
        displayName: "Gender",
        fieldName: "gender",
        control: "radio",
        options: [
            { label: "Male label", value: "Male" },
            { label: "Female label", value: "Female" },
        ],
        type: "text",
        showOnListing: true,
        sortable: true,
    },
    {
        displayName: "Vehicle",
        fieldName: "vehicle",
        control: "checkbox",
        options: [
            { label: "Car label", value: "Car" },
            { label: "Bike label", value: "Bike" },
        ],
        type: "array",
        showOnListing: true,
    },
    {
        displayName: "Image",
        fieldName: "image",
        control: "image",
        type: "image",
        showOnListing: false,
    },
    {
        displayName: "Status",
        fieldName: "status",
        control: "select",
        options: [
            { label: "Enabled", value: 1 },
            { label: "Disabled", value: 0 },
        ],
        type: "boolean",
        showOnListing: true,
        sortable: true,
    },
    {
        displayName: "Created Date/Time",
        fieldName: "createdAt",
        type: "dateTime",
    },
    {
        displayName: "Updated Date/Time",
        fieldName: "updatedAt",
        type: "dateTime",
    }
];
moduleConfig.initialValues = {
    name: "",
    email: "",
    password: "",
    model: "",
    date: "",
    description: "",
    content: '',
    country: "",
    gender: "",
    vehicle: "",
    image: null,
    status: 1
}
moduleConfig.validationSchema = yup.object({
    name: yup.string().required("Required"),
    email: yup.string().required("Required").email(),
    password: yup.string().required("Required").min(6).max(12),
    model: yup.number().required("Required"),
    date: yup.date().required(),
    description: yup.string().required("Required"),
    content: yup.string().required("Required"),
    country: yup.string().required("Required"),
    gender: yup.string().required("Required"),
    vehicle: yup.array().required("Required"),
    status: yup.number().required("Required"),
    image: yup.mixed()
        .required("Required")
        .test(
            "FILE_SIZE",
            "File size should be less than 1 MB.",
            (file) => {
                if (file instanceof File) {
                    return file.size <= 1000000
                } else {
                    return true
                }
            }
        )
        .test(
            "FILE_FORMAT",
            "File should be in JPG/PNG format.",
            (file) => {
                if (file instanceof File) {
                    return (['image/jpeg', 'image/jpg', 'image/png'].includes(file.type))
                } else {
                    return true
                }
            }
        )
})

export default moduleConfig;