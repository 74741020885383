import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const ModuleHeader = ({ moduleTitle, children }) => {
    return <section className="content-header mb-xl-2 mb-lg-2 mb-md-2">
        <Row className="moduleHeader">
            <Col xl={6} lg={6} md={6} sm={12} xs={12} className="m-0 p-0"><h4>{moduleTitle}</h4></Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12} className="m-0 p-0 text-right">
                {children}
            </Col>
        </Row>
    </section>;
};

export default ModuleHeader;
