import React from 'react';
import { Pagination } from 'react-bootstrap';

const ModulePagination = ({ pagination, setFilter }) => {
    let numberOptionsToShow = 2;
    let minOptionToShow = Math.max((pagination.currentPage - numberOptionsToShow), 1)
    let maxOptionToShow = Math.min((pagination.currentPage + numberOptionsToShow), pagination.pageCount)

    let items = [];
    if (minOptionToShow > 1) {
        items.push(
            <Pagination.Ellipsis key="ellipsis-min" disabled={true} />
        );
    }
    for (let page = 1; page <= pagination.pageCount; page++) {
        if (page >= minOptionToShow && page <= maxOptionToShow) {
            items.push(
                <Pagination.Item
                    key={page}
                    active={page === pagination.currentPage}

                    onClick={() => setFilter("page", page)}
                >
                    {page}
                </Pagination.Item>,
            );
        }
    }

    if (maxOptionToShow < pagination.pageCount) {
        items.push(
            <Pagination.Ellipsis key="ellipsis-max" disabled={true} />
        );
    }

    return (
        <div className="m-0 p-0 text-center footerPagination ">
            {(pagination && pagination.pageCount > 1) ?
                <Pagination>


                    {(pagination.currentPage > 1) &&
                        <>
                            < Pagination.First
                                key="first"
                                disabled={(pagination.currentPage === 1)}
                                onClick={() => setFilter("page", 1)}
                            />
                            <Pagination.Prev
                                key="prev"
                                disabled={(pagination.currentPage === 1)}
                                onClick={() => {
                                    if (pagination.currentPage > 1) {
                                        setFilter("page", pagination.currentPage - 1)
                                    }
                                }} />
                        </>
                    }



                    {items}



                    {(pagination.currentPage < pagination.pageCount) &&
                        <>
                            <Pagination.Next
                                key="next"
                                disabled={(pagination.currentPage === pagination.pageCount)}
                                onClick={() => {
                                    if (pagination.currentPage < pagination.pageCount) {
                                        setFilter("page", pagination.currentPage + 1)
                                    }
                                }}
                            />
                            <Pagination.Last
                                key="last"
                                disabled={(pagination.currentPage === pagination.pageCount)}
                                onClick={() => setFilter("page", pagination.pageCount)}
                            />

                        </>
                    }
                </Pagination>
                : <>
                </>

            }

        </div>

    )
};

export default ModulePagination;
