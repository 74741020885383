import React from 'react'
import ListingViewHOC from '../../../HOC/module/ListingViewHOC';
import moduleConfig from "./moduleConfig"

const AdminListing = () => {
    let breadcrumbs = {
        listing: {
            label: moduleConfig.title,
            url: `/${moduleConfig.url}`
        }
    };

    const customButtonsForItems = [
        {
            label: "Change Password",
            className: "fas fa-key",
            url: "change-password"
        }
    ]

    const Component = ListingViewHOC(breadcrumbs);
    return <Component moduleConfig={moduleConfig} customButtonsForItems={customButtonsForItems
    } />;
}

export default AdminListing;
