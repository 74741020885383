import React from 'react'

const Footer = () => {
    return (
        <footer className="main-footer">
            <strong>Copyright © {new Date().getFullYear()} <a href="https://www.ahead.pro" target="_blank">AHEAD FZCO</a>. </strong>
            All rights reserved.
            <div className="float-right d-none d-sm-inline-block">
                <b>Version</b> 3.1.0
            </div>
        </footer>

    )
}

export default Footer
