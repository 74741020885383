import React from "react";
import { Button } from "react-bootstrap";
import { getFullName } from "../../../../helpers/CommonHelper";

import ModuleDisplayTimeAgo from "../../modules/fieldsDisplay/ModuleDisplayTimeAgo";

const CommentItem = ({ comment }) => {
  //console.log(comment)
  return (
    <div className="direct-chat-msg right">
      <div className="direct-chat-text d-flex flex-column">
        <div className=" d-flex align-items-center justify-content-between">
          <Button className="direct-chat-name float-right m-0 p-0 text-primary" href={`/members/${comment.member._id}`} variant="link">
            {comment.member && comment.member.fullName
              ? comment.member.fullName
              : getFullName(comment.member)}
          </Button>
        
          <span className="direct-chat-timestamp d-flex align-align-items-center justify-content-end float-right">
            <ModuleDisplayTimeAgo value={comment.createdAt} />
          </span>
        </div>

        <span>{comment.comment}</span>
      </div>
    </div>
  );
};

export default CommentItem;
