import React from "react";
import { Alert, Badge, Button, Card, Table } from "react-bootstrap";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";

const MemberRecentRegistrations = ({ items }) => {
  const registration = [...items].reverse();

  return (
    <div className="member-recent-registrations">
      <Card className="card-primary">
        <Card.Header>
          <Card.Title>
            Recent Activity Registration(s){" "}
            {items &&
              items.length > 0 &&
              "- Total " + items.length + " item(s)"}
          </Card.Title>
        </Card.Header>
        <Card.Body className="p-0 m-0 overflow-auto">
          {!items || items.length <= 0 ? (
            <Alert variant="warning mb-0">No records found.</Alert>
          ) : (
            <>
              <div className="registrationTable ">
                <Table  bordered  size="sm" className="p-0 m-0">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Activity</th>
                      {/* <th>Participation Fee</th> */}

                      <th>Partner Participating</th>
                      {/* <th>Partner Fee</th> */}

                      <th>Guests Participating</th>
                      <th>Kids Participating</th>
                      {/* <th>Guests Count</th> */}
                      {/* <th>Per Guest Fee</th> */}
                      <th> Member Status </th>
                      <th> Partner Status</th>
                      <th>Guest Status</th>
                      <th>Kids Status</th>
                      {/* 
                                            <th> Status</th> */}
                      <th>Registration Date/Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {registration.map((item, index) => {
                      return (
                        <>
                        <tr index={index} className={item.additionalRequests && item.additionalRequests.length<=0 && "borderBottom"} >
                          <td rowSpan={item.additionalRequests && item.additionalRequests.length>0 && item.additionalRequests.length+1}>{index + 1}</td>
                          <td rowSpan={item.additionalRequests && item.additionalRequests.length>0 && item.additionalRequests.length+1} className="">
                            <Button
                              href={`/activities/${item.activityId}`}
                              variant="link"
                            >
                              {item.activityName ? item.activityName : "-"}
                            </Button>
                          </td>
                          {/* <td className="text-center">{item.participationFee}</td> */}

                          <td className="text-center">
                            <ModuleDisplayBoolean
                              value={item.partnerParticipating}
                            />
                          </td>
                          {/* <td className="text-center">{item.partnerParticipationFee}</td> */}


                          <td className="text-center">
                            <>
                              {item.guestParticipating === "1" && item.NumberOfGuestParticipating > 0 ? (
                                <Badge bg="success">
                                  Yes ({item.NumberOfGuestParticipating})
                                </Badge>
                              ) : (
                                <Badge bg="warning" text="dark">
                                  No
                                </Badge>
                              )}
                            </>
                          </td>
                          <td className="text-center">
                            <>
                              {item.kidsParticipating === "1" && item.NumberOfKidsParticipating > 0 ? (
                                <Badge bg="success">
                                  Yes ({item.NumberOfKidsParticipating})
                                </Badge>
                              ) : (
                                <Badge bg="warning" text="dark">
                                  No
                                </Badge>
                              )}
                            </>
                          </td>
                          {/* <td className="text-center">{item.guestParticipationFee}</td> */}

                          <td className="text-center">
                            <>
                              {item.memberStatus === "1" ? (
                                <Badge bg="success">Confirmed</Badge>
                              ) : item.memberStatus === "0" ? (
                                <Badge bg="warning" text="dark">
                                  {" "}
                                  Payment Pending
                                </Badge>
                              ) : (
                                "-"
                              )}
                            </>
                          </td>
                          <td className="text-center">
                            <>
                              {item.partnerParticipating === "1" && item.partnerStatus === "1" ? (
                                <Badge bg="success">Confirmed</Badge>
                              ) : item.partnerParticipating === "1" && item.partnerStatus === "0" ? (
                                <Badge bg="warning" text="dark">
                                  {" "}
                                  Payment Pending
                                </Badge>
                              ) : (
                                "-"
                              )}
                            </>
                          </td>
                          <td className="text-center">
                            <>
                              {item.guestParticipating === "1" && item.guestStatus === "1" ? (
                                <Badge bg="success">Confirmed</Badge>
                              ) : item.guestParticipating === "1" && item.guestStatus === "0" ? (
                                <Badge bg="warning" text="dark">
                                  {" "}
                                  Payment Pending
                                </Badge>
                              ) : (
                                "-"
                              )}
                            </>
                          </td>
                          <td className="text-center">
                            <>
                              {item.kidsParticipating === "1" && item.kidsStatus === "1" ? (
                                <Badge bg="success">Confirmed</Badge>
                              ) : item.kidsParticipating === "1" && item.kidsStatus === "0" ? (
                                <Badge bg="warning" text="dark">
                                  {" "}
                                  Payment Pending
                                </Badge>
                              ) : (
                                "-"
                              )}
                            </>
                          </td>
                          {/* <td className="text-center"><>{((item.status === '1') ? <Badge bg="success">Confirmed</Badge> : <Badge bg="warning" text="dark">Pending</Badge>)}</></td> */}
                          <td class="font-small font-monospace ">
                            <ModuleDisplayDateTime value={item.createdAt} />
                          </td>
                        </tr>
                        {item.additionalRequests && item.additionalRequests.length > 0 && item.additionalRequests.map((request, index) => {
                                return (

                                  <tr className={index+1 === item.additionalRequests.length ? "borderBottom bg-table" : "bg-table"} >


                                
                                        <td className="text-center">
                                          <>
                                            {request.partnerParticipating === "1" ? (
                                              <Badge bg="success">
                                                Yes
                                              </Badge>
                                            ) : (
                                              <Badge
                                                bg="warning"
                                                text="dark"
                                              >
                                                No
                                              </Badge>
                                            )}
                                          </>
                                        </td>
                                      

                                 
                                        <td className="text-center">
                                          <>
                                            {request.guestParticipating === "1" ? (
                                              <Badge bg="success">
                                                Yes ({request.NumberOfGuestParticipating})
                                              </Badge>
                                            ) : (
                                              <Badge
                                                bg="warning"
                                                text="dark"
                                              >
                                                No
                                              </Badge>
                                            )}
                                          </>
                                        </td>
                                      
                                
                                        <td className="text-center">
                                          <>
                                            {request.kidsParticipating === "1" ? (
                                              <Badge bg="success">
                                                Yes ({request.NumberOfKidsParticipating})
                                              </Badge>
                                            ) : (
                                              <Badge
                                                bg="warning"
                                                text="dark"
                                              >
                                                No
                                              </Badge>
                                            )}
                                          </>
                                        </td>
                                      
                                    <td className="text-center">
                                      <>
                                        {request.memberStatus === "1" ? (
                                          <Badge bg="success">
                                            Confirmed
                                          </Badge>
                                        ) : request.memberStatus === "0" ? (
                                          <Badge
                                            bg="warning"
                                            text="dark"
                                          >
                                            Payment Pending
                                          </Badge>
                                        ) : "-"}
                                      </>
                                    </td>
                                  
                                        <td className="text-center">
                                          <>
                                            {request.partnerStatus === "1"
                                              ? (
                                                <Badge bg="success">Confirmed</Badge>
                                              )
                                              : (
                                                request.partnerParticipating === "1"
                                                  ? (
                                                    <Badge bg="warning" text="dark">Payment Pending</Badge>
                                                  ) :
                                                  "-"
                                              )
                                            }
                                          </>
                                        </td>
                                      
                                   
                                        <td className="text-center">
                                          <>
                                            {request.guestStatus === "1" ? (
                                              <Badge bg="success">Confirmed</Badge>
                                            ) : request.guestParticipating === "1" ? (
                                              <Badge bg="warning" text="dark">
                                                Payment Pending
                                              </Badge>
                                            ) : "-"}
                                          </>
                                        </td>
                                   

                                
                                        <td className="text-center">
                                          <>
                                            {request.kidsStatus === "1" ? (
                                              <Badge bg="success">Confirmed</Badge>
                                            ) : request.kidsParticipating === "1" ? (
                                              <Badge bg="warning" text="dark">
                                                Payment Pending
                                              </Badge>
                                            ) : "-"}
                                          </>
                                        </td>
                                     



                                    <td class="font-small font-monospace ">
                                      <ModuleDisplayDateTime
                                        value={request.createdAt}
                                      />
                                    </td>
                                  </tr>
                                )
                              })}
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default MemberRecentRegistrations;
