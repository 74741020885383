import React, { useState, useContext, useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DashboardLayout from "../../components/UI/layouts/DashboardLayout";

import ModuleHeader from "../../components/UI/modules/views/partials/common/ModuleHeader";
import axios from "../../config/axios";
import {
  updateReducer,
  updateInitialState,
} from "../../reducers/module/updateReducer";
import { BreadcrumbContext } from "../../contexts/BreadcrumbContext";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../config/toastNotification";
import { isEmpty } from "../../helpers/CommonHelper";
import BlockErrorMessage from "../../components/UI/errorMessages/BlockErrorMessage";
import { ContainerLoading } from "../../components/UI/loading/ContainerLoading";
import ModuleUpdateView from "../../components/UI/modules/views/ModuleUpdateView";
import FormErrorMessage from "../../components/UI/errorMessages/FormErrorMessage";

const UpdateViewHOC = (breadcrumbs) => {
  const WithUpdateViewFunctions = ({ moduleConfig }) => {
    const [updateState, updateDispatch] = useReducer(
      updateReducer,
      updateInitialState
    );
    const [formInitialValues, setFormInitialValues] = useState({});

    const { setBreadcrumb } = useContext(BreadcrumbContext);
    let { id } = useParams();
    let navigate = useNavigate();

    const onSubmit = async (data) => {
      updateDispatch({
        type: "FORM_SUBMISSION_REQUEST",
      });
     

      try {
        const res = await axios.post(`${moduleConfig.url}/${id}`, data);
        updateDispatch({
          type: "FORM_SUBMISSION_SUCCESS",
          payload: res.data,
        });
        navigate(`/${moduleConfig.url}`);
        toast.addNotification({
          ...toastNotification,
          title: "Success!",
          message: `${moduleConfig.singleItemTitle} updated successfully.`,
          type: "success",
        });
      } catch (error) {
        //console.log(error.message);
        const status = error.response ? error.response.status : 500;
        const errorMessage =
          status === 400 ? error.response.data.errorMessage : error.message;
        //console.log(errorMessage);

        updateDispatch({
          type: "FORM_SUBMISSION_FAILURE",
          payload: errorMessage,
        });
        setTimeout(() => {
          updateDispatch({
            type: "FORM_INITIAL_STATE",
          });
        }, 5000);
      }
    };

    const loadItem = async () => {
      if (id) {
        updateDispatch({
          type: "FETCH_REQUEST",
        });

        try {
          const res = await axios.get(`${moduleConfig.url}/${id}`); //
          updateDispatch({
            type: "FETCH_SUCCESS",
            payload: res.data,
          });
        } catch (error) {
          updateDispatch({
            type: "FETCH_FAILURE",
            payload: error,
          });
        }
      }
    };
    useEffect(() => {
      //console.log("useEffect called for [id]")
      loadItem();
      breadcrumbs.details = {
        label: "Details",
        url: `/${moduleConfig.url}/${id}`,
      };
      setBreadcrumb([
        breadcrumbs.listing,
        breadcrumbs.details,
        breadcrumbs.update,
      ]);
    }, [id]);
    useEffect(() => {
      if (updateState.data.item && updateState.data.item._id) {
        if (moduleConfig.fields && moduleConfig.fields.length > 0) {
          let formFieldsWithFetchData = {};
          moduleConfig.fields.map((field, index) => {
            formFieldsWithFetchData[field.fieldName] =
              updateState.data.item[field.fieldName];
          });
          setFormInitialValues(formFieldsWithFetchData);
        }
      }
    }, [updateState.data]);
    return (
      <DashboardLayout>
        {updateState.isFetching && <ContainerLoading />}
        {updateState.hasFetchingError === true ? (
          <BlockErrorMessage error={updateState.error} />
        ) : (
          <>



            <ModuleHeader
              moduleTitle={`Update ${moduleConfig.singleItemTitle}`}
              moduleUrl={moduleConfig.url}
            ></ModuleHeader>

            {updateState.hasSubmissionError === true && (
              <FormErrorMessage error={updateState.error} />
            )}


            {Object.keys(formInitialValues).length > 0 &&
              updateState.data &&
              updateState.data.item &&
              !isEmpty(updateState.data.item._id) && (
                <ModuleUpdateView
                  fields={moduleConfig.fields}
                  initialValues={formInitialValues}
                  validationSchema={moduleConfig.validationSchema}
                  onSubmit={onSubmit}
                  formSubmissionError={updateState.error}
                  loading={updateState}
                />
              )}
          </>
        )
        }
      </DashboardLayout >
    );
  };
  return WithUpdateViewFunctions;
};

export default UpdateViewHOC;
