import React, { useState, useEffect } from "react";
import { Alert, Badge, Button, Card, Table } from "react-bootstrap";
import axios from "../../../../../config/axios";
import ModuleDisplayBoolean from "../../../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import CommentItem from "../../../../UI/shared/recentComments/CommentItem";

const ActivityRecentRegistrations = ({ activityId, limit }) => {
  const [state, setState] = useState({
    isLoading: true,
    status: "fetching", //fetching, fetched, fetchFailed
    error: "",
    data: {},
  });

  const loadData = async () => {
    try {
      const res = await axios.get(
        `activity-registrations/${activityId}?limit=5`
      ); //
      //console.log(res);
      setState({
        isLoading: false,
        status: "fetched", //fetching, fetched, fetchFailed
        error: "",
        data: res.data,
      });
    } catch (error) {
      setState({
        isLoading: false,
        status: "fetchFailed", //fetching, fetched, fetchFailed
        error: error,
        data: {},
      });
    }
  };

  useEffect(() => {
    if (activityId) {
      loadData();
    }
  }, [activityId]);

  return (
    <div className="activity-recent-registrations">
      <Card className="card-primary mb-0">
        <Card.Header className="cardHead">
          <Card.Title>
            Recent Registration(s){" "}
            {state.data.pagination && state.data.pagination.totalItems
              ? "- Total " + state.data.pagination.totalItems + " item(s)"
              : null}
          </Card.Title>

          <div class="card-tools mt-0">
            <Button
              variant="primary"
              size="xs"
              href={`/activity-registrations/${activityId}`}
            >
              View More
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="m-0 p-0">
          {state.status === "fetchFailed" && state.error ? (
            <Alert variant="danger" className="m-0">
              <b>Error!</b> Fetching items failed.
            </Alert>
          ) : (
            <>
              {!state.data.items || state.data.items.length <= 0 ? (
                <Alert variant="warning" className="m-0">
                  No items found .
                </Alert>
              ) : (
                <>
                  <div className="registrationTable ">
                    <Table  bordered  size="sm" className="m-0 p-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Member Name</th>
                          {state.data.activity &&
                            state.data.activity.partnerAllowed === "1" && (
                              <th className="text-center">Partner Participating</th>
                            )}



                          {state.data.activity &&
                            state.data.activity.guestAllowed === "1" && (
                              <th className="text-center">No. of Guests Participating</th>
                            )}
 {state.data.activity &&
                            state.data.activity.kidsAllowed === "1" && (
                              <th className="text-center">No. of kids Participating</th>
                            )}


                          <th>Member Status</th>
                          {state.data.activity &&
                            state.data.activity.partnerAllowed === "1" &&
                            <th>Partner Status</th>}
                          {state.data.activity &&
                            state.data.activity.guestAllowed === "1" &&
                            <th>Guest Status</th>}
                             {state.data.activity &&
                            state.data.activity.kidsAllowed === "1" &&
                            <th>kids Status</th>}
                          {/* <th>Status</th> */}
                          <th>Registration Date/Time</th>
                        </tr>
                      </thead>

                      <tbody>
                        {state.data.items.map((item, index) => {
                          return (
                            <>
                              <tr index={index} className={item.additionalRequests && item.additionalRequests.length<=0 && "borderBottom"} >
                                <td rowSpan={item.additionalRequests && item.additionalRequests.length>0 && item.additionalRequests.length+1}>{index + 1}</td>
                                <td rowSpan={item.additionalRequests && item.additionalRequests.length>0 && item.additionalRequests.length+1}>
                                  <Button
                                    href={`/members/${item.memberId}`}
                                    variant="link"
                                  >
                                    {item.member.fullName
                                      ? item.member.fullName
                                      : "-"}
                                  </Button>
                                </td>

                                {state.data.activity &&
                                  state.data.activity.partnerAllowed === "1" && (
                                    <td className="text-center">
                                      <>
                                        {item.partnerParticipating === "1" ? (
                                          <Badge bg="success">Yes</Badge>
                                        ) : (
                                          <Badge bg="warning" text="dark">
                                            No
                                          </Badge>
                                        )}
                                      </>
                                    </td>
                                  )}
                                {state.data.activity &&
                                  state.data.activity.guestAllowed === "1" && (
                                    <td className="text-center">
                                      <>
                                        {item.guestParticipating === "1" ? (
                                          <Badge bg="success">
                                            Yes ({item.NumberOfGuestParticipating})
                                          </Badge>
                                        ) : (
                                          <Badge bg="warning" text="dark">
                                            No
                                          </Badge>
                                        )}
                                      </>
                                    </td>
                                  )}
                                   {state.data.activity &&
                                  state.data.activity.kidsAllowed === "1" && (
                                    <td className="text-center">
                                      <>
                                        {item.kidsParticipating === "1" ? (
                                          <Badge bg="success">
                                            Yes ({item.NumberOfKidsParticipating})
                                          </Badge>
                                        ) : (
                                          <Badge bg="warning" text="dark">
                                            No
                                          </Badge>
                                        )}
                                      </>
                                    </td>
                                  )}
                                <td className="text-center">


                                  {item.memberStatus === "1" ? (
                                    <Badge bg="success">Confirmed</Badge>
                                  ) : item.memberStatus === "0" ? (
                                    <Badge bg="warning" text="dark">
                                      Payment Pending
                                    </Badge>
                                  ) : "-"}

                                </td>
                                {state.data.activity &&
                                  state.data.activity.partnerAllowed === "1" &&
                                  <td className="text-center">

                                    {item.partnerStatus === "1"
                                      ? (
                                        <Badge bg="success">Confirmed</Badge>
                                      )
                                      : (
                                        item.partnerParticipating === "1"
                                          ? (
                                            <Badge bg="warning" text="dark">Payment Pending</Badge>
                                          ) :
                                          "-"
                                      )
                                    }

                                  </td>}
                                {state.data.activity &&
                                  state.data.activity.guestAllowed === "1" &&
                                  <td className="text-center">

                                    {item.guestStatus === "1" ? (
                                      <Badge bg="success">Confirmed</Badge>
                                    ) : item.guestParticipating === "1" ? (
                                      <Badge bg="warning" text="dark">
                                        Payment Pending
                                      </Badge>
                                    ) : "-"}
                                  </td>}
                                  {state.data.activity &&
                                  state.data.activity.kidsAllowed === "1" &&
                                  <td className="text-center">

                                    {item.kidsStatus === "1" ? (
                                      <Badge bg="success">Confirmed</Badge>
                                    ) : item.kidsParticipating === "1" ? (
                                      <Badge bg="warning" text="dark">
                                        Payment Pending
                                      </Badge>
                                    ) : "-"}
                                  </td>}
                                {/* <td className="text-center">
                                <>
                                  {item.status === "1" ? (
                                    <Badge bg="success">Confirmed</Badge>
                                  ) : (
                                    <Badge bg="warning" text="dark">
                                      Pending
                                    </Badge>
                                  )}
                                </>
                              </td> */}
                               <td class="font-small font-monospace text-secondary">
                                  <ModuleDisplayDateTime value={item.createdAt} />
                                </td>
                              </tr>
                              {item.additionalRequests && item.additionalRequests.length > 0 && item.additionalRequests.map((request, index) => {
                                return (

                                  <tr className={`bg-table ${index+1 === item.additionalRequests.length ?"borderBottom":""} `}>


                                    {state.data.activity &&
                                      state.data.activity
                                        .partnerAllowed === "1" && (
                                        <td className="text-center">
                                          <>
                                            {request.partnerParticipating === "1" ? (
                                              <Badge bg="success">
                                                Yes
                                              </Badge>
                                            ) : (
                                              <Badge
                                                bg="warning"
                                                text="dark"
                                              >
                                                No
                                              </Badge>
                                            )}
                                          </>
                                        </td>
                                      )}

                                    {state.data.activity &&
                                      state.data.activity
                                        .guestAllowed === "1" && (
                                        <td className="text-center">
                                          <>
                                            {request.guestParticipating === "1" ? (
                                              <Badge bg="success">
                                                Yes ({request.NumberOfGuestParticipating})
                                              </Badge>
                                            ) : (
                                              <Badge
                                                bg="warning"
                                                text="dark"
                                              >
                                                No
                                              </Badge>
                                            )}
                                          </>
                                        </td>
                                      )}
                                    {state.data.activity &&
                                      state.data.activity
                                        .kidsAllowed === "1" && (
                                        <td className="text-center">
                                          <>
                                            {request.kidsParticipating === "1" ? (
                                              <Badge bg="success">
                                                Yes ({request.NumberOfKidsParticipating})
                                              </Badge>
                                            ) : (
                                              <Badge
                                                bg="warning"
                                                text="dark"
                                              >
                                                No
                                              </Badge>
                                            )}
                                          </>
                                        </td>
                                      )}
                                    <td className="text-center">
                                      <>
                                        {request.memberStatus === "1" ? (
                                          <Badge bg="success">
                                            Confirmed
                                          </Badge>
                                        ) : request.memberStatus === "0" ? (
                                          <Badge
                                            bg="warning"
                                            text="dark"
                                          >
                                            Payment Pending
                                          </Badge>
                                        ) : "-"}
                                      </>
                                    </td>
                                    {state.data.activity &&
                                      state.data.activity
                                        .partnerAllowed === "1" && (
                                        <td className="text-center">
                                          <>
                                            {request.partnerStatus === "1"
                                              ? (
                                                <Badge bg="success">Confirmed</Badge>
                                              )
                                              : (
                                                request.partnerParticipating === "1"
                                                  ? (
                                                    <Badge bg="warning" text="dark">Payment Pending</Badge>
                                                  ) :
                                                  "-"
                                              )
                                            }
                                          </>
                                        </td>
                                      )}
                                    {state.data.activity &&
                                      state.data.activity
                                        .guestAllowed === "1" && (
                                        <td className="text-center">
                                          <>
                                            {request.guestStatus === "1" ? (
                                              <Badge bg="success">Confirmed</Badge>
                                            ) : request.guestParticipating === "1" ? (
                                              <Badge bg="warning" text="dark">
                                                Payment Pending
                                              </Badge>
                                            ) : "-"}
                                          </>
                                        </td>
                                      )}

                                    {state.data.activity &&
                                      state.data.activity
                                        .kidsAllowed === "1" && (
                                        <td className="text-center">
                                          <>
                                            {request.kidsStatus === "1" ? (
                                              <Badge bg="success">Confirmed</Badge>
                                            ) : request.kidsParticipating === "1" ? (
                                              <Badge bg="warning" text="dark">
                                                Payment Pending
                                              </Badge>
                                            ) : "-"}
                                          </>
                                        </td>
                                      )}



                                    <td class="font-small font-monospace text-secondary">
                                      <ModuleDisplayDateTime
                                        value={request.createdAt}
                                      />
                                    </td>
                                  </tr>
                                )
                              })}


                            </>
                          );
                        })}
                      </tbody>
                    </Table>
                  </div>
                </>
              )}
            </>
          )}
        </Card.Body>

        {state.isLoading && (
          <div class="overlay">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>
        )}
      </Card>
    </div>
  );
};

export default ActivityRecentRegistrations;
