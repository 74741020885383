import React, { useEffect, useReducer } from "react";
import {
  Badge,
  Alert,
  ButtonToolbar,
  OverlayTrigger,
  Button,
  Popover,
  Stack,
  CloseButton,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import ModuleDisplayBoolean from "../../modules/fieldsDisplay/ModuleDisplayBoolean";
import ModuleDisplayEmail from "../../modules/fieldsDisplay/ModuleDisplayEmail";
import ModuleDisplayPhone from "../../modules/fieldsDisplay/ModuleDisplayPhone";
import ModuleDisplayURL from "../../modules/fieldsDisplay/ModuleDisplayURL";
import { Store as toast } from "react-notifications-component";
import { FaQuestion } from "react-icons/fa6";
import {
  createInitialState,
  createReducer,
} from "../../../modules/memberCars/reducers/createReducer";
import axios from "../../../../config/axios";
import { toastNotification } from "../../../../config/toastNotification";
import {
  listingInitialState,
  listingReducer,
} from "../../../modules/memberCars/reducers/listingReducer";
export default function StatsListing(props) {
  const { heading, items, clickable, border, style, highlight } = props;
  const colors = ["teal", "pink", "info", "green", "purple", "yellow", "dark"];
  const [show, setShow] = React.useState(false);
  const handleToggle = () => {
    setShow((prev) => !prev);
  };
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const loadItems = async (value) => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.post(`lookups/has-value`, {
        lookupName: "Ferrari Car Colors",
        value: value,
      });
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
     
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    if (items) {
      items.map((item, index) => {
        if (item.text === "Other Color") {
          loadItems(item.value);
        }
      });
    }
  }, [items]);
  const [addState, addDispatch] = useReducer(createReducer, createInitialState);
  const onSubmit = async (data) => {
    addDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
   // console.log(data);
    try {
      const res = await axios.post(`lookups/quick-add `, data); //
      //console.log(res);

      addDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      if (items) {
        items.map((item, index) => {
          if (item.text === "Other Color") {
            loadItems(item.value);
          }
        });
      }
      toast.addNotification({
        ...toastNotification,
        model: "Success!",
        message: `added successfully.`,
        type: "success",
      });
    } catch (error) {
     // console.log(error.response);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.message : error.message;

      addDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        addDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };
  const popoverHoverFocus = (value) => {
    return (
      <Popover id="popover-basic">
        <Popover.Header>
          <Stack
            as="Stack"
            direction="horizontal"
            className="align-items-center"
          >
            <strong className="flex-grow-1 mb-0">New Color</strong>
            <CloseButton onClick={handleToggle} />
          </Stack>
        </Popover.Header>
        <Popover.Body>
          It seems we don't have this color in our current list. Would you
          like to include it? <br />
          <Button
            className="py-0 px-1 mt-2 font-small"
            variant="info"
            onClick={() =>
              onSubmit({
                lookupName: "Ferrari Car Colors",
                value: value,
              })
            }
          >
            Add to List
          </Button>
        </Popover.Body>
      </Popover>
    );
  };

  return (
    <div className={`mb-0 details ${border}`}>
      {heading && (
        <span class="badge headerText badge-light  text-dark  p-2  font-small d-flex">
          {heading}
        </span>
      )}
      {clickable ? (
        <>
          {items.map((item, index) => {
            return (
              <>
                {item.click === false ? (
                  <div
                    title={item.title}
                    class="badge badge-light me-1 ms-1 mt-1 text-dark text-decoration-none p-2 font-small removeCursor"
                  >
                    {item.title} :&nbsp;
                    <Badge bg={colors[index]} className="ms-2" pill>
                      {item.value}
                    </Badge>
                  </div>
                ) : (
                  <Link
                    title={item.title}
                    to={item.url}
                    class="badge badge-light me-1 ms-1 mt-1 text-dark text-decoration-none p-2 font-small"
                  >
                    {item.title} :&nbsp;
                    <Badge bg={colors[index]} className="ms-2" pill>
                      {item.value}
                    </Badge>
                  </Link>
                )}
              </>
            );
          })}
        </>
      ) : (
        items.map((item, index) => {
          return (
            <>
              {item.hobby ? (
                <Badge
                  bg="light"
                  className=" ms-1 text-dark mt-1 mb-1 p-2 font-small d-flex"
                >
                  <span class="text-secondary font-small">{item.hobby}</span>
                </Badge>
              ) : (
                <Badge
                  bg={item.highlight === true ? "yellow" : "light"}
                  className=" me-1 text-dark mt-1 mb-1 p-2 font-small d-flex"
                >
                  {item.text}
                  {item.text ? <>{"\t"}:</> : null}

                  {item.type === "boolean" ? (
                    <span class="text-secondary font-small ms-2">
                      <ModuleDisplayBoolean value={item.value} />
                    </span>
                  ) : item.type === "text" ? (
                    <span class="text-secondary font-small ms-2 d-flex">
                      {item.value}
                      {item.text === "Other Color" &&
                        listingState &&
                        listingState.data &&
                        listingState.data.isAvailable === false && (
                          <ButtonToolbar style={{ padding: "0px 0" }}>
                            <OverlayTrigger
                              trigger="click"
                              placement="top"
                              overlay={popoverHoverFocus(item.value)}
                              show={show}
                              onToggle={handleToggle}
                            >
                              <Button
                                className="invisibleButton"
                                title="Add new value"
                              >
                                <FaQuestion className="text-info font-small" />
                              </Button>
                            </OverlayTrigger>
                          </ButtonToolbar>
                        )}
                    </span>
                  ) : item.type === "email" ? (
                    <span class="text-secondary font-small ms-2">
                      <ModuleDisplayEmail value={item.value} />
                    </span>
                  ) : item.type === "phone" ? (
                    <span class="text-secondary font-small ms-2">
                      <ModuleDisplayPhone value={item.value} />
                    </span>
                  ) : item.type === "link" ? (
                    <span class="text-secondary font-small ms-2">
                      <ModuleDisplayURL value={item.value} />
                    </span>
                  ) : (
                    <Badge bg={colors[index]} className="ms-2" pill>
                      {item.value}
                    </Badge>
                  )}
                </Badge>
              )}
            </>
          );
        })
      )}
    </div>
  );
}
