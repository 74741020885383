import React, { useState, useReducer, useEffect, useContext } from "react";
import {
  Alert,
  Card,
  Col,
  Row,
  Table,
  Badge,
  Image,
  Button,
  Dropdown,
} from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import queryString from "query-string";
import moduleConfig from "./moduleConfig";
import axios from "../../../config/axios";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import useConfirm from "../../../HOC/custom/useConfirm";
import ListingFooter from "./partials/listing/ListingFooter";
import SearchArea from "./partials/listing/SearchArea";
import { listingReducer, listingInitialState } from "./reducers/listingReducer";
import ModuleDisplayBoolean from "../../UI/modules/fieldsDisplay/ModuleDisplayBoolean";
import ModuleDisplayDateTime from "../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";

const customButtonsForItems = [
  // {
  //   label: "Change Password",
  //   className: "fas fa-key",
  //   url: "change-password"
  // }
];

const ActivityRegistrationsListing = () => {
  let { id } = useParams();

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const [isCheck, setIsCheck] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);

  const { search } = useLocation();
  const [appliedFilters, setAppliedFilters] = useState(
    queryString.parse(search)
  );
  if (appliedFilters.limit) {
    moduleConfig.perPageItems = appliedFilters.limit;
  }

  let breadcrumbs = {
    activityListing: {
      label: "Activities",
      url: `/activities`,
    },
    activityDetails: {
      label: "Details",
      url: `/activities/${id}`,
    },
    listing: {
      label: moduleConfig.title,
      url: `/${moduleConfig.url}`,
    },
  };

  let history = useNavigate();

  const setFilter = (name, value) => {
    console.log(`setFilter called with ${name}, ${value}`);
    let newAppliedFilters = JSON.parse(JSON.stringify(appliedFilters));

    if (value) {
      newAppliedFilters[name] = value;
    } else {
      delete newAppliedFilters[name];
    }

    if (name !== "page") {
      delete newAppliedFilters["page"];
    }

    if (name === "limit" || name === "page") {
      setIsCheck([]);
      setIsCheckAll(false);
    }

    let newURL = queryString.stringify(newAppliedFilters);
    history({ search: newURL });
    setAppliedFilters(newAppliedFilters);
  };

  const loadItems = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.get(`${moduleConfig.url}/${id}${search}`); //
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };

  const resetFilters = () => {
    //console.log(`resetFilters called`)
    let newAppliedFilters = JSON.parse(JSON.stringify(appliedFilters));

    moduleConfig.perPageItems = 10;
    delete newAppliedFilters["limit"];
    delete newAppliedFilters["status"];
    delete newAppliedFilters["q"];

    let newURL = queryString.stringify(newAppliedFilters);
    history({ search: newURL });
    //console.log(newAppliedFilters);
    setAppliedFilters(newAppliedFilters);
  };

  useEffect(() => {
    loadItems();
  }, [search]);

  useEffect(() => {
    setBreadcrumb([
      breadcrumbs.activityListing,
      breadcrumbs.activityDetails,
      breadcrumbs.listing,
    ]);
  }, []);

  const handleSelectAll = () => {
    setIsCheckAll(!isCheckAll);

    if (listingState.data.items && listingState.data.items.length) {
      setIsCheck(listingState.data.items.map((li) => li._id));
    }

    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleCheckBoxClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
    setIsCheckAll(false);
  };

  const download = (type) => {
    console.log("Download Click");

    axios
      .post(
        `/activity-registrations/download/${id}`,
        {
          type,
          ids: isCheck,
        }
        // { responseType: 'blob' }
      )
      .then(function (response) {
        console.log(response);

        let blob = new Blob([response.data], { type: "text/csv" }),
          downloadUrl = window.URL.createObjectURL(blob),
          filename = "registrations.csv";
        //  disposition = response.headers["content-disposition"];

        // if (disposition && disposition.indexOf("attachment") !== -1) {
        //   let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
        //     matches = filenameRegex.exec(disposition);

        //   if (matches != null && matches[1]) {
        //     filename = matches[1].replace(/['"]/g, "");
        //   }
        // }

        let a = document.createElement("a");
        if (typeof a.download === "undefined") {
          window.location.href = downloadUrl;
        } else {
          a.href = downloadUrl;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
        }
      });
  };

  return (
    <DashboardLayout>
      {listingState.isLoading && <ContainerLoading />}

      {listingState.status !== "fetching" && (
        <>
          {listingState.error && listingState.status === "fetchFailed" ? (
            <BlockErrorMessage error={listingState.error} />
          ) : (
            <>
              <ModuleHeader
                moduleTitle={moduleConfig.title}
                moduleUrl={moduleConfig.url}
              >
                {/* <Button variant="primary" onClick={() => download()}>Download</Button> */}

                <div className="moduleHeaderTopButtons">
                  {listingState.data &&
                    listingState.data.pagination &&
                    listingState.data.pagination.totalItems > 0 && (
                      <Dropdown className="">
                        <Dropdown.Toggle
                          variant="primary"
                          className="border"
                          id="dropdown-basic"
                        >
                          <i className="fa fa-download"> </i> Download &nbsp;
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => download("all")}>
                            All Items
                          </Dropdown.Item>
                          <Dropdown.Item
                            disabled={
                              isCheck && isCheck.length > 0 ? false : true
                            }
                            onClick={() => download("selected")}
                          >
                            Selected Items
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                </div>
              </ModuleHeader>

              <div className="listingPage">
                {listingState.data &&
                  listingState.data.pagination &&
                  listingState.data.pagination.totalItems > 0 ? (
                  <>
                    {/* Search Area */}
                    <SearchArea
                      moduleConfig={moduleConfig}
                      setFilter={setFilter}
                      appliedFilters={appliedFilters}
                      resetFilters={resetFilters}
                    />

                    {/* Listing */}
                    <section className="content listing">
                      {listingState.error && (
                        <FormErrorMessage error={listingState.error} />
                      )}

                      {!listingState.data.items ||
                        listingState.data.items.length <= 0 ? (
                        <Alert variant="warning">
                          No records found.{" "}
                          <Alert.Link href="#" onClick={() => resetFilters()}>
                            <i className="fas fa-times" /> Reset Filters
                          </Alert.Link>
                          .
                        </Alert>
                      ) : (
                        <>
                          {listingState.data.items &&
                            listingState.data.items.length && (
                              <>
                                <div className="registrationTable m-0 p-0 mt-2">
                                  <Table bordered size="sm">
                                    <thead>
                                      <tr>
                                        <th>
                                          <input
                                            className="selectAll"
                                            type="checkbox"
                                            name="selectAll"
                                            id="selectAll"
                                            onChange={handleSelectAll}
                                            checked={isCheckAll}
                                          />
                                        </th>
                                        <th>#</th>
                                        <th>Member Name</th>

                                        {listingState.data.activity &&
                                          listingState.data.activity
                                            .partnerAllowed === "1" && (
                                            <th className="text-center">Partner Participating</th>
                                          )}

                                        {listingState.data.activity &&
                                          listingState.data.activity
                                            .guestAllowed === "1" && (
                                            <th className="text-center">No. of Guests Participating</th>
                                          )}

                                        {listingState.data.activity &&
                                          listingState.data.activity
                                            .kidsAllowed === "1" && (
                                            <th className="text-center">No. of Kids Participating</th>
                                          )}

                                        <th className="text-center">Member Status</th>
                                        {listingState.data.activity &&
                                          listingState.data.activity
                                            .partnerAllowed === "1" && (
                                            <th>Partner Status</th>
                                          )}
                                        {listingState.data.activity &&
                                          listingState.data.activity
                                            .guestAllowed === "1" && (
                                            <th className="text-center">Guest(s) Status</th>
                                          )}

                                        {listingState.data.activity &&
                                          listingState.data.activity
                                            .kidsAllowed === "1" && (
                                            <th className="text-center">Kid(s) Status</th>
                                          )}

                                        {/* <th>Status</th> */}
                                        <th>Registration Date/Time</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {listingState.data.items.map(
                                        (item, index) => {
                                          return (
                                            <>
                                              <tr index={index} className={item.additionalRequests && item.additionalRequests.length<=0 && "borderBottom"} >
                                                <td rowSpan={item.additionalRequests && item.additionalRequests.length>0 && item.additionalRequests.length+1}>
                                                  <input
                                                    type="checkbox"
                                                    className="selectAll"
                                                    id={item._id}
                                                    name={item._id}
                                                    onChange={handleCheckBoxClick}
                                                    checked={isCheck.includes(
                                                      item._id
                                                    )}
                                                  />
                                                </td>
                                                <td rowSpan={item.additionalRequests && item.additionalRequests.length>0 && item.additionalRequests.length+1}>{index + 1}</td>
                                                <td rowSpan={item.additionalRequests && item.additionalRequests.length>0 && item.additionalRequests.length+1}>
                                                  <Button
                                                    href={`/members/${item.memberId}`}
                                                    variant="link"
                                                  >
                                                    {item.member.fullName
                                                      ? item.member.fullName
                                                      : "-"}
                                                  </Button>
                                                </td>

                                                {listingState.data.activity &&
                                                  listingState.data.activity
                                                    .partnerAllowed === "1" && (
                                                    <td className="text-center">
                                                      <>
                                                        {item.partnerParticipating === "1" ? (
                                                          <Badge bg="success">
                                                            Yes
                                                          </Badge>
                                                        ) : (
                                                          <Badge
                                                            bg="warning"
                                                            text="dark"
                                                          >
                                                            No
                                                          </Badge>
                                                        )}
                                                      </>
                                                    </td>
                                                  )}
                                                {listingState.data.activity &&
                                                  listingState.data.activity
                                                    .guestAllowed === "1" && (
                                                    <td className="text-center">
                                                      <>
                                                        {item.guestParticipating === "1" ? (
                                                          <Badge bg="success">
                                                            Yes ({item.NumberOfGuestParticipating})
                                                          </Badge>
                                                        ) : (
                                                          <Badge
                                                            bg="warning"
                                                            text="dark"
                                                          >
                                                            No
                                                          </Badge>
                                                        )}
                                                      </>
                                                    </td>
                                                  )}
                                                {listingState.data.activity &&
                                                  listingState.data.activity
                                                    .kidsAllowed === "1" && (
                                                    <td className="text-center">
                                                      <>
                                                        {item.kidsParticipating === "1" ? (
                                                          <Badge bg="success">
                                                            Yes ({item.NumberOfKidsParticipating})
                                                          </Badge>
                                                        ) : (
                                                          <Badge
                                                            bg="warning"
                                                            text="dark"
                                                          >
                                                            No
                                                          </Badge>
                                                        )}
                                                      </>
                                                    </td>
                                                  )}
                                                <td className="text-center">
                                                  <>
                                                    {item.memberStatus === "1" ? (
                                                      <Badge bg="success">
                                                        Confirmed
                                                      </Badge>
                                                    ) : item.memberStatus === "0" ? (
                                                      <Badge
                                                        bg="warning"
                                                        text="dark"
                                                      >
                                                        Payment Pending
                                                      </Badge>
                                                    ) : "-"}
                                                  </>
                                                </td>
                                                {listingState.data.activity &&
                                                  listingState.data.activity
                                                    .partnerAllowed === "1" && (
                                                    <td className="text-center">
                                                      <>
                                                        {item.partnerStatus === "1"
                                                          ? (
                                                            <Badge bg="success">Confirmed</Badge>
                                                          )
                                                          : (
                                                            item.partnerParticipating === "1"
                                                              ? (
                                                                <Badge bg="warning" text="dark">Payment Pending</Badge>
                                                              ) :
                                                              "-"
                                                          )
                                                        }
                                                      </>
                                                    </td>
                                                  )}
                                                {listingState.data.activity &&
                                                  listingState.data.activity
                                                    .guestAllowed === "1" && (
                                                    <td className="text-center">
                                                      <>
                                                        {item.guestStatus === "1" ? (
                                                          <Badge bg="success">Confirmed</Badge>
                                                        ) : item.guestParticipating === "1" ? (
                                                          <Badge bg="warning" text="dark">
                                                            Payment Pending
                                                          </Badge>
                                                        ) : "-"}
                                                      </>
                                                    </td>
                                                  )}

                                                {listingState.data.activity &&
                                                  listingState.data.activity
                                                    .kidsAllowed === "1" && (
                                                    <td className="text-center">
                                                      <>
                                                        {item.kidsStatus === "1" ? (
                                                          <Badge bg="success">Confirmed</Badge>
                                                        ) : item.kidsParticipating === "1" ? (
                                                          <Badge bg="warning" text="dark">
                                                            Payment Pending
                                                          </Badge>
                                                        ) : "-"}
                                                      </>
                                                    </td>
                                                  )}



                                                <td class="font-small font-monospace text-secondary">
                                                  <ModuleDisplayDateTime
                                                    value={item.createdAt}
                                                  />
                                                </td>
                                              </tr>

                                              {item.additionalRequests && item.additionalRequests.length > 0 && item.additionalRequests.map((request, index) => {
                                                return (

                                                  <tr className={`bg-table ${index+1 === item.additionalRequests.length ?"borderBottom":""} `}>
                                                   

                                                    {listingState.data.activity &&
                                                      listingState.data.activity
                                                        .partnerAllowed === "1" && (
                                                        <td className="text-center">
                                                          <>
                                                            {request.partnerParticipating === "1" ? (
                                                              <Badge bg="success">
                                                                Yes
                                                              </Badge>
                                                            ) : (
                                                              <Badge
                                                                bg="warning"
                                                                text="dark"
                                                              >
                                                                No
                                                              </Badge>
                                                            )}
                                                          </>
                                                        </td>
                                                      )}

                                                    {listingState.data.activity &&
                                                      listingState.data.activity
                                                        .guestAllowed === "1" && (
                                                        <td className="text-center">
                                                          <>
                                                            {request.guestParticipating === "1" ? (
                                                              <Badge bg="success">
                                                                Yes ({request.NumberOfGuestParticipating})
                                                              </Badge>
                                                            ) : (
                                                              <Badge
                                                                bg="warning"
                                                                text="dark"
                                                              >
                                                                No
                                                              </Badge>
                                                            )}
                                                          </>
                                                        </td>
                                                      )}
                                                    {listingState.data.activity &&
                                                      listingState.data.activity
                                                        .kidsAllowed === "1" && (
                                                        <td className="text-center">
                                                          <>
                                                            {request.kidsParticipating === "1" ? (
                                                              <Badge bg="success">
                                                                Yes ({request.NumberOfKidsParticipating})
                                                              </Badge>
                                                            ) : (
                                                              <Badge
                                                                bg="warning"
                                                                text="dark"
                                                              >
                                                                No
                                                              </Badge>
                                                            )}
                                                          </>
                                                        </td>
                                                      )}
                                                    <td className="text-center">
                                                      <>
                                                        {request.memberStatus === "1" ? (
                                                          <Badge bg="success">
                                                            Confirmed
                                                          </Badge>
                                                        ) : request.memberStatus === "0" ? (
                                                          <Badge
                                                            bg="warning"
                                                            text="dark"
                                                          >
                                                            Payment Pending
                                                          </Badge>
                                                        ) : "-"}
                                                      </>
                                                    </td>
                                                    {listingState.data.activity &&
                                                      listingState.data.activity
                                                        .partnerAllowed === "1" && (
                                                        <td className="text-center">
                                                          <>
                                                            {request.partnerStatus === "1"
                                                              ? (
                                                                <Badge bg="success">Confirmed</Badge>
                                                              )
                                                              : (
                                                                request.partnerParticipating === "1"
                                                                  ? (
                                                                    <Badge bg="warning" text="dark">Payment Pending</Badge>
                                                                  ) :
                                                                  "-"
                                                              )
                                                            }
                                                          </>
                                                        </td>
                                                      )}
                                                    {listingState.data.activity &&
                                                      listingState.data.activity
                                                        .guestAllowed === "1" && (
                                                        <td className="text-center">
                                                          <>
                                                            {request.guestStatus === "1" ? (
                                                              <Badge bg="success">Confirmed</Badge>
                                                            ) : request.guestParticipating === "1" ? (
                                                              <Badge bg="warning" text="dark">
                                                                Payment Pending
                                                              </Badge>
                                                            ) : "-"}
                                                          </>
                                                        </td>
                                                      )}

                                                    {listingState.data.activity &&
                                                      listingState.data.activity
                                                        .kidsAllowed === "1" && (
                                                        <td className="text-center">
                                                          <>
                                                            {request.kidsStatus === "1" ? (
                                                              <Badge bg="success">Confirmed</Badge>
                                                            ) : request.kidsParticipating === "1" ? (
                                                              <Badge bg="warning" text="dark">
                                                                Payment Pending
                                                              </Badge>
                                                            ) : "-"}
                                                          </>
                                                        </td>
                                                      )}



                                                    <td class="font-small font-monospace text-secondary">
                                                      <ModuleDisplayDateTime
                                                        value={request.createdAt}
                                                      />
                                                    </td>
                                                  </tr>
                                                )
                                              })}



                                            </>
                                          );
                                        }
                                      )}
                                    </tbody>
                                  </Table>
                                  {/* <Card>
                                    <Card.Header>
                                      Additional Requests
                                    </Card.Header>
                                    <Card.Body className="p-0 mb-0">
                                      <div className="registrationTable m-0 p-0 mt-2">
                                        <Table bordered hover size="sm" className="mb-0">
                                          <thead>

                                            <tr>
                                            
                                                <th>
                                                  member name
                                                </th>
                                                <th>
                                                  partner Participating
                                                </th>
                                                <th>
                                                  No of guest Participating
                                                </th>
                                                <th>
                                                  No of kids Participating
                                                </th>
                                                <th>
                                                  partner status
                                                </th>
                                                <th>
                                                  guest status
                                                </th>
                                                <th>
                                                  kids status
                                                </th>

                                            
                                            </tr>
                                          </thead>
                                        </Table>
                                      </div>
                                    </Card.Body>
                                  </Card> */}

                                </div>
                              </>
                            )}
                        </>
                      )}
                    </section>

                    <ListingFooter
                      listingState={listingState}
                      moduleConfig={moduleConfig}
                      isCheck={isCheck}
                      setFilter={setFilter}
                    />
                  </>
                ) : (
                  <Alert variant="warning">No records found.</Alert>
                )}
              </div>
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default ActivityRegistrationsListing;
