import React, { useReducer, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../components/UI/layouts/DashboardLayout";


import ModuleHeader from "../../components/UI/modules/views/partials/common/ModuleHeader";
import axios from "../../config/axios";
import { createReducer, createInitialState } from "../../reducers/module/createReducer"
import { BreadcrumbContext } from '../../contexts/BreadcrumbContext';
import { Store as toast } from 'react-notifications-component';
import { toastNotification } from '../../config/toastNotification'
import { ContainerLoading } from "../../components/UI/loading/ContainerLoading";
import ModuleCreateView from "../../components/UI/modules/views/ModuleCreateView";

const CreateViewHOC = (breadcrumbs) => {
    const WithCreateViewFunctions = ({ moduleConfig }) => {
        const [createState, createDispatch] = useReducer(createReducer, createInitialState)
        const { setBreadcrumb } = useContext(BreadcrumbContext);
        let navigate = useNavigate();
        const onSubmit = async (data) => {
            createDispatch({
                type: "FORM_SUBMISSION_REQUEST"
            });

            try {
                const res = await axios.post(`${moduleConfig.url}`, data)//
                //console.log(res);
                createDispatch({
                    type: "FORM_SUBMISSION_SUCCESS",
                    payload: res.data
                });

                navigate(`/${moduleConfig.url}`)
                toast.addNotification({
                    ...toastNotification,
                    title: "Success!",
                    message: `${moduleConfig.singleItemTitle} created successfully.`,
                    type: "success",
                });
            } catch (error) {
               // console.log("create View HOC", error.response)

                const status = error.response ? error.response.status : 500;
                const errorMessage = (status === 400) ? error.response.data.errorMessage : error.message

                createDispatch({
                    type: "FORM_SUBMISSION_FAILURE",
                    payload: errorMessage
                });
                setTimeout(() => {
                    createDispatch({
                        type: "FORM_INITIAL_STATE"
                    });
                }, 5000)
            }
        }
        useEffect(() => {
            // console.log(breadcrumbs);
            setBreadcrumb([
                breadcrumbs.listing,
                breadcrumbs.create
            ])
        }, [])
        return (<DashboardLayout >
            {/* {(createState.isSubmitting) && <ContainerLoading />} */}

            <ModuleHeader
                moduleTitle={`Create ${moduleConfig.singleItemTitle}`}
                moduleUrl={moduleConfig.url}
            >
            </ModuleHeader>

            <ModuleCreateView
                fields={moduleConfig.fields}
                initialValues={moduleConfig.initialValues}
                validationSchema={moduleConfig.validationSchema}
                onSubmit={onSubmit}
                formSubmissionError={createState.error}
                submitting={createState.isSubmitting}
            />

        </DashboardLayout >);
    }
    return WithCreateViewFunctions;
}

export default CreateViewHOC;