export const addGalleryInitialState = {
    data: {},
    isLoading: true,
    status: 'fetching',//fetched,submitting,submitted
    error: "",
};


export const addGalleryItemReducer = (state, action) => {
    switch (action.type) {
        case "FETCH_SUCCESS":
            return {
                ...state,
                isLoading: false,
                status: 'fetched',//fetching,fetched,fetchFailed,submitting,submitFailed,submitted
                data: action.payload
            };

        case "FETCH_FAILURE":
            return {
                ...state,
                isLoading: false,
                status: 'fetchFailed',//fetching,fetched,fetchFailed,submitting,submitFailed,submitted
                error: action.payload
            };

        case "FORM_SUBMISSION_REQUEST":
            return {
                ...state,
                isLoading: true,
                status: 'submitting',//fetching,fetched,fetchFailed,submitting,submitFailed,submitted
                error: ""
            };


        case "FORM_SUBMISSION_FAILED":
            // console.log(action.payload);
            return {
                ...state,
                isLoading: false,
                status: 'submitFailed',//fetching,fetched,fetchFailed,submitting,submitFailed,submitted
                error: action.payload
            };


        case "FORM_SUBMISSION_SUCCESS":
            return {
                ...state,
                isLoading: false,
                status: 'submitted',//fetching,fetched,fetchFailed,submitting,submitFailed,submitted

            };

        case "FORM_INITIAL_STAGE":
            return {

                ...state,
                isLoading: false,
                status: 'fetched',//fetching,fetched,fetchFailed,submitting,submitFailed,submitted
                error: ""
            };
        default:
            return state;
    }
}