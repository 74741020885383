import React, { useState, useContext, useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import moduleConfig from "./moduleConfig";
import { Store as toast } from "react-notifications-component";
import { updateInitialState, updateReducer } from "./reducers/updateReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import { toastNotification } from "../../../config/toastNotification";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import { isEmpty } from "../../../helpers/CommonHelper";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSRichTextEditor from "../../UI/forms/CMSRichTextEditor";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import CMSFormDate from "../../UI/forms/CMSFormDate";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";

const NewsFeedUpdate = () => {
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [formInitialValues, setFormInitialValues] = useState({});

  console.log(updateState);

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let { id } = useParams();
  let navigate = useNavigate();

  let breadcrumbs = {
    listing: {
      label: moduleConfig.title,
      url: `/${moduleConfig.url}`,
    },
    details: {
      label: "Details",
      url: `/${moduleConfig.url}/${id}`,
    },
    update: {
      label: "Update",
    },
  };

  const onSubmit = async (data) => {
    //console.log(data);

    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    try {
      const res = await axios.post(`${moduleConfig.url}/${id}`, data); //
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      navigate(`/${moduleConfig.url}`);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} updated successfully.`,
        type: "success",
      });
    } catch (error) {
      //console.log(error.message);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      console.log(errorMessage);

      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  const loadItem = async () => {
    if (id) {
      updateDispatch({
        type: "FETCH_REQUEST",
      });

      try {
        const res = await axios.get(`${moduleConfig.url}/${id}`); //
        updateDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        updateDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };
  useEffect(() => {
    //console.log("useEffect called for [id]")
    loadItem();

    setBreadcrumb([
      breadcrumbs.listing,
      breadcrumbs.details,
      breadcrumbs.update,
    ]);
  }, [id]);
  useEffect(() => {
    if (updateState.data.item && updateState.data.item._id) {
      //if (moduleConfig.fields && moduleConfig.fields.length > 0) {

      // let formFieldsWithFetchData = {}
      // moduleConfig.fields.map((field, index) => {

      //     formFieldsWithFetchData[field.fieldName] = updateState.data.item[field.fieldName]
      // })
      // console.log("Here =>>>>>>>>>>>>>>", formFieldsWithFetchData)
      setFormInitialValues(updateState.data.item);

      //};
    }
  }, [updateState.data]);
  return (
    <DashboardLayout>
      {updateState.status === "fetching" && updateState.isLoading && (
        <ContainerLoading />
      )}
      {updateState.error && updateState.status === "fetchFailed" ? (
        <BlockErrorMessage error={updateState.error} />
      ) : (
        <>
          <ModuleHeader
            moduleTitle={`Update ${moduleConfig.singleItemTitle}`}
            moduleUrl={moduleConfig.url}
          ></ModuleHeader>

          {Object.keys(formInitialValues).length > 0 &&
            updateState.data &&
            updateState.data.item &&
            !isEmpty(updateState.data.item._id) && (
              <section className="content">
                <Card>
                  <Card.Body>
                    {updateState.isLoading && (
                      <div class="overlay position-absolute containerLoading">
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                      </div>
                    )}
                    <Formik
                      initialValues={formInitialValues}
                      validationSchema={yup.object({
                        title: yup.string().required("Required").max(100),
                        date: yup.string().required("Required"),
                        shortDescription: yup
                          .string()
                          .required("Required")
                          .max(200),
                        description: yup.string(),
                        type: yup.string().required(),
                        //target: yup.string().required("Required"),
                        target: yup.string().nullable().when("type", {
                          is: (type) => type === "Private",
                          then: yup.string().required("Required"),
                        }),
                        status: yup.string().required("Required"),
                      })}
                      enableReinitialize={true}
                      validateOnChange={true}
                      validateOnBlur={true}
                      onSubmit={async (values) => {
                        // const formData = new FormData();
                        // for (let name in values) {
                        //     if (Array.isArray(values[name])) {
                        //         formData.append(name, JSON.stringify(values[name]));
                        //     } else {
                        //         formData.append(name, values[name]);
                        //     }
                        // }

                        onSubmit(values);
                      }}
                    >
                      {(form) => (
                        <Form onSubmit={form.handleSubmit}>
                          {console.log(form.errors)}
                          {updateState.error && (
                            <FormErrorMessage error={updateState.error} />
                          )}

                          <CMSFormInput
                            name="title"
                            label="Title"
                            form={form}
                            required={true}
                          />

                          <CMSFormDate
                            name="date"
                            label="Date"
                            form={form}
                            required={true}
                            value={new Date(form.values["date"])}
                            minDate={new Date()}
                            maxDate={new Date("2040-12-28")}
                            disabled={false}
                          />

                          <CMSFormInput
                            name="shortDescription"
                            label="Short Description"
                            required={true}
                            form={form}
                          />
                          <CMSRichTextEditor
                            name="description"
                            label="Description*"
                            form={form}
                          />


                          <CMSFormSelect
                            name="type"
                            label="Type"
                            required={true}
                            form={form}
                            options={[
                              { value: "All", label: "All" },
                              {
                                value: "Private",
                                label: "Private",
                              },
                              {
                                value: "Public",
                                label: "Public",
                              },
                            ]}
                            onChange={(e) => {
                              form.setFieldValue('type', e.target.value)
                              if (e.target.value && (e.target.value === "All" || e.target.value === "Public")) {
                                form.setFieldValue('target', null)
                              } else {
                                if (form.values['target'] === null) {
                                  form.setFieldValue('target', 'All-Members')
                                }
                              }
                            }}
                          />


                          {(form.values['type'] === 'Private') && (
                            <CMSFormSelect
                              name="target"
                              label="Target"
                              required={(form.values['type'] === 'All' || form.values['type'] === 'Private')}
                              form={form}
                              options={[
                                { value: "All-Members", label: "All-Members" },
                                {
                                  value: "Selected-Members",
                                  label: "Selected-Members",
                                },
                                {
                                  value: "Selected-Circles",
                                  label: "Selected-Circles",
                                },
                              ]}
                            />
                          )}

                          <CMSFormSelect
                            name="status"
                            label="Status"
                            required={true}
                            form={form}
                            options={[
                              { label: "Select Status...", value: "" },
                              { label: "Enabled", value: "1" },
                              { label: "Disabled", value: "0" },
                            ]}
                          />

                          <button className="btn btn-primary" type="submit">
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </section>
            )}
        </>
      )}
    </DashboardLayout>
  );
};
export default NewsFeedUpdate;
