import React, { useReducer, useEffect } from "react";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { Button, Modal } from "react-bootstrap";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../../config/toastNotification";
import BlockSuccessMessage from "../../errorMessages/BlockSuccessMessage";
import FormErrorMessage from "../../errorMessages/FormErrorMessage";
import axios from "../../../../config/axios";
import { initialState, manageCirclesReducer } from "./manageCirclesReducer";
import BlockErrorMessage from "../../errorMessages/BlockErrorMessage";
import CMSFormMultiSelect from "../../forms/CMSFormMultiSelect";
const AddCircles = ({ show, setShow, parentId, loadData, moduleConfig }) => {
  const [state, dispatch] = useReducer(manageCirclesReducer, initialState);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const loadItems = async () => {
    if (parentId) {
      try {
        const res = await axios.get(
          `${moduleConfig.url}/circles-list-for-target/${parentId}`
        ); //
        dispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
        //  console.log(res);
      } catch (error) {
        dispatch({
          type: "FETCH_FAILURE",
          payload: "Members loading failed.",
        });
      }
    }
  };

  useEffect(() => {
    if (show) {
     // console.log("useEffect called");
      loadItems();
    }
  }, [show]);

  const onSubmit = async (data, resetForm) => {
    dispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(
        `${moduleConfig.url}/add-circles-in-target/${parentId}`,
        data
      ); //
      // dispatch({
      //     type: "FORM_SUBMISSION_SUCCESS",
      //     payload: res.data
      // });
      loadData();
      resetForm();
      handleClose();
      dispatch({
        type: "FORM_INITIAL_STAGE",
      });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Circles added successfully.`,
        type: "success",
      });
    } catch (error) {
      dispatch({
        type: "FORM_SUBMISSION_FAILED",
        payload: error,
      });
      setTimeout(() => {
        dispatch({
          type: "FORM_INITIAL_STAGE",
        });
      }, 5000);
    }
  };
  return (
    <Formik
      initialValues={{
        circleIds: [],
      }}
      validationSchema={yup.object().shape({
        circleIds: yup.array().of(yup.string()).min(1, "Select Circles"),
      })}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={async (values, { resetForm }) => {
        //console.log(values);
        onSubmit(values, resetForm);
      }}
    >
      {(form) => (
        <Modal show={show} onHide={handleClose}>
          {/* {console.log(form.errors)} */}
          {(state.isLoading) && <div class="overlay">
            <i class="fas fa-2x fa-sync-alt text-white fa-spin"></i>
          </div>}

          {state.error && state.status === "fetchFailed" ? (
            <BlockErrorMessage error={state.error} />
          ) : (
            <>
              <Form onSubmit={form.handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Circles in Activity</Modal.Title>
                </Modal.Header>
                {state.status === "submitted" ? (
                  <Modal.Body>
                    <BlockSuccessMessage
                      heading="Done!"
                      message={`Circles added to activity successfully.`}
                    />
                  </Modal.Body>
                ) : (
                  <>
                    <Modal.Body>
                      {state.error && <FormErrorMessage error={state.error} />}


                      <CMSFormMultiSelect
                        name="circleIds"
                        label="Select Circles"
                        required={true}
                        form={form}
                        options={state.data.items}
                        displayValue="name"
                      />

                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button variant="primary" type="submit">
                        Submit
                      </Button>
                    </Modal.Footer>
                  </>
                )}
              </Form>
            </>
          )}
        </Modal>
      )}
    </Formik>
  );
};

export default AddCircles;
