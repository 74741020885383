import React, { useReducer, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import moduleConfig from "./moduleConfig";
import {
  detailsReducer,
  memberDetailsInitialState,
} from "./reducers/detailsReducer";
import Details from "./partials/details/Details";
import { Button, Col, Dropdown, Row } from "react-bootstrap";
import useConfirm from "../../../HOC/custom/useConfirm";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import ActivityRecentRegistrations from "./partials/details/ActivityRecentRegistrations";
import GalleryPictures from "../../UI/shared/gallery/GalleryPictures";
import RecentComments from "../../UI/shared/recentComments/RecentComments";
import RecentLikes from "../../UI/shared/recentLikes/RecentLikes";
import { OverlayLoading } from "../../UI/loading/OverlayLoading";

let breadcrumbs = {
  listing: {
    label: moduleConfig.title,
    url: `/${moduleConfig.url}`,
  },
  details: {
    label: "Details",
  },
};

const ActivityView = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [detailsState, detailsDispatch] = useReducer(
    detailsReducer,
    memberDetailsInitialState
  );
  let { id } = useParams();
  const { confirm } = useConfirm();
  const loadItem = async () => {
    if (id) {
      detailsDispatch({
        type: "FETCH_REQUEST",
      });
      try {
        const res = await axios.get(`${moduleConfig.url}/${id}`); //
        detailsDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        detailsDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };
  useEffect(() => {
    loadItem();
    setBreadcrumb([breadcrumbs.listing, breadcrumbs.details]);
  }, [id]);

  const confirmAndDisabled = async (id) => {
    const isConfirmed = await confirm(
      `Do you really want to disable this ${moduleConfig.singleItemTitle}?`
    );

    if (isConfirmed) {
      await changeStatus(id);
    }
  };

  const confirmAndEnabled = async (id) => {
    const isConfirmed = await confirm(
      `Do you really want to enable this ${moduleConfig.singleItemTitle}?`
    );

    if (isConfirmed) {
      await changeStatus(id, true);
    }
  };

  const changeStatus = async (id, undo = false) => {
    console.log("In block Item Function");
    detailsDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.post(`${moduleConfig.url}/change-status`, {
        id,
        undo: undo === true ? "1" : "0",
      });
      loadItem();
      // detailsDispatch({
      //     type: "DELETE_SUCCESS"
      // });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} ${undo ? "restored" : "archived"
          } successfully.`,
        type: "success",
      });
    } catch (error) {
      detailsDispatch({
        type: "BLOCK_FAILURE",
        payload: error,
      });

      setTimeout(() => {
        detailsDispatch({
          type: "REMOVE_ERROR",
        });
      }, 5000);
    }
  };

  return (
    <DashboardLayout>
      {detailsState.isFetching && <OverlayLoading />}
      {detailsState.data.item && !detailsState.isFetching && (
        <>
          {detailsState.hasError ? (
            <BlockErrorMessage error={detailsState.error} />
          ) : (
            <>
              {detailsState.data.item && (
                <>
                  <ModuleHeader
                    moduleTitle={`View ${moduleConfig.singleItemTitle}`}
                    moduleUrl={moduleConfig.url}
                  ></ModuleHeader>

                  <div className="border d-flex mb-1 p-1 bg-light text-white rounded">
                    <Link
                      title="Update Item"
                      to={`/${moduleConfig.url}/update/${detailsState.data.item._id}`}
                      className="btn btn-primary  border me-1"
                    >
                      <i className="fas fa-pencil-alt text-white me-1"></i>{" "}
                      <span className="text-white">Update</span>
                    </Link>

                    {/* {(detailsState.data.item.status === "1" ||
                      detailsState.data.item.status === 1) && (
                        <Link
                          title="Archive Item"
                          className="btn btn-primary  border me-1"
                        >
                          {" "}
                          
                          <i className="fas fa-clock text-white me-1"></i>{" "}
                          <span className="text-white">Past Activity</span>
                        </Link>
                      )} */}

                    {(detailsState.data.item.status === "1" ||
                      detailsState.data.item.status === 1) && (
                        <Link
                          title="Disable Item"
                          onClick={() =>
                            confirmAndDisabled(detailsState.data.item._id)
                          }
                          className="btn btn-primary  border me-1"
                        >
                          <i className="fas fa-trash text-white me-1"></i>{" "}
                          <span className="text-white">Disable</span>
                        </Link>
                      )}

                    {(detailsState.data.item.status === "0" ||
                      detailsState.data.item.status === 0) && (
                        <Link
                          title="Enable Item"
                          onClick={() =>
                            confirmAndEnabled(detailsState.data.item._id)
                          }
                          className="btn btn-primary  border me-1"
                        >
                          <i className="fa fa-arrow-up text-white me-1"></i>{" "}
                          <span className="text-white">Enable</span>
                        </Link>
                      )}

                    <Dropdown className="">
                      <Dropdown.Toggle
                        variant="primary"
                        className="border"
                        id="dropdown-basic"
                      >
                        <i className="fa fa-cogs"> </i> Configurations &nbsp;
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item
                          href={`/${moduleConfig.url}/features-settings/${detailsState.data.item._id}`}
                        >
                          Features
                        </Dropdown.Item>
                        <Dropdown.Item
                          href={`/${moduleConfig.url}/partner-participation-settings/${detailsState.data.item._id}`}
                        >
                          Partner Participation
                        </Dropdown.Item>
                        <Dropdown.Item
                          href={`/${moduleConfig.url}/guest-participation-settings/${detailsState.data.item._id}`}
                        >
                          Guests Participation
                        </Dropdown.Item>
                        <Dropdown.Item
                          href={`/${moduleConfig.url}/kids-participation-settings/${detailsState.data.item._id}`}
                        >
                          Kids Participation
                        </Dropdown.Item>

                      </Dropdown.Menu>
                    </Dropdown>

                    {detailsState.data.item.target === "Selected-Members" && (
                      <Link
                        title="Manage Targeted Members"
                        to={`/${moduleConfig.url}/manage-members/${detailsState.data.item._id}`}
                        className="btn btn-primary  border me-1"
                      >
                        <i className="fas fa-users text-white me-1"></i>{" "}
                        <span className="text-white">
                          Manage Targeted Members
                        </span>
                      </Link>
                    )}

                    {detailsState.data.item.target === "Selected-Circles" && (
                      <Link
                        title="Manage Targeted Circles"
                        to={`/${moduleConfig.url}/manage-circles/${detailsState.data.item._id}`}
                        className="btn btn-primary  border me-1"
                      >
                        <i className="fas fa-users text-white me-1"></i>{" "}
                        <span className="text-white">
                          Manage Targeted Circles
                        </span>
                      </Link>
                    )}
                  </div>

                  <Details
                    item={detailsState.data.item}
                    moduleConfig={moduleConfig}
                  />

                  <GalleryPictures
                    moduleUrl="activity-pictures"
                    parentId={detailsState.data.item._id}
                    detailsDispatch={detailsDispatch}
                  //disabledAddPictureButton={false}
                  />

                  <Row>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <ActivityRecentRegistrations
                        activityId={detailsState.data.item._id}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-2"></Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <RecentComments
                        moduleUrl="activity-comments"
                        parentId={detailsState.data.item._id}
                      />
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                      <RecentLikes
                        moduleUrl="activity-likes"
                        parentId={detailsState.data.item._id}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default ActivityView;
