import React, { useEffect, useReducer } from "react";
import {
  Alert,
  Card,
  Col,
  Row,
  Table,
  Badge,
  Image,
  OverlayTrigger,
  Popover,
  ButtonToolbar,
  Button,
  Stack,
  CloseButton,
} from "react-bootstrap";
import ModuleDisplayDateTime from "../../../../UI/modules/fieldsDisplay/ModuleDisplayDateTime";
import StatsListing from "../../../../UI/shared/infoListing/StatsListing";
import ReactFancyBox from "react-fancybox";
import { Store as toast } from "react-notifications-component";
import { FaQuestion } from "react-icons/fa6";
import {
  createInitialState,
  createReducer,
} from "../../reducers/createReducer";
import axios from "../../../../../config/axios";
import { toastNotification } from "../../../../../config/toastNotification";
import { listingInitialState, listingReducer } from "../../reducers/listingReducer";
const Details = ({ item }) => {
  const [show, setShow] = React.useState(false);
  const [addState, addDispatch] = useReducer(createReducer, createInitialState);
  const handleToggle = () => {
    setShow((prev) => !prev);
  };
  const [listingState, listingDispatch] = useReducer(
    listingReducer,
    listingInitialState
  );
  const loadItems = async () => {
    listingDispatch({
      type: "FETCH_REQUEST",
    });

    try {
      const res = await axios.post(`lookups/has-value`, {
        lookupName: "Ferrari Car Models",
        value: item.otherModel,
      });
      listingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
      console.log(res.data, "----------->>>>>>>>>>>>>");
    } catch (error) {
      listingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };
  useEffect(() => {
    if (item) {
      loadItems();
    }

  }, [item]);
  const onSubmit = async (data) => {
    addDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    console.log(data);
    try {
      const res = await axios.post(`lookups/quick-add `, data); //
      console.log(res);

      addDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      loadItems();
      toast.addNotification({
        ...toastNotification,
        model: "Success!",
        message: `added successfully.`,
        type: "success",
      });
    } catch (error) {
      console.log(error.response);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.message : error.message;

      addDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        addDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };
  const popoverHoverFocus = (
    <Popover id="popover-basic">
      <Popover.Header>
        <Stack as="Stack" direction="horizontal" className="align-items-center">
          <strong className="flex-grow-1 mb-0">New Model</strong>
          <CloseButton onClick={handleToggle} />
        </Stack>
      </Popover.Header>
      <Popover.Body>
        It seems we don't have this model in our current list. Would you like
        to include it? <br />
        <Button
          className="py-0 px-1 mt-2 font-small"
          variant="info"
          onClick={() =>
            onSubmit({
              lookupName: "Ferrari Car Models",
              value: item.otherModel,
            })
          }
        >
          Add to List
        </Button>
      </Popover.Body>
    </Popover>
  );

  var serverUploadsUrl = process.env.REACT_APP_BACKEND_URL + "public/";
  const getItems = () => {
    let items = [];
    if (item.year) {
      items = [...items, { text: "Year", value: item.year, type: "text" }];
    }
    if (item.color) {
      items = [...items, { text: "Color", value: item.color, type: "text" }];
    }
    if (item.color === "Other" && item.otherColor) {
      items = [
        ...items,
        { text: "Other Color", value: item.otherColor, type: "text" },
      ];
    }
    if (item.chassisNumber) {
      items = [
        ...items,
        {
          text: "Chassis Number",
          value: item.chassisNumber,
          type: "text",
        },
      ];
    }
    if (item.plateNumber) {
      items = [
        ...items,
        { text: "Plate Number", value: item.plateNumber, type: "text" },
      ];
    }
    if (item.emirate) {
      items = [
        ...items,
        { text: "Emirate", value: item.emirate, type: "text" },
      ];
    }
    if (item.dateOfPurchase) {
      items = [
        ...items,
        {
          text: "Date Of Purchase",
          value: item.dateOfPurchase,
          type: "text",
        },
      ];
    }
    if (item.purchaseFrom) {
      items = [
        ...items,
        {
          text: "Purchase From",
          value: item.purchaseFrom,
          type: "text",
        },
      ];
    }

    return items;
  };
  return (
    <Card className="item mb-2">
      <Card.Header className="ps-1 pe-2 py-0">
        <Card.Title className="d-flex m-0 p-0">
          <div className="position-relative ms-1">
            {/* <Image
                        src={memberDummyImage}
                        width="45px"
                        className="border rounded-circle"
                    /> */}

            {/* {item.hasConflict === "1" || item.hasConflict === 1 ? (
              <div
                className=" position-absolute bg-danger text-white statusIcon profile-subimage"
                title="Car has conflict"
              >
                <i className="fas fa-question icon-small"></i>
              </div>
            ) : null} */}
          </div>

          <div className="d-flex flex-column ms-1">
            <span className="m-0 text-bold d-flex align-items-center memberName">
              {item.model === "Other" ? item.otherModel : item.model}{" "}
              {item.model === "Other" && "(Other)"}
              {item.model === "Other" && item.otherModel && (
                <ButtonToolbar style={{ padding: "0px 0" }}>
                  <OverlayTrigger
                    trigger="click"
                    placement="right"
                    overlay={popoverHoverFocus}
                    show={show}
                    onToggle={handleToggle}
                  >
                    <Button className="invisibleButton" title="Add new value">
                      <FaQuestion className="text-info font-small" />
                    </Button>
                  </OverlayTrigger>
                </ButtonToolbar>
                // <FaQuestion className=" ms-2 text-danger " />
              )}
              <span>
                {" "}
                {item.hasConflict === "1" || item.hasConflict === 1 ? (
                  <div
                    className="bg-danger text-white ms-2  statusIcon profile-subimage"
                    title="Car has conflict"
                  >
                    <i className="fas fa-question ms-1 icon-small"></i>
                  </div>
                ) : null}
              </span>
            </span>

            <span className="font-italic font-small font-weight-normal text-success fs-8  ">
              <span className="membershipId">({item.chassisNumber})</span>
            </span>
          </div>
        </Card.Title>

        <div class="card-tools mt-1">
          <div className="carTools">
            {item.createdAt && (
              <span class="badge badge-light me-1 text-dark">
                Created Date :{" "}
                <span class="text-secondary">
                  <ModuleDisplayDateTime value={item.createdAt} />{" "}
                </span>
              </span>
            )}
            <span class="badge badge-white me-1 text-dark">
              Status:{" "}
              {item.status === "1" || item.status === 1 ? (
                <Badge bg="success">Active</Badge>
              ) : (
                <Badge bg="warning" text="dark">
                  Archived {item.archivedBy && <>({item.archivedBy})</>}
                </Badge>
              )}
            </span>
          </div>
        </div>
      </Card.Header>
      <Card.Body className="p-0">
        <Row className="d-flex align-items-center justify-content-between">
          <Col xl={8} lg={8} md={8} xs={12}>
            <StatsListing items={getItems()} />
          </Col>

          {item.registrationCard && (
            <Col lg={2} xl={2} md={2}>
              <Card className="align-items-center d-flex py-2 mb-0  px-3 justify-content-center">
                <div className="registrationCard">
                  <ReactFancyBox
                    thumbnail={`${serverUploadsUrl}${item.registrationCard}`}
                    image={`${serverUploadsUrl}${item.registrationCard}`}
                  />
                </div>
              </Card>
            </Col>
          )}

          {item.registrationCardBack && (
            <Col lg={2} xl={2} md={2}>
              <Card className="align-items-center d-flex py-2 mb-0  px-3 justify-content-center">
                <div className="registrationCard">
                  <ReactFancyBox
                    thumbnail={`${serverUploadsUrl}${item.registrationCardBack}`}
                    image={`${serverUploadsUrl}${item.registrationCardBack}`}
                  />
                </div>
              </Card>
            </Col>
          )}
        </Row>

        {(item.hasConflict === "1" || item.hasConflict === 1) && (
          <Alert variant="danger mb-0 p-1">
            <span className="text-bold">Conflict Reason : </span>
            {item.conflictReason}
          </Alert>
        )}
      </Card.Body>
    </Card>
  );
};

export default Details;
