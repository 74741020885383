import React, { useReducer, useEffect, useContext } from "react";
import moduleConfig from "./moduleConfig"
import { Card, Row, Col } from 'react-bootstrap';
import { Formik, Form, Field, ErrorMessage } from "formik"

import * as yup from "yup"
import { useNavigate } from "react-router-dom";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import axios from "../../../config/axios";
import { createReducer, createInitialState } from "./reducers/createReducer"
import { BreadcrumbContext } from '../../../contexts/BreadcrumbContext';
import { Store as toast } from 'react-notifications-component';
import { toastNotification } from '../../../config/toastNotification'
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import ModuleCreateView from "../../UI/modules/views/ModuleCreateView";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSFormController from "../../UI/forms/CMSFormController";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import CMSRichTextEditor from "../../UI/forms/CMSRichTextEditor";
import CMSFormDate from "../../UI/forms/CMSFormDate";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";


let breadcrumbs = {
    listing: {
        label: moduleConfig.title,
        url: `/${moduleConfig.url}`
    },
    create: {
        label: "Create",
    }
};


const NewsFeedCreate = () => {

    const [createState, createDispatch] = useReducer(createReducer, createInitialState)
    const { setBreadcrumb } = useContext(BreadcrumbContext);
    let navigate = useNavigate();
    const onSubmit = async (data) => {
        createDispatch({
            type: "FORM_SUBMISSION_REQUEST"
        });


        try {
            const res = await axios.post(`${moduleConfig.url}`, data)//
            console.log(res);
            createDispatch({
                type: "FORM_SUBMISSION_SUCCESS",
                payload: res.data
            });

            navigate(`/${moduleConfig.url}`)
            toast.addNotification({
                ...toastNotification,
                title: "Success!",
                message: `${moduleConfig.singleItemTitle} created successfully.`,
                type: "success",
            });
        } catch (error) {

            console.log(error.response);
            const status = error.response ? error.response.status : 500;
            const errorMessage = (status === 400) ? error.response.data.message : error.message

            createDispatch({
                type: "FORM_SUBMISSION_FAILURE",
                payload: errorMessage
            });
            setTimeout(() => {
                createDispatch({
                    type: "FORM_INITIAL_STATE"
                });
            }, 5000)
        }
    }

    // const loadData = async () => {
    //     try {
    //         const res = await axios.get(`members/create-data`)//
    //         createDispatch({
    //             type: "FETCH_SUCCESS",
    //             payload: res.data
    //         });
    //     } catch (error) {
    //         createDispatch({
    //             type: "FETCH_FAILURE",
    //             payload: "Date loading failed."
    //         });
    //     }
    // }
    useEffect(() => {
        ///  loadData()
        setBreadcrumb([
            breadcrumbs.listing,
            breadcrumbs.create
        ])

        createDispatch({
            type: "FETCH_SUCCESS",
            payload: []
        });
    }, [])
    return (<DashboardLayout >


        {
            (createState.status === 'fetchFailed')
                ? <BlockErrorMessage error={createState.error} />
                : <>
                    {/* {console.log(createState.data)} */}
                    {(createState.status !== 'fetching') && <>
                        <ModuleHeader
                            moduleTitle={`Create ${moduleConfig.singleItemTitle}`}
                            moduleUrl={moduleConfig.url}
                        >
                        </ModuleHeader>

                        <section className="content">
                            <Card>
                                <Card.Body>
                                    {(createState.isLoading) && <div class="overlay position-absolute containerLoading">
                                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                                    </div>}
                                    <Formik
                                        initialValues={{
                                            title: "",
                                            date: "",
                                            shortDescription: "",
                                            description: "",
                                            type: "All",
                                            target: null,
                                            status: "",
                                        }
                                        }
                                        validationSchema={yup.object({
                                            title: yup.string().required("Required").max(100),
                                            date: yup.string().required("Required"),
                                            shortDescription: yup.string().required("Required").max(200),
                                            description: yup.string(),
                                            type: yup.string().required(),
                                            //target: yup.string().required("Required"),
                                            target: yup.string().nullable().when("type", {
                                                is: (type) => type === "Private",
                                                then: yup.string().required("Required"),
                                            }),
                                            status: yup.string().required("Required"),
                                        })}
                                        enableReinitialize={true}
                                        validateOnChange={true}
                                        validateOnBlur={true}
                                        onSubmit={async (values) => {

                                            onSubmit(values)
                                        }}
                                    >

                                        {(form) => (
                                            <Form onSubmit={form.handleSubmit}>

                                                {(createState.error) &&
                                                    <FormErrorMessage error={createState.error} />
                                                }

                                                {/* {console.log(form.errors)} */}
                                                <CMSFormInput name="title" label="Title" required={true} form={form} />
                                                <CMSFormDate name="date" label="Date" required={true} form={form} />

                                                <CMSFormInput name="shortDescription" label="Short Description" required={true} form={form} />
                                                <CMSRichTextEditor name="description" label="Description" form={form} />

                                                <CMSFormSelect
                                                    name="type"
                                                    label="Type"
                                                    required={true}
                                                    form={form}
                                                    options={[
                                                        { value: "All", label: "All" },
                                                        {
                                                            value: "Private",
                                                            label: "Private",
                                                        },
                                                        {
                                                            value: "Public",
                                                            label: "Public",
                                                        },
                                                    ]}
                                                    onChange={(e) => {
                                                        form.setFieldValue('type', e.target.value)
                                                        if (e.target.value && (e.target.value === "All" || e.target.value === "Public")) {
                                                            form.setFieldValue('target', null)
                                                        } else {
                                                            if (form.values['target'] === null) {
                                                                form.setFieldValue('target', 'All-Members')
                                                            }
                                                        }
                                                    }}
                                                />


                                                {(form.values['type'] === 'Private') && (
                                                    <CMSFormSelect
                                                        name="target"
                                                        label="Target"
                                                        required={(form.values['type'] === 'All' || form.values['type'] === 'Private')}
                                                        form={form}
                                                        options={[
                                                            { value: "All-Members", label: "All-Members" },
                                                            {
                                                                value: "Selected-Members",
                                                                label: "Selected-Members",
                                                            },
                                                            {
                                                                value: "Selected-Circles",
                                                                label: "Selected-Circles",
                                                            },
                                                        ]}
                                                    />
                                                )}




                                                <CMSFormSelect name="status" label="Status" required={true} form={form} options={[{ label: "Select Status...", value: "" }, { label: "Enabled", value: "1" }, { label: "Disabled", value: "0" }]} />










                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </Form>
                                        )}
                                    </Formik>




                                </Card.Body>
                            </Card>
                        </section>
                    </>}



                </>
        }





    </DashboardLayout >);
}

export default NewsFeedCreate;
