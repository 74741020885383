import React, { useReducer, useEffect, useState } from "react";
import { Field, Form, Formik, useFormikContext } from "formik";
import * as yup from "yup";
import {
  Alert,
  Card,
  Col,
  Row,
  Table,
  Badge,
  Button,
  Modal,
} from "react-bootstrap";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../../../config/toastNotification";
import { ContainerLoading } from "../../../../UI/loading/ContainerLoading";
import BlockSuccessMessage from "../../../../UI/errorMessages/BlockSuccessMessage";
import FormErrorMessage from "../../../../UI/errorMessages/FormErrorMessage";
import CMSFormInput from "../../../../UI/forms/CMSFormInput";
import { Multiselect } from "multiselect-react-dropdown";
import moduleConfig from "../../moduleConfig";
import axios from "../../../../../config/axios";
import {
  addGalleryInitialState,
  addGalleryItemReducer,
} from "../../reducers/addGalleryItemReducer";
import BlockErrorMessage from "../../../../UI/errorMessages/BlockErrorMessage";
import CMSFormImageWithCrop from "../../../../UI/forms/CMSFormImageWithCrop";
import CMSFormSelect from "../../../../UI/forms/CMSFormSelect";
import CMSFormVideoInput from "../../../../UI/forms/CMSFormVideoInput";

const AddGalleryPicture = ({ show, setShow, albumId, loadItem }) => {
  const [state, dispatch] = useReducer(
    addGalleryItemReducer,
    addGalleryInitialState
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    dispatch({
      type: "FETCH_SUCCESS",
      payload: {},
    });
  }, [show]);

  const onSubmit = async (data, resetForm) => {
    dispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });

    try {
      const res = await axios.post(
        `gallery-pictures/add-picture/${albumId}`,
        data
      ); //

      resetForm();
      handleClose();
      dispatch({
        type: "FORM_INITIAL_STAGE",
      });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Picture added successfully.`,
        type: "success",
      });
      loadItem();
    } catch (error) {
      dispatch({
        type: "FORM_SUBMISSION_FAILED",
        payload: error,
      });
      setTimeout(() => {
        dispatch({
          type: "FORM_INITIAL_STAGE",
        });
      }, 5000);
    }
  };

  let imageRequirement = {
    dimension: {
      width: 600,
      height: 600,
    },
    allowedExtensions: ["image/png", "image/jpg", "image/jpeg"],
    size: 1024 * 1024 * 2,
  };
  return (
    <Formik
      initialValues={{
        type: "image",
        title: "",
        file: "",
        // image: "",
        // video: "",
        status: "1",
      }}
      validationSchema={yup.object({
        type: yup.string().required("Required"),
        title: yup.string().required("Required"),
        file: yup.mixed().when('type', {
          is: 'image',
          then: yup
            .mixed()
            .required("Please upload image.")
            .imageTypeCheck(imageRequirement.allowedExtensions)
            .imageSizeCheck(imageRequirement.size)
            .imageDimensionCheck(imageRequirement.dimension),
          otherwise: yup
            .mixed()
            .required("Please upload a video.")
            .test(
              "mime",
              `Please upload a video file in "mp4", "mov", or "avi" format only.`,
              (value) => {
                // Check for video MIME types
                return value
                  ? value.type === "video/mp4" ||
                    value.type === "video/quicktime" ||
                    value.type === "video/x-msvideo"
                    ? true
                    : false
                  : true;
              }
            )
            .test(
              "duration",
              "Video length should not exceed 30 seconds.",
              async (value) => {
                const url = URL.createObjectURL(value);
                // Check if the video duration is less than or equal to 30 seconds
                const videoElement = document.createElement('video');
                videoElement.src = url;
                await new Promise(resolve => {
                  videoElement.onloadedmetadata = () => resolve();
                });
                const duration = Math.round(videoElement.duration);
                console.log(duration)
                return duration <= 30 ? true : false; // Duration in milliseconds
              }
            )
            .test(
              "size",
              "Uploaded video file is too big (20MB maximum are allowed).",
              (value) => {
                // Adjust the maximum file size for videos
                return value ? (value.size < 1024 * 1024 * 20 ? true : false) : true;
              }
            ),

        }),
        // image: yup.mixed().when('type', {
        //   is: 'image',
        //   then: yup
        //     .mixed()
        //     .required("Please upload image.")
        //     .imageTypeCheck(imageRequirement.allowedExtensions)
        //     .imageSizeCheck(imageRequirement.size)
        //     .imageDimensionCheck(imageRequirement.dimension),
        // }),
        // video: yup.mixed().when('type', {
        //   is: 'video',
        //   then: yup
        //     .mixed()
        //     .required("Please upload video.")
        // }),



        //video: yup.mixed(),
        status: yup.string().required("Required"),
      })}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={false}
      onSubmit={async (values, { resetForm }) => {
        //console.log(values);
        ///onSubmit(values, resetForm)

        if (values['type'] == 'video') {
          values['image'] = ''
        } else {
          values['video'] = ''
        }


        const formData = new FormData();
        for (let name in values) {
          if (Array.isArray(values[name])) {
            formData.append(name, JSON.stringify(values[name]));
          } else {
            formData.append(name, values[name]);
          }
        }
        //console.log(formData);
        onSubmit(formData, resetForm);
      }}
    >
      {(form) => (
        <Modal show={show} onHide={handleClose} size="lg">
          {/* {console.log(form.errors)} */}
          {state.isLoading && (
            <div class="overlay ">
              <i class="fas fa-2x fa-sync-alt text-white fa-spin"></i>
            </div>
          )}

          {state.error && state.status === "fetchFailed" ? (
            <BlockErrorMessage error={state.error} />
          ) : (
            <>
              <Form onSubmit={form.handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>Add Picture in Gallery </Modal.Title>
                </Modal.Header>


                {state.status === "submitted" ? (
                  <Modal.Body>
                    <BlockSuccessMessage
                      heading="Done!"
                      message={`Picture added to gallery successfully.`}
                    />
                  </Modal.Body>
                ) : (
                  <>
                    <Modal.Body>



                      {state.error && <FormErrorMessage error={state.error} />}

                      <CMSFormInput
                        name="title"
                        label="Title"
                        form={form}
                        required={true}
                      />

                      <CMSFormSelect
                        name="type"
                        label="Type"
                        form={form}
                        required={true}
                        showEmptyOption={false}
                        options={[
                          { label: "Image", value: "image" },
                          { label: "Video", value: "video" },
                        ]}
                      />


                      {(form.values.type === 'image') && <>
                        <CMSFormImageWithCrop
                          label="Image"
                          name="file"
                          required={true}
                          form={form}
                          aspect={1 / 1}
                          outputSize={imageRequirement.dimension}
                        />
                      </>}

                      {(form.values.type === 'video') && <>
                        <CMSFormVideoInput
                          label="Video"
                          name="file"
                          required={true}
                          form={form}
                        // aspect={1 / 1}
                        // outputSize={imageRequirement.dimension}
                        />

                      </>}
                      <CMSFormSelect
                        name="status"
                        label="Status"
                        required={true}
                        form={form}
                        options={[

                          { value: "1", label: "Active" },
                          {
                            value: "0",
                            label: "Archived",
                          },
                        ]}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button variant="primary" type="submit">
                        Submit
                      </Button>
                    </Modal.Footer>
                  </>
                )}
              </Form>
            </>
          )}
        </Modal>
      )}
    </Formik>
  );
};

export default AddGalleryPicture;
