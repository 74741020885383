import React, { useEffect, useContext, useReducer } from "react";
import { Accordion } from "react-bootstrap";
import DashboardLayout from "../../UI/layouts/DashboardLayout";

import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import {
  settingReducer,
  settingInitialState,
} from "../../../reducers/settingReducer";
import axios from "../../../config/axios";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";

import { Alert } from "react-bootstrap";
import { Form, Formik } from "formik";

import { upperCaseFirst } from "../../../helpers/CommonHelper";
import { Store as toast } from "react-notifications-component";
import { toastNotification } from "../../../config/toastNotification";
import * as Yup from "yup";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSFormController from "../../UI/forms/CMSFormController";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";

const SettingsListing = () => {
  const { setBreadcrumb } = useContext(BreadcrumbContext);
  const [settingState, settingDispatch] = useReducer(
    settingReducer,
    settingInitialState
  );

  const onSubmit = async (data) => {
    settingDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    try {
      const res = await axios.post(`settings`, data); //
      settingDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `Settings updated successfully.`,
        type: "success",
      });
    } catch (error) {
      //console.log(error.message);
      settingDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: error.message,
      });
      setTimeout(() => {
        settingDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  const getInitialValues = (group) => {
    let initialValues = {};
    if (group) {
      Object.entries(group).map(([key, items]) => {
        initialValues["category"] = key;
        if (items && items.length > 0) {
          items.map(({ name, value }) => {
            initialValues[name] = value;
          });
        }
      });
    }
    return initialValues;
  };

  const getValidationSchema = (group) => {
    const validationSchema = {};
    if (group) {
      Object.entries(group).map(([key, items]) => {
        if (items && items.length > 0) {
          items.map(({ name, value_type, is_required }, index) => {
            let validator = Yup["string"]();
            if (is_required === "Yes") {
              validator = validator["required"]([
                upperCaseFirst(name.replace("_", " ")) + " is required.",
              ]);
            }
            validationSchema[name] = validator;
          });
        }
      });
    }
    return Yup.object().shape(validationSchema);
  };

  const loadItems = async () => {
    settingDispatch({
      type: "FETCH_REQUEST",
    });
    try {
      const res = await axios.get(`settings`); //
      settingDispatch({
        type: "FETCH_SUCCESS",
        payload: res.data,
      });
    } catch (error) {
      settingDispatch({
        type: "FETCH_FAILURE",
        payload: error,
      });
    }
  };

  useEffect(() => {
    loadItems();
    setBreadcrumb([
      {
        label: "Settings",
        url: `/settings`,
      },
    ]);
  }, []);
  return (
    <DashboardLayout>
      {settingState.isFetching ? (
        <ContainerLoading />
      ) : (
        <>
          {settingState.hasFetchingError ? (
            <BlockErrorMessage error={settingState.error} />
          ) : (
            <>
              <ModuleHeader
                moduleTitle="Settings"
                moduleUrl="settings"
              ></ModuleHeader>

              {settingState.hasSubmissionError && (
                <FormErrorMessage error={settingState.error} />
              )}

              {settingState.data &&
              settingState.data.items &&
              settingState.data.items.length > 0 ? (
                <>
                  <Accordion defaultActiveKey="0">
                    {settingState.isSubmitting && (
                      <ContainerLoading/>
                    )}

                    {settingState.data.items.map((group, index) => (
                      <Accordion.Item eventKey={index + ""} key={index}>
                        {Object.entries(group).map(([key, items]) => {
                          return (
                            <React.Fragment key={key}>
                              <Accordion.Header>
                                <b> {key}</b>
                              </Accordion.Header>
                              <Accordion.Body className="p-1 px-2">
                                <Formik
                                  initialValues={getInitialValues(group)}
                                  validationSchema={getValidationSchema(group)}
                                  enableReinitialize={true}
                                  validateOnChange={true}
                                  validateOnBlur={false}
                                  onSubmit={async (values) => {
                                    console.log(values);
                                    // const formData = new FormData();
                                    // for (let name in values) {
                                    //     if (Array.isArray(values[name])) {
                                    //         formData.append(name, JSON.stringify(values[name]));
                                    //     } else {
                                    //         formData.append(name, values[name]);
                                    //     }
                                    // }

                                    onSubmit(values);
                                  }}
                                >
                                  {(form) => (
                                    <Form onSubmit={form.handleSubmit}>
                                      {items && items.length > 0 && (
                                        <>
                                          {console.log(items)}
                                          {items.map(
                                            (
                                              {
                                                name,
                                                value,
                                                value_type,
                                                is_required,
                                                description,
                                                category,
                                              },
                                              index
                                            ) => {
                                              return (
                                                <CMSFormController
                                                  key={index}
                                                  control="input"
                                                  type={value_type}
                                                  label={upperCaseFirst(
                                                    name.replace("_", " ")
                                                  )}
                                                  name={name}
                                                  helpMessage={
                                                    description
                                                      ? { message: description }
                                                      : null
                                                  }
                                                  form={form}
                                                />
                                              );
                                            }
                                          )}
                                        </>
                                      )}

                                      <button
                                        className="btn btn-primary"
                                        type="submit"
                                      >
                                        Submit
                                      </button>
                                    </Form>
                                  )}
                                </Formik>
                              </Accordion.Body>
                            </React.Fragment>
                          );
                        })}
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </>
              ) : (
                <Alert variant="warning">Settings Not Found.</Alert>
              )}
            </>
          )}
        </>
      )}
    </DashboardLayout>
  );
};

export default SettingsListing;
