import React from 'react'
import { moduleDefaultConfig } from "../../../config/moduleDefaultConfig"
import * as yup from "yup"
let moduleConfig = {}
moduleConfig = {
    ...moduleDefaultConfig
};

moduleConfig.title = 'Nationalities';
moduleConfig.singleItemTitle = 'Nationality';
moduleConfig.url = 'nationalities';

moduleConfig.fields = [
    {
        displayName: "Name",
        fieldName: "name",
        type: "text",
        control: "input",
        showOnListing: true,
        sortable: true,
    },
    {
        displayName: "Status",
        fieldName: "status",
        control: "select",
        options: [
            { label: "Enabled", value: 1 },
            { label: "Disabled", value: 0 },
        ],
        type: "boolean",
        showOnListing: true,
        sortable: true,
    },
    {
        displayName: "Created Date/Time",
        fieldName: "createdAt",
        type: "dateTime",
    },
    {
        displayName: "Updated Date/Time",
        fieldName: "updatedAt",
        type: "dateTime",
    }
];
moduleConfig.initialValues = {
    name: "",
    status: 1
}
moduleConfig.validationSchema = yup.object({
    status: yup.number().required("Required"),
    name: yup.string().required("Required").max(100),
})

export default moduleConfig;