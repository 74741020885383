import React, { useState, useContext, useEffect, useReducer } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Row, Col } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import moduleConfig from "./moduleConfig";
import { Store as toast } from "react-notifications-component";
import { updateInitialState, updateReducer } from "./reducers/updateReducer";
import { BreadcrumbContext } from "../../../contexts/BreadcrumbContext";
import axios from "../../../config/axios";
import { toastNotification } from "../../../config/toastNotification";
import DashboardLayout from "../../UI/layouts/DashboardLayout";
import { ContainerLoading } from "../../UI/loading/ContainerLoading";
import BlockErrorMessage from "../../UI/errorMessages/BlockErrorMessage";
import ModuleHeader from "../../UI/modules/views/partials/common/ModuleHeader";
import { isEmpty } from "../../../helpers/CommonHelper";
import FormErrorMessage from "../../UI/errorMessages/FormErrorMessage";
import CMSFormInput from "../../UI/forms/CMSFormInput";
import CMSFormSelect from "../../UI/forms/CMSFormSelect";

const CircleUpdate = () => {
  const [updateState, updateDispatch] = useReducer(
    updateReducer,
    updateInitialState
  );
  const [formInitialValues, setFormInitialValues] = useState({});

  console.log(updateState);

  const { setBreadcrumb } = useContext(BreadcrumbContext);
  let { id } = useParams();
  let navigate = useNavigate();

  let breadcrumbs = {
    listing: {
      label: moduleConfig.title,
      url: `/${moduleConfig.url}`,
    },
    details: {
      label: "Details",
      url: `/${moduleConfig.url}/${id}`,
    },
    update: {
      label: "Update",
    },
  };

  const onSubmit = async (data) => {
    //console.log(data);

    updateDispatch({
      type: "FORM_SUBMISSION_REQUEST",
    });
    try {
      const res = await axios.post(`${moduleConfig.url}/${id}`, data); //
      updateDispatch({
        type: "FORM_SUBMISSION_SUCCESS",
        payload: res.data,
      });
      navigate(`/${moduleConfig.url}`);
      toast.addNotification({
        ...toastNotification,
        title: "Success!",
        message: `${moduleConfig.singleItemTitle} updated successfully.`,
        type: "success",
      });
    } catch (error) {
      //console.log(error.message);
      const status = error.response ? error.response.status : 500;
      const errorMessage =
        status === 400 ? error.response.data.errorMessage : error.message;
      console.log(errorMessage);

      updateDispatch({
        type: "FORM_SUBMISSION_FAILURE",
        payload: errorMessage,
      });
      setTimeout(() => {
        updateDispatch({
          type: "FORM_INITIAL_STATE",
        });
      }, 5000);
    }
  };

  const loadItem = async () => {
    if (id) {
      updateDispatch({
        type: "FETCH_REQUEST",
      });

      try {
        const res = await axios.get(`${moduleConfig.url}/${id}`); //
        updateDispatch({
          type: "FETCH_SUCCESS",
          payload: res.data,
        });
      } catch (error) {
        updateDispatch({
          type: "FETCH_FAILURE",
          payload: error,
        });
      }
    }
  };
  useEffect(() => {
    //console.log("useEffect called for [id]")
    loadItem();

    setBreadcrumb([
      breadcrumbs.listing,
      breadcrumbs.details,
      breadcrumbs.update,
    ]);
  }, [id]);
  useEffect(() => {
    if (updateState.data.item && updateState.data.item._id) {
      //if (moduleConfig.fields && moduleConfig.fields.length > 0) {

      // let formFieldsWithFetchData = {}
      // moduleConfig.fields.map((field, index) => {

      //     formFieldsWithFetchData[field.fieldName] = updateState.data.item[field.fieldName]
      // })
      // console.log("Here =>>>>>>>>>>>>>>", formFieldsWithFetchData)
      setFormInitialValues(updateState.data.item);

      //};
    }
  }, [updateState.data]);
  return (
    <DashboardLayout>
      {updateState.status === "fetching" && updateState.isLoading && (
        <ContainerLoading />
      )}

      {updateState.error && updateState.status === "fetchFailed" ? (
        <BlockErrorMessage error={updateState.error} />
      ) : (
        <>
          <ModuleHeader
            moduleTitle={`Update ${moduleConfig.singleItemTitle}`}
            moduleUrl={moduleConfig.url}
          ></ModuleHeader>

          {Object.keys(formInitialValues).length > 0 &&
            updateState.data &&
            updateState.data.item &&
            !isEmpty(updateState.data.item._id) && (
              <section className="content">
                <Card>
                  <Card.Body>
                    {updateState.isLoading && (
                      <div class="overlay position-absolute containerLoading">
                        <i class="fas fa-2x fa-sync-alt fa-spin"></i>
                      </div>
                    )}
                    <Formik
                      initialValues={formInitialValues}
                      validationSchema={yup.object({
                        name: yup.string().required("Required"),
                        color: yup.string().required("Required"),
                        status: yup.string().required("Required"),
                      })}
                      enableReinitialize={true}
                      validateOnChange={true}
                      validateOnBlur={false}
                      onSubmit={async (values) => {
                        onSubmit(values);
                      }}
                    >
                      {(form) => (
                        <Form onSubmit={form.handleSubmit}>
                          {/* {console.log(form)} */}
                          {updateState.error && (
                            <FormErrorMessage error={updateState.error} />
                          )}

                          <Row>
                            <Col xs={12} s={12} md={6} lg={6}>
                              <CMSFormInput
                                name="name"
                                label="Name"
                                form={form}
                                required={true}
                              />
                            </Col>
                            <Col xs={12} s={12} md={6} lg={6}></Col>
                          </Row>

                          <Row>
                            <Col xs={12} s={12} md={6} lg={6}>
                            <CMSFormSelect
                                name="color"
                                label="Color"
                                required={true}
                                form={form}
                                options={[
                                  {
                                    value: "Blue",
                                    label: "Blue",
                                  },
                                  { value: "Green", label: "Green" },
                                  {
                                    value: "Navy",
                                    label: "Navy",
                                  },
                                  {
                                    value: "Orange",
                                    label: "Orange",
                                  },
                                  { value: "Pink", label: "Pink" },
                                  {
                                    value: "Purple",
                                    label: "Purple",
                                  },
                                  {
                                    value: "Red",
                                    label: "Red",
                                  },
                                  { value: "Teal", label: "Teal" },
                                  {
                                    value: "Tomato",
                                    label: "Tomato",
                                  },
                                  {
                                    value: "Orange",
                                    label: "Orange",
                                  },
                                  { value: "Violet", label: "Violet" },
                                  {
                                    value: "Yellow",
                                    label: "Yellow",
                                  },

                                ]}
                              />
                            </Col>
                            <Col xs={12} s={12} md={6} lg={6}></Col>
                          </Row>

                          <button className="btn btn-primary" type="submit">
                            Submit
                          </button>
                        </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </section>
            )}
        </>
      )}
    </DashboardLayout>
  );
};
export default CircleUpdate;
