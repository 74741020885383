import React from 'react'

const ModuleDisplayRichText = ({ value }) => {
    return (
        <div>
            {<div dangerouslySetInnerHTML={{ __html: value }} />}
        </div>
    )
}

export default ModuleDisplayRichText