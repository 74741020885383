import React from "react";
import { Card } from "react-bootstrap";
import { Formik, Form } from "formik";

import CMSFormController from "../../forms/CMSFormController";
import FormErrorMessage from "../../errorMessages/FormErrorMessage";

const ModuleCreateView = ({
  submitting,
  fields,
  initialValues,
  validationSchema,
  onSubmit,
  formSubmissionError,
}) => {
  return (
    <section className="content">
      <Card>
        <Card.Body>
          {submitting && (
            <div class="overlay position-absolute containerLoading">
              <i class="fas fa-2x fa-sync-alt fa-spin"></i>
            </div>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={async (values) => {
             
              const formData = new FormData();
              for (let name in values) {

                if (Array.isArray(values[name])) {
                  formData.append(name, JSON.stringify(values[name]));
                } else {
                  formData.append(name, values[name]);
                }
              }

              //console.log("formData in Create View==> ", formData)

              // if (formData.image) {
              //   formData.image = formData.image === "null" ? null : formData.image;
              // }

              onSubmit(formData);
            }}
          >
            {(form) => (
              <Form onSubmit={form.handleSubmit}>
               

                {formSubmissionError && (
                  <FormErrorMessage error={formSubmissionError} />
                )}

                {fields && fields.length > 0 && (
                  <>
                    {fields.map(
                      ({ displayName, fieldName, ...rest }, index) => {
                        return (
                          <CMSFormController
                            key={index}
                            form={form}
                            label={displayName}
                            name={fieldName}
                            {...rest}
                          />
                        );
                      }
                    )}
                  </>
                )}
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </Form>
            )}
          </Formik>
        </Card.Body>
      </Card>
    </section>
  );
};

export default ModuleCreateView;
