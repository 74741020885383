import React from "react"
import { Field, ErrorMessage } from "formik"
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
const CMSFormRadioButtons = (props) => {
    const { label, name, options, form,required, ...rest } = props

    return (<div>
             {
                (label !== "") &&
                <>
                    {
                        (required === true)
                            ? <OverlayTrigger
                                key={`tooltip-${name}`}
                                placement='right'
                                overlay={
                                    <Tooltip id={`tooltip-right`}>
                                        Required field.
                                    </Tooltip>
                                }
                            ><label htmlFor={name} className={`required ' ${(form.touched[name] && form.errors[name]) ? "is-invalidText" : ""}`}> {label}</label></OverlayTrigger>
                            : <label htmlFor={name} className={` ${(form.touched[name] && form.errors[name]) ? "is-invalidText" : ""}`}> {label}</label>
                    }
                </>
            }
        {(label !== "") && <label htmlFor={name} className={` ${(form.touched[name] && form.errors[name]) ? "is-invalidText required" : "required"}`}> {label}</label>}

        <div className={` ${(form.touched[name] && form.errors[name]) ? "is-invalid" : ""}`}>
            {
                options.map(option => {

                    return (<div key={option.label}>
                        <Field
                            name={name}

                            type="radio"
                            id={option.value}


                            value={option.value}
                            checked={form.values[name] === option.value}
                        />
                        <label htmlFor={option.value} className={` ${(form.touched[name] && form.errors[name]) ? "is-invalidText ms-2" : "ms-2"}`}>{option.label}</label>
                    </div>
                    )
                })
            }
        </div>
        {(form.touched[name] && form.errors[name]) &&
            <span id={`${name}-error"`} className="error invalid-feedback"  >
                <ErrorMessage name={name} />
            </span>
        }

    </div>
    )

}

export default CMSFormRadioButtons