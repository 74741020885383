import React, { createContext } from 'react';
import { useReducer } from "react";
import { initialState, reducer } from "../reducers/confirmReducer";

export const ConfirmContext = createContext({});


const ConfirmContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <ConfirmContext.Provider value={[state, dispatch]}>
            {props.children}
        </ConfirmContext.Provider>
    );
};

export default ConfirmContextProvider;