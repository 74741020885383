import React from 'react';
import { Field, ErrorMessage } from "formik"
import { OverlayTrigger, Popover, Row, Tooltip } from 'react-bootstrap';

const CMSFormVideoInput = (props) => {
    const { name, label, form, required, helpMessage, height, width, ...rest } = props
    const [source, setSource] = React.useState();

    const handleFileChange = async (e) => {
        if (e.target && e.target.files[0]) {
            const file = e.target.files[0];
            const url = URL.createObjectURL(file);
            setSource(url);
            setTimeout(() => { form.setFieldValue(name, file, true) }, 1)
        } else {
            setSource(null);
            await form.setFieldValue(name, '', true)
            form.setFieldTouched(name, true);
        }
    };

    return (
        <div className="form-group ">
            {/* Created Useable Component */}
            {
                (label !== "") &&
                <>
                    {
                        (required === true)
                            ? <OverlayTrigger
                                key={`tooltip-${name}`}
                                placement='right'
                                overlay={
                                    <Tooltip id={`tooltip-right`}>
                                        Required field.
                                    </Tooltip>
                                }
                            ><label htmlFor={name} className={`required ' ${(form.touched[name] && form.errors[name]) ? "is-invalidText" : ""}`}> {label}</label></OverlayTrigger>
                            : <label htmlFor={name} className={` ${(form.touched[name] && form.errors[name]) ? "is-invalidText" : ""}`}> {label}</label>
                    }
                </>
            }

            {/* Created Useable Component */}
            {
                (helpMessage && helpMessage.message)
                && <OverlayTrigger
                    trigger='hover'
                    key={`info-popup-${name}`}
                    placement="right"
                    overlay={
                        <Popover id={`popover-positioned-${name}`}>

                            {
                                (helpMessage.heading) &&
                                <Popover.Header as="h3">{helpMessage.heading}</Popover.Header>

                            }


                            <Popover.Body>
                                {helpMessage.message}
                            </Popover.Body>
                        </Popover>
                    }
                ><i className="fa fa-info-circle ml-2 infoIcon"></i></OverlayTrigger>
            }


            <input
                name={name}
                type='file'
                accept=".mov,.mp4"
                className={`form-control ${(form.touched[name] && form.errors[name]) ? "is-invalid" : ""}`}

                // ref={inputRef}
                onChange={handleFileChange}
                onBlur={form.handleBlur}
            />

            {(form.touched[name] && form.errors[name]) &&
                <span id={`${name}-error"`} className="error invalid-feedback"  >
                    <ErrorMessage name={name} />
                </span>
            }


            {source && (
                <Row >
                    <div className="formImagePreview mt-3 ms-2">
                        <video
                            className="VideoInput_video"
                            width="100%"
                            height="150"
                            controls
                            src={source}
                        />
                    </div>
                </Row>
            )}
        </div>
    )
};

export default CMSFormVideoInput;
